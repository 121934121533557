import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 0C154.506 0 66.81 59.065 25.402 144.696h461.195C445.19 59.065 357.493 0 256 0M256 512c101.493 0 189.19-59.065 230.598-144.696H25.402C66.81 452.935 154.506 512 256 512"
        fill="#d80027"
      />
      <Path
        d="M322.783 300.522 256 178.087l-66.783 122.435h50.087v33.391h33.392v-33.391z"
        fill="#6da544"
      />
    </Svg>
  );
};
Icon.displayName = 'Lebanon';
export const Lebanon = memo<IconProps>(Icon);
