import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#fcfcfc" />
      <Path
        d="m105.739 237.449-27.826-50.483 27.826-49.691 27.826 49.691zM50.087 237.449l-27.826-50.483 27.826-49.691 27.826 49.691zM105.739 374.725l-27.826-50.483 27.826-49.691 27.826 49.691zM50.087 374.725l-27.826-50.483 27.826-49.691 27.826 49.691zM133.565 49.692l-7.878-14.068a256.7 256.7 0 0 0-39.594 28.909l19.646 35.641zM105.739 411.826l-19.854 35.456a256.7 256.7 0 0 0 39.568 28.955l8.113-14.719z"
        fill="#a2001d"
      />
      <Path
        d="M155.826 322.783v168.863C186.595 504.743 220.448 512 256 512c110.071 0 203.906-69.472 240.077-166.957z"
        fill="#6da544"
      />
      <Path
        d="M496.077 345.043C506.368 317.31 512 287.314 512 256 512 114.616 397.384 0 256 0c-35.549 0-69.405 7.253-100.174 20.348v324.696h340.251z"
        fill="#a2001d"
      />
    </Svg>
  );
};
Icon.displayName = 'Belarus';
export const Belarus = memo<IconProps>(Icon);
