import * as React from 'react';
import { Svg, Path, Circle } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path d="M.001 44.522H0v422.956h.001V256.001z" fill="#ff9811" />
      <Circle cx={255.999} cy={256} r={255.999} fill="#f0f0f0" />
      <Path
        d="M256.001.002c-83.752 0-158.106 40.223-204.811 102.4h409.621C414.107 40.224 339.752.002 256.001.002M256.001 511.999c83.752 0 158.106-40.223 204.811-102.4H51.19c46.704 62.178 121.059 102.4 204.811 102.4M.001 256.001a257 257 0 0 0 5.127 51.2h501.747c3.359-16.543 5.126-33.665 5.126-51.2s-1.768-34.657-5.126-51.2H5.128a257 257 0 0 0-5.127 51.2"
        fill="#0052b4"
      />
      <Path
        d="M74.981 74.982c-99.973 99.973-99.973 262.064 0 362.039L256 256.002z"
        fill="#d80027"
      />
      <Path
        d="m103.611 189.219 16.574 51.015h53.646l-43.397 31.532 16.574 51.016-43.397-31.53-43.399 31.53 16.578-51.016-43.398-31.532h53.642z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Cuba';
export const Cuba = memo<IconProps>(Icon);
