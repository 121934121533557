import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M74.98 437.02c-99.974-99.975-99.974-262.065 0-362.04C74.973 74.991 222.609 256 222.609 256z"
        fill="#000"
      />
      <Path
        d="M222.609 256 33.953 128.513a255 255 0 0 0-15.439 31.763l95.509 95.725-95.506 95.73a255 255 0 0 0 15.426 31.741z"
        fill="#ffda44"
      />
      <Path
        d="M509.833 222.609H222.602L74.978 74.984a257 257 0 0 0-41.025 53.53L161.192 256 33.943 383.47a257 257 0 0 0 41.035 53.545l147.624-147.624h287.231A259 259 0 0 0 512 256c0-11.317-.744-22.461-2.167-33.391"
        fill="#6da544"
      />
      <Path
        d="M100.138 459.077C143.307 492.259 197.344 512 256 512c118.279 0 217.805-80.221 247.181-189.217H236.433z"
        fill="#0052b4"
      />
      <Path
        d="M503.181 189.217C473.805 80.221 374.279 0 256 0c-58.656 0-112.693 19.741-155.862 52.923l136.294 136.294z"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'SouthAfrica';
export const SouthAfrica = memo<IconProps>(Icon);
