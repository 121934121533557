import useCustomerSupportVisibility from '@/hooks/use-customer-support-visibility/use-customer-support-visibility';
import { PropsWithChildren } from 'react';
import { Intercom, LiveChatLoaderProvider } from 'react-live-chat-loader';

/**
 * Hack to hide/show the Unbounce bar when intercom is opened/closed
 */
export const onIntercomHide = () => {
  document
    .querySelector('.ub-emb-bar style:nth-child(2)')
    ?.removeAttribute('media');
  document
    .querySelector<HTMLElement>('.ub-emb-bar-frame')
    ?.style.setProperty('top', '0px');
};
export const onIntercomShow = () => {
  document
    .querySelector('.ub-emb-bar style:nth-child(2)')
    ?.setAttribute('media', 'max-width: 1px');
  document
    .querySelector<HTMLElement>('.ub-emb-bar-frame')
    ?.style.setProperty('top', '-54px');
};

export const INTERCOM_APP_ID =
  process.env.NEXT_PUBLIC_INTERCOM_APP_ID || 'itmrov8c';

export const IntercomProvider = ({ children }: PropsWithChildren<{}>) => {
  const { intercomShouldBeVisible, setIntercomLoaded } =
    useCustomerSupportVisibility();

  return (
    <LiveChatLoaderProvider
      provider="intercom"
      providerKey={INTERCOM_APP_ID}
      onReady={() => setIntercomLoaded(true)}
    >
      {children}
      {intercomShouldBeVisible && <Intercom color={'rgb(0, 40, 9)'} />}
    </LiveChatLoaderProvider>
  );
};
