import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M155.826 166.957h340.25C459.906 69.472 366.07 0 256 0 185.306 0 121.313 28.659 74.989 74.989z"
        fill="#6da544"
      />
      <Path
        d="M155.826 345.043h340.25C459.906 442.528 366.07 512 256 512c-70.694 0-134.687-28.659-181.011-74.989z"
        fill="#d80027"
      />
      <Path
        d="M74.98 74.98c-99.974 99.974-99.974 262.065 0 362.04L256 256z"
        fill="#0052b4"
      />
      <Path
        d="M300.522 211.478v55.652c0 34.08 44.522 44.523 44.522 44.523s44.522-10.443 44.522-44.523v-55.652z"
        fill="#dedde0"
      />
      <Path d="M333.913 257.113h22.261v32.278h-22.261z" fill="#786145" />
      <Path
        d="M367.304 244.87c0-12.295-9.966-22.261-22.261-22.261s-22.261 9.966-22.261 22.261c-6.147 0-11.13 4.983-11.13 11.13s4.983 11.13 11.13 11.13h44.522c6.147 0 11.13-4.983 11.13-11.13s-4.982-11.13-11.13-11.13"
        fill="#6da544"
      />
    </Svg>
  );
};
Icon.displayName = 'EquatorialGuinea';
export const EquatorialGuinea = memo<IconProps>(Icon);
