import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f9f9f9" />
      <Path
        d="M256 512c110.07 0 203.906-69.472 240.076-166.957H15.922C52.094 442.528 145.928 512 256 512M356.174 211.478v-33.391h11.13v-22.261h-22.261v11.131h-22.26v-11.131h-22.261v22.261h11.13v33.391h-22.261v-77.911h11.131v-22.26h-22.261v11.128H267.13v-11.128h-22.26v11.128h-11.131v-11.128h-22.261v22.26h11.131v77.911h-22.261v-33.391h11.13v-22.261h-22.261v11.131h-22.26v-11.131h-22.261v22.261h11.13v33.391h-22.261v89.044h244.87v-89.044z"
        fill="#d80027"
      />
      <Path
        d="M256 289.391c-18.441 0-33.391 14.95-33.391 33.391 0 14.536 9.294 26.9 22.261 31.484v46.43h-33.391v44.522h55.652v-90.951c12.967-4.584 22.261-16.947 22.261-31.484-.001-18.441-14.951-33.392-33.392-33.392m0 44.522c-6.147 0-11.13-4.984-11.13-11.13s4.983-11.13 11.13-11.13 11.13 4.983 11.13 11.13c0 6.146-4.983 11.13-11.13 11.13"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Gibraltar';
export const Gibraltar = memo<IconProps>(Icon);
