import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 0C185.307 0 121.307 28.654 74.98 74.98L256 256zM0 256c0 70.693 28.654 134.693 74.98 181.02L256 256zM256 512c70.693 0 134.693-28.654 181.02-74.98L256 256zM512 256c0-70.693-28.654-134.693-74.98-181.02L256 256z"
        fill="#000"
      />
      <Path
        d="M166.957 178.087 256 189.22l89.043-11.133v-53.426l-35.617 17.809L256 89.043l-53.426 53.427-35.617-17.809z"
        fill="#ffda44"
      />
      <Path
        d="M256 389.565c-49.099 0-89.043-39.945-89.043-89.043v-89.039l33.391-33.394h111.304l33.391 33.394v89.039c0 49.098-39.944 89.043-89.043 89.043"
        fill="#d80027"
      />
      <Path
        d="M256 356.174c-30.687 0-55.652-24.966-55.652-55.652V211.48h111.304v89.041c0 30.687-24.965 55.653-55.652 55.653"
        fill="#f0f0f0"
      />
      <Path
        d="M166.957 178.087h33.391v33.391h-33.391zM311.652 178.087h33.391v33.391h-33.391zM311.652 250.435h33.391v33.391h-33.391zM166.957 250.435h33.391v33.391h-33.391zM256 356.174c-5.816 0-11.423-.9-16.696-2.562v34.361c5.412 1.032 10.988 1.593 16.696 1.593s11.284-.561 16.696-1.593v-34.361A55.5 55.5 0 0 1 256 356.174M201.092 309.556l-26.648 26.648a89.4 89.4 0 0 0 19.314 27.908l23.6-23.6c-8.422-8.137-14.289-18.897-16.266-30.956M337.556 336.206l-26.648-26.648c-1.978 12.058-7.844 22.817-16.265 30.957l23.6 23.6a89.4 89.4 0 0 0 19.313-27.909"
        fill="#ffda44"
      />
      <Path
        d="M244.87 230.03v18.55c0 6.146 4.983 11.13 11.13 11.13s11.13-4.984 11.13-11.13v-18.55zM244.87 304.233v18.55c0 6.146 4.983 11.13 11.13 11.13s11.13-4.984 11.13-11.13v-18.55zM278.261 267.13v18.552c0 6.146 4.983 11.13 11.13 11.13s11.13-4.984 11.13-11.13V267.13zM244.87 267.13v18.552c0 6.146 4.983 11.13 11.13 11.13s11.13-4.984 11.13-11.13V267.13zM211.478 267.13v18.552c0 6.146 4.983 11.13 11.13 11.13s11.13-4.984 11.13-11.13V267.13z"
        fill="#0052b4"
      />
    </Svg>
  );
};
Icon.displayName = 'Ceuta';
export const Ceuta = memo<IconProps>(Icon);
