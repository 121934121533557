import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#338af3" />
      <Path
        d="M255.26 166.957c-49.179 0-89.043 39.866-89.043 89.043s39.865 89.043 89.043 89.043c49.176 0 89.043-39.866 89.043-89.043s-39.868-89.043-89.043-89.043M232.999 256c0-12.294 9.966-22.261 22.261-22.261s22.261 9.967 22.261 22.261c0 12.295-9.967 22.261-22.261 22.261-12.295 0-22.261-9.966-22.261-22.261m45.341-38.079a44.3 44.3 0 0 0-11.209-4.83v-22.8a66.4 66.4 0 0 1 27.245 11.595zm-33.469-5.211a44.2 44.2 0 0 0-11.749 4.669l-16.11-16.11a66.4 66.4 0 0 1 27.859-11.244zm-11.75 81.911a44.2 44.2 0 0 0 11.749 4.668v22.684c-10.248-1.601-19.714-5.542-27.858-11.244zm34.011 4.288a44.2 44.2 0 0 0 11.208-4.829l16.035 16.035a66.4 66.4 0 0 1-27.244 11.595v-22.8h.001zm26.748-20.769a44.2 44.2 0 0 0 4.499-11.009h22.722a66.4 66.4 0 0 1-11.11 27.117zm4.499-33.27a44.2 44.2 0 0 0-4.499-11.008l16.11-16.11a66.4 66.4 0 0 1 11.11 27.117h-22.722zm-97.234-27.985 16.035 16.035a44.3 44.3 0 0 0-5.04 11.95h-22.722a66.4 66.4 0 0 1 11.727-27.985m-11.727 50.245h22.722a44.2 44.2 0 0 0 5.04 11.95l-16.035 16.035a66.4 66.4 0 0 1-11.727-27.985"
        fill="#f0f0f0"
      />
      <Path
        d="M399.955 256c0-39.58-15.898-75.441-41.648-101.565l-23.611 23.611c19.708 20.08 31.868 47.596 31.868 77.954 0 61.472-49.833 111.304-111.304 111.304-61.473 0-111.304-49.832-111.304-111.304 0-30.737 12.457-58.563 32.6-78.704l-23.611-23.611c-26.185 26.185-42.38 62.359-42.38 102.315 0 66.215 44.477 122.042 105.186 139.235-6.656 12.644-5.642 28.563 3.866 40.428 12.488-10.008 25.417-20.37 36.385-29.158l36.385 29.158c9.602-11.983 10.547-28.104 3.67-40.809C356.102 377.239 399.955 321.745 399.955 256"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'UnitedNations';
export const UnitedNations = memo<IconProps>(Icon);
