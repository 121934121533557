import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#0052b4" />
      <Path
        d="M365.865 273.86 411.826 256l-45.961-17.859-25.245-9.809-41.015-15.937-15.937-41.015c26.874 8.809 48.141 30.076 56.951 56.951l25.245 9.809c-7.629-47.096-44.91-84.378-92.005-92.006L256 100.174l-17.86 45.961c-47.096 7.63-84.376 44.91-92.005 92.006L100.174 256l45.962 17.86c7.629 47.096 44.909 84.376 92.005 92.005L256 411.826l17.86-45.961c47.095-7.629 84.376-44.91 92.005-92.005m-83.457-44.267L256 256h94.365l-67.958 26.408L256 256v94.366l-26.408-67.959L256 256h-94.365l67.957-26.407L256 256v-94.365zm-54.077-58.212-15.938 41.015-41.013 15.937c8.809-26.877 30.076-48.142 56.951-56.952m-56.95 112.288 41.012 15.937 15.938 41.013c-26.875-8.81-48.14-30.075-56.95-56.95m112.288 56.95 15.937-41.013 41.013-15.937c-8.81 26.875-30.075 48.14-56.95 56.95"
        fill="#f0f0f0"
      />
      <Path
        d="M350.365 256H256l26.408 26.408zM161.635 256H256l-26.408-26.407zM256 350.366V256l-26.408 26.408zM256 256l26.408-26.407L256 161.635z"
        fill="#0052b4"
      />
      <Path
        d="M244.87 33.391h22.261v44.522H244.87zM33.391 244.87h44.522v22.261H33.391zM244.87 434.087h22.261v44.522H244.87zM434.087 244.87h44.522v22.261h-44.522z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Nato';
export const Nato = memo<IconProps>(Icon);
