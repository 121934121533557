import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M233.739 256S75.13 437.055 74.98 437.019C121.306 483.346 185.307 512 256 512c141.384 0 256-114.616 256-256z"
        fill="#d80027"
      />
      <Path
        d="M74.98 74.98c-99.974 99.974-99.974 262.065 0 362.04L256 256z"
        fill="#0052b4"
      />
    </Svg>
  );
};
Icon.displayName = 'CzechRepublic';
export const CzechRepublic = memo<IconProps>(Icon);
