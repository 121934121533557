import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M12 16q1.875 0 3.187-1.312Q16.5 13.375 16.5 11.5t-1.313-3.187T12 7 8.812 8.313 7.5 11.5t1.312 3.188T12 16m0-1.8q-1.125 0-1.913-.787A2.6 2.6 0 0 1 9.3 11.5q0-1.125.787-1.912A2.6 2.6 0 0 1 12 8.8q1.125 0 1.912.787.788.788.788 1.913t-.788 1.912A2.6 2.6 0 0 1 12 14.2m0 4.8q-3.476 0-6.35-1.837Q2.775 15.324 1.3 12.2a.8.8 0 0 1-.1-.312 3 3 0 0 1 0-.775.8.8 0 0 1 .1-.313q1.474-3.125 4.35-4.962Q8.524 4 12 4q3.474 0 6.35 1.838 2.874 1.837 4.35 4.962.075.125.1.313a3 3 0 0 1 0 .774.8.8 0 0 1-.1.313q-1.475 3.125-4.35 4.963Q15.474 19 12 19"
      />
    </Svg>
  );
};
Icon.displayName = 'Visibility';
export const Visibility = memo<IconProps>(themed(Icon));
