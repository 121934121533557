import useAnalyticsWithBookingCart from '@/hooks/use-analytics-with-booking-cart-data';
import usePush from '@/hooks/use-push';
import DcsFlags from '@/types/dcs-flags';
import useDcsFlag from '../use-dcs-flag';
import useEnableAuth0 from '../use-enable-auth0';
import useQuery from '../use-query';
import useUniqueRenters from '../use-unique-renters/use-unique-renters';
import { UNIQUE_RENTERS_SIGNIN_PARAM } from '@/types/unique-renters';

const useAuthentication = () => {
  const { trackEvent, AnalyticsEvents } = useAnalyticsWithBookingCart();

  const push = usePush();
  const { data: enableAuth0, isLoading: isLoadingData } = useEnableAuth0();
  const { enabled: enabledUniqueRenters, isLoading: isLoadingUniqueRenters } =
    useUniqueRenters();
  const { isFlagEnabled, isLoading: isLoadingEnableAuthEndpointsFlag } =
    useDcsFlag({
      flag: DcsFlags.EnableAuthEndpoints,
    });

  const query = useQuery();
  const enableAuth0Param = query('enable_auth0', 'boolean') as boolean;

  const getAuthRedirectUrl = () => {
    const currentUrl = window.location.href;
    const authSigninParam = `auth_signin=true${
      enabledUniqueRenters ? `&${UNIQUE_RENTERS_SIGNIN_PARAM}` : ''
    }`;

    return currentUrl.includes(`?`)
      ? `${currentUrl}&${authSigninParam}`
      : `${currentUrl}?${authSigninParam}`;
  };

  const handleAuth = () => {
    if (
      isLoadingData ||
      isLoadingEnableAuthEndpointsFlag ||
      isLoadingUniqueRenters
    )
      return;

    if (enableAuth0?.enabled || enableAuth0Param) {
      trackEvent(AnalyticsEvents.Auth0.SigninRedirect);
      const redirectUrl = encodeURIComponent(getAuthRedirectUrl());
      window.location.href = `${process?.env.NEXT_PUBLIC_API_BASE_URL}${
        isFlagEnabled ? '/rest/auth0' : '/v2/users'
      }/login?redirect_url=${redirectUrl}`;
    } else {
      push({ sessions_dialog: 'signInOrSignUp' });
    }
  };

  return handleAuth;
};

export default useAuthentication;
