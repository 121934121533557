import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 189.217h247.181a254.6 254.6 0 0 0-28.755-66.783H256zM96.643 456.348h318.713a257.3 257.3 0 0 0 59.069-66.783H37.574a257.4 257.4 0 0 0 59.069 66.783M256 0v55.652h159.357C371.627 20.824 316.249 0 256 0"
        fill="#338af3"
      />
      <Path
        d="M256 189.217h247.181a254.6 254.6 0 0 0-28.755-66.783H256zM0 256c0 23.107 3.08 45.489 8.819 66.783h494.363C508.92 301.489 512 279.107 512 256z"
        fill="#338af3"
      />
      <Path
        d="m222.609 149.821-31.266 14.707 16.649 30.28-33.95-6.494-4.302 34.295-23.646-25.224-23.648 25.224-4.301-34.295-33.95 6.492 16.648-30.279-31.264-14.706 31.265-14.705-16.649-30.28 33.949 6.494 4.303-34.295 23.647 25.224 23.647-25.224 4.301 34.295 33.951-6.494-16.649 30.281z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Uruguay';
export const Uruguay = memo<IconProps>(Icon);
