import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M0 256C0 114.616 114.616 0 256 0s256 114.616 256 256"
        fill="#a2001d"
      />
    </Svg>
  );
};
Icon.displayName = 'Indonesia';
export const Indonesia = memo<IconProps>(Icon);
