import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#fcfcfc" />
      <Path
        d="M299.479 178.846c0-24.012-19.466-43.478-43.479-43.478s-43.479 19.466-43.479 43.478H116.87c0 23.641 20.594 42.808 44.235 42.808h-1.424c0 23.643 19.164 42.811 42.809 42.811 0 20.933 15.034 38.327 34.889 42.045l-.111.25h37.465l-.111-.25c19.856-3.72 34.889-21.113 34.889-42.045 23.644 0 42.809-19.168 42.809-42.811h-1.426c23.641 0 44.236-19.167 44.236-42.808z"
        fill="#ffda44"
      />
      <Path
        d="m236.887 302.324-26.958 60.863a122.1 122.1 0 0 0 46.072 8.996c16.301 0 31.848-3.213 46.071-8.996l-26.957-60.863z"
        fill="#ffda44"
      />
      <Path
        d="M200.348 200.227v74.891c0 42.598 55.652 55.652 55.652 55.652s55.652-13.054 55.652-55.652v-74.891z"
        fill="#f0f0f0"
      />
      <Path
        d="m62.079 257.802 30.099 84.184 29.786-84.184h22.732L101.27 369.106H83.085L39.348 257.802zM411.826 369.106V257.802h21.633v111.304z"
        fill="#338af3"
      />
      <Path
        d="M222.609 224.411v90.754c7.651 5.631 15.883 9.504 22.261 11.984V224.411zM289.391 224.411v90.754c-7.651 5.631-15.883 9.504-22.261 11.984V224.411z"
        fill="#d80027"
      />
      <Path d="M200.348 200.225h111.304v35.316H200.348z" fill="#0052b4" />
    </Svg>
  );
};
Icon.displayName = 'VirginIslands';
export const VirginIslands = memo<IconProps>(Icon);
