import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M5 22q-.824 0-1.412-.587A1.93 1.93 0 0 1 3 20V7q0-.424.288-.713A.97.97 0 0 1 4 6q.424 0 .713.287Q5 6.576 5 7v13h10q.424 0 .713.288.287.287.287.712 0 .424-.287.712A.97.97 0 0 1 15 22zm4-4q-.825 0-1.412-.587A1.93 1.93 0 0 1 7 16V4q0-.824.588-1.412A1.93 1.93 0 0 1 9 2h9q.824 0 1.413.587Q20 3.176 20 4v12q0 .824-.587 1.413A1.93 1.93 0 0 1 18 18zm0-2h9V4H9z"
      />
    </Svg>
  );
};
Icon.displayName = 'ContentCopy';
export const ContentCopy = memo<IconProps>(themed(Icon));
