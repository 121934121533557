import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M459.098 411.875 303.223 256.001h-.001l155.876-155.877a258.207 258.207 0 0 0-47.222-47.222L256.001 208.777l-.001.001L100.125 52.902a258.19 258.19 0 0 0-47.222 47.222l155.875 155.874.001.001L52.902 411.876a258.207 258.207 0 0 0 47.222 47.222L256 303.223l.001-.001 155.875 155.875a258.324 258.324 0 0 0 47.222-47.222"
        fill="#d80027"
      />
      <Path
        d="M211.478 77.913 256 89.043l44.522-11.13V40.07l-17.809 8.904L256 22.261l-26.713 26.713-17.809-8.904z"
        fill="#ffda44"
      />
      <Path
        d="M211.478 77.913v27.828c0 34.08 44.522 44.522 44.522 44.522s44.522-10.443 44.522-44.522V77.913z"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'Jersey';
export const Jersey = memo<IconProps>(Icon);
