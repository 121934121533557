import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg
      viewBox="0 0 512.001 512.001"
      width={size}
      height={size}
      {...otherProps}
    >
      <Path
        d="M437.027 437.027c96.011-96.011 99.802-249.296 11.413-349.855L256 255.999 87.172 448.44c100.559 88.389 253.843 84.598 349.855-11.413"
        fill="#496e2d"
      />
      <Path
        d="M74.974 74.974c96.011-96.011 249.296-99.802 349.855-11.413L256 256.003 63.562 424.829C-24.828 324.27-21.036 170.985 74.974 74.974"
        fill="#0052b4"
      />
      <Path
        d="M424.827 63.56 63.56 424.828a259 259 0 0 0 11.413 12.198 260 260 0 0 0 12.201 11.414L448.439 87.172a259 259 0 0 0-11.414-12.201 260 260 0 0 0-12.198-11.411"
        fill="#ffda44"
      />
      <Path
        d="m107.861 89.036 5.527 17.007h17.881l-14.466 10.509 5.525 17.007-14.467-10.511-14.467 10.511 5.527-17.007-14.467-10.509h17.882zM199.2 89.036l5.527 17.007h17.88l-14.466 10.509 5.526 17.007-14.467-10.511-14.467 10.511 5.527-17.007-14.467-10.509h17.881zM107.861 178.083l5.527 17.007h17.881l-14.466 10.51 5.525 17.007-14.467-10.511-14.467 10.511 5.527-17.007-14.467-10.51h17.882zM199.2 178.083l5.527 17.007h17.88l-14.466 10.51 5.526 17.007-14.467-10.511-14.467 10.511 5.527-17.007-14.467-10.51h17.881zM153.531 133.559l5.527 17.007h17.88l-14.466 10.51 5.526 17.007-14.467-10.511-14.467 10.511 5.526-17.007-14.467-10.51h17.882z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'SolomonIslands';
export const SolomonIslands = memo<IconProps>(Icon);
