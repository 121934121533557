import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="m10.95 12.7-1.4-1.4a.96.96 0 0 0-.7-.3q-.4 0-.7.3t-.3.712.3.713l2.1 2.125q.3.3.7.3t.7-.3l4.25-4.25q.3-.3.3-.713 0-.412-.3-.712t-.713-.3-.712.3zM12 21.9a2 2 0 0 1-.625-.1Q8 20.676 6 17.637 4 14.6 4 11.1V6.375q0-.625.362-1.125.363-.5.938-.725l6-2.25a2.1 2.1 0 0 1 .7-.125q.35 0 .7.125l6 2.25q.575.225.938.725.362.5.362 1.125V11.1q0 3.5-2 6.538-2 3.037-5.375 4.162-.15.05-.3.075T12 21.9m0-2q2.6-.825 4.3-3.3t1.7-5.5V6.375l-6-2.25-6 2.25V11.1q0 3.025 1.7 5.5t4.3 3.3"
      />
    </Svg>
  );
};
Icon.displayName = 'ShieldCheck';
export const ShieldCheck = memo<IconProps>(themed(Icon));
