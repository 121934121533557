import { usePostHog } from 'posthog-js/react';
import PosthogFlags, { SegmentedChatFlagValues } from '@/types/posthog-flags';

const useSegmentedChatExperiment = () => {
  const posthog = usePostHog();
  const featureFlag = posthog.getFeatureFlag(PosthogFlags.SegmentedChat);
  const isSegmentedChatEnabled =
    featureFlag === SegmentedChatFlagValues.segmentedChatEnabled;

  return {
    enabled: isSegmentedChatEnabled,
    isLoading: featureFlag === undefined,
  };
};

export default useSegmentedChatExperiment;
