import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M244.87 256H512c0-23.106-3.08-45.49-8.819-66.783H244.87zM244.87 122.435h229.556a257.4 257.4 0 0 0-59.07-66.783H244.87zM256 512c60.249 0 115.626-20.824 159.356-55.652H96.644C140.374 491.176 195.751 512 256 512M37.574 389.565h436.852a254.5 254.5 0 0 0 28.755-66.783H8.819a254.5 254.5 0 0 0 28.755 66.783"
        fill="#d80027"
      />
      <Path
        d="M118.584 39.978h23.329l-21.7 15.765 8.289 25.509-21.699-15.765-21.699 15.765 7.16-22.037a257.4 257.4 0 0 0-49.652 55.337h7.475l-13.813 10.035a256 256 0 0 0-6.194 10.938l6.596 20.301-12.306-8.941a254 254 0 0 0-8.372 19.873l7.267 22.368h26.822l-21.7 15.765 8.289 25.509-21.699-15.765-12.998 9.444A258.5 258.5 0 0 0 0 256h256V0c-50.572 0-97.715 14.67-137.416 39.978m9.918 190.422-21.699-15.765L85.104 230.4l8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765zm-8.289-100.083 8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822zM220.328 230.4l-21.699-15.765L176.93 230.4l8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765zm-8.289-100.083 8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822zm0-74.574 8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822z"
        fill="#0052b4"
      />
    </Svg>
  );
};
Icon.displayName = 'UnitedStatesOfAmerica';
export const UnitedStatesOfAmerica = memo<IconProps>(Icon);
