import { useEffect } from 'react';
import DcsFlags from '@/types/dcs-flags';
import create from 'zustand';
import { UniqueRentersType } from '@/types/unique-renters';
import useRequestOtp from '../use-request-otp/use-request-otp';
import useRequestValidateOtp from '../use-request-validate-otp/use-request-validate-otp';
import useDcsFlag from '../use-dcs-flag';
import useCountdown from '../use-countdown/use-countdown';
import useRenterVerificationStatus from '../use-renter-verification-status/use-renter-verification-status';
import useUniqueRentersExperiment from '../experiments/use-unique-renters-experiment/use-unique-renters-experiment';
import useMeStore from '../use-me-store';
import { ModalContentVariant } from '@/components/otp-modal/otp-modal';

export enum UniqueRentersStatusVariantEnum {
  Success = 'success',
  Error = 'error',
}

export type UniqueRentersStatus = {
  message: string;
  status?:
    | UniqueRentersStatusVariantEnum.Success
    | UniqueRentersStatusVariantEnum.Error;
};

type UniqueRentersStore = {
  variant?: ModalContentVariant;
  isVerifyingPhone: boolean;
  phone?: string;
  token?: string;
  status?: UniqueRentersStatus;
  validateOtpCallback: () => void;
  togglePhoneVerification: (isVerifying: boolean) => void;
  initiatePhoneVerification: (phone: string) => void;
  setPhone: (phone: string) => void;
  setVariant: (variant?: ModalContentVariant) => void;
  setStatus: (newStatus?: UniqueRentersStatus) => void;
  setToken: (token?: string) => void;
  reset: () => void;
  finishVerification: (
    newToken: string,
    newStatus?: UniqueRentersStatus,
  ) => void;
  restartVerification: (newVariant?: ModalContentVariant) => void;
  setValidateOtpCallback: (callback: () => void) => void;
};

export const useUniqueRentersStore = create<UniqueRentersStore>((set) => ({
  variant: undefined,
  token: undefined,
  phone: undefined,
  status: undefined,
  isVerifyingPhone: false,
  validateOtpCallback: () => {},
  setToken: (newToken) => set({ token: newToken }),
  setPhone: (newPhone) => set({ phone: newPhone }),
  setVariant: (newVariant) => set({ variant: newVariant }),
  setStatus: (newStatus) => set({ status: newStatus }),
  togglePhoneVerification: (value) => set({ isVerifyingPhone: value }),
  setValidateOtpCallback: (callback) => set({ validateOtpCallback: callback }),
  reset: () =>
    set({
      variant: undefined,
      isVerifyingPhone: false,
      token: '',
      status: undefined,
    }),
  restartVerification: (newVariant?: ModalContentVariant) =>
    set({
      variant: newVariant,
      isVerifyingPhone: false,
      token: '',
    }),
  initiatePhoneVerification: (newPhone: string) =>
    set({
      phone: newPhone,
      isVerifyingPhone: true,
      status: undefined,
      token: '',
    }),
  finishVerification: (newToken: string, newStatus?: UniqueRentersStatus) =>
    set({
      variant: undefined,
      isVerifyingPhone: false,
      token: newToken,
      status: newStatus,
    }),
}));

const useUniqueRenters = (resetOnMount?: boolean) => {
  const { me } = useMeStore();
  const { data, isLoading: isLoadingFlag } = useDcsFlag<UniqueRentersType>({
    flag: DcsFlags.EnableUniqueRenters,
  });

  const { data: isUniqueRentersEnabled } = useUniqueRentersExperiment();

  const { countdownActive, startCountdown, stopCountdown } = useCountdown();

  const requestOtpMutation = useRequestOtp();
  const requestOtpValidationMutation = useRequestValidateOtp();
  const requestRenterVerificationMutation = useRenterVerificationStatus();

  const handleStartCountdown = (seconds?: number) => {
    const notifySecs = data?.confirmOtp.notification?.notifySeconds || seconds;
    if (notifySecs) startCountdown(notifySecs);
  };

  const getStatusForInputHint = () => {
    if (!variant && status) {
      return status;
    }

    return { status: undefined, message: '' };
  };

  const {
    variant,
    status,
    phone,
    token,
    reset,
    validateOtpCallback,
    isVerifyingPhone,
    setToken,
    setVariant,
    setPhone,
    setStatus,
    setValidateOtpCallback,
    togglePhoneVerification,
    initiatePhoneVerification,
    finishVerification,
    restartVerification,
  } = useUniqueRentersStore();

  const isUniqueRentersVerificationActive = !!(
    isUniqueRentersEnabled &&
    !me.phone &&
    !token
  );

  useEffect(() => {
    if (isUniqueRentersEnabled && resetOnMount) {
      setStatus(undefined);
      setToken(undefined);
    }
  }, [isUniqueRentersEnabled, resetOnMount]);

  return {
    data,
    status,
    variant,
    phone,
    isVerifyingPhone,
    enabled: !!isUniqueRentersEnabled,
    isUniqueRentersVerificationActive,
    countdownActive,
    reset,
    validateOtpCallback,
    stopCountdown,
    startCountdown: handleStartCountdown,
    token,
    setToken,
    setVariant,
    setValidateOtpCallback,
    setPhone,
    setStatus,
    togglePhoneVerification,
    initiatePhoneVerification,
    finishVerification,
    getStatusForInputHint,
    restartVerification,
    isLoading: isLoadingFlag,
    requestOtp: requestOtpMutation,
    requestOtpValidation: requestOtpValidationMutation,
    requestRenterVerification: requestRenterVerificationMutation,
  };
};

export default useUniqueRenters;
