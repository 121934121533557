import React from 'react';
import { Box } from '../box';
import { Typography } from '../typography';

type DialogBodyProps = {
  children: React.ReactNode;
};

const DialogBody = ({ children }: DialogBodyProps) => {
  return (
    <Box paddingHorizontal="$medium">
      <Typography variant="paragraphMedium">{children}</Typography>
    </Box>
  );
};

export { DialogBody };
