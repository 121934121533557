import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M512 256c0 141.384-114.616 256-256 256S0 397.384 0 256L256 0c141.384 0 256 114.616 256 256"
        fill="#d80027"
      />
      <Path d="M256 256V0C114.616 0 0 114.616 0 256z" fill="#0052b4" />
      <Path
        d="m205.054 166.957 4.145 12.755h13.41l-10.85 7.881 4.145 12.755-10.85-7.883-10.85 7.883 4.145-12.755-10.85-7.881h13.41zM137.698 66.783l6.907 21.259h22.352l-18.084 13.135 6.909 21.258-18.084-13.139-18.083 13.139 6.908-21.258-18.083-13.135h22.35zM204.481 89.043l6.907 21.26h22.351l-18.083 13.135 6.907 21.258-18.082-13.139-18.083 13.139 6.908-21.258-18.083-13.135h22.35zM151.693 178.087l6.908 21.259h22.35l-18.083 13.135 6.908 21.258-18.083-13.138-18.083 13.138 6.908-21.258-18.083-13.135h22.351zM93.176 122.435l6.908 21.259h22.351l-18.084 13.135 6.908 21.258-18.083-13.138-18.082 13.138 6.907-21.258-18.082-13.135h22.35z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Samoa';
export const Samoa = memo<IconProps>(Icon);
