import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M233.739 233.739S75.13 437.056 74.98 437.019C121.306 483.346 185.307 512 256 512c141.384 0 256-114.616 256-256z"
        fill="#338af3"
      />
      <Path
        d="M233.739 256S75.13 74.945 74.98 74.981C121.306 28.654 185.307 0 256 0c141.384 0 256 114.616 256 256z"
        fill="#6da544"
      />
      <Path
        d="M74.98 74.98c-99.974 99.974-99.974 262.065 0 362.04C74.973 437.061 512 256 512 256z"
        fill="#d80027"
      />
      <Path
        d="M133.565 150.261c-49.099 0-89.043 39.945-89.043 89.043v33.391c0 49.099 39.945 89.043 89.043 89.043s89.043-39.945 89.043-89.043v-33.391c.001-49.098-39.944-89.043-89.043-89.043m55.652 122.435c0 24.872-16.402 45.981-38.957 53.09v-36.394l23.624-23.624-23.611-23.611-.013-8.403V222.61h-33.39V256l-23.608 23.607 23.608 23.607v22.573c-22.555-7.109-38.957-28.218-38.957-53.09v-33.391c0-30.687 24.966-55.652 55.652-55.652s55.652 24.966 55.652 55.652z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Eritrea';
export const Eritrea = memo<IconProps>(Icon);
