import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M22.4 5.55a8.5 8.5 0 0 1-2.45.672 4.28 4.28 0 0 0 1.875-2.361 8.6 8.6 0 0 1-2.71 1.036 4.268 4.268 0 0 0-7.271 3.891 12.12 12.12 0 0 1-8.796-4.46 4.26 4.26 0 0 0-.577 2.147c0 1.48.753 2.787 1.897 3.551a4.26 4.26 0 0 1-1.932-.533v.053a4.27 4.27 0 0 0 3.423 4.184 4.3 4.3 0 0 1-1.927.074 4.27 4.27 0 0 0 3.986 2.964A8.56 8.56 0 0 1 1.6 18.535a12.1 12.1 0 0 0 6.541 1.917c7.85 0 12.141-6.502 12.141-12.142q0-.276-.012-.552A8.7 8.7 0 0 0 22.4 5.55"
      />
    </Svg>
  );
};
Icon.displayName = 'Twitter';
export const Twitter = memo<IconProps>(themed(Icon));
