import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#ffda44" />
      <Path
        d="M256 0c-.186.002-11.13 102.4-11.13 102.4h215.942C414.107 40.223 339.752 0 256 0M256 512c83.752 0 158.107-40.223 204.811-102.4H51.189C97.893 471.777 172.248 512 256 512M506.874 204.8H244.87L256 307.2h250.874c3.36-16.543 5.126-33.665 5.126-51.2s-1.768-34.657-5.126-51.2"
        fill="#496e2d"
      />
      <Path
        d="M256 307.2V0C114.616 0 0 114.616 0 256c0 17.535 1.768 34.657 5.126 51.2z"
        fill="#d80027"
      />
      <Path
        d="m141.257 122.435 16.576 51.015h53.645l-43.397 31.533L184.655 256l-43.398-31.53L97.859 256l16.577-51.017-43.397-31.533h53.643z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Togo';
export const Togo = memo<IconProps>(Icon);
