import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#ffda44" />
      <Path
        d="M512 256c0-87.861-44.269-165.375-111.713-211.478H111.713C44.27 90.625 0 168.139 0 256c0 87.863 44.27 165.375 111.713 211.478h288.574C467.731 421.375 512 343.863 512 256"
        fill="#a2001d"
      />
      <Path
        d="M200.348 189.217h111.304v-44.521l-22.261 11.13L256 122.435l-33.391 33.391-22.261-11.13zM356.174 256h-55.631a25.5 25.5 0 0 0 3.45-12.813c0-14.187-11.501-25.686-25.686-25.686-9.801 0-18.313 5.492-22.644 13.562-4.331-8.071-12.843-13.562-22.644-13.562-14.185 0-25.686 11.499-25.686 25.686 0 4.673 1.27 9.04 3.45 12.813h-54.959c0 17.023 14.827 30.822 31.849 30.822h-1.026c0 13.963 9.287 25.753 22.02 29.54l-25.017 25.019 23.611 23.611 33.493-33.493c1.312.481 2.669.865 4.072 1.129l-20.238 45.695A83.2 83.2 0 0 0 256 384.456a83.2 83.2 0 0 0 31.412-6.133l-20.238-45.695a25.4 25.4 0 0 0 4.07-1.129l33.493 33.493 23.61-23.611-25.018-25.018c12.732-3.789 22.02-15.577 22.02-29.54h-1.027c17.024-.001 31.852-13.8 31.852-30.823"
        fill="#ffda44"
      />
      <Path
        d="m256 272.696-36.174 16.695v33.392L256 345.043l36.174-22.26v-33.392z"
        fill="#6da544"
      />
      <Path d="M219.826 256h72.348v44.522h-72.348z" fill="#0052b4" />
    </Svg>
  );
};
Icon.displayName = 'Montenegro';
export const Montenegro = memo<IconProps>(Icon);
