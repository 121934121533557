import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M512 256c0-31.314-5.633-61.311-15.923-89.043L256 155.826l-240.077 11.13C5.633 194.689 0 224.686 0 256s5.633 61.311 15.923 89.043L256 356.174l240.077-11.13C506.367 317.311 512 287.314 512 256"
        fill="#f0f0f0"
      />
      <Path
        d="M256 512c110.071 0 203.906-69.472 240.077-166.957H15.923C52.094 442.528 145.929 512 256 512"
        fill="#0052b4"
      />
      <Path
        d="M15.923 166.957h480.155C459.906 69.472 366.071 0 256 0S52.094 69.472 15.923 166.957"
        fill="#d80027"
      />
      <Path
        d="M322.783 178.088h-44.522l7.421-55.653 29.68-22.261 29.681 22.261v44.522zM189.217 178.088h44.522l-7.421-55.653-29.681-22.261-29.68 22.261v44.522z"
        fill="#338af3"
      />
      <Path
        d="M285.682 178.088h-59.364v-55.653L256 100.174l29.682 22.261z"
        fill="#0052b4"
      />
      <Path
        d="M166.957 166.958v122.434c0 29.153 14.082 55.079 35.802 71.332l15.583-3.899 19.664 19.782a89 89 0 0 0 17.995 1.829c6.13 0 12.117-.623 17.901-1.809l22.447-18.69 12.892 2.751c21.711-16.252 35.803-42.151 35.803-71.296V166.958z"
        fill="#f0f0f0"
      />
      <Path
        d="M166.957 166.957h35.617v35.617h-35.617zM238.191 166.957h35.617v35.617h-35.617zM309.426 166.957h35.617v35.617h-35.617zM202.574 202.574h35.617v35.617h-35.617zM273.809 202.574h35.617v35.617h-35.617zM166.957 238.18h35.617v35.617h-35.617zM202.574 273.798h35.617v35.617h-35.617zM238.191 238.18h35.617v35.617h-35.617z"
        fill="#d80027"
      />
      <Path
        d="M309.426 238.18h35.617v35.617h-35.617zM273.809 273.798h35.617v35.617h-35.617zM238.191 309.415h35.617v35.617h-35.617zM202.574 309.418h-33.319c3.056 13.24 9.064 25.355 17.302 35.617h16.017zM309.426 345.036h16.016a88.75 88.75 0 0 0 17.302-35.617h-33.318zM202.574 345.036v15.541c10.359 7.795 22.465 13.384 35.617 16.066v-31.607zM273.809 345.036v31.607c13.153-2.68 25.258-8.271 35.617-16.066v-15.541z"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'Croatia';
export const Croatia = memo<IconProps>(Icon);
