import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M512 256c0-110.07-69.472-203.906-166.957-240.076v480.155C442.528 459.906 512 366.072 512 256"
        fill="#ff9811"
      />
      <Path
        d="M0 256c0 110.072 69.472 203.906 166.957 240.078V15.924C69.472 52.094 0 145.93 0 256"
        fill="#6da544"
      />
    </Svg>
  );
};
Icon.displayName = 'Ireland';
export const Ireland = memo<IconProps>(Icon);
