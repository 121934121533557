import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M12.088 178.088C4.246 202.656-.001 228.829 0 255.998c-.001 27.17 4.247 53.345 12.089 77.913L256 345.043l243.911-11.13c7.841-24.569 12.09-50.745 12.09-77.913-.001-27.17-4.248-53.344-12.09-77.913L256 166.957z"
        fill="#f0f0f0"
      />
      <Path
        d="m499.91 178.088-243.911-11.13-243.912 11.13a256 256 0 0 0-6.002 22.261h499.824a255 255 0 0 0-5.999-22.261M6.088 311.651a255 255 0 0 0 6 22.26l.024.001 243.886 11.13 243.911-11.13a255 255 0 0 0 6.001-22.261z"
        fill="#d80027"
      />
      <Path
        d="M255.999 511.999c114.216 0 210.946-74.803 243.911-178.087H12.089c32.965 103.283 129.697 178.087 243.91 178.087"
        fill="#6da544"
      />
      <Path
        d="M255.999.001C141.785.002 45.055 74.805 12.088 178.088l487.821.001C466.946 74.804 370.215 0 255.999.001"
        fill="#338af3"
      />
      <Path
        d="M116.986 105.74c0-23.977 16.851-44.007 39.354-48.921a50.2 50.2 0 0 0-10.733-1.165c-27.662 0-50.087 22.424-50.087 50.087s22.423 50.087 50.087 50.087c3.686 0 7.274-.41 10.733-1.165-22.502-4.916-39.354-24.946-39.354-48.923M185.944 128.507l3.39 10.435h10.972l-8.876 6.45 3.39 10.435-8.876-6.449-8.878 6.449 3.391-10.435-8.877-6.45h10.972zM220.964 128.507l3.391 10.435h10.972l-8.877 6.45 3.391 10.435-8.877-6.449-8.877 6.449 3.391-10.435-8.876-6.45h10.971zM255.985 128.507l3.39 10.435h10.973l-8.877 6.45 3.39 10.435-8.876-6.449-8.878 6.449 3.392-10.435-8.878-6.45h10.973zM291.006 128.507l3.391 10.435h10.971l-8.876 6.45 3.391 10.435-8.877-6.449-8.878 6.449 3.392-10.435-8.878-6.45h10.972zM326.026 128.507l3.391 10.435h10.972l-8.876 6.45 3.391 10.435-8.878-6.449-8.877 6.449 3.391-10.435-8.876-6.45h10.971zM220.964 92.08l3.391 10.434h10.972l-8.877 6.451 3.391 10.434-8.877-6.449-8.877 6.449 3.391-10.434-8.876-6.451h10.971zM255.985 92.08l3.39 10.434h10.973l-8.877 6.451 3.39 10.434-8.876-6.449-8.878 6.449 3.392-10.434-8.878-6.451h10.973zM291.006 92.08l3.391 10.434h10.971l-8.876 6.451 3.391 10.434-8.877-6.449-8.878 6.449 3.392-10.434-8.878-6.451h10.972zM326.026 92.08l3.391 10.434h10.972l-8.876 6.451 3.391 10.434-8.878-6.449-8.877 6.449 3.391-10.434-8.876-6.451h10.971zM255.985 55.652l3.39 10.436h10.973l-8.877 6.449 3.39 10.436-8.876-6.449-8.878 6.449 3.392-10.436-8.878-6.449h10.973zM291.006 55.652l3.391 10.436h10.971l-8.876 6.449 3.391 10.436-8.877-6.449-8.878 6.449 3.392-10.436-8.878-6.449h10.972zM326.026 55.652l3.391 10.436h10.972l-8.876 6.449 3.391 10.436-8.878-6.449-8.877 6.449 3.391-10.436-8.876-6.449h10.971z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Uzbekistn';
export const Uzbekistn = memo<IconProps>(Icon);
