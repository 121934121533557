import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 0C154.506 0 66.81 59.065 25.402 144.696h461.195C445.19 59.065 357.493 0 256 0M256 512c101.493 0 189.19-59.065 230.598-144.696H25.402C66.81 452.935 154.506 512 256 512"
        fill="#0052b4"
      />
      <Path d="M204.59 267.13 256 178.087l51.41 89.043z" fill="#ffda44" />
      <Path
        d="M322.783 296.531 256 329.923l-66.783-33.392V252.01h133.566z"
        fill="#6da544"
      />
      <Path
        d="m318.963 181.907-23.611 23.611c10.071 10.071 16.301 23.984 16.301 39.352 0 30.736-24.917 55.652-55.652 55.652s-55.652-24.917-55.652-55.652c0-15.368 6.23-29.281 16.301-39.352l-23.611-23.611c-16.115 16.112-26.081 38.373-26.081 62.963 0 49.178 39.866 89.043 89.043 89.043s89.043-39.866 89.043-89.043c-.001-24.59-9.967-46.851-26.081-62.963"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'ElSalvador';
export const ElSalvador = memo<IconProps>(Icon);
