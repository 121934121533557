import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M411.876 52.902c-91.652-70.526-220.1-70.526-311.752-.001L256 208.777zM255.998 303.224 100.124 459.098c91.652 70.526 220.1 70.526 311.752 0L256 303.222z"
        fill="#d80027"
      />
      <Path
        d="M208.777 256 52.902 100.124c-70.526 91.652-70.526 220.1 0 311.752zM303.224 256l155.874 155.876c70.526-91.652 70.526-220.1 0-311.752z"
        fill="#6da544"
      />
      <Circle cx={256} cy={256} r={111.304} fill="#f0f0f0" />
      <Path
        d="m256 178.087 9.639 16.696h19.279l-9.64 16.695 9.64 16.696h-19.279L256 244.87l-9.639-16.696h-19.278l9.639-16.696-9.639-16.695h19.278zM207.005 256l9.639 16.696h19.278l-9.639 16.695 9.639 16.696h-19.278l-9.639 16.696-9.64-16.696h-19.278l9.639-16.696-9.639-16.695h19.278zM304.996 256l9.639 16.696h19.278l-9.639 16.695 9.639 16.696h-19.278l-9.639 16.696-9.64-16.696h-19.278l9.639-16.696-9.639-16.695h19.278z"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'Burundi';
export const Burundi = memo<IconProps>(Icon);
