import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M0 256.001C0 114.616 114.616 0 256 0s256 114.616 256 256.001"
        fill="#d80027"
      />
      <Path
        d="M155.826 133.564c0-37.298 26.213-68.456 61.217-76.101a78.2 78.2 0 0 0-16.696-1.811c-43.031 0-77.913 34.882-77.913 77.913s34.882 77.913 77.913 77.913c5.733 0 11.315-.637 16.696-1.812-35.004-7.645-61.217-38.803-61.217-76.102M256 61.217l5.526 17.005h17.881L264.94 88.733l5.526 17.005L256 95.229l-14.466 10.509 5.526-17.005-14.466-10.511h17.88z"
        fill="#f0f0f0"
      />
      <Path
        d="m212.625 94.608 5.525 17.006h17.881l-14.466 10.51 5.526 17.005-14.466-10.509-14.468 10.509 5.527-17.005-14.467-10.51h17.881zM299.376 94.608l5.527 17.006h17.88l-14.467 10.51 5.527 17.005-14.467-10.509-14.466 10.509 5.526-17.005-14.466-10.51h17.88zM282.681 144.695l5.526 17.006h17.88l-14.466 10.51 5.526 17.005-14.466-10.509-14.466 10.509 5.526-17.005-14.466-10.51h17.879zM229.32 144.695l5.525 17.006h17.882l-14.467 10.51 5.527 17.005-14.467-10.509-14.467 10.509 5.526-17.005-14.466-10.51h17.881z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Singapore';
export const Singapore = memo<IconProps>(Icon);
