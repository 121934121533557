import { Close } from '@drivekyte/icons';
import { RNTamaguiViewNonStyleProps } from '@tamagui/core';
import { Dialog as DialogTM } from '@tamagui/dialog';
import React, { useContext } from 'react';
import { Box } from '../box';
import { IconButton } from '../icon-button';
import { DialogContext } from './context/dialog-context';

type DialogHeaderProps = {
  children?: React.ReactNode;
} & RNTamaguiViewNonStyleProps;

const topContentHeights = {
  xSmall: 180,
  small: 240,
  medium: 240,
  large: 640,
};

const DialogHeader = ({ children, ...props }: DialogHeaderProps) => {
  const { variant } = useContext(DialogContext);

  const isLarge = variant === 'large';

  return (
    <Box
      borderTopLeftRadius="$small"
      borderTopRightRadius="$small"
      width={isLarge ? '400px' : '100%'}
      height={topContentHeights[variant]}
      backgroundColor="$red50"
      {...props}
    >
      <Box
        position="absolute"
        left={isLarge ? '$tiny' : undefined}
        right={!isLarge ? '$tiny' : undefined}
      >
        <DialogTM.Close asChild>
          <IconButton
            size="medium"
            variant="tertiary"
            icon={<Close color="$contentInversePrimary" />}
            marginTop="$xSmall"
            alignSelf={isLarge ? 'flex-start' : 'flex-end'}
          />
        </DialogTM.Close>
      </Box>
      {children}
    </Box>
  );
};

export { DialogHeader };
