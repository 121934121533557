import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill="#1565C0"
        d="M22.5 17.867c0 1.178-.895 2.133-2 2.133h-17c-1.104 0-2-.955-2-2.133V6.133C1.5 4.955 2.396 4 3.5 4h17c1.105 0 2 .955 2 2.133z"
      />
      <Path
        fill="#fff"
        d="M7.593 9.25 6.28 13.167s-.333-1.656-.366-1.864c-.748-1.705-1.85-1.61-1.85-1.61l1.3 5.059v-.002h1.58l2.185-5.498zm1.252 5.5h1.435l.869-5.5H9.695zm10.16-5.5h-1.511l-2.355 5.5h1.426l.294-.785h1.798l.153.786h1.306zm-1.748 3.665.781-2.079.41 2.079zm-4.072-2.061c0-.303.249-.529.963-.529.464 0 .995.337.995.337l.233-1.155s-.678-.257-1.345-.257c-1.51 0-2.288.722-2.288 1.636 0 1.653 1.99 1.427 1.99 2.276 0 .145-.116.481-.944.481-.832 0-1.38-.304-1.38-.304l-.248 1.108s.532.303 1.559.303c1.03 0 2.457-.77 2.457-1.876 0-1.33-1.992-1.427-1.992-2.02"
      />
      <Path
        fill="#FFC043"
        d="M6.106 12.223 5.623 9.85s-.219-.514-.787-.514h-2.22s2.83.836 3.49 2.888"
      />
    </Svg>
  );
};
Icon.displayName = 'Visa';
export const Visa = memo<IconProps>(themed(Icon));
