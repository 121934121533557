import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        fillRule="evenodd"
        d="M2.035 16.22a.4.4 0 0 1 .325-.351.39.39 0 0 1 .414.203c.04.074.055.158.044.24a4.4 4.4 0 0 0 1.237 3.611 4.29 4.29 0 0 0 3.572 1.25.4.4 0 0 1 .29.083.4.4 0 0 1-.198.709 5.08 5.08 0 0 1-4.222-1.479 5.2 5.2 0 0 1-1.462-4.266m1.956.232a.4.4 0 0 1 .324-.35.39.39 0 0 1 .415.203c.04.073.055.158.044.24-.081.703.14 1.43.674 1.97s1.253.764 1.948.681a.39.39 0 0 1 .397.199.4.4 0 0 1-.155.546.4.4 0 0 1-.15.047 3.13 3.13 0 0 1-2.597-.91 3.2 3.2 0 0 1-.9-2.626m13.717 1.223.008-.007.007-.008zm-1.427 4.288a.39.39 0 0 1-.347-.329.4.4 0 0 1 .201-.419.4.4 0 0 1 .238-.044 4.29 4.29 0 0 0 3.572-1.25 4.4 4.4 0 0 0 1.237-3.61.4.4 0 0 1 .082-.294.393.393 0 0 1 .701.2 5.2 5.2 0 0 1-1.462 4.267 5.08 5.08 0 0 1-4.222 1.479m.23-1.978a.39.39 0 0 1-.347-.327.4.4 0 0 1 .201-.42.4.4 0 0 1 .239-.044 2.34 2.34 0 0 0 1.948-.681c.534-.54.756-1.267.674-1.97a.4.4 0 0 1 .196-.4.39.39 0 0 1 .541.157.4.4 0 0 1 .046.15 3.2 3.2 0 0 1-.9 2.626 3.13 3.13 0 0 1-2.598.91M5.107 8.905a.58.58 0 0 0 .305.507.56.56 0 0 0 .586-.036l.822-.553v7.192c0 .827.59 1.542 1.393 1.695.894.17 2.295.385 3.725.385s2.832-.215 3.726-.385a1.73 1.73 0 0 0 1.393-1.695V8.823l.822.553a.565.565 0 0 0 .881-.364.58.58 0 0 0-.25-.592l-1.453-.98V4.419c0-.672-.389-1.292-1.001-1.57C15.254 2.484 13.83 2 11.938 2s-3.315.484-4.118.848c-.612.277-1 .898-1 1.57v3.023l-1.453.979a.57.57 0 0 0-.26.485m3.988 4.09-1.138.737V9.674l1.138 1.125zm-1.131-5.24v-.013c.021-.02 1.156-1.143 3.974-1.143 2.822 0 3.957 1.126 3.975 1.144v.012l-1.7 1.718h-4.55zm.07-3.623a.55.55 0 0 1 .252-.236 8.3 8.3 0 0 1 1.946-.595v.424c0 .635-.509 1.15-1.137 1.15-.486 0-.898-.31-1.061-.743m.29 10.905 1.34-.966h4.549l1.339.966s-1.245 1.334-3.614 1.334c-2.37 0-3.613-1.334-3.613-1.334m6.458-10.163a1.143 1.143 0 0 1-1.138-1.15v-.423a8.3 8.3 0 0 1 1.945.595c.11.05.196.134.254.236a1.135 1.135 0 0 1-1.061.742m1.137 8.858-1.137-.736v-2.197l1.137-1.125z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
Icon.displayName = 'FrontSensors';
export const FrontSensors = memo<IconProps>(themed(Icon));
