import isEmpty from 'lodash/isEmpty';
import { Me } from '@/types/me';
import { identify as identifyAnalytics } from '@/utils/analytics';
import useMeStore from '../use-me-store';
import { useUniqueRentersStore } from '../use-unique-renters/use-unique-renters';
import { OtpContentEnum } from '@/components/otp-modal/otp-modal';
import { UNIQUE_RENTERS_SIGNIN_PARAM } from '@/types/unique-renters';

type UseMeResponse = {
  me: Me;
  identify: (nextMe: Me) => void;
  reset: () => void;
  isAuthenticated: boolean;
  isProfileFilled: boolean;
  isVerified: boolean;
};

const useMe = (): UseMeResponse => {
  const { me, set, reset } = useMeStore();
  const isAuthenticated = !isEmpty(me);
  const { restartVerification, setStatus } = useUniqueRentersStore();

  const isProfileFilled = !!me.firstName && !!me.lastName && !!me.phone;

  const identify = (nextMe: Me) => {
    const phoneIsNotDefined = nextMe.uuid && !nextMe.phone;
    const locationIsCheckout = window.location.href.includes('checkout?');
    const locationContainsUniqueRenters = window.location.href.includes(
      UNIQUE_RENTERS_SIGNIN_PARAM,
    );
    const showPhoneVerificationModal =
      locationContainsUniqueRenters && phoneIsNotDefined && !locationIsCheckout;

    identifyAnalytics(nextMe);
    set(nextMe);

    if (showPhoneVerificationModal) {
      restartVerification(OtpContentEnum.Phone);
      setStatus(undefined);
    }
  };

  return {
    me,
    reset,
    identify,
    isProfileFilled,
    isAuthenticated,
    isVerified: me?.primaryDriver?.verification_status === 'verified',
  };
};

export default useMe;
