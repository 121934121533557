import { useRouter } from 'next/router';

const transformFnMapper = {
  boolean: (value: string) => value === 'true',
  number: (value: string) => Number(value),
  string: (value: string) => value,
  raw: (value: unknown) => value,
};

const useQuery = () => {
  const router = useRouter() || {};

  const get = (key: string) => {
    if (typeof window === 'undefined') return '';

    const { query } = router;

    if (query && Object.keys(router.query).length > 0) {
      return router?.query[key];
    }

    const location = window.location.toString();
    return new URL(location).searchParams.get(key);
  };

  const query = <T = 'boolean' | 'number' | 'string' | 'raw'>(
    key: string,
    transformFn: 'boolean' | 'number' | 'string' | 'raw' = 'string',
    defaultValue = '',
  ) => {
    if (transformFn === 'raw') {
      return transformFnMapper[transformFn](get(key));
    }

    const value = String(get(key) || defaultValue);
    return transformFnMapper[transformFn](value) as unknown as T;
  };

  return query;
};

export default useQuery;
