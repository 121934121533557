import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M12 15q1.25 0 2.125-.875A2.9 2.9 0 0 0 15 12q0-1.25-.875-2.125A2.9 2.9 0 0 0 12 9q-1.25 0-2.125.875A2.9 2.9 0 0 0 9 12q0 1.25.875 2.125A2.9 2.9 0 0 0 12 15m0 2q-2.075 0-3.537-1.463Q7 14.075 7 12q0-2.075 1.463-3.537Q9.926 7 12 7q2.075 0 3.537 1.463Q17 9.926 17 12q0 2.075-1.463 3.537Q14.075 17 12 17M2 13a.97.97 0 0 1-.712-.287A.97.97 0 0 1 1 12q0-.424.288-.713A.97.97 0 0 1 2 11h2q.424 0 .713.287Q5 11.576 5 12q0 .424-.287.713A.97.97 0 0 1 4 13zm18 0a.97.97 0 0 1-.712-.287A.97.97 0 0 1 19 12q0-.424.288-.713A.97.97 0 0 1 20 11h2q.424 0 .712.287.288.288.288.713 0 .424-.288.713A.97.97 0 0 1 22 13zm-8-8a.97.97 0 0 1-.713-.287A.97.97 0 0 1 11 4V2q0-.424.287-.712A.97.97 0 0 1 12 1q.424 0 .713.288Q13 1.575 13 2v2q0 .424-.287.713A.97.97 0 0 1 12 5m0 18a.97.97 0 0 1-.713-.288A.97.97 0 0 1 11 22v-2q0-.424.287-.712A.97.97 0 0 1 12 19q.424 0 .713.288.287.287.287.712v2q0 .424-.287.712A.97.97 0 0 1 12 23M5.65 7.05 4.575 6a.87.87 0 0 1-.288-.7q.014-.425.288-.725.3-.3.725-.3t.7.3L7.05 5.65q.275.3.275.7t-.275.7a.86.86 0 0 1-.687.288 1.07 1.07 0 0 1-.713-.288M18 19.425l-1.05-1.075a1.02 1.02 0 0 1-.275-.713q0-.412.275-.687a.86.86 0 0 1 .688-.287q.412.012.712.287L19.425 18a.87.87 0 0 1 .287.7 1.09 1.09 0 0 1-.287.725q-.3.3-.725.3a.9.9 0 0 1-.7-.3M16.95 7.05a.86.86 0 0 1-.287-.687 1.07 1.07 0 0 1 .287-.713L18 4.575a.87.87 0 0 1 .7-.288q.425.014.725.288.3.3.3.725t-.3.7L18.35 7.05q-.3.275-.7.275t-.7-.275M4.575 19.425q-.3-.3-.3-.725t.3-.7l1.075-1.05q.3-.275.712-.275.414 0 .688.275.3.275.288.688a1.07 1.07 0 0 1-.288.712L6 19.425a.87.87 0 0 1-.7.287 1.09 1.09 0 0 1-.725-.287"
      />
    </Svg>
  );
};
Icon.displayName = 'LightMode';
export const LightMode = memo<IconProps>(themed(Icon));
