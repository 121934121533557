import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#0052b4" />
      <Path
        d="m112.449 155.827 5.526 17.008h17.88l-14.465 10.509 5.525 17.005-14.466-10.509-14.467 10.509 5.527-17.005-14.466-10.509h17.879zM168.101 139.132l5.526 17.007h17.88l-14.465 10.509 5.525 17.005-14.466-10.509-14.466 10.509 5.526-17.005-14.465-10.509h17.88zM223.753 122.436l5.526 17.007h17.88l-14.465 10.51 5.525 17.005-14.466-10.51-14.466 10.51 5.526-17.005-14.465-10.51h17.88zM399.551 155.827l-5.526 17.008h-17.88l14.466 10.509-5.526 17.005 14.466-10.509 14.467 10.509-5.527-17.005 14.466-10.509h-17.88zM343.899 139.132l-5.526 17.007h-17.88l14.466 10.509-5.526 17.005 14.466-10.509 14.466 10.509-5.526-17.005 14.465-10.509h-17.88zM288.247 122.436l-5.526 17.007h-17.88l14.465 10.51-5.525 17.005 14.466-10.51 14.466 10.51-5.526-17.005 14.465-10.51h-17.88z"
        fill="#f0f0f0"
      />
      <Path
        d="M300.522 267.13 256 211.478l-22.261 11.13v22.261l-33.391 22.261h-22.261v28.759c32.628 13.212 55.652 45.181 55.652 82.546H256v-22.261l22.261-22.261 22.261 22.261 22.261-22.261v-22.261l22.261-33.391z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Kosovo';
export const Kosovo = memo<IconProps>(Icon);
