import { createTamagui, setupReactNative } from '@tamagui/core';
import { createAnimations } from '@tamagui/animations-react-native';
import { createMedia } from '@tamagui/react-native-media-driver';
import { Text, TextInput, View } from 'react-native';
import { Gilroy, HankenGrotesk } from './fonts';
import { themes } from './themes';
import { tokens } from './tokens';

setupReactNative({ View, Text, TextInput });

const baseConfig = {
  defaultTheme: 'lightTheme',
  fonts: {
    heading: Gilroy,
    gilroy: Gilroy,
    hankenGrotesk: HankenGrotesk,
  },
  tokens,
  themes,
  media: createMedia({
    xs: { maxWidth: 479 },
    gtXs: { minWidth: 479 + 1 },
    sm: { maxWidth: 759 },
    gtSm: { minWidth: 759 + 1 },
    md: { maxWidth: 1039 },
    gtMd: { minWidth: 1039 + 1 },
    lg: { maxWidth: 1439 },
    gtLg: { minWidth: 1439 + 1 },
    hoverNone: { hover: 'none' },
    pointerCoarse: { pointer: 'coarse' },
  }),
  animations: createAnimations({
    fast: {
      damping: 20,
      mass: 1.2,
      stiffness: 250,
    },
    medium: {
      damping: 10,
      mass: 0.9,
      stiffness: 100,
    },
    slow: {
      damping: 20,
      stiffness: 60,
    },
  }),
};
const themeConfig = createTamagui(baseConfig);

export type Conf = typeof themeConfig;

declare module '@tamagui/core' {
  interface TamaguiCustomConfig extends Conf {}
}

export default themeConfig;
export { themeConfig };
