import { StackProps } from '@tamagui/core';
import { Box } from '../box';

export const Divider = (props: StackProps) => {
  return (
    <Box
      height={1}
      width="100%"
      backgroundColor="$borderDefault"
      marginVertical="$medium"
      {...props}
    />
  );
};
