import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M367.304 25.402C333.648 9.128 295.89 0 256 0s-77.648 9.128-111.304 25.402L122.435 256l22.261 230.598C178.352 502.872 216.11 512 256 512s77.648-9.128 111.304-25.402L389.565 256z"
        fill="#ffda44"
      />
      <Path
        d="m255.998 166.957 22.1 68.02h71.528l-57.864 42.043 22.101 68.023-57.865-42.04-57.865 42.04 22.105-68.023-57.864-42.043h71.523zM144.696 25.411C59.066 66.818 0 154.507 0 256s59.066 189.182 144.696 230.589z"
        fill="#496e2d"
      />
      <Path
        d="M367.304 25.411v461.178C452.934 445.182 512 357.493 512 256S452.934 66.818 367.304 25.411"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'Senegal';
export const Senegal = memo<IconProps>(Icon);
