import { useMutation } from 'react-query';
import request, { ApiResponse, RequestError } from '@/utils/request';
import {
  UniqueRentersStatusVariantEnum,
  useUniqueRentersStore,
} from '../use-unique-renters/use-unique-renters';

export type RequestOtpPayload = {
  phone_number: string;
  email?: string;
};

const requestOtp = async (payload: RequestOtpPayload) => {
  const { data: response } = await request({
    url: 'rest/user/phone-verification/trigger-otp',
    method: 'POST',
    data: payload,
  });

  return response;
};

const useRequestOtp = () => {
  const { setStatus } = useUniqueRentersStore();

  return useMutation<ApiResponse<any>, RequestError, RequestOtpPayload>(
    requestOtp,
    {
      onError: (error) => {
        setStatus({
          status: UniqueRentersStatusVariantEnum.Error,
          message: error.response?.data.status ?? error.message,
        });
      },
    },
  );
};

export default useRequestOtp;
