import * as React from 'react';
import { Svg, Circle } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Circle cx={12} cy={12} r={4} fill={`${color}`} />
    </Svg>
  );
};
Icon.displayName = 'LocationDot';
export const LocationDot = memo<IconProps>(themed(Icon));
