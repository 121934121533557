import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 0c-51.376 0-99.208 15.145-139.3 41.198l65.839 44.129-82.365 42.666 82.365 42.666-82.365 42.666 82.365 42.661-82.365 42.665 82.365 42.665-82.365 42.67 82.365 42.666-65.847 44.143C156.786 496.853 204.621 512 256 512c141.384 0 256-114.616 256-256S397.384 0 256 0"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'Bahrain';
export const Bahrain = memo<IconProps>(Icon);
