import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M0 256c0 97.035 53.989 181.454 133.565 224.873V31.127C53.989 74.546 0 158.965 0 256"
        fill="#f0f0f0"
      />
      <Path
        d="M256 0c-44.35 0-86.064 11.283-122.435 31.127v449.745C169.936 500.717 211.65 512 256 512c141.384 0 256-114.616 256-256S397.384 0 256 0"
        fill="#496e2d"
      />
      <Path
        d="M365.453 298.337c-32.387 23.401-77.613 16.117-101.013-16.269-23.402-32.388-16.117-77.613 16.27-101.013 10.098-7.296 21.444-11.609 32.987-13.108-22.207-3.321-45.682 1.683-65.319 15.872-39.86 28.802-48.827 84.463-20.026 124.325 28.801 39.859 84.463 48.827 124.325 20.023 19.639-14.189 31.76-34.902 35.578-57.031-5.046 10.486-12.703 19.904-22.802 27.201M364.066 166.959l18.244 19.661 24.336-11.272-13.063 23.424 18.243 19.663-26.316-5.185-13.062 23.426-3.201-26.63-26.316-5.185 24.337-11.272z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Pakistan';
export const Pakistan = memo<IconProps>(Icon);
