import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M13.875 22h-3.75a.93.93 0 0 1-.65-.25 1 1 0 0 1-.325-.625l-.3-2.325a4 4 0 0 1-.612-.3 8 8 0 0 1-.563-.375l-2.175.9a1.12 1.12 0 0 1-.7.025.93.93 0 0 1-.55-.425L2.4 15.4a.94.94 0 0 1-.125-.7.96.96 0 0 1 .375-.6l1.875-1.425a2.4 2.4 0 0 1-.025-.338v-.675q0-.162.025-.337L2.65 9.9a.96.96 0 0 1-.375-.6.94.94 0 0 1 .125-.7l1.85-3.225a.79.79 0 0 1 .538-.437q.362-.088.712.037l2.175.9q.276-.2.575-.375.3-.175.6-.3l.3-2.325a1 1 0 0 1 .325-.625q.275-.25.65-.25h3.75q.375 0 .65.25a1 1 0 0 1 .325.625l.3 2.325q.325.125.613.3.287.175.562.375l2.175-.9a1.12 1.12 0 0 1 .7-.025.93.93 0 0 1 .55.425L21.6 8.6a.94.94 0 0 1 .125.7.96.96 0 0 1-.375.6l-1.875 1.425q.025.176.025.338v.675q0 .162-.05.337l1.875 1.425q.3.225.375.6a.94.94 0 0 1-.125.7l-1.85 3.2a.98.98 0 0 1-.562.438q-.363.112-.713-.013l-2.125-.9a7 7 0 0 1-.575.375q-.3.175-.6.3l-.3 2.325a1 1 0 0 1-.325.625.93.93 0 0 1-.65.25m-1.825-6.5q1.45 0 2.475-1.025A3.37 3.37 0 0 0 15.55 12q0-1.45-1.025-2.475A3.37 3.37 0 0 0 12.05 8.5q-1.475 0-2.487 1.025A3.4 3.4 0 0 0 8.55 12q0 1.45 1.013 2.475T12.05 15.5m0-2q-.624 0-1.062-.437A1.45 1.45 0 0 1 10.55 12q0-.625.438-1.062a1.45 1.45 0 0 1 1.062-.438q.625 0 1.063.438.438.437.437 1.062 0 .624-.437 1.063a1.45 1.45 0 0 1-1.063.437M11 20h1.975l.35-2.65q.775-.2 1.438-.588a6 6 0 0 0 1.212-.937l2.475 1.025.975-1.7-2.15-1.625q.126-.35.175-.738a6.1 6.1 0 0 0 0-1.575 3.5 3.5 0 0 0-.175-.737l2.15-1.625-.975-1.7-2.475 1.05a5.6 5.6 0 0 0-1.212-.962 5.6 5.6 0 0 0-1.438-.588L13 4h-1.975l-.35 2.65q-.774.2-1.437.587-.663.388-1.213.938L5.55 7.15l-.975 1.7 2.15 1.6q-.124.375-.175.75a6 6 0 0 0-.05.8q0 .4.05.775t.175.75l-2.15 1.625.975 1.7 2.475-1.05q.55.575 1.213.962.662.388 1.437.588z"
      />
    </Svg>
  );
};
Icon.displayName = 'Settings';
export const Settings = memo<IconProps>(themed(Icon));
