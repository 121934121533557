import create from 'zustand';
import { getMiliseconds } from '@/utils/number-format/number-format';

type CountdownStore = {
  countdown?: number;
  set: (nextCountdown?: number) => void;
};

const useCountdownStore = create<CountdownStore>((set) => ({
  countdown: undefined,
  set: (nextCountdown?: number) => set({ countdown: nextCountdown }),
}));

const useCountdown = () => {
  const { countdown, set } = useCountdownStore();

  const startCountdown = (timeForCountdown: number) => {
    const now = new Date().getTime();
    const renotifyTime = getMiliseconds(timeForCountdown);

    set(now + renotifyTime);
  };

  const stopCountdown = () => set(undefined);

  return {
    countdownActive: countdown,
    startCountdown,
    stopCountdown,
    setCountdownActive: set,
  };
};

export default useCountdown;
