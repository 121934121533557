import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M0 256C0 114.616 114.616 0 256 0s256 114.616 256 256c-11.13 0-256 33.391-256 33.391z"
        fill="#d80027"
      />
      <Path
        d="M512 256c0 141.384-114.616 256-256 256S0 397.384 0 256"
        fill="#000"
      />
      <Path
        d="m220.898 203.612 21.702 15.75-8.27 25.508 21.686-15.776 21.704 15.75-8.302-25.5 21.684-15.774-26.815.016-8.303-25.499-8.271 25.508z"
        fill="#ffda44"
      />
      <Path
        d="M320 145.149c-20.173-11.647-42.23-17.144-64-17.106v33.384a94 94 0 0 1 47.304 12.641c45.178 26.084 60.713 84.06 34.628 129.238-26.083 45.178-84.058 60.713-129.237 34.629a94 94 0 0 1-31.619-29.824L149.2 326.515c10.597 16.109 24.993 30.056 42.8 40.336 61.124 35.29 139.561 14.273 174.85-46.851 35.29-61.124 14.274-139.561-46.85-174.851"
        fill="#ffda44"
      />
      <Path
        d="M182.19 233.739c-8.85 16.179-2.908 36.47 13.27 45.32l108.449 59.26c-7.375 13.481-3.458 29.89 10.023 37.265l29.295 16.025c13.481 7.376 30.392 2.424 37.767-11.058l16.025-29.294z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Angola';
export const Angola = memo<IconProps>(Icon);
