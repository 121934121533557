import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M8.996 7.2a.4.4 0 0 0-.4.4v8.8a.4.4 0 0 0 .632.325l7.934-4.362a.4.4 0 0 0-.01-.732L9.228 7.274h-.001a.4.4 0 0 0-.232-.074"
      />
      <Path
        fill={`${color}`}
        d="M19.89 16.732c.379.227.505.72.248 1.08a10 10 0 1 1 .052-11.55c.253.362.123.854-.258 1.078-.38.224-.868.094-1.127-.264a8.4 8.4 0 1 0-.044 9.91c.262-.356.75-.482 1.129-.255"
      />
    </Svg>
  );
};
Icon.displayName = 'Carplay';
export const Carplay = memo<IconProps>(themed(Icon));
