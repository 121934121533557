enum DcsFlags {
  AirportInstructions = 'airport-instructions-config',
  DisableSubscriptionSearch = 'disable-subscription-search',
  EnableIntercom = 'enable-intercom',
  EnableHandoverOutpost = 'enable-handover-outpost',
  EnableSelfPickup = 'enable-self-pickup',
  EnableLegBasedSelfPickup = 'enable-leg-based-self-pickup',
  GuestCheckout = 'exp_guest_checkout_service_area',
  PricingExperiment = 'exp_hidden_delivery_fees_v2',
  RefineCityAddress = 'refine-city-address',
  SentryErrorsToIgnore = 'consumer-web-ignored-errors',
  ServiceAreaLabels = 'service-area-labels',
  TimeSlotsForceUpdate = 'time-slots-force-update-for-web',
  VehicleClassInfoPage = 'vehicle-class-info-page',
  YoungRenterFee = 'young-renter-fee',
  FreeUnlimitedMileage = 'free-unlimited-mileage-experiment',
  EnableQuotesV3 = 'enable-quotes-v3',
  CheckoutV2Sections = 'checkout-v2-sections',
  EnableRememberRecentSearches = 'enable-remember-recent-searches',
  AdditionalPaymentMethods = 'enable-additional-payment-methods-ff',
  EnableApplePayDefault = 'enable-apple-pay-default',
  EnableOnboardingV2 = 'enable-new-onboarding-flow',
  TypeformSurvey = 'typeform-survey',
  EnableAuthEndpoints = 'enable-auth-endpoints',
  TransparentFeesDialogConfig = 'transparent-fees-dialog-config',
  EnableDiscountedOTCBundle = 'enable-discounted-otc-bundle-web',
  EnableStripePaymentElements = 'enable-stripe-payment-elements',
  EnableUniqueRenters = 'enable-unique-renters',
  MerchandisingConfig = 'merchandising-config',
  UpsellConfig = 'upsell-config',
}

export type TimeslotsForceUpdate = {
  allowedUsers: string[];
} | null;

export default DcsFlags;
