import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill="#3C4043"
        d="M11.71 12.304v2.61h-.843V8.47H13.1q.851 0 1.444.556.605.557.605 1.359c0 .548-.201 1-.605 1.367q-.586.55-1.444.548h-1.39zm0-3.04v2.247h1.408c.333 0 .614-.113.834-.333a1.077 1.077 0 0 0 0-1.57 1.1 1.1 0 0 0-.834-.34H11.71zM17.352 10.359q.935 0 1.475.492.54.491.54 1.345v2.717h-.804v-.612h-.035q-.52.755-1.39.755-.743 0-1.242-.432a1.37 1.37 0 0 1-.5-1.078q0-.686.526-1.087.527-.406 1.404-.405.752 0 1.233.272v-.19a.93.93 0 0 0-.347-.733 1.2 1.2 0 0 0-.816-.302q-.705-.001-1.114.586l-.742-.457q.614-.872 1.812-.871m-1.088 3.2a.65.65 0 0 0 .281.54q.277.213.654.215.533 0 .947-.388.416-.388.417-.91-.393-.306-1.097-.307-.513.001-.855.242-.348.252-.347.608M23.947 10.501l-2.808 6.349h-.869l1.044-2.221-1.851-4.128h.917l1.334 3.166h.017L23.03 10.5z"
      />
      <Path
        fill="#4285F4"
        d="M8.36 11.778q-.001-.404-.071-.777H4.758v1.423H6.79a1.7 1.7 0 0 1-.754 1.147v.924h1.21c.707-.643 1.112-1.594 1.112-2.717"
      />
      <Path
        fill="#34A853"
        d="M6.037 13.571c-.337.224-.77.354-1.278.354-.981 0-1.813-.65-2.11-1.525h-1.25v.952a3.77 3.77 0 0 0 3.36 2.035c1.015 0 1.868-.328 2.489-.893z"
      />
      <Path
        fill="#FABB05"
        d="M2.53 11.694c0-.246.042-.484.118-.707v-.952H1.4a3.6 3.6 0 0 0-.4 1.659c0 .597.144 1.16.4 1.659l1.248-.952a2.2 2.2 0 0 1-.117-.707"
      />
      <Path
        fill="#E94235"
        d="M4.759 9.462c.554 0 1.05.188 1.442.554l1.072-1.053A3.65 3.65 0 0 0 4.76 8c-1.469 0-2.74.828-3.36 2.035l1.25.952a2.245 2.245 0 0 1 2.11-1.525"
      />
    </Svg>
  );
};
Icon.displayName = 'Googlepay';
export const Googlepay = memo<IconProps>(themed(Icon));
