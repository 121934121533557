import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 512.001 512.001"
      {...otherProps}
    >
      <Path
        d="M512 256.001a258 258 0 0 0-2.167-33.391H222.606C170.305 170.312 74.976 74.989 74.98 74.981c-99.974 99.974-99.974 262.064 0 362.04l147.629-147.628h287.224A259 259 0 0 0 512 256.001"
        fill="#000"
      />
      <Path
        d="M511.927 261.823q-.016.742-.036 1.484zM511.681 268.819c-.023.489-.057.976-.083 1.464.026-.487.058-.975.083-1.464M511.336 274.399c-.051.719-.101 1.438-.159 2.155.058-.717.107-1.436.159-2.155M510.481 228.099c.094.856.188 1.711.273 2.569-.085-.859-.18-1.713-.273-2.569M511.597 241.718c.027.488.06.975.083 1.464-.024-.489-.056-.977-.083-1.464M511.89 248.696q.02.742.036 1.484-.017-.744-.036-1.484M511.177 235.447c.058.717.108 1.436.159 2.155-.052-.719-.101-1.438-.159-2.155"
        fill="#f0f0f0"
      />
      <Path
        d="M509.833 222.609c.115.877.217 1.759.322 2.638C494.961 98.365 386.975.001 256 .001c-70.694 0-134.695 28.655-181.022 74.984L222.602 222.61h287.231z"
        fill="#d80027"
      />
      <Path
        d="M509.833 289.392H222.602L74.978 437.017c46.327 46.328 110.328 74.984 181.022 74.984 130.975 0 238.962-98.364 254.155-225.247-.105.879-.209 1.761-.322 2.638"
        fill="#6da544"
      />
      <Path
        d="M510.753 281.333c-.085.858-.179 1.713-.273 2.569.094-.856.189-1.71.273-2.569"
        fill="#f0f0f0"
      />
      <Path d="M511.89 263.307" fill="#000" />
      <Path
        d="M511.756 244.778H213.201L59.844 91.444c-4.957 5.228-10.33 12.528-13.653 17.727L192.85 255.907 46.068 402.692c2.906 5.17 9.237 12.822 13.682 17.801l153.454-153.454h298.588c.159-3.692.209-7.4.209-11.13a249 249 0 0 0-.245-11.131"
        fill="#ffda44"
      />
      <Path
        d="M62.187 310.573V289.13c21.398 0 38.805-17.409 38.805-38.805 0-15.139-12.317-27.455-27.455-27.455-10.313 0-18.704 8.39-18.704 18.705 0 6.595 5.365 11.961 11.961 11.961 3.728 0 6.761-3.035 6.761-6.763h21.442c0 15.553-12.652 28.205-28.202 28.205-18.419.001-33.402-14.985-33.402-33.401 0-22.137 18.01-40.146 40.145-40.146 26.962 0 48.898 21.936 48.898 48.897-.001 33.217-27.028 60.245-60.249 60.245"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Vanuatu';
export const Vanuatu = memo<IconProps>(Icon);
