import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M18 15v-2h2q.424 0 .712.287.288.288.288.713 0 .424-.288.713A.97.97 0 0 1 20 15zm0 4v-2h2q.424 0 .712.288.288.287.288.712 0 .424-.288.712A.97.97 0 0 1 20 19zm-4 1q-.825 0-1.412-.587A1.93 1.93 0 0 1 12 18h-2v-4h2q0-.825.588-1.412A1.93 1.93 0 0 1 14 12h2q.424 0 .712.287.288.288.288.713v6q0 .424-.288.712A.97.97 0 0 1 16 20zm-7-3q-1.65 0-2.825-1.175T3 13t1.175-2.825T7 9h1.5q.624 0 1.063-.437Q10 8.124 10 7.5t-.437-1.062A1.45 1.45 0 0 0 8.5 6H5a.97.97 0 0 1-.713-.287A.97.97 0 0 1 4 5q0-.424.287-.713A.97.97 0 0 1 5 4h3.5q1.45 0 2.475 1.025A3.37 3.37 0 0 1 12 7.5q0 1.45-1.025 2.475A3.37 3.37 0 0 1 8.5 11H7q-.824 0-1.412.588A1.93 1.93 0 0 0 5 13q0 .825.588 1.412Q6.175 15 7 15h2v2z"
      />
    </Svg>
  );
};
Icon.displayName = 'ElectricalServices';
export const ElectricalServices = memo<IconProps>(themed(Icon));
