import { FunctionComponent } from 'react';
import { BaseButton, BaseButtonProps } from '../base-button';
import { Box } from '../box';
import { LoadingIndicator } from '../loading-indicator';
import { ThemeTokens } from '@tamagui/core';

export type { ButtonSize, ButtonVariant } from '../base-button';

export type ButtonProps = BaseButtonProps;

export const Button = BaseButton.styleable(
  (
    {
      loading,
      disabled,
      variant,
      state = 'default',
      children,
      icon,
      ...props
    }: ButtonProps,
    ref,
  ) => {
    const getTextColor: () => ThemeTokens = () => {
      if (disabled) {
        return '$contentDisabled';
      }

      if (variant === 'primary') {
        return '$contentInversePrimary';
      }

      if (state === 'danger') {
        return '$contentNegative';
      }

      return '$contentPrimary';
    };

    return (
      <BaseButton
        ref={ref}
        {...props}
        variant={variant}
        state={state}
        loading={loading}
        disabled={disabled}
      >
        {loading && (
          <Box position="absolute">
            <LoadingIndicator />
          </Box>
        )}
        <Box
          opacity={loading ? 0 : 1}
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          {icon && (
            <Box marginRight="$xSmall">
              <BaseButton.Icon>{icon}</BaseButton.Icon>
            </Box>
          )}
          <BaseButton.Text color={getTextColor()}>{children}</BaseButton.Text>
        </Box>
      </BaseButton>
    );
  },
) as FunctionComponent<ButtonProps>;
