import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M367.304 25.402C333.648 9.128 295.89 0 256 0s-77.648 9.128-111.304 25.402L122.435 256l22.261 230.598C178.352 502.872 216.11 512 256 512s77.648-9.128 111.304-25.402L389.565 256z"
        fill="#ffda44"
      />
      <Path
        d="M144.696 25.411C59.066 66.818 0 154.507 0 256s59.066 189.182 144.696 230.589z"
        fill="#0052b4"
      />
      <Path
        d="M367.304 25.411v461.178C452.934 445.182 512 357.493 512 256S452.934 66.818 367.304 25.411"
        fill="#d80027"
      />
      <Path
        d="M345.043 201.419h-61.217c0-15.368-12.458-27.826-27.826-27.826s-27.826 12.458-27.826 27.826h-61.217c0 15.131 13.181 27.396 28.31 27.396h-.912c0 15.132 12.266 27.399 27.398 27.399 0 13.397 9.622 24.529 22.33 26.909l-21.59 48.742A88.8 88.8 0 0 0 256 338.407a88.8 88.8 0 0 0 33.506-6.542l-21.589-48.742c12.708-2.38 22.33-13.512 22.33-26.909 15.132 0 27.398-12.267 27.398-27.399h-.913c15.131 0 28.311-12.266 28.311-27.396"
        fill="#ff9811"
      />
      <Path
        d="M256 239.304 219.826 256v33.391L256 311.652l36.174-22.261V256z"
        fill="#0052b4"
      />
      <Path d="M219.826 222.609h72.348V256h-72.348z" fill="#d80027" />
    </Svg>
  );
};
Icon.displayName = 'Moldova';
export const Moldova = memo<IconProps>(Icon);
