import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#6da544" />
      <Path
        d="m392.984 367.304 7.025 14.689 15.864-3.666-7.104 14.651 12.757 10.116-15.884 3.58.045 16.283-12.703-10.187-12.702 10.187.044-16.283-15.883-3.58 12.757-10.116-7.104-14.651 15.863 3.666zM327.632 211.478l7.026 14.689 15.864-3.667-7.105 14.652 12.757 10.116-15.883 3.58.044 16.282-12.703-10.186-12.702 10.186.043-16.282-15.882-3.58 12.757-10.116-7.105-14.652 15.865 3.667zM392.984 122.435l7.025 14.689 15.864-3.668-7.103 14.652 12.756 10.117-15.884 3.579.043 16.283-12.701-10.187-12.702 10.187.044-16.283-15.882-3.579 12.756-10.117-7.104-14.652 15.863 3.668zM450.067 189.217l7.025 14.689 15.865-3.667-7.105 14.652 12.757 10.116-15.883 3.58.044 16.283-12.703-10.187-12.702 10.187.043-16.283-15.882-3.58 12.757-10.116-7.105-14.652 15.864 3.667zM409.249 267.13l5.527 17.007h17.881l-14.467 10.51 5.527 17.005-14.468-10.509-14.465 10.509 5.525-17.005-14.466-10.51h17.881z"
        fill="#ffda44"
      />
      <Path
        d="M283.826 328.348c-39.957 0-72.349-32.392-72.349-72.348s32.391-72.348 72.349-72.348c12.458 0 24.181 3.151 34.415 8.696-16.055-15.702-38.01-25.392-62.243-25.392-49.176 0-89.042 39.866-89.042 89.043s39.866 89.043 89.042 89.043c24.232 0 46.188-9.69 62.242-25.392-10.233 5.547-21.956 8.698-34.414 8.698M160.986 100.174c-7.7-13.304-22.075-22.261-38.551-22.261s-30.851 8.957-38.551 22.261zM161.134 100.431l-38.699 38.699-38.699-38.699a44.3 44.3 0 0 0-5.822 22.004c0 24.589 19.933 44.522 44.522 44.522s44.522-19.932 44.522-44.522a44.3 44.3 0 0 0-5.824-22.004"
        fill="#ffda44"
      />
      <Path
        d="M111.304 128v37.554a44.6 44.6 0 0 0 11.13 1.402c3.843 0 7.573-.488 11.13-1.402V128z"
        fill="#a2001d"
      />
    </Svg>
  );
};
Icon.displayName = 'CocosIslands';
export const CocosIslands = memo<IconProps>(Icon);
