import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M367.304 25.402C333.648 9.128 295.891 0 256 0s-77.648 9.128-111.304 25.402L122.435 256l22.261 230.598C178.352 502.872 216.109 512 256 512s77.648-9.128 111.304-25.402L389.565 256z"
        fill="#ffda44"
      />
      <Path
        d="M144.696 25.411C59.065 66.818 0 154.507 0 256s59.065 189.182 144.696 230.589zM367.304 25.411v461.178C452.934 445.182 512 357.493 512 256S452.934 66.818 367.304 25.411"
        fill="#0052b4"
      />
      <Path
        d="m328.348 278.261 16.695 22.261v-89.044l-16.695 22.261h-50.087v-50.087l22.261-16.695h-89.044l22.261 16.695v50.087h-50.087l-16.695-22.261v89.044l16.695-22.261h50.087v50.087l-22.261 16.695h89.044l-22.261-16.695v-50.087z"
        fill="#d80027"
      />
      <Path
        d="M322.783 244.87H267.13v-55.653h-22.26v55.653h-55.653v22.26h55.653v55.653h22.26V267.13h55.653z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Madeira';
export const Madeira = memo<IconProps>(Icon);
