import { getTokenValue } from '@tamagui/core';
import { Dialog as DialogTM } from '@tamagui/dialog';
import React, { Children, isValidElement } from 'react';
import { Box } from '../box';
import { DialogContext } from './context/dialog-context';
import { DialogBody } from './dialog-body';
import { DialogContent } from './dialog-content';
import { DialogFooter } from './dialog-footer';
import { DialogHeader } from './dialog-header';
import { DialogTitle } from './dialog-title';
import { DialogVariant } from './dialog.types';

export type DialogProps = {
  children: React.ReactNode;
  testID?: string;
  visible: boolean;
  variant?: DialogVariant;
  onRequestToClose: (visible: boolean) => void;
  renderTopContent?: () => React.ReactNode;
};

const modalContentSizes = {
  xSmall: 358,
  small: 480,
  medium: 640,
  large: 800,
};

const Dialog = ({
  children,
  variant = 'small',
  visible,
  onRequestToClose,
}: DialogProps) => {
  const hasHeader = Children.toArray(children).some(
    (item) => isValidElement(item) && item.type === DialogHeader,
  );

  const contentWidth = Math.min(
    modalContentSizes[variant],
    window.innerWidth - 2 * getTokenValue('$small', 'space'), // we want to handle the situation when viewport is smaller than the modal width (including side margins 16px each)
  );

  return (
    <DialogTM
      modal
      open={visible}
      onOpenChange={(open) => {
        onRequestToClose(open);
      }}
    >
      <DialogTM.Portal>
        <DialogTM.Overlay
          key="overlay"
          opacity={0.5}
          backgroundColor="rgba(0, 0, 0, 0.3)"
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
        />
        <DialogTM.Content
          style={{
            border: 'none',
            borderRadius: 8,
            padding: 0,
            overflow: 'hidden',
            maxWidth: contentWidth,
          }}
          key="content"
        >
          <DialogContext.Provider
            variant={variant}
            hasHeader={hasHeader}
            contentWidth={contentWidth}
          >
            <Box
              flexDirection={
                variant === 'large' && hasHeader ? 'row' : 'column'
              }
            >
              {children}
            </Box>
          </DialogContext.Provider>
        </DialogTM.Content>
      </DialogTM.Portal>
    </DialogTM>
  );
};

Dialog.Header = DialogHeader;
Dialog.Content = DialogContent;
Dialog.Title = DialogTitle;
Dialog.Body = DialogBody;
Dialog.Footer = DialogFooter;

export default Dialog;
export { Dialog };
