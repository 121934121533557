import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M12 14q-.825 0-1.412-.588A1.93 1.93 0 0 1 10 12q0-.825.588-1.412A1.93 1.93 0 0 1 12 10q.825 0 1.412.588Q14 11.175 14 12t-.588 1.412A1.93 1.93 0 0 1 12 14m-7.75 4.325q-1.05-1.3-1.65-2.912A9.7 9.7 0 0 1 2 12q0-1.825.6-3.425t1.65-2.9a.86.86 0 0 1 .688-.325q.412 0 .712.3.275.274.3.688.025.412-.25.762a8.2 8.2 0 0 0-1.25 2.263Q4 10.6 4 12a7.86 7.86 0 0 0 1.675 4.9q.274.35.263.763a1.02 1.02 0 0 1-.313.712.916.916 0 0 1-1.375-.05m2.85-2.85q-.524-.75-.812-1.625A5.9 5.9 0 0 1 6 12q0-.975.287-1.875.288-.9.813-1.625a.76.76 0 0 1 .638-.338q.411-.012.737.313.275.274.287.688.013.412-.212.787-.274.45-.413.963A4.2 4.2 0 0 0 8 12q0 .575.137 1.088.138.512.413.962.225.375.2.8a1.04 1.04 0 0 1-.3.7q-.3.3-.712.275a.8.8 0 0 1-.638-.35m8.425.05a.98.98 0 0 1-.287-.687 1.4 1.4 0 0 1 .212-.788q.274-.45.413-.963A4.2 4.2 0 0 0 16 12a4.2 4.2 0 0 0-.137-1.088 3.7 3.7 0 0 0-.413-.962 1.37 1.37 0 0 1-.2-.8 1.04 1.04 0 0 1 .3-.7.93.93 0 0 1 .712-.287.76.76 0 0 1 .638.337q.524.725.813 1.625.287.9.287 1.875t-.288 1.85a6.2 6.2 0 0 1-.812 1.625q-.225.326-.65.338a.95.95 0 0 1-.725-.288m2.85 2.85a1.1 1.1 0 0 1-.312-.7 1.06 1.06 0 0 1 .237-.775 8.2 8.2 0 0 0 1.25-2.262Q20 13.4 20 12a7.85 7.85 0 0 0-1.675-4.9 1.15 1.15 0 0 1-.262-.762 1.02 1.02 0 0 1 .312-.713.916.916 0 0 1 1.375.05q1.05 1.3 1.65 2.9T22 12q0 1.8-.6 3.412-.6 1.613-1.65 2.913a.916.916 0 0 1-1.375.05"
      />
    </Svg>
  );
};
Icon.displayName = 'Sensors';
export const Sensors = memo<IconProps>(themed(Icon));
