import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#6da544" />
      <Path
        d="M256 100.174 467.478 256 256 411.826 44.522 256z"
        fill="#ffda44"
      />
      <Circle cx={256} cy={256} r={89.043} fill="#f0f0f0" />
      <Path
        d="M211.478 250.435c-15.484 0-30.427 2.355-44.493 6.725.623 48.64 40.227 87.884 89.015 87.884 30.168 0 56.812-15.017 72.919-37.968-27.557-34.497-69.958-56.641-117.441-56.641M343.393 273.06a89.5 89.5 0 0 0 1.651-17.06c0-49.178-39.866-89.043-89.043-89.043-36.694 0-68.194 22.201-81.826 53.899a183.7 183.7 0 0 1 37.305-3.812c51.717-.001 98.503 21.497 131.913 56.016"
        fill="#0052b4"
      />
    </Svg>
  );
};
Icon.displayName = 'Brazil';
export const Brazil = memo<IconProps>(Icon);
