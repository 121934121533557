import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 0C172.248 0 97.893 40.223 51.189 102.4h409.622C414.107 40.223 339.752 0 256 0M256 512c83.752 0 158.107-40.223 204.811-102.4H51.189C97.893 471.777 172.248 512 256 512M0 256a257 257 0 0 0 5.127 51.2h501.748C510.234 290.657 512 273.535 512 256s-1.768-34.657-5.126-51.2H5.127A257 257 0 0 0 0 256"
        fill="#d80027"
      />
      <Path
        d="M74.98 74.98c-99.974 99.974-99.974 262.065 0 362.04L256 256z"
        fill="#0052b4"
      />
      <Path
        d="m103.61 189.217 16.575 51.016h53.646l-43.398 31.532 16.574 51.018-43.397-31.531-43.399 31.531 16.578-51.018-43.398-31.532h53.642z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'PuertoRico';
export const PuertoRico = memo<IconProps>(Icon);
