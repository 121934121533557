import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M367.304 25.402C333.648 9.128 295.89 0 256 0s-77.648 9.128-111.304 25.402L122.435 256l22.261 230.598C178.352 502.872 216.11 512 256 512s77.648-9.128 111.304-25.402L389.565 256z"
        fill="#ffda44"
      />
      <Path
        d="M144.696 25.411C59.066 66.817 0 154.506 0 256s59.066 189.183 144.696 230.589zM367.304 25.411v461.178C452.934 445.183 512 357.493 512 256S452.934 66.817 367.304 25.411"
        fill="#0052b4"
      />
      <Path
        d="m333.913 155.826 14.933 7.466zl-14.934-7.466c-.9 1.801-20.399 41.474-23.584 102.075h-22.699v-94.609L256 133.565l-16.696 22.261v94.609h-22.699c-3.186-60.601-22.684-100.274-23.585-102.075l-29.866 14.933c.206.41 20.499 41.715 20.499 103.838v16.696h55.652v94.609h33.391v-94.609h55.652V267.13c0-32.118 5.557-58.602 10.22-75.164 5.083-18.06 10.246-28.607 10.297-28.712z"
        fill="#000"
      />
    </Svg>
  );
};
Icon.displayName = 'Barbados';
export const Barbados = memo<IconProps>(Icon);
