import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 512c77.591 0 147.114-34.524 194.061-89.043H61.939C108.886 477.476 178.409 512 256 512M512 256C512 114.616 397.384 0 256 0S0 114.616 0 256c0 23.107 3.08 45.489 8.819 66.783h494.363C508.92 301.489 512 279.107 512 256"
        fill="#0052b4"
      />
      <Path
        d="M20.348 356.174a255 255 0 0 0 17.227 33.391h436.852a255.6 255.6 0 0 0 17.227-33.391z"
        fill="#d80027"
      />
      <Path
        d="m182.99 272.696 4.144 12.754h13.411l-10.849 7.883 4.144 12.754-10.85-7.883-10.85 7.883 4.145-12.754-10.849-7.883h13.411zM182.99 439.652l4.144 12.755h13.411l-10.849 7.882 4.144 12.754-10.85-7.882-10.85 7.882 4.145-12.754-10.849-7.882h13.411zM105.274 383.189l4.144 12.753h13.411l-10.849 7.882 4.144 12.756-10.85-7.883-10.85 7.883 4.146-12.756-10.85-7.882h13.41zM134.959 289.391l4.144 12.755h13.41l-10.848 7.882 4.144 12.755-10.85-7.883-10.85 7.883 4.146-12.755-10.851-7.882h13.411zM87.72 341.915l13.408.001 4.145-12.757 4.145 12.756 13.411-.001-10.85 7.883 4.145 12.755-10.851-7.883-10.849 7.88 4.144-12.753zM124.11 453.911l4.144-12.752-10.851-7.882h13.411l4.145-12.755 4.145 12.754h13.41l-10.85 7.882 4.144 12.756-10.85-7.884zM260.677 383.189l-4.143 12.753h-13.411l10.848 7.882-4.144 12.756 10.85-7.883 10.85 7.883-4.145-12.756 10.85-7.882h-13.411zM230.992 289.391l-4.143 12.755h-13.411l10.848 7.882-4.144 12.755 10.85-7.883 10.85 7.883-4.145-12.755 10.85-7.882h-13.411zM278.232 341.915l-13.409.001-4.145-12.757-4.144 12.756-13.411-.001 10.849 7.883-4.145 12.755 10.851-7.883 10.849 7.88-4.144-12.753zM241.841 453.911l-4.143-12.752 10.85-7.882h-13.411l-4.145-12.755-4.144 12.754h-13.411l10.85 7.882-4.144 12.756 10.85-7.884z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'CapeVerde';
export const CapeVerde = memo<IconProps>(Icon);
