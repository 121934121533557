import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M0 256C0 114.616 114.616 0 256 0s256 114.616 256 256z"
        fill="#f0f0f0"
      />
      <Path
        d="M189.217 242.005 175.222 256h13.995zM322.783 242.006V256h13.994zM7.386 194.784A256.5 256.5 0 0 0 0 256h80.777l61.216-61.216zM370.005 194.784 431.221 256H512c0-21.101-2.572-41.6-7.386-61.216z"
        fill="#0052b4"
      />
      <Path
        d="M354.264 194.784h-31.481L383.999 256h31.482zM189.217 226.263v-31.479h-.002L127.999 256h31.482z"
        fill="#d80027"
      />
      <Path
        d="M119.922 39.142a257 257 0 0 0-30.026 22.075h52.1zM422.104 61.217a257 257 0 0 0-30.024-22.075l-22.074 22.075z"
        fill="#0052b4"
      />
      <Path
        d="M289.391 161.391h204.55c-9.678-24.319-22.98-46.797-39.233-66.783H289.391V2.167A259 259 0 0 0 256 0c-11.319 0-22.461.744-33.391 2.167v92.441H57.292c-16.253 19.986-29.555 42.464-39.233 66.783h204.549V256h66.783v-94.609"
        fill="#d80027"
      />
      <Path
        d="M189.217 61.217zl-38.593-38.593a255 255 0 0 0-20.856 10.625l27.969 27.969zM322.783 61.217l38.594-38.593a255 255 0 0 0-22.558-8.924l-16.036 16.037z"
        fill="#d80027"
      />
      <Path
        d="M512 256c0 141.384-114.616 256-256 256S0 397.384 0 256z"
        fill="#f0f0f0"
      />
      <Path
        d="M469.33 288.65c-21.333 0-21.333-19.506-42.667-19.506-21.333 0-21.333 19.506-42.665 19.506s-21.333-19.506-42.665-19.506S320 288.65 298.666 288.65s-21.335-19.506-42.67-19.506c-21.333 0-21.333 19.506-42.665 19.506-21.33 0-21.33-19.506-42.663-19.506-21.335 0-21.335 19.506-42.67 19.506s-21.335-19.506-42.67-19.506c-21.33 0-21.33 19.506-42.663 19.506-21.218 0-21.341-19.291-42.332-19.497a256 256 0 0 0 4.103 34.448c17.004 2.499 18.493 19.182 38.23 19.182 21.333 0 21.333-19.504 42.663-19.504 21.335 0 21.335 19.504 42.67 19.504s21.335-19.504 42.67-19.504c21.333 0 21.333 19.504 42.663 19.504 21.333 0 21.333-19.504 42.665-19.504 21.335 0 21.335 19.504 42.67 19.504s21.335-19.504 42.667-19.504 21.333 19.504 42.665 19.504 21.333-19.504 42.665-19.504c21.335 0 21.335 19.504 42.667 19.504 19.739 0 21.228-16.682 38.233-19.181a256.5 256.5 0 0 0 4.105-34.448c-20.995.207-21.12 19.496-42.339 19.496M426.663 335.927c-21.333 0-21.333 19.506-42.665 19.506s-21.333-19.506-42.665-19.506-21.333 19.506-42.667 19.506-21.335-19.506-42.67-19.506c-21.333 0-21.333 19.506-42.665 19.506-21.33 0-21.33-19.506-42.663-19.506-21.335 0-21.335 19.506-42.67 19.506s-21.335-19.506-42.67-19.506c-21.33 0-21.33 19.506-42.663 19.506-15.243 0-19.597-9.956-28.622-15.644a254.7 254.7 0 0 0 23.237 49.308c1.637.302 3.419.469 5.385.469 21.333 0 21.333-19.504 42.663-19.504 21.335 0 21.335 19.504 42.67 19.504s21.335-19.504 42.67-19.504c21.333 0 21.333 19.504 42.663 19.504 21.333 0 21.333-19.504 42.665-19.504 21.335 0 21.335 19.504 42.67 19.504s21.335-19.504 42.667-19.504 21.333 19.504 42.665 19.504 21.333-19.504 42.665-19.504c21.335 0 21.335 19.504 42.667 19.504 1.967 0 3.75-.167 5.387-.47a254.8 254.8 0 0 0 23.239-49.306c-9.026 5.685-13.38 15.643-28.626 15.643-21.332 0-21.332-19.506-42.667-19.506"
        fill="#0052b4"
      />
      <Path
        d="M511.862 247.957c.022.711.047 1.422.063 2.136-.016-.713-.042-1.425-.063-2.136M.075 250.092c.017-.713.041-1.424.063-2.136-.021.712-.047 1.424-.063 2.136M0 256c0 4.663.132 9.296.381 13.897 20.943.235 21.082 19.494 42.285 19.494 21.333 0 21.333-19.504 42.663-19.504 21.335 0 21.335 19.504 42.67 19.504s21.335-19.504 42.67-19.504c21.333 0 21.333 19.504 42.663 19.504 21.333 0 21.333-19.504 42.665-19.504 21.335 0 21.335 19.504 42.67 19.504s21.335-19.504 42.667-19.504 21.333 19.504 42.665 19.504 21.333-19.504 42.665-19.504c21.335 0 21.335 19.504 42.667 19.504 21.205 0 21.344-19.259 42.291-19.494.246-4.602.378-9.234.378-13.897zM511.925 250.092"
        fill="#f0f0f0"
      />
      <Path
        d="M426.663 402.709c-21.333 0-21.333 19.506-42.665 19.506s-21.333-19.506-42.665-19.506-21.333 19.506-42.667 19.506-21.335-19.506-42.67-19.506c-21.333 0-21.333 19.506-42.665 19.506-21.33 0-21.33-19.506-42.663-19.506-21.335 0-21.335 19.506-42.67 19.506s-21.335-19.506-42.67-19.506c-14.734 0-19.293 9.304-27.733 15.061 22.389 27.426 50.359 50.11 82.164 66.352L256 478.266l116.24 5.855a257.1 257.1 0 0 0 82.163-66.35c-8.444-5.756-13.003-15.062-27.74-15.062"
        fill="#0052b4"
      />
      <Path
        d="m397.983 408.814-77.875 14.895-9.866-78.665L256 402.899l-54.24-57.856-9.87 78.665-77.871-14.895 38.189 69.452-12.472 5.866C174.624 501.949 214.137 512 256 512s81.376-10.051 116.264-27.867l-12.467-5.865z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'BritishColumbia';
export const BritishColumbia = memo<IconProps>(Icon);
