import { useMemo } from 'react';
import { Trip } from '@/types/trip';
import { getCurrentLegType } from '@/utils/get-leg-params/get-leg-params';
import { useMediaQuery, useTheme } from '@drivekyte/web-components';
import create from 'zustand';
import useMe from '../use-me';
import {
  CustomSupportContextProps,
  initialMeSupport,
  initialCustomSupport,
} from './types';
import useSegmentedChatExperiment from '../experiments/use-segmented-chat/use-segmented-chat-experiment';

type CustomerSupportStore = {
  intercomLoaded: boolean;
  intercomLauncherVisible: boolean;
  supportContextProps: CustomSupportContextProps;
  setSupportContextProps: (supportContextProps: any) => void;
  setIntercomLauncherVisible: (intercomLauncherVisible: boolean) => void;
  setIntercomLoaded: (intercomLoaded: boolean) => void;
};

export const useCustomerSupportStore = create<CustomerSupportStore>((set) => ({
  intercomLoaded: false,
  intercomLauncherVisible: false,
  supportContextProps: initialCustomSupport,
  setSupportContextProps: (
    supportContextNextProps: CustomSupportContextProps,
  ) => set(() => ({ supportContextProps: supportContextNextProps })),
  setIntercomLauncherVisible: (intercomLauncherVisible: boolean) =>
    set(() => ({ intercomLauncherVisible })),
  setIntercomLoaded: (intercomLoaded: boolean) =>
    set(() => ({ intercomLoaded })),
}));

const useCustomerSupportVisibility = () => {
  const { me, isAuthenticated } = useMe();
  const [theme] = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme?.breakpoints?.sm})`);
  const source = isMobile ? 'web_mobile' : 'web_desktop';

  const { enabled: isSegmentedChatEnabled, isLoading: isSegmentedChatLoading } =
    useSegmentedChatExperiment();

  const {
    intercomLoaded,
    supportContextProps,
    intercomLauncherVisible,
    setSupportContextProps,
    setIntercomLoaded,
    setIntercomLauncherVisible,
  } = useCustomerSupportStore();

  const intercomUserSessionActive =
    typeof sessionStorage !== 'undefined' &&
    sessionStorage.getItem('intercom-session-active') === 'true';

  const intercomShouldBeVisible =
    !isSegmentedChatLoading &&
    (isSegmentedChatEnabled
      ? intercomLauncherVisible || intercomUserSessionActive
      : true);

  const setIntercomVisibility = (value: boolean) => {
    setIntercomLauncherVisible(value);
  };

  const addIntercomSupportContextProps = (route: string, data?: Trip) => {
    if (!isSegmentedChatEnabled) return;

    if (route === 'trip-details' && data) {
      const { delivery_leg: deliveryLeg, return_leg: returnLeg } = data as Trip;

      let currentLeg = {};
      const isDeliveryPickupAtLot = deliveryLeg.is_pickup_at_lot;
      const isReturnPickupAtLot = returnLeg.is_pickup_at_lot;
      const isDeliveryAirport = !!data.airport_info?.start_address;
      const isReturnAirport = !!data.airport_info?.end_address;

      const currentLegType = getCurrentLegType(data);

      const deliveryLegType = isDeliveryAirport
        ? 'airport'
        : isDeliveryPickupAtLot
        ? 'lot'
        : 'address';

      const returnLegType = isReturnAirport
        ? 'airport'
        : isReturnPickupAtLot
        ? 'lot'
        : 'address';

      if (currentLegType) {
        currentLeg = {
          current_leg: currentLegType,
          current_leg_state: data.current_leg_state,
        };
      }

      setSupportContextProps({
        ...initialCustomSupport,
        ...currentLeg,
        trip_uuid: data.uuid,
        trip_status: data.status,
        trip_list_status: data.list_status,
        first_leg_type: deliveryLegType,
        second_leg_type: returnLegType,
        first_leg_market: deliveryLeg?.service_area?.code,
        second_leg_market: returnLeg?.service_area?.code,
        chat_source: 'trip-details',
      });
      return;
    }

    setSupportContextProps({
      ...initialCustomSupport,
      chat_source: route,
    });
  };

  const supportContextPropsFormatted = useMemo(() => {
    if (isAuthenticated) {
      return {
        ...(isSegmentedChatEnabled && {
          ...initialCustomSupport,
          ...supportContextProps,
        }),
        platform_source: source,
        email: me.email,
        user_id: me.uuid,
        created_at: undefined, // TODO get createdAt from BE
        name: `${me.firstName} ${me.lastName}`,
        phone: me.phone || undefined,
        user_hash: undefined, // TODO get hash from BE
        credits: me.credits,
        active_trip_uuid: me.activeTrip?.tripUUID,
        is_active_trip_subscription: me.activeTrip?.isSubscription,
        accepted_invitations: me.invite?.acceptedInvitations,
        invite_code: me.invite?.code,
        verification_status: me.primaryDriver.verification_status, // TODO verify field
        verification_error: me.primaryDriver.verificationError,
        is_passport_required: me.primaryDriver.passport_required,
        is_secondary_driver: me.primaryDriver.secondary_drivers_toggle,
        has_app_installed: me.hasAppInstalled,
        age: me.age,
        is_young_renter: me.youngRenter,
      };
    }
    return {
      ...initialMeSupport,
      ...(isSegmentedChatEnabled && {
        ...initialCustomSupport,
        ...supportContextProps,
      }),
      platform_source: source,
    };
  }, [me, isAuthenticated, supportContextProps]);

  return {
    intercomLoaded,
    setIntercomLoaded,
    addIntercomSupportContextProps,
    supportContextProps: supportContextPropsFormatted,
    intercomShouldBeVisible,
    setIntercomVisibility,
  };
};

export default useCustomerSupportVisibility;
