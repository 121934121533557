import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M496.076 166.957H15.922C5.633 194.691 0 224.686 0 256s5.633 61.311 15.922 89.043h480.155C506.368 317.311 512 287.314 512 256s-5.632-61.309-15.924-89.043"
        fill="#a2001d"
      />
      <Path
        d="M256.741 512c87.682 0 165.058-44.092 211.196-111.304H45.546C91.684 467.908 169.058 512 256.741 512M256.741.002c87.682 0 165.058 44.092 211.196 111.304H45.546C91.684 44.094 169.058.002 256.741.002"
        fill="#6da544"
      />
      <Path
        d="m256 166.957 22.101 68.02h71.525l-57.864 42.044 22.103 68.022L256 303.004l-57.865 42.039 22.104-68.022-57.865-42.044h71.525z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Suriname';
export const Suriname = memo<IconProps>(Icon);
