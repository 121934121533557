import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { Event } from '@sentry/browser';
import { COMMIT_SHA, SENTRY_DSN } from '@/config/global-constants';
import DcsFlags from '@/types/dcs-flags';
import request, { ApiResponse } from '@/utils/request';

type IgnoredErrors = {
  ignoreErrors: string[];
};

type IgnoredErrorsResponse = IgnoredErrors | null;

const defaultErrors = [
  '_0x3bf095',
  'Failed to fetch',
  'The Internet connection appears to be offline.',
  'window.webkit.messageHandlers.selectedTextHandler.postMessage',
  'fullscreen error',
  'Error requesting Pay Now store enabled flag',
  'InvalidValueError: not a LatLngBounds or LatLngBoundsLiteral: not an Object',
  'TypeError: Illegal invocation',
  'ChunkLoadError: Loading chunk 5350 failed.',
  "TypeError: undefined is not an object (evaluating 'l.then')",
  'ReferenceError: UET is not defined',
  'TypeError: window.UET is not a constructor',
  'SyntaxError: Unexpected private name #o. Cannot parse class method with private name.',
  "SyntaxError: Invalid character: '#'",
  'TypeError: b.C is not a function',
  'Error: executing a cancelled action',
];

const getIgnoredErrors = async () => {
  const { data: response } = await request<ApiResponse<IgnoredErrorsResponse>>({
    url: `/v1/dcs/public/${DcsFlags.SentryErrorsToIgnore}`,
  });

  return response.data;
};

const useInitSentry = () => {
  const [isInitialized, setIsInitialized] = useState(false);

  const dropIgnoredEvents = (event: Event, eventsToIgnore: string[]) => {
    const shouldIgnore = event.exception?.values?.some((exception) =>
      eventsToIgnore.some(
        (ignored) => exception.value && exception.value.includes(ignored),
      ),
    );

    if (shouldIgnore) return null;

    return event;
  };

  const initializeSentry = async () => {
    if (SENTRY_DSN) {
      const data = await getIgnoredErrors();

      Sentry.init({
        dsn: SENTRY_DSN,
        release: COMMIT_SHA,
        tracesSampleRate: 0.6,
        ignoreErrors: data?.ignoreErrors ?? defaultErrors,
        beforeSend: (event) =>
          dropIgnoredEvents(event, data?.ignoreErrors ?? defaultErrors),
      });
    }
  };

  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true);
      initializeSentry();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useInitSentry;
