import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M111.713 44.522C152.798 16.436 202.476 0 256 0s103.202 16.436 144.287 44.522L256 55.652z"
        fill="#ffda44"
      />
      <Path
        d="m74.994 437.006 118.042-118.04L256 211.478l62.957 107.481 118.049 118.047C483.336 390.682 512 326.693 512 256c0-87.861-44.269-165.375-111.713-211.478H111.713C44.269 90.625 0 168.139 0 256c0 70.693 28.664 134.682 74.994 181.006"
        fill="#d80027"
      />
      <Path
        d="m492.552 354.021-187.17-77.558 13.566 42.484 118.058 118.058c23.572-23.569 42.57-51.711 55.546-82.984M512 256c0-34.725-6.921-67.83-19.448-98.021L256 256zM437.006 74.994a257.4 257.4 0 0 0-36.719-30.472h-56.655L256 256zM256 44.522h-87.631L256 256zM74.994 74.994c-23.572 23.57-42.569 51.711-55.545 82.984L256 256zM0 256c0 34.725 6.921 67.83 19.448 98.021L256 256z"
        fill="#0052b4"
      />
      <Path
        d="M111.713 467.478C152.797 495.564 202.476 512 256 512s103.202-16.436 144.287-44.522zM256 256l62.963 62.963c16.115-16.114 26.081-38.374 26.081-62.963 0-49.178-39.866-89.043-89.043-89.043S166.957 206.822 166.957 256c0 24.588 9.966 46.849 26.081 62.963zM294.957 384c0 21.515-17.443 27.826-38.957 27.826s-38.957-6.311-38.957-27.826 17.443-38.957 38.957-38.957 38.957 17.442 38.957 38.957"
        fill="#ffda44"
      />
      <Path
        d="M294.957 384c0 21.515-17.443 38.957-38.957 38.957S217.043 405.515 217.043 384"
        fill="#0052b4"
      />
    </Svg>
  );
};
Icon.displayName = 'Tibet';
export const Tibet = memo<IconProps>(Icon);
