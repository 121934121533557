import { Text, styled } from '@tamagui/core';

export type TypographyVariant =
  | 'headingXLarge'
  | 'headingLarge'
  | 'headingMedium'
  | 'headingSmall'
  | 'labelLarge'
  | 'labelMedium'
  | 'labelSmall'
  | 'labelXSmall'
  | 'paragraphLarge'
  | 'paragraphMedium'
  | 'paragraphSmall'
  | 'paragraphXSmall';

export const Typography = styled(Text, {
  // set Stylistics Style to 3 for web.
  // Causes a console error in the browser.
  // @ts-expect-error
  fontFeatureSettings: "'ss03'",
  color: '$contentPrimary',
  variants: {
    variant: {
      headingXLarge: {
        fontSize: 52,
        lineHeight: 62,
        fontFamily: '$gilroy',
        fontWeight: '700',
        letterSpacing: -0.5,
      },
      headingLarge: {
        fontSize: 32,
        lineHeight: 42,
        fontFamily: '$gilroy',
        fontWeight: '700',
        letterSpacing: -0.5,
      },
      headingMedium: {
        fontSize: 24,
        lineHeight: 32,
        fontFamily: '$gilroy',
        fontWeight: '700',
      },
      headingSmall: {
        fontSize: 18,
        lineHeight: 28,
        fontFamily: '$gilroy',
        fontWeight: '700',
      },
      labelLarge: {
        fontSize: 18,
        lineHeight: 28,
        fontFamily: '$hankenGrotesk',
        fontWeight: '600',
      },
      labelMedium: {
        fontSize: 16,
        lineHeight: 24,
        fontFamily: '$hankenGrotesk',
        fontWeight: '600',
      },
      labelSmall: {
        fontSize: 14,
        lineHeight: 20,
        fontFamily: '$hankenGrotesk',
        fontWeight: '600',
      },
      labelXSmall: {
        fontSize: 12,
        lineHeight: 20,
        fontFamily: '$hankenGrotesk',
        fontWeight: '600',
      },
      paragraphLarge: {
        fontSize: 18,
        lineHeight: 28,
        fontFamily: '$hankenGrotesk',
        fontWeight: '400',
      },
      paragraphMedium: {
        fontSize: 16,
        lineHeight: 24,
        fontFamily: '$hankenGrotesk',
        fontWeight: '400',
      },
      paragraphSmall: {
        fontSize: 14,
        lineHeight: 20,
        fontFamily: '$hankenGrotesk',
        fontWeight: '400',
      },
      paragraphXSmall: {
        fontSize: 12,
        lineHeight: 20,
        fontFamily: '$hankenGrotesk',
        fontWeight: '400',
      },
    },
  } as const,
});
