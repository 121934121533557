import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M496.077 166.957H222.609v-66.783H89.043v66.783h-73.12C5.633 194.689 0 224.686 0 256s5.633 61.31 15.923 89.043L256 367.304l240.077-22.261C506.367 317.31 512 287.314 512 256s-5.633-61.311-15.923-89.043"
        fill="#0052b4"
      />
      <Path
        d="M256 512c110.071 0 203.906-69.472 240.077-166.957H15.923C52.094 442.528 145.929 512 256 512"
        fill="#d80027"
      />
      <Path
        d="M89.043 166.957v22.26c0 51.121 66.783 66.784 66.783 66.784s66.783-15.663 66.783-66.784v-22.26l-22.261 22.261-44.522-33.391-44.522 33.391z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Slovenia';
export const Slovenia = memo<IconProps>(Icon);
