import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 512c101.493 0 189.19-59.065 230.598-144.696H25.402C66.81 452.935 154.506 512 256 512M256 0C154.506 0 66.81 59.065 25.402 144.696h461.195C445.19 59.065 357.493 0 256 0M157.545 166.957l8.288 25.509h26.821l-21.698 15.764 8.289 25.509-21.7-15.765-21.7 15.765 8.289-25.509-21.699-15.764h26.821zM157.545 278.261l8.288 25.51h26.821l-21.698 15.764 8.289 25.508-21.7-15.765-21.7 15.765 8.289-25.508-21.699-15.764h26.821zM354.455 166.957l8.289 25.509h26.821l-21.699 15.764 8.289 25.509-21.7-15.765-21.7 15.765 8.289-25.509-21.699-15.764h26.822zM354.455 278.261l8.289 25.51h26.821l-21.699 15.764 8.289 25.508-21.7-15.765-21.7 15.765 8.289-25.508-21.699-15.764h26.822zM256 222.609l8.289 25.51h26.821l-21.699 15.764 8.289 25.508-21.7-15.765-21.7 15.765 8.289-25.508-21.699-15.764h26.821z"
        fill="#338af3"
      />
    </Svg>
  );
};
Icon.displayName = 'Honduras';
export const Honduras = memo<IconProps>(Icon);
