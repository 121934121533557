import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M0 256C0 114.616 114.616 0 256 0s256 114.616 256 256l-256 22.261z"
        fill="#ffda44"
      />
      <Path
        d="M34.256 384C78.521 460.516 161.245 512 256 512s177.479-51.484 221.744-128L256 367.304z"
        fill="#d80027"
      />
      <Path
        d="M477.744 384C499.526 346.346 512 302.631 512 256H0c0 46.631 12.474 90.346 34.256 128z"
        fill="#0052b4"
      />
    </Svg>
  );
};
Icon.displayName = 'Colombia';
export const Colombia = memo<IconProps>(Icon);
