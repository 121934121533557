import { tokens } from '../tokens';

export const lightTheme = {
  // Background
  backgroundPrimary: tokens.color.primaryB,
  backgroundSecondary: tokens.color.gray50,
  backgroundTertiary: tokens.color.gray100,
  backgroundInversePrimary: tokens.color.primaryA,
  backgroundInverseSecondary: tokens.color.gray800,
  backgroundInverseTertiary: tokens.color.gray700,
  // Background Overlay
  backgroundOverlayPrimary: tokens.color.black30,
  backgroundOverlaySecondary: tokens.color.black20,
  backgroundOverlayTertiary: tokens.color.black10,
  backgroundOverlayInversePrimary: tokens.color.white30,
  backgroundOverlayInverseSecondary: tokens.color.white20,
  backgroundOverlayInverseTertiary: tokens.color.white10,
  // Background Neutral
  backgroundNeutral: tokens.color.gray600,
  backgroundNeutralHover: tokens.color.gray700,
  backgroundNeutralActive: tokens.color.gray800,
  backgroundNeutralSubtle: tokens.color.gray100,
  backgroundNeutralSubtleHover: tokens.color.gray200,
  backgroundNeutralSubtleActive: tokens.color.gray300,
  // Background Accent
  backgroundAccent: tokens.color.blue500,
  backgroundAccentHover: tokens.color.blue600,
  backgroundAccentActive: tokens.color.blue700,
  backgroundAccentSubtle: tokens.color.blue100,
  backgroundAccentSubtleHover: tokens.color.blue200,
  backgroundAccentSubtleActive: tokens.color.blue300,
  // Backgronud Positive
  backgroundPositive: tokens.color.green500,
  backgroundPositiveHover: tokens.color.green600,
  backgroundPositiveActive: tokens.color.green700,
  backgroundPositiveSubtle: tokens.color.green100,
  backgroundPositiveSubtleHover: tokens.color.green200,
  backgroundPositiveSubtleActive: tokens.color.green300,
  // Background Negative
  backgroundNegative: tokens.color.red500,
  backgroundNegativeHover: tokens.color.red600,
  backgroundNegativeActive: tokens.color.red700,
  backgroundNegativeSubtle: tokens.color.red100,
  backgroundNegativeSubtleHover: tokens.color.red200,
  backgroundNegativeSubtleActive: tokens.color.red300,
  // Background Warning
  backgroundWarning: tokens.color.yellow400,
  backgroundWarningHover: tokens.color.yellow500,
  backgroundWarningActive: tokens.color.yellow600,
  backgroundWarningSubtle: tokens.color.yellow100,
  backgroundWarningSubtleHover: tokens.color.yellow200,
  backgroundWarningSubtleActive: tokens.color.yellow300,
  // Content
  contentPrimary: tokens.color.primaryA,
  contentSecondary: tokens.color.gray600,
  contentInversePrimary: tokens.color.primaryB,
  contentInverseSecondary: tokens.color.gray300,
  contentDisabled: tokens.color.gray500,
  contentInverseDisabled: tokens.color.gray400,
  contentAccent: tokens.color.blue500,
  contentPositive: tokens.color.green500,
  contentNegative: tokens.color.red500,
  contentWarning: tokens.color.yellow600,
  contentAccentSubtle: tokens.color.blue700,
  contentPositiveSubtle: tokens.color.green700,
  contentNegativeSubtle: tokens.color.red700,
  contentWarningSubtle: tokens.color.yellow700,
  // Border
  borderDefault: tokens.color.gray200,
  borderInverseDefault: tokens.color.gray700,
  borderDisabled: tokens.color.gray100,
  borderInverseDisabled: tokens.color.gray800,
  borderHover: tokens.color.gray400,
  borderInverseHover: tokens.color.gray600,
  borderActive: tokens.color.primaryA,
  borderInverseActive: tokens.color.primaryB,
  borderAccent: tokens.color.blue400,
  borderPositive: tokens.color.green400,
  borderNegative: tokens.color.red400,
  borderWarning: tokens.color.yellow400,
  borderAccentSubtle: tokens.color.blue200,
  borderPositiveSubtle: tokens.color.green200,
  borderNegativeSubtle: tokens.color.red200,
  borderWarningSubtle: tokens.color.yellow200,
};
