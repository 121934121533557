import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M10 16h4q.424 0 .713-.287A.97.97 0 0 0 15 15v-3a.97.97 0 0 0-.287-.713A.97.97 0 0 0 14 11v-1q0-.825-.588-1.412A1.93 1.93 0 0 0 12 8q-.825 0-1.412.588A1.93 1.93 0 0 0 10 10v1a.97.97 0 0 0-.713.287A.97.97 0 0 0 9 12v3q0 .424.287.713Q9.576 16 10 16m1-5v-1q0-.424.287-.713A.97.97 0 0 1 12 9q.424 0 .713.287Q13 9.576 13 10v1zm1 10.9a2 2 0 0 1-.625-.1Q8 20.676 6 17.637 4 14.6 4 11.1V6.375q0-.625.362-1.125.363-.5.938-.725l6-2.25a2.1 2.1 0 0 1 .7-.125q.35 0 .7.125l6 2.25q.575.225.938.725.362.5.362 1.125V11.1q0 3.5-2 6.538-2 3.037-5.375 4.162-.15.05-.3.075T12 21.9m0-2q2.6-.825 4.3-3.3t1.7-5.5V6.375l-6-2.25-6 2.25V11.1q0 3.025 1.7 5.5t4.3 3.3"
      />
    </Svg>
  );
};
Icon.displayName = 'ShieldLock';
export const ShieldLock = memo<IconProps>(themed(Icon));
