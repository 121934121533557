import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 512.001 512.001"
      {...otherProps}
    >
      <Path
        d="m138.771 483.645 188.065-156.808 156.808-188.065c-24.38-47.248-63.038-85.927-110.267-110.338L185.163 185.165 28.434 373.377c24.409 47.231 63.088 85.888 110.337 110.268"
        fill="#ffda44"
      />
      <Path
        d="M437.027 437.028c80.766-80.767 96.271-202.064 46.546-298.41L138.617 483.574c96.346 49.724 217.644 34.219 298.41-46.546"
        fill="#d80027"
      />
      <Path
        d="M74.973 74.973c-80.766 80.766-96.27 202.064-46.547 298.41L373.383 28.427C277.037-21.298 155.739-5.792 74.973 74.973"
        fill="#6da544"
      />
    </Svg>
  );
};
Icon.displayName = 'RepublicOfTheCongo';
export const RepublicOfTheCongo = memo<IconProps>(Icon);
