import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M11 22v-1q-3.125-.35-5.363-2.587Q3.4 16.175 3.05 13.05h-1a.97.97 0 0 1-.713-.288.97.97 0 0 1-.287-.712q0-.424.287-.713a.97.97 0 0 1 .713-.287h1q.35-3.124 2.587-5.363Q7.875 3.45 11 3.1v-1q0-.424.287-.713A.97.97 0 0 1 12 1.1q.424 0 .712.287.288.288.288.713v1q3.124.35 5.362 2.588t2.588 5.362h1q.424 0 .712.287.288.288.288.713 0 .424-.288.712a.97.97 0 0 1-.712.288h-1q-.35 3.124-2.588 5.363Q16.125 20.65 13 21v1q0 .424-.288.712A.97.97 0 0 1 12 23a.97.97 0 0 1-.713-.288A.97.97 0 0 1 11 22m1-2.95q2.9 0 4.95-2.05T19 12.05 16.95 7.1 12 5.05q-2.901 0-4.95 2.05T5 12.05q0 2.9 2.05 4.95T12 19.05"
      />
    </Svg>
  );
};
Icon.displayName = 'Locate';
export const Locate = memo<IconProps>(themed(Icon));
