import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#ffda44" />
      <Path
        d="M74.98 437.02a257.5 257.5 0 0 0 34.216 28.748l356.57-356.571a257.4 257.4 0 0 0-28.747-34.216 257.6 257.6 0 0 0-34.216-28.747L46.234 402.805A257.7 257.7 0 0 0 74.98 437.02"
        fill="#000"
      />
      <Path
        d="M74.98 74.98c-80.762 80.764-96.267 202.055-46.544 298.398L373.378 28.437C277.035-21.286 155.743-5.78 74.98 74.98"
        fill="#6da544"
      />
      <Path
        d="M437.019 437.02c80.762-80.762 96.266-202.055 46.545-298.398L138.622 483.564c96.341 49.723 217.634 34.218 298.397-46.544"
        fill="#338af3"
      />
    </Svg>
  );
};
Icon.displayName = 'Tanzania';
export const Tanzania = memo<IconProps>(Icon);
