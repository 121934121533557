import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="m11 14.4-3.9 3.9a.95.95 0 0 1-.7.275.95.95 0 0 1-.7-.275.95.95 0 0 1-.275-.7q0-.425.275-.7l4.9-4.9-4.9-4.9a.95.95 0 0 1-.275-.7q0-.425.275-.7a.95.95 0 0 1 .7-.275q.424 0 .7.275L11 9.6V3.425q0-.425.262-.7a.88.88 0 0 1 .663-.275q.25 0 .5.112.249.114.45.313L17 7q.15.15.212.325.063.176.063.375 0 .2-.063.375A.9.9 0 0 1 17 8.4L13.4 12l3.6 3.6q.15.15.212.325.063.176.063.375 0 .2-.063.375A.9.9 0 0 1 17 17l-4.125 4.125a1.5 1.5 0 0 1-.45.313 1.2 1.2 0 0 1-.5.112.88.88 0 0 1-.663-.275.97.97 0 0 1-.262-.7zm2-4.8 1.9-1.9L13 5.85zm0 8.55 1.9-1.85-1.9-1.9z"
      />
    </Svg>
  );
};
Icon.displayName = 'Bluetooth';
export const Bluetooth = memo<IconProps>(themed(Icon));
