import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="m15.71 13.334-.71-.709v-1.791h1.25v2.5zm-1.542-1.542-.959-.958h.959zm2.916 1.542v-2.5h1.25v2.5zm-1.166 4.916L11 13.334H2.918q-.522 0-.886-.365a1.2 1.2 0 0 1-.364-.885q0-.522.364-.886t.886-.364H8.5l-6.75-6.75a.8.8 0 0 1-.23-.584.8.8 0 0 1 .23-.583.8.8 0 0 1 .583-.23.8.8 0 0 1 .584.23l14.166 14.167a.8.8 0 0 1 .23.583.8.8 0 0 1-.23.583.8.8 0 0 1-.583.23.8.8 0 0 1-.583-.23M15.626 10A.607.607 0 0 1 15 9.375v-.458q0-.813-.48-1.24a1.66 1.66 0 0 0-1.145-.427h-1.292q-1.166 0-1.979-.812a2.7 2.7 0 0 1-.812-1.98q0-.979.593-1.729.594-.75 1.49-.979a.48.48 0 0 1 .49.115.68.68 0 0 1 .218.531.53.53 0 0 1-.146.354.9.9 0 0 1-.375.25 1.63 1.63 0 0 0-.729.552 1.46 1.46 0 0 0-.291.907q0 .666.458 1.104Q11.46 6 12.084 6h1.292q1.167 0 2.02.75.855.75.855 1.875v.75a.607.607 0 0 1-.625.625m2.083 0a.607.607 0 0 1-.625-.625v-1.25q0-1.25-.791-2.187a3.27 3.27 0 0 0-2-1.146.652.652 0 0 1-.542-.667.77.77 0 0 1 .125-.437q.124-.187.417-.292.333-.125.573-.375t.343-.583a.8.8 0 0 1 .24-.354.54.54 0 0 1 .344-.125q.333 0 .53.229a.53.53 0 0 1 .115.52q-.104.355-.291.667t-.438.584q1.167.54 1.896 1.666a4.5 4.5 0 0 1 .73 2.5v1.25a.607.607 0 0 1-.626.625"
      />
    </Svg>
  );
};
Icon.displayName = 'SmokeFree';
export const SmokeFree = memo<IconProps>(themed(Icon));
