import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M74.98 74.98C20.115 129.845-4.632 203.419.721 275.165L275.164.722C203.418-4.632 129.845 20.115 74.98 74.98"
        fill="#ffda44"
      />
      <Path
        d="M91.41 452.071c100.581 84.622 250.94 79.617 345.61-15.052S536.695 191.99 452.072 91.41z"
        fill="#0052b4"
      />
      <Path
        d="m255.087 245.689 22.061-11.95-22.061-11.95c-5.151-33.461-31.589-59.9-65.05-65.05l-11.951-22.062-11.95 22.061c-33.46 5.15-59.898 31.589-65.05 65.05l-22.062 11.951 22.061 11.95c5.151 33.461 31.589 59.9 65.05 65.05l11.951 22.062 11.95-22.061c33.462-5.151 59.9-31.59 65.051-65.051m-77 32.572c-24.589 0-44.522-19.932-44.522-44.522s19.932-44.522 44.522-44.522 44.522 19.932 44.522 44.522-19.933 44.522-44.522 44.522"
        fill="#000"
      />
      <Path
        d="m178.087 200.348 9.639 16.695h19.279l-9.64 16.696 9.64 16.696h-19.279l-9.639 16.695-9.639-16.695H149.17l9.639-16.696-9.639-16.696h19.278z"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'Bonaire';
export const Bonaire = memo<IconProps>(Icon);
