import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M19 20a.97.97 0 0 1-.712-.288A.97.97 0 0 1 18 19V5q0-.424.288-.713A.97.97 0 0 1 19 4q.424 0 .712.287Q20 4.576 20 5v14q0 .424-.288.712A.97.97 0 0 1 19 20M5 20a.97.97 0 0 1-.713-.288A.97.97 0 0 1 4 19V5q0-.424.287-.713A.97.97 0 0 1 5 4q.424 0 .713.287Q6 4.576 6 5v14q0 .424-.287.712A.97.97 0 0 1 5 20m7-12a.97.97 0 0 1-.713-.287A.97.97 0 0 1 11 7V5q0-.424.287-.713A.97.97 0 0 1 12 4q.424 0 .713.287Q13 4.576 13 5v2q0 .424-.287.713A.97.97 0 0 1 12 8m0 6a.97.97 0 0 1-.713-.287A.97.97 0 0 1 11 13v-2q0-.424.287-.713A.97.97 0 0 1 12 10q.424 0 .713.287.287.288.287.713v2q0 .424-.287.713A.97.97 0 0 1 12 14m0 6a.97.97 0 0 1-.713-.288A.97.97 0 0 1 11 19v-2q0-.424.287-.712A.97.97 0 0 1 12 16q.424 0 .713.288.287.287.287.712v2q0 .424-.287.712A.97.97 0 0 1 12 20"
      />
    </Svg>
  );
};
Icon.displayName = 'Road';
export const Road = memo<IconProps>(themed(Icon));
