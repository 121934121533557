import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M512 256c0 141.384-114.616 256-256 256S0 397.384 0 256C0 256.061 256 .028 256 0c141.384 0 256 114.616 256 256"
        fill="#0052b4"
      />
      <Path d="M256 0h-.043zM255.315 256H256v-.685z" fill="#f0f0f0" />
      <Path
        d="M256 133.565V0h-.043C114.591.024 0 114.629 0 256h133.565v-75.211L208.776 256h46.54l.685-.685v-46.537l-75.213-75.213z"
        fill="#f0f0f0"
      />
      <Path
        d="M129.515 33.391a257.2 257.2 0 0 0-96.124 96.124V256h66.783V100.174H256V33.391z"
        fill="#d80027"
      />
      <Path
        d="m256 224.519-90.955-90.953h-31.48v.002L255.998 256H256z"
        fill="#d80027"
      />
      <Path d="M289.391 256" fill="#496e2d" />
      <Path
        d="m345.043 256 4.738 14.576h15.325l-12.398 9.008 4.736 14.577-12.401-9.009-12.399 9.009 4.736-14.577-12.398-9.008h15.325zM282.081 282.081l13.655 6.957 10.838-10.837-2.398 15.138 13.656 6.958-15.138 2.396-2.397 15.14-6.959-13.657-15.137 2.398 10.839-10.837zM256 345.043l14.576-4.736.002-15.324 9.006 12.397 14.577-4.737-9.009 12.4 9.009 12.401-14.577-4.736-9.006 12.398v-15.325zM282.081 408.006l6.957-13.656-10.837-10.836 15.138 2.397 6.958-13.656 2.397 15.138 15.138 2.396-13.656 6.959 2.398 15.139-10.838-10.84zM345.043 434.087l-4.736-14.577h-15.326l12.399-9.008-4.736-14.576 12.399 9.008 12.401-9.008-4.736 14.576 12.398 9.008h-15.325zM408.006 408.006l-13.656-6.956-10.836 10.837 2.397-15.139-13.656-6.957 15.138-2.398 2.398-15.138 6.957 13.656 15.138-2.397-10.839 10.837zM434.087 345.043l-14.577 4.738v15.325l-9.008-12.399-14.576 4.737 9.009-12.401-9.009-12.4 14.576 4.737 9.008-12.397v15.324zM408.006 282.082l-6.956 13.655 10.836 10.837-15.138-2.398-6.957 13.657-2.398-15.14-15.138-2.397 13.656-6.957-2.397-15.137 10.837 10.836z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'CookIslands';
export const CookIslands = memo<IconProps>(Icon);
