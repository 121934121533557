import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M4 20q-.824 0-1.412-.587A1.93 1.93 0 0 1 2 18V6q0-.824.587-1.412A1.93 1.93 0 0 1 4 4h16q.824 0 1.413.588Q22 5.175 22 6v12q0 .824-.587 1.413A1.93 1.93 0 0 1 20 20zm0-2h16V6H4z"
      />
      <Path
        fill={`${color}`}
        d="M6 14.55c0 .248.201.45.45.45h5.1a.45.45 0 0 0 .45-.45q0-.425-.238-.788a1.57 1.57 0 0 0-.662-.562 5 5 0 0 0-1.012-.337A5 5 0 0 0 9 12.75q-.575 0-1.088.113-.512.112-1.012.337a1.57 1.57 0 0 0-.663.563A1.4 1.4 0 0 0 6 14.55m8.75-1.05h2.5a.73.73 0 0 0 .75-.75.73.73 0 0 0-.75-.75h-2.5a.73.73 0 0 0-.75.75.73.73 0 0 0 .75.75M9 12q.624 0 1.063-.437.437-.438.437-1.063t-.437-1.062A1.45 1.45 0 0 0 9 9q-.625 0-1.062.438A1.45 1.45 0 0 0 7.5 10.5q0 .624.438 1.063Q8.374 12 9 12m5.75-1.5h2.5a.73.73 0 0 0 .75-.75.73.73 0 0 0-.75-.75h-2.5a.73.73 0 0 0-.75.75.73.73 0 0 0 .75.75"
      />
    </Svg>
  );
};
Icon.displayName = 'Identification';
export const Identification = memo<IconProps>(themed(Icon));
