import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M512 256c0-101.494-59.065-189.19-144.696-230.598v461.195C452.935 445.19 512 357.493 512 256M0 256c0 101.493 59.065 189.19 144.696 230.598V25.402C59.065 66.81 0 154.506 0 256"
        fill="#338af3"
      />
      <Path
        d="m322.898 299.288-43.286-43.286 41.203-41.205-1.867-21.762-11.796-11.798L256 232.389l-51.152-51.152-11.796 11.798-1.867 21.762 41.203 41.205-43.287 43.286 23.612 23.61L256 279.613l43.287 43.285z"
        fill="#acabb1"
      />
      <Path
        d="m318.963 193.037-23.611 23.611c10.071 10.071 16.301 23.984 16.301 39.352 0 30.736-24.917 55.652-55.652 55.652S200.348 286.736 200.348 256c0-15.368 6.23-29.281 16.301-39.352l-23.611-23.611c-16.115 16.112-26.081 38.373-26.081 62.963 0 49.178 39.866 89.043 89.043 89.043s89.043-39.866 89.043-89.043c0-24.589-9.966-46.85-26.08-62.963"
        fill="#6da544"
      />
    </Svg>
  );
};
Icon.displayName = 'Guatemala';
export const Guatemala = memo<IconProps>(Icon);
