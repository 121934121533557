import { useTheme } from '@tamagui/core';
import { Platform } from 'react-native';
import { TextInputMask, TextInputMaskProps } from 'react-native-masked-text';

const isWeb = Platform.OS === 'web';

export type InputMaskProps = {
  disabled?: boolean;
} & TextInputMaskProps;

export const InputMask = ({ disabled, ...props }: InputMaskProps) => {
  const theme = useTheme();

  return (
    <TextInputMask
      {...props}
      placeholderTextColor={theme.contentSecondary.val}
      style={{
        width: '100%',
        fontSize: 16,
        fontFamily: isWeb ? 'Hanken Grotesk' : 'HankenGroteskRegular',
        fontWeight: '400',
        fontStyle: 'normal',
        lineHeight: 24,
        color: disabled ? theme.contentDisabled.val : theme.borderActive.val,
        flex: 1,
        paddingTop: 20,
        // @ts-ignore
        outlineStyle: 'none',
      }}
      maxLength={props.options?.mask?.length}
      type="custom"
    />
  );
};

export default TextInputMaskProps;
