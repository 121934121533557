import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { API_BASE_URL, REQUEST_TIMEOUT_MS } from '@/config/global-constants';
import { getAnonymousId } from '../analytics/analytics';

// TODO: enforce requirement of error type structure
export type RequestError<T = any> = AxiosError<T>;

export type RequestResponse<T> = AxiosResponse<T>;

export type ApiResponse<T> = {
  data: T;
};

const request = <TData = any>(
  requestConfig: AxiosRequestConfig = {},
  anonymousId: string = '',
) =>
  axios.request<TData, RequestResponse<TData>>({
    baseURL: API_BASE_URL,
    timeout: REQUEST_TIMEOUT_MS,
    withCredentials: true,
    headers: {
      ...requestConfig.headers,
      'X-Client-ID': 'consumer-web',
      'X-Anon-ID': getAnonymousId() || anonymousId,
    },
    ...requestConfig,
  });

export default request;
