import { ColorTokens } from '@tamagui/core';
import { ButtonState, ButtonVariant } from './base-button';

export const disabledBgColors: Record<ButtonVariant, ColorTokens> = {
  primary: '$backgroundTertiary',
  secondary: '$backgroundSecondary',
  tertiary: '$backgroundPrimary',
};

export const loadingBgColors: Record<
  ButtonVariant,
  Record<ButtonState, ColorTokens>
> = {
  primary: {
    default: '$backgroundPositiveActive',
    danger: '$backgroundNegativeActive',
  },
  secondary: {
    default: '$backgroundNeutralSubtleActive',
    danger: '$backgroundNegativeSubtleActive',
  },
  tertiary: {
    default: '$backgroundTertiary',
    danger: '$backgroundTertiary',
  },
};

export const bgColors: Record<
  ButtonVariant,
  Record<ButtonState, ColorTokens>
> = {
  primary: {
    default: '$backgroundPositive',
    danger: '$backgroundNegative',
  },
  secondary: {
    default: '$backgroundNeutralSubtle',
    danger: '$backgroundNeutralSubtle',
  },
  tertiary: {
    default: '$backgroundPrimary',
    danger: '$backgroundPrimary',
  },
};

export const hoverBgColors: Record<
  ButtonVariant,
  Record<ButtonState, ColorTokens>
> = {
  primary: {
    default: '$backgroundPositiveHover',
    danger: '$backgroundNegativeHover',
  },
  secondary: {
    default: '$backgroundNeutralSubtleHover',
    danger: '$backgroundNeutralSubtleHover',
  },
  tertiary: {
    default: '$backgroundSecondary',
    danger: '$backgroundSecondary',
  },
};

export const pressBgColors: Record<
  ButtonVariant,
  Record<ButtonState, ColorTokens>
> = {
  primary: {
    default: '$backgroundPositiveActive',
    danger: '$backgroundNegativeActive',
  },
  secondary: {
    default: '$backgroundNeutralSubtleActive',
    danger: '$backgroundNeutralSubtleActive',
  },
  tertiary: {
    default: '$backgroundTertiary',
    danger: '$backgroundTertiary',
  },
};

export const textColors: Record<
  ButtonVariant,
  Record<ButtonState, ColorTokens>
> = {
  primary: {
    default: '$contentInversePrimary',
    danger: '$contentInversePrimary',
  },
  secondary: {
    default: '$contentPrimary',
    danger: '$contentNegative',
  },
  tertiary: {
    default: '$contentPrimary',
    danger: '$contentNegative',
  },
};
