import * as React from 'react';
import { Svg, Path, Circle } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M512 256c0 141.384-114.616 256-256 256S0 397.384 0 256 256 0 256 0s256 114.616 256 256"
        fill="#a2001d"
      />
      <Path
        d="M0 256C0 114.616 114.616 0 256 0s256 114.616 256 256"
        fill="#0052b4"
      />
      <Path
        d="M345.043 322.783 256 311.652l-89.043 11.131V189.217h178.086z"
        fill="#f0f0f0"
      />
      <Circle cx={256} cy={267.13} r={44.522} fill="#0052b4" />
      <Circle cx={256} cy={267.13} r={22.261} fill="#a2001d" />
      <Path d="M222.609 211.478h66.782L256 244.87z" fill="#6da544" />
      <Path d="M244.87 233.739h22.261v66.783H244.87z" fill="#ffda44" />
      <Path
        d="M291.617 293.843h-71.236l-53.424 28.94h178.086z"
        fill="#6da544"
      />
    </Svg>
  );
};
Icon.displayName = 'Haiti';
export const Haiti = memo<IconProps>(Icon);
