import * as React from 'react';
import { Svg, Path, Circle } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 512.001 512.001"
      {...otherProps}
    >
      <Path
        d="M437.02 437.02c-99.974 99.974-262.065 99.974-362.04 0s-99.974-262.065 0-362.04c7.87 7.869 204.631 157.408 204.631 157.408z"
        fill="#0052b4"
      />
      <Path
        d="M74.981 74.981c99.974-99.974 262.065-99.974 362.04 0s99.974 262.065 0 362.04"
        fill="#6da544"
      />
      <Circle cx={256} cy={256} r={66.783} fill="#ffda44" />
      <Path
        d="m95.325 367.305 7.025 14.689 15.863-3.667-7.104 14.651 12.758 10.117-15.884 3.579.044 16.283-12.702-10.187-12.702 10.187.044-16.283-15.884-3.579 12.758-10.117-7.105-14.651 15.864 3.667zM49.372 244.87l7.025 14.689 15.863-3.668-7.103 14.652 12.756 10.117-15.883 3.579.044 16.283-12.702-10.186-12.701 10.186.043-16.283-15.883-3.579 12.757-10.117-7.104-14.652 15.863 3.668zM95.325 144.696l7.025 14.689 15.863-3.668-7.103 14.652 12.757 10.117-15.884 3.579.044 16.283-12.702-10.186-12.702 10.186.044-16.283-15.883-3.579 12.756-10.117-7.104-14.652 15.864 3.668zM152.408 222.609l7.025 14.689 15.864-3.668-7.105 14.653 12.757 10.116-15.883 3.58.043 16.282-12.701-10.186-12.702 10.186.044-16.282-15.883-3.58 12.756-10.116-7.103-14.653 15.863 3.668zM111.591 289.392l5.526 17.006h17.88l-14.466 10.51 5.526 17.005-14.466-10.509-14.466 10.509 5.526-17.005-14.466-10.51h17.88z"
        fill="#f0f0f0"
      />
      <Path
        d="M256 300.522h22.261s9.646-17.065 0-33.391l22.261-22.261-11.13-22.261h-11.13s-5.565 16.696-27.826 16.696-27.826-16.696-27.826-16.696h-11.13l11.13 22.261-11.13 22.261 11.13 11.13s11.13-22.261 33.391-11.13c-.001 0 9.46 13.913-.001 33.391"
        fill="#6da544"
      />
      <Path
        d="M422.153 140.223c.525-2.735.804-5.556.804-8.444 0-19.8-12.928-36.571-30.8-42.357 5.461 11.1 8.539 23.584 8.539 36.792 0 4.454-.358 8.824-1.031 13.089a45.6 45.6 0 0 0-10.366-11.651c-15.901-12.652-37.63-12.987-53.695-2.332 12.403 2.708 24.395 8.213 35.002 16.652 8.304 6.608 15.014 15.546 20.152 24.174l-67.975 21.285c66.783 11.13 122.435-33.391 122.435-33.391-6.167-12.332-15.743-14.412-23.065-13.817"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'ChristmasIsland';
export const ChristmasIsland = memo<IconProps>(Icon);
