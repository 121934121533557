import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M512 256c0 141.384-114.616 256-256 256S0 397.384 0 256C0 256.061 256 .028 256 0c141.384 0 256 114.616 256 256"
        fill="#338af3"
      />
      <Path
        d="M256 122.435v86.343l-89.043-86.343zM133.565 256h75.212l-75.212-100.174-11.13 77.913z"
        fill="#0052b4"
      />
      <Path d="M256 0h-.043zM255.315 256H256v-.685z" fill="#f0f0f0" />
      <Path
        d="M256 133.565V0h-.043C114.592.024 0 114.629 0 256h133.565v-75.211L208.777 256h46.54l.685-.685v-46.537l-75.214-75.213z"
        fill="#f0f0f0"
      />
      <Path
        d="M129.515 33.391a257.2 257.2 0 0 0-96.124 96.124V256h66.783V100.174H256V33.391z"
        fill="#d80027"
      />
      <Path
        d="m256 224.519-90.953-90.953h-31.481v.002L255.998 256H256z"
        fill="#d80027"
      />
      <Path
        d="M289.391 166.957v89.042c0 59.64 77.913 77.914 77.913 77.914s77.913-18.274 77.913-77.914v-89.042l-77.913-22.261z"
        fill="#f3f3f3"
      />
      <Path
        d="M445.217 166.957v-33.391H289.391v33.391h66.781v66.784h-66.781v22.261l66.781.001V330.5c6.718 2.376 11.132 3.414 11.132 3.414s4.412-1.036 11.129-3.414v-74.498h66.784V233.74h-66.784v-66.784z"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'Fiji';
export const Fiji = memo<IconProps>(Icon);
