import { useRouter } from 'next/router';
import { useDateFormatter } from '@drivekyte/date-utils';
import AnalyticsEvents, {
  AnalyticsEventsType,
} from '@/config/analytics-events';
import useMe from '@/hooks/use-me';
import useBookingCart from '@/hooks/use-booking-cart';
import useQuery from '@/hooks/use-query';
import { RequestError } from '@/utils/request';
import useAnalyticsWithSource from '../use-analytics-with-source';

enum AnalyticsLegType {
  DELIVERY = 'delivery',
  PUAL = 'pual',
  AIRPORT = 'airport',
}

const useAnalyticsWithBookingCartData = () => {
  const query = useQuery();
  const router = useRouter();
  const { ...bookingCart } = useBookingCart();
  const { isAuthenticated } = useMe();
  const { trackEvent } = useAnalyticsWithSource();
  const { formatISOWithoutTimezone } = useDateFormatter();

  const getTripUUID = () => {
    const id = query('id') as string;

    if (id && id !== 'undefined' && router.pathname.includes('trip-details')) {
      return id;
    }

    return undefined;
  };

  const getLegType = () => {
    let startLegType;
    let endLegType;

    if (bookingCart.startAddress) {
      startLegType = !!bookingCart.isStartAddressPickupAtLot
        ? AnalyticsLegType.PUAL
        : !!bookingCart.startAddressExclusionZoneId
        ? AnalyticsLegType.AIRPORT
        : AnalyticsLegType.DELIVERY;
    }
    if (bookingCart.endAddress) {
      endLegType = !!bookingCart.isEndAddressPickupAtLot
        ? AnalyticsLegType.PUAL
        : !!bookingCart.endAddressExclusionZoneId
        ? AnalyticsLegType.AIRPORT
        : AnalyticsLegType.DELIVERY;
    }

    return { startLegType, endLegType };
  };

  const handleTrackEvent = (
    event: AnalyticsEventsType,
    eventDetails?: { [property: string]: unknown },
    error?: RequestError,
  ) => {
    const tripUUID = getTripUUID();

    const utmSource = query('utm_source') as string;

    const { startLegType, endLegType } = getLegType();

    const bookingCartParams = {
      ...(bookingCart.serviceAreaCode && {
        service_area_code: bookingCart.serviceAreaCode,
      }),
      ...(bookingCart.startAddress && {
        start_address: bookingCart.startAddress,
      }),
      ...(bookingCart.startDate && {
        start_date: formatISOWithoutTimezone(bookingCart.startDate),
      }),
      ...(bookingCart.endServiceAreaCode && {
        end_service_area_code: bookingCart.endServiceAreaCode,
      }),
      ...(bookingCart.endAddress && {
        end_address: bookingCart.endAddress,
      }),
      ...(bookingCart.endDate && {
        end_address: formatISOWithoutTimezone(bookingCart.endDate),
      }),
      ...(utmSource && { utm_source: utmSource }),
      ...(startLegType && { start_leg_type: startLegType }),
      ...(endLegType && { end_leg_type: endLegType }),
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    trackEvent(event, {
      screen: router.pathname.replace('[slug]', query('slug') as string),
      is_authenticated: !!isAuthenticated,
      ...(router.pathname.includes('checkout') && {
        is_guest_checkout: !isAuthenticated,
      }),
      ...(tripUUID && { trip_uuuid: tripUUID }),
      ...bookingCartParams,
      ...(error && { error: error.response?.data.status ?? error.message }),
      ...eventDetails,
    });
  };

  return { trackEvent: handleTrackEvent, AnalyticsEvents };
};

export default useAnalyticsWithBookingCartData;
