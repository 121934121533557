import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M512 256c0 141.384-114.616 256-256 256S0 397.384 0 256c11.13 0 256-33.391 256-33.391z"
        fill="#338af3"
      />
      <Path
        d="M0 256C0 114.616 114.616 0 256 0s256 114.616 256 256"
        fill="#f0f0f0"
      />
      <Path
        d="M357.616 176.644 256 278.261 154.384 176.644c-13.141 19.507-20.818 42.986-20.818 68.225v33.391c0 53.616 34.645 99.289 82.722 115.82-6.417 12.576-5.335 28.271 4.068 40.006 12.488-10.009 25.417-20.37 36.385-29.158l36.385 29.158c9.513-11.872 10.517-27.802 3.847-40.452 47.42-16.89 81.461-62.228 81.461-115.374v-33.39c.001-25.24-7.677-48.718-20.818-68.226"
        fill="#6da544"
      />
      <Path
        d="M256 367.304c-49.099 0-89.043-39.945-89.043-89.043V244.87c0-49.099 39.945-89.043 89.043-89.043s89.043 39.945 89.043 89.043v33.391c0 49.098-39.944 89.043-89.043 89.043"
        fill="#ffda44"
      />
      <Path
        d="M311.652 278.261V244.87c0-30.687-24.966-55.652-55.652-55.652s-55.652 24.966-55.652 55.652v33.391L256 289.391z"
        fill="#338af3"
      />
      <Path
        d="M200.348 278.261c0 30.687 24.966 55.652 55.652 55.652s55.652-24.966 55.652-55.652z"
        fill="#6da544"
      />
      <Path
        d="M322.783 155.826c0-18.441-14.95-33.391-33.391-33.391-8.555 0-16.352 3.22-22.261 8.509v-19.64h11.13V89.043h-11.13v-11.13H244.87v11.13h-11.13v22.261h11.13v19.64c-5.909-5.289-13.707-8.509-22.261-8.509-18.441 0-33.391 14.95-33.391 33.391 0 9.887 4.301 18.767 11.13 24.882v19.64h111.304v-19.64c6.83-6.115 11.131-14.995 11.131-24.882"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'SanMarino';
export const SanMarino = memo<IconProps>(Icon);
