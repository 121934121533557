import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M503.181 322.783C508.92 301.489 512 279.107 512 256s-3.08-45.489-8.819-66.783L233.739.974c-50.021 4.31-95.924 23-133.565 51.921L8.819 189.217C3.08 210.511 0 232.893 0 256c0 22.972 3.043 45.231 8.718 66.413l91.455 136.692c37.641 28.921 83.544 47.612 133.565 51.921z"
        fill="#ffda44"
      />
      <Path
        d="M8.819 322.783c14.812 54.959 47.457 102.593 91.355 136.322V322.783zM233.739 511.026A259 259 0 0 0 256 512c118.279 0 217.805-80.221 247.181-189.217H233.739zM503.181 189.217C473.805 80.221 374.279 0 256 0c-7.502 0-14.923.342-22.261.974v188.243zM100.174 52.895c-43.898 33.73-76.543 81.363-91.355 136.322h91.355z"
        fill="#0052b4"
      />
      <Path
        d="M509.833 222.609H200.348V6.085a254.3 254.3 0 0 0-66.783 25.042v191.481H2.167C.742 233.539 0 244.683 0 256s.742 22.461 2.167 33.391h131.398v191.481a254.3 254.3 0 0 0 66.783 25.042V289.392h309.485C511.256 278.461 512 267.317 512 256s-.744-22.461-2.167-33.391"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'AlandIslands';
export const AlandIslands = memo<IconProps>(Icon);
