import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M496.077 345.043C506.367 317.31 512 287.314 512 256s-5.633-61.31-15.923-89.043L256 144.696 15.923 166.957C5.633 194.69 0 224.686 0 256s5.633 61.31 15.923 89.043L256 367.304z"
        fill="#f0f0f0"
      />
      <Path
        d="M256 512c110.07 0 203.906-69.472 240.076-166.957H15.922C52.094 442.528 145.928 512 256 512"
        fill="#d80027"
      />
      <Path
        d="M256 0C145.928 0 52.094 69.472 15.922 166.957h480.155C459.906 69.472 366.07 0 256 0"
        fill="#496e2d"
      />
      <Path d="m256 200.348 13.813 42.513h44.703l-36.165 26.277 13.815 42.514L256 285.379l-36.166 26.273 13.815-42.514-36.165-26.277h44.703z" />
      <Path
        d="M332.058 50.087v51.941c0 8.185-6.657 14.841-14.84 14.841v22.261c20.458 0 37.101-16.643 37.101-37.102V50.087zM172.522 102.028c0 8.185-6.657 14.841-14.841 14.841v22.261c20.459 0 37.102-16.643 37.102-37.102V50.087h-22.261z"
        fill="#f0f0f0"
      />
      <Path
        d="M298.663 50.087h22.261v51.941h-22.261zM265.275 79.767a3.715 3.715 0 0 1-3.71 3.711 3.716 3.716 0 0 1-3.711-3.711v-29.68h-22.261v29.68a3.715 3.715 0 0 1-3.71 3.711 3.715 3.715 0 0 1-3.71-3.711v-29.68h-22.261v29.68c0 14.322 11.65 25.972 25.971 25.972a25.8 25.8 0 0 0 14.84-4.676 25.82 25.82 0 0 0 14.841 4.676c1.111 0 2.203-.078 3.277-.215-1.578 6.499-7.431 11.345-14.407 11.345v22.261c20.458 0 37.101-16.643 37.101-37.102V50.087h-22.261z"
        fill="#f0f0f0"
      />
      <Path d="M205.913 116.87h33.391v22.261h-33.391z" fill="#f0f0f0" />
    </Svg>
  );
};
Icon.displayName = 'Somaliland';
export const Somaliland = memo<IconProps>(Icon);
