import { createStyledContext } from '@tamagui/core';
import { DialogVariant } from '../dialog.types';

type DialogContextValue = {
  variant: DialogVariant;
  hasHeader: boolean;
  contentWidth: number;
};

export const DialogContext = createStyledContext<DialogContextValue>({
  variant: 'small',
  hasHeader: false,
  contentWidth: 0,
});
