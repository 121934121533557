import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M16.175 13H5a.97.97 0 0 1-.713-.287A.97.97 0 0 1 4 12q0-.424.287-.713A.97.97 0 0 1 5 11h11.175l-4.9-4.9a.92.92 0 0 1-.287-.7Q11 5 11.3 4.7q.3-.275.7-.288t.7.288l6.6 6.6q.15.15.212.325.063.175.063.375t-.062.375a.9.9 0 0 1-.213.325l-6.6 6.6a.93.93 0 0 1-.687.275q-.413 0-.713-.275-.3-.3-.3-.713 0-.412.3-.712z"
      />
    </Svg>
  );
};
Icon.displayName = 'ArrowRightAlt';
export const ArrowRightAlt = memo<IconProps>(themed(Icon));
