import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M512 256c0 141.384-114.616 256-256 256S0 397.384 0 256C0 256.061 256 .028 256 0c141.384 0 256 114.616 256 256"
        fill="#0052b4"
      />
      <Path d="M256 0h-.043zM255.315 256H256v-.685z" fill="#f0f0f0" />
      <Path
        d="M256 133.565V0h-.043C114.591.024 0 114.629 0 256h133.565v-75.211L208.776 256h46.54l.685-.685v-46.537l-75.213-75.213z"
        fill="#f0f0f0"
      />
      <Path
        d="M129.515 33.391a257.2 257.2 0 0 0-96.124 96.124V256h66.783V100.174H256V33.391z"
        fill="#d80027"
      />
      <Path
        d="m256 224.519-90.955-90.953h-31.48v.002L255.998 256H256z"
        fill="#d80027"
      />
      <Path
        d="m400.696 155.826-33.392 11.131-33.391-11.131L322.783 128l11.13-27.826h66.783L389.565 128z"
        fill="#acabb1"
      />
      <Path
        d="M289.391 155.826V278.26c0 59.64 77.913 77.914 77.913 77.914s77.913-18.274 77.913-77.914V155.826z"
        fill="#338af3"
      />
      <Path
        d="M296.415 307.266c19.152 36.769 70.89 48.908 70.89 48.908s51.738-12.139 70.89-48.908l-70.89-118.048z"
        fill="#6da544"
      />
      <Path
        d="m445.217 277.023-77.913-121.197-77.913 121.198v1.236c0 11.038 2.674 20.656 7.023 29.006l70.89-110.275 70.89 110.274c4.349-8.351 7.023-17.968 7.023-29.006z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'PitcairnIslands';
export const PitcairnIslands = memo<IconProps>(Icon);
