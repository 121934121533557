import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M512 256c0 141.384-114.616 256-256 256S0 397.384 0 256C0 256.061 256 .028 256 0c141.384 0 256 114.616 256 256"
        fill="#0052b4"
      />
      <Path d="M256 0h-.043zM255.315 256H256v-.685z" fill="#f0f0f0" />
      <Path
        d="M256 133.565V0h-.043C114.592.024 0 114.629 0 256h133.565v-75.211L208.777 256h46.539l.685-.685v-46.537l-75.213-75.213z"
        fill="#f0f0f0"
      />
      <Path
        d="M129.515 33.391a257.2 257.2 0 0 0-96.124 96.124V256h66.783V100.174H256V33.391z"
        fill="#d80027"
      />
      <Path
        d="m256 224.519-90.953-90.953s-31.481.002-31.481 0v.002L255.999 256H256zM443.33 189.959l5.525 17.006h17.881l-14.466 10.51 5.526 17.005-14.466-10.509-14.467 10.509 5.526-17.005-14.467-10.51h17.881zM379.411 312.393l8.289 25.51h26.821l-21.7 15.764 8.29 25.509-21.7-15.765-21.7 15.765 8.29-25.509-21.7-15.764h26.821zM381.825 112.046l6.908 21.259h22.351L393 146.44l6.908 21.258-18.083-13.139-18.082 13.139 6.907-21.258-18.082-13.135h22.35zM320.322 189.217l8.288 25.51h26.822l-21.7 15.764 8.29 25.509-21.7-15.765-21.7 15.765 8.29-25.509-21.7-15.764h26.821z"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'NewZealand';
export const NewZealand = memo<IconProps>(Icon);
