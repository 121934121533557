import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="m19.8 12.925-15.4 6.5a.99.99 0 0 1-.95-.088Q3 19.051 3 18.5v-13q0-.55.45-.838a.99.99 0 0 1 .95-.087l15.4 6.5q.625.275.625.925t-.625.925M5 17l11.85-5L5 7v3.5l6 1.5-6 1.5z"
      />
    </Svg>
  );
};
Icon.displayName = 'Send';
export const Send = memo<IconProps>(themed(Icon));
