import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M509.833 222.609H289.392V2.167C278.461.744 267.317 0 256 0s-22.461.744-33.391 2.167v220.442H2.167C.744 233.539 0 244.683 0 256s.744 22.461 2.167 33.391h220.441v220.442a258.35 258.35 0 0 0 66.783 0V289.392h220.442C511.256 278.461 512 267.317 512 256s-.744-22.461-2.167-33.391"
        fill="#d80027"
      />
      <Path
        d="m328.348 267.13 16.695 11.131v-44.522l-16.695 11.131H267.13v-61.218l11.131-16.695h-44.522l11.131 16.695v61.218h-61.218l-16.695-11.131v44.522l16.695-11.131h61.218v61.218l-11.131 16.695h44.522l-11.131-16.695V267.13z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Guernsey';
export const Guernsey = memo<IconProps>(Icon);
