import { createStyledContext } from '@tamagui/core';
import { InputState } from './base-input.types';
import { generateBasicId } from '../../utils/generate-basic-id';

export type InputContextProps = {
  state: InputState;
  disabled?: boolean;
  labelId?: string;
};

export const InputContext = createStyledContext<InputContextProps>({
  state: 'default',
  disabled: false,
  labelId: generateBasicId('input'),
});
