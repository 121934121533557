import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#338af3" />
      <Path
        d="M400.696 258.783H111.304c0 19.979 17.401 36.174 37.378 36.174h-1.204c0 19.979 16.195 36.174 36.174 36.174 0 19.979 16.195 36.174 36.174 36.174h72.348c19.979 0 36.174-16.195 36.174-36.174 19.979 0 36.174-16.195 36.174-36.174h-1.204c19.975 0 37.378-16.195 37.378-36.174"
        fill="#ffda44"
      />
      <Path
        d="M356.174 211.478c0 55.324-44.85 100.174-100.174 100.174s-100.174-44.85-100.174-100.174"
        fill="#338af3"
      />
      <Path
        d="m332.515 211.478-31.265 14.707 16.649 30.28-33.95-6.495-4.302 34.296L256 259.041l-23.648 25.225-4.301-34.296-33.95 6.493 16.648-30.279-31.264-14.706 31.265-14.706-16.649-30.279 33.949 6.494 4.303-34.296L256 163.916l23.648-25.225 4.301 34.296 33.951-6.494-16.649 30.28z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Kazakhstan';
export const Kazakhstan = memo<IconProps>(Icon);
