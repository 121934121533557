import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M446.793 426.666C487.336 381.374 512 321.572 512 256s-24.665-125.375-65.208-170.667L256 66.783 65.208 85.333C24.665 130.625 0 190.428 0 256s24.664 125.374 65.207 170.666L256 445.217z"
        fill="#ffda44"
      />
      <Path
        d="M65.208 85.333h381.585C399.918 32.966 331.813 0 256 0S112.082 32.966 65.208 85.333"
        fill="#000"
      />
      <Path
        d="m0 256 256 22.261L512 256c0-29.924-5.151-58.642-14.588-85.334H14.588C5.151 197.358 0 226.076 0 256"
        fill="#d80027"
      />
      <Path
        d="M14.588 341.333h482.826C506.849 314.641 512 285.924 512 256H0c0 29.924 5.151 58.641 14.588 85.333"
        fill="#000"
      />
      <Path
        d="M446.793 426.666H65.207C112.081 479.034 180.186 512 256 512s143.919-32.966 190.793-85.334"
        fill="#d80027"
      />
      <Path
        d="M341.426 256c0 47.183-38.243 85.426-85.426 85.426S170.574 303.183 170.574 256s38.243-85.426 85.426-85.426 85.426 38.243 85.426 85.426"
        fill="#f0f0f0"
      />
      <Path
        d="M287.295 260.356 256 246.795s7.322-24.303 7.712-25.966c.285-1.215.437-2.482.437-3.784 0-4.611-1.869-8.785-4.891-11.805l7.87-7.87a27.74 27.74 0 0 0-19.676-8.15 27.74 27.74 0 0 0-19.676 8.15l7.87 7.87a16.64 16.64 0 0 0-4.89 11.805c0 1.686.255 3.311.72 4.846l-11.849 11.848h21.536s-8.904 13.374-13.765 24.357.067 24.389 11.301 29.383l6.496 2.887L256 300.522v11.13l-11.13 11.13h22.261v-22.261l10.157-10.157h21.008c.101-.209.207-.413.303-.627 4.991-11.233-.069-24.389-11.304-29.381"
        fill="#000"
      />
    </Svg>
  );
};
Icon.displayName = 'Uganda';
export const Uganda = memo<IconProps>(Icon);
