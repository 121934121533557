import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M200.349 222.609h309.484C493.47 97.002 386.067 0 256 0a257 257 0 0 0-55.652 6.085zM133.565 222.608V31.127C63.272 69.481 12.95 139.832 2.167 222.609h131.398zM133.564 289.391H2.167c10.783 82.777 61.105 153.128 131.398 191.481zM200.348 289.392v216.523A257 257 0 0 0 256 512c130.067 0 237.47-97.002 253.833-222.609H200.348z"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'Denmark';
export const Denmark = memo<IconProps>(Icon);
