import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M0 256c0 31.314 5.633 61.31 15.923 89.043L256 356.174l240.077-11.13C506.368 317.31 512 287.314 512 256s-5.632-61.31-15.923-89.043L256 155.826l-240.077 11.13C5.633 194.69 0 224.686 0 256"
        fill="#d80027"
      />
      <Path
        d="M256 0C145.929 0 52.094 69.472 15.923 166.957h480.155C459.906 69.472 366.071 0 256 0"
        fill="#000"
      />
      <Path
        d="M496.077 345.043H15.923C52.094 442.527 145.929 512 256 512s203.906-69.473 240.077-166.957"
        fill="#496e2d"
      />
      <Path
        d="m332.515 122.435-31.264-14.706L317.9 77.45l-33.95 6.495-4.302-34.298L256 74.872l-23.647-25.225-4.303 34.298-33.949-6.495 16.649 30.279-31.265 14.706z"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'Malawi';
export const Malawi = memo<IconProps>(Icon);
