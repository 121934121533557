import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M4.374 20.3q-.6.224-1.063-.237Q2.85 19.6 3.074 19l3.225-9.025q.35-1.05 1.425-1.3 1.074-.25 1.875.55l4.55 4.55q.8.8.55 1.875t-1.3 1.425zm1.3-2.6 7.05-2.5-4.55-4.55z"
      />
      <Path
        fill={`${color}`}
        fillRule="evenodd"
        d="M9.97 7.88a.75.75 0 0 1 .007-1.06c.586-.58.846-1.157.925-1.596.04-.221.032-.4.006-.525-.025-.117-.06-.163-.064-.168a.75.75 0 0 1 1.06-1.061c.494.493.606 1.287.475 2.019-.14.774-.564 1.624-1.348 2.398a.75.75 0 0 1-1.06-.007M15.637 2.288a.75.75 0 0 1 .948.475c.55 1.647.45 2.89-.198 3.943-.547.89-1.46 1.57-2.345 2.23l-.218.164a.75.75 0 0 1-.9-1.2l.126-.095c.983-.736 1.67-1.25 2.06-1.886.353-.573.503-1.329.052-2.682a.75.75 0 0 1 .474-.949M19.023 8.907c-.893.263-1.913.917-3.118 2.123a.75.75 0 0 1-1.061-1.06c1.293-1.294 2.524-2.14 3.756-2.502 1.263-.371 2.458-.215 3.61.361a.75.75 0 0 1-.671 1.342c-.849-.424-1.654-.517-2.516-.264M18.244 13.185c-.583-.054-1.064.07-1.34.345a.75.75 0 0 1-1.06-1.06c.725-.725 1.744-.851 2.536-.78.41.038.803.13 1.138.255.319.119.652.29.887.525a.75.75 0 0 1-1.061 1.06l-.022-.017a1 1 0 0 0-.072-.046 2 2 0 0 0-.254-.116 3 3 0 0 0-.752-.167"
        clipRule="evenodd"
      />
    </Svg>
  );
};
Icon.displayName = 'Celebration';
export const Celebration = memo<IconProps>(themed(Icon));
