import {
  ColorTokens,
  createStyledContext,
  GetProps,
  Stack,
  styled,
  useTheme,
  withStaticProperties,
} from '@tamagui/core';
import {
  cloneElement,
  createElement,
  FunctionComponent,
  isValidElement,
  useContext,
} from 'react';
import { Typography } from '../typography';
import {
  bgColors,
  disabledBgColors,
  hoverBgColors,
  loadingBgColors,
  pressBgColors,
} from './colors';

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary';
export type ButtonState = 'default' | 'danger';
export type ButtonSize = 'large' | 'medium' | 'small';
export type ButtonIconProps = {
  children: JSX.Element | FunctionComponent;
  size?: number;
  color?: ColorTokens;
};

type ButtonContextProps = {
  size?: ButtonSize;
  variant?: ButtonVariant;
  state?: ButtonState;
  disabled?: boolean;
  loading?: boolean;
};

// ButtonContext, shared props between Frame and Text
export const ButtonContext = createStyledContext<ButtonContextProps>({
  size: 'large' as ButtonSize,
  variant: 'primary' as ButtonVariant,
  state: 'default' as ButtonState,
  disabled: false,
  loading: false,
});

const ButtonFrame = styled(Stack, {
  name: 'Button',
  context: ButtonContext,
  alignItems: 'center',
  justifyContent: 'center',
  paddingHorizontal: '$small',
  height: 56,
  borderRadius: '$small',
  focusStyle: {
    borderWidth: 4,
    borderColor: '$borderActive',
  },
  variants: {
    loading: {
      true: {
        disabled: true,
      },
    },
    disabled: {
      true: (_, { props }) => {
        // @ts-expect-error
        const variant = props.variant as ButtonVariant;
        // @ts-expect-error
        const state = props.state as ButtonState;

        return {
          // @ts-expect-error
          backgroundColor: props.loading
            ? loadingBgColors?.[variant]?.[state]
            : disabledBgColors?.[variant],
          pointerEvents: 'none',
        };
      },
    },
    size: {
      large: {
        height: 56,
      },
      medium: {
        height: 48,
      },
      small: {},
    },
    variant: {
      primary: {},
      secondary: {},
      tertiary: {},
    },
    state: (state: ButtonState, { props }) => {
      // @ts-expect-error
      const variant = props.variant as ButtonVariant;

      return {
        backgroundColor: bgColors?.[variant]?.[state],
        hoverStyle: {
          backgroundColor: hoverBgColors?.[variant]?.[state],
        },
        pressStyle: {
          backgroundColor: pressBgColors?.[variant]?.[state],
        },
      };
    },
  } as const,
});

const ButtonText = styled(Typography, {
  context: ButtonContext,
  userSelect: 'none',
  variants: {
    disabled: {
      true: {
        color: '$contentDisabled',
      },
    },
    variant: {
      primary: {
        variant: 'labelLarge',
      },
      secondary: {
        variant: 'labelLarge',
      },
      tertiary: {
        variant: 'labelLarge',
      },
    },
  } as const,
});

const ButtonIcon = (props: ButtonIconProps) => {
  const { disabled, variant, state } =
    useContext<ButtonContextProps>(ButtonContext);

  const theme = useTheme();

  const mapVariantAndStateToColor = {
    primary: {
      default: '$contentInversePrimary',
      danger: '$contentInversePrimary',
    },
    secondary: {
      default: '$contentPrimary',
      danger: '$contentNegative',
    },
    tertiary: {
      default: '$contentPrimary',
      danger: '$contentNegative',
    },
  };

  const iconProps = {
    size: 24,
    color: disabled
      ? theme.contentDisabled.get()
      : mapVariantAndStateToColor?.[variant as ButtonVariant]?.[
          state as ButtonState
        ],
    key: 'button-icon',
    ...props,
  };

  if (isValidElement(props.children)) {
    return cloneElement(props.children, iconProps);
  } else {
    // @ts-expect-error
    return createElement(props.children, iconProps);
  }
};

export const BaseButton = withStaticProperties(ButtonFrame, {
  Text: ButtonText,
  Icon: ButtonIcon,
  Props: ButtonContext.Provider,
});

export type BaseButtonProps = GetProps<typeof BaseButton> & {
  icon?: JSX.Element | FunctionComponent;
};
