import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M5.35 16.35c-.25 0-.45-.15-.6-.4-.1-.2-.15-.5-.2-.85-.1-1.1 0-2.75.35-3.25.15-.25.4-.35.6-.35.15 0 .3.05 3.55 1.4l.95.4c.35.15.55.5.55.9s-.25.7-.6.8l-1.35.45c-3 .9-3.1.9-3.25.9m6.65 1.8c0 3.15 0 3.25-.05 3.4-.1.25-.3.4-.55.45-.8.15-3.3-.8-3.85-1.4-.1-.15-.15-.25-.2-.4 0-.1 0-.2.05-.3.05-.15.15-.3 2.4-2.95l.65-.8c.2-.3.65-.35 1-.25.35.15.6.45.55.8zm-.6-6.7c-.15.05-.65.2-1.25-.8 0 0-4.05-6.45-4.15-6.65-.05-.2 0-.5.2-.7.6-.65 3.85-1.55 4.7-1.35.3.05.45.25.55.5.05.3.45 6.25.5 7.6.1 1.2-.4 1.35-.55 1.4m2.2 1.5c-.2-.3-.2-.7 0-.95l.85-1.15c1.8-2.5 1.9-2.65 2.05-2.7.2-.15.45-.15.7-.05.7.35 2.2 2.55 2.3 3.35v.05c0 .3-.1.5-.3.65-.15.1-.25.15-3.7.95-.55.15-.85.2-1 .25v-.05c-.3.15-.7-.05-.9-.35m5.85 4.25c-.1.8-1.75 2.9-2.55 3.2-.25.1-.5.1-.7-.1-.15-.1-.25-.3-2.05-3.2l-.55-.85c-.2-.3-.15-.7.1-1.05.25-.3.6-.4.95-.3l1.35.45c3 1 3.1 1 3.2 1.1.2.25.3.5.25.75"
      />
    </Svg>
  );
};
Icon.displayName = 'Yelp';
export const Yelp = memo<IconProps>(themed(Icon));
