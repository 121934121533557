import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 0v256L74.98 437.02C121.307 483.346 185.307 512 256 512c141.384 0 256-114.616 256-256S256 0 256 0"
        fill="#d80027"
      />
      <Path
        d="M256 0C185.307 0 121.307 28.654 74.98 74.98L256 256h256C512 114.616 397.384 0 256 0"
        fill="#0052b4"
      />
      <Path
        d="m175.291 256-31.264-14.706 16.649-30.279-33.95 6.495-4.302-34.297-23.648 25.224-23.647-25.224-4.303 34.297-33.949-6.496 16.649 30.28L22.261 256l31.265 14.706-16.649 30.279 33.952-6.494 4.299 34.296 23.648-25.224 23.648 25.224 4.302-34.296 33.948 6.495-16.648-30.279zM68.273 100.174l10.404 14.543 17.045-5.399-10.618 14.388 10.404 14.543-16.966-5.651-10.617 14.387.133-17.88-16.965-5.652 17.048-5.399zM68.273 365.015l10.404 14.543 17.045-5.4-10.618 14.389 10.404 14.543-16.966-5.651-10.617 14.387.133-17.88-16.965-5.652 17.048-5.399zM216.666 232.595l-10.403 14.543-17.046-5.401 10.618 14.389-10.404 14.544 16.966-5.652 10.618 14.387-.134-17.879 16.965-5.652-17.047-5.399z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Philippines';
export const Philippines = memo<IconProps>(Icon);
