import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 0C145.929 0 52.094 69.472 15.923 166.957h480.155C459.906 69.472 366.071 0 256 0"
        fill="#a2001d"
      />
      <Path
        d="M256 512c110.071 0 203.906-69.472 240.077-166.957H15.923C52.094 442.528 145.929 512 256 512"
        fill="#000"
      />
      <Path
        d="M194.783 239.304c-.14 0-.277.009-.417.011v-.011h-48.937c2.481-9.586 11.179-16.696 21.528-16.696v-33.391c-30.687 0-55.652 24.966-55.652 55.652v27.826999999999998h83.47800000000001a5.57 5.57 0 0 1 5.565 5.565v11.13H89.043v33.391h144.696V278.26c0-21.48-17.476-38.956-38.956-38.956M278.261 289.391V189.217H244.87v133.566h55.652v-33.392zM389.565 289.392V189.217h-33.391v100.175h-11.131v-33.391h-33.391v66.783h100.174v-33.392z"
        fill="#496e2d"
      />
    </Svg>
  );
};
Icon.displayName = 'Iraq';
export const Iraq = memo<IconProps>(Icon);
