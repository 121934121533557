import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M378.435 31.127C342.064 11.283 300.35 0 256 0s-86.064 11.283-122.435 31.127L111.304 256l22.261 224.873C169.936 500.717 211.65 512 256 512s86.064-11.283 122.435-31.127L400.696 256z"
        fill="#ffda44"
      />
      <Path
        d="M133.565 480.871V31.126C53.989 74.546 0 158.965 0 256s53.989 181.454 133.565 224.871"
        fill="#338af3"
      />
      <Path
        d="M512 256c0-97.035-53.989-181.454-133.565-224.874v449.745C458.011 437.454 512 353.035 512 256M200.348 322.783 155.825 256l44.522-66.783L244.871 256zM311.652 322.783 267.13 256l44.522-66.783L356.174 256zM256 411.826l-44.522-66.783L256 278.261l44.522 66.782z"
        fill="#6da544"
      />
    </Svg>
  );
};
Icon.displayName = 'StVincentAndTheGrenadines';
export const StVincentAndTheGrenadines = memo<IconProps>(Icon);
