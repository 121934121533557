import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M486.598 367.304C502.873 333.648 512 295.891 512 256s-9.127-77.648-25.402-111.304L256 122.435 25.402 144.696C9.127 178.352 0 216.109 0 256s9.127 77.648 25.402 111.304L256 389.565z"
        fill="#0052b4"
      />
      <Path
        d="M256 512c101.493 0 189.19-59.065 230.598-144.696H25.402C66.81 452.935 154.506 512 256 512"
        fill="#d80027"
      />
      <Path
        d="m443.367 306.252-13.521 10.566 5.868 16.121-14.227-9.593-13.522 10.564 4.728-16.495-14.225-9.596 17.149-.598 4.732-16.495 5.87 16.124zM408.741 246.282l-9.091 14.553 11.031 13.142-16.651-4.146-9.094 14.549-1.199-17.117-16.649-4.153L383 256.681l-1.196-17.118 11.029 13.147zM355.694 201.769l-3.566 16.785 14.86 8.58-17.065 1.799-3.568 16.78-6.98-15.674-17.067 1.791 12.754-11.482-6.979-15.674 14.86 8.578zM290.622 178.087l2.391 16.993 16.897 2.978-15.422 7.526 2.389 16.992-11.92-12.344-15.426 7.522 8.058-15.153-11.921-12.342 16.9 2.979zM221.377 178.087l8.057 15.151 16.896-2.981-11.917 12.347 8.053 15.152-15.422-7.524-11.922 12.341 2.391-16.989-15.423-7.522 16.899-2.982zM156.305 201.769l12.754 11.483 14.857-8.578-6.977 15.68 12.751 11.48-17.065-1.795-6.982 15.674-3.563-16.783-17.066-1.792 14.86-8.582zM103.258 246.282l15.913 6.428 11.026-13.144-1.193 17.121 15.908 6.429-16.649 4.149-1.199 17.115-9.09-14.549-16.651 4.149 11.03-13.145zM68.634 306.252l17.152.598 5.866-16.122 4.735 16.496 17.147.599-14.226 9.596 4.728 16.494-13.52-10.564-14.227 9.593 5.869-16.124z"
        fill="#f0f0f0"
      />
      <Path
        d="M486.598 144.696C445.19 59.065 357.493 0 256 0S66.81 59.065 25.402 144.696z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Venezuela';
export const Venezuela = memo<IconProps>(Icon);
