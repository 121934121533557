import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 0C145.929 0 52.094 69.472 15.923 166.957h480.155C459.906 69.472 366.071 0 256 0"
        fill="#d80027"
      />
      <Path
        d="M256 512c110.071 0 203.906-69.472 240.077-166.957H15.923C52.094 442.528 145.929 512 256 512"
        fill="#000"
      />
      <Path
        d="m152.962 194.783 13.813 42.512h44.703l-36.165 26.278 13.814 42.514-36.165-26.275-36.165 26.275 13.814-42.514-36.165-26.278h44.703zM359.038 194.783l13.813 42.512h44.704l-36.166 26.278 13.814 42.514-36.165-26.275-36.165 26.275 13.814-42.514-36.165-26.278h44.703z"
        fill="#6da544"
      />
    </Svg>
  );
};
Icon.displayName = 'Syria';
export const Syria = memo<IconProps>(Icon);
