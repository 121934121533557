export const UNIQUE_RENTERS_SIGNIN_PARAM = 'unique_renters_signin=true';

type UniqueRenterField = {
  label: string;
  error: string;
};

export type UniqueRentersState = {
  title: string;
  description: string;
  fields: { [key: string]: UniqueRenterField };
  ctas: { [key: string]: string };
  notification?: {
    description: string;
    notifySeconds: number;
  };
  troubleshoot?: {
    title: string;
    cta: string;
    visibleAfterResending: number;
  };
};

export type UniqueRentersType = {
  confirmOtp: UniqueRentersState;
  confirmPhone: UniqueRentersState;
  confirmLogin: {
    title: string;
    description: string;
    cta: string;
  };
  status: {
    verified: string;
  };
};
