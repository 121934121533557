import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M19.95 21q-3.125 0-6.175-1.363t-5.55-3.862-3.862-5.55T3 4.05q0-.45.3-.75t.75-.3H8.1a.93.93 0 0 1 .625.237.9.9 0 0 1 .325.563l.65 3.5q.05.4-.025.675T9.4 8.45L6.975 10.9q.5.926 1.188 1.787.687.863 1.512 1.663.775.775 1.625 1.438.85.661 1.8 1.212l2.35-2.35a1.4 1.4 0 0 1 .588-.338 1.6 1.6 0 0 1 .712-.062l3.45.7q.35.1.575.363A.88.88 0 0 1 21 15.9v4.05q0 .45-.3.75t-.75.3M6.025 9l1.65-1.65L7.25 5H5.025q.125 1.024.35 2.025T6.025 9m8.95 8.95q.976.424 1.987.675 1.013.25 2.038.325v-2.2l-2.35-.475z"
      />
    </Svg>
  );
};
Icon.displayName = 'Call';
export const Call = memo<IconProps>(themed(Icon));
