import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M15 20q-3.35 0-5.675-2.325T7 12q0-3.325 2.325-5.662Q11.65 4 15 4q3.325 0 5.663 2.338T23 12q0 3.35-2.337 5.675T15 20m0-2q2.5 0 4.25-1.75T21 12t-1.75-4.25T15 6t-4.25 1.75T9 12t1.75 4.25T15 18m1-6.4V9a.97.97 0 0 0-.287-.713A.97.97 0 0 0 15 8a.97.97 0 0 0-.713.287A.97.97 0 0 0 14 9v3.025q0 .2.088.387.087.188.212.313L16.575 15q.3.3.713.3.412 0 .712-.3t.3-.713-.3-.712zM3 9a.97.97 0 0 1-.712-.287A.97.97 0 0 1 2 8q0-.424.288-.713A.97.97 0 0 1 3 7h2q.424 0 .713.287Q6 7.576 6 8q0 .424-.287.713A.97.97 0 0 1 5 9zm-1 4a.97.97 0 0 1-.712-.287A.97.97 0 0 1 1 12q0-.424.288-.713A.97.97 0 0 1 2 11h3q.424 0 .713.287Q6 11.576 6 12q0 .424-.287.713A.97.97 0 0 1 5 13zm1 4a.97.97 0 0 1-.712-.288A.97.97 0 0 1 2 16q0-.424.288-.713A.97.97 0 0 1 3 15h2q.424 0 .713.287Q6 15.576 6 16q0 .424-.287.712A.97.97 0 0 1 5 17z"
      />
    </Svg>
  );
};
Icon.displayName = 'Hurry';
export const Hurry = memo<IconProps>(themed(Icon));
