import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M345.043 15.922C317.309 5.633 287.314 0 256 0c-31.314-.001-61.31 5.633-89.043 15.922l-22.261 240.077 22.261 240.077C194.689 506.368 224.685 512 256 512c31.313 0 61.31-5.632 89.043-15.924L367.303 256z"
        fill="#ffda44"
      />
      <Path
        d="M512 256c0-110.07-69.472-203.907-166.957-240.077v480.156C442.528 459.905 511.999 366.072 512 256"
        fill="#d80027"
      />
      <Path
        d="M0 255.999c0 110.073 69.472 203.906 166.955 240.079l.001-480.154C69.472 52.094 0 145.928 0 255.999"
        fill="#0052b4"
      />
    </Svg>
  );
};
Icon.displayName = 'Romania';
export const Romania = memo<IconProps>(Icon);
