import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 0C154.506 0 66.81 59.065 25.402 144.696h461.195C445.19 59.065 357.493 0 256 0"
        fill="#ff9811"
      />
      <Path
        d="M256 512c101.493 0 189.19-59.065 230.598-144.696H25.402C66.81 452.935 154.506 512 256 512"
        fill="#6da544"
      />
      <Circle cx={256} cy={256} r={89.043} fill="#0052b4" />
      <Circle cx={256} cy={256} r={55.652} fill="#f0f0f0" />
      <Path
        d="m256 187.326 17.169 38.938 42.304-4.601L290.337 256l25.136 34.337-42.304-4.601L256 324.674l-17.169-38.938-42.304 4.6L221.663 256l-25.136-34.337 42.304 4.601z"
        fill="#0052b4"
      />
    </Svg>
  );
};
Icon.displayName = 'India';
export const India = memo<IconProps>(Icon);
