import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 0C150.165 0 59.337 64.226 20.348 155.826h471.305C452.664 64.226 361.835 0 256 0"
        fill="#a2001d"
      />
      <Path
        d="M256 512c105.835 0 196.664-64.226 235.652-155.826H20.348C59.337 447.774 150.165 512 256 512"
        fill="#496e2d"
      />
      <Path
        d="M503.181 189.217H8.819C3.08 210.51 0 232.893 0 256s3.08 45.49 8.819 66.783h494.363C508.92 301.49 512 279.107 512 256s-3.08-45.49-8.819-66.783"
        fill="#0052b4"
      />
    </Svg>
  );
};
Icon.displayName = 'Gambia';
export const Gambia = memo<IconProps>(Icon);
