import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M345.043 256c0 22.261-39.866 77.913-89.043 77.913S166.957 278.261 166.957 256c0-49.178 39.866-89.043 89.043-89.043s89.043 39.865 89.043 89.043"
        fill="#d80027"
      />
      <Path
        d="M345.043 256c0 49.178-39.866 89.043-89.043 89.043S166.957 305.178 166.957 256"
        fill="#0052b4"
      />
      <Path d="m350.442 334.705 23.61-23.61 15.741 15.74-23.61 23.61zM311.085 374.06l23.61-23.612 15.741 15.741-23.61 23.611zM397.67 381.917l23.611-23.61 15.74 15.74-23.61 23.61zM358.314 421.278l23.61-23.61 15.741 15.74-23.61 23.611zM374.055 358.313l23.61-23.61 15.741 15.74-23.61 23.61zM334.709 397.667l23.61-23.61 15.741 15.74-23.61 23.611zM397.678 177.29l-62.963-62.963 15.741-15.74 62.962 62.962zM334.713 161.558l-23.61-23.61 15.74-15.74 23.61 23.61zM374.06 200.907l-23.61-23.611 15.74-15.74 23.61 23.61zM381.915 114.336l-23.61-23.611 15.74-15.741 23.61 23.61zM421.283 153.688l-23.611-23.61 15.74-15.741 23.612 23.61zM90.723 358.318l62.962 62.962-15.74 15.74-62.963-62.961zM153.68 374.05l23.611 23.611-15.74 15.74-23.61-23.61zM114.33 334.703l23.611 23.611-15.74 15.741-23.611-23.61zM137.943 311.091l62.962 62.963-15.74 15.74-62.963-62.962zM153.684 90.72 90.72 153.683l-15.74-15.741 62.962-62.963zM177.287 114.325l-62.963 62.963-15.74-15.741 62.962-62.962zM200.912 137.947l-62.962 62.962-15.74-15.74 62.962-62.962z" />
    </Svg>
  );
};
Icon.displayName = 'SouthKorea';
export const SouthKorea = memo<IconProps>(Icon);
