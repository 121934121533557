import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M7.858 2.07c-1.064.05-1.79.22-2.425.47-.658.256-1.215.6-1.77 1.156a4.9 4.9 0 0 0-1.15 1.772c-.246.637-.413 1.364-.46 2.429s-.057 1.407-.052 4.122c.005 2.716.017 3.056.069 4.123.05 1.064.22 1.79.47 2.426.256.657.6 1.214 1.156 1.769a4.9 4.9 0 0 0 1.774 1.15c.636.245 1.363.413 2.428.46 1.064.046 1.407.057 4.122.052s3.056-.017 4.123-.068c1.066-.05 1.789-.221 2.425-.47a4.9 4.9 0 0 0 1.769-1.156 4.9 4.9 0 0 0 1.15-1.774c.246-.636.413-1.363.46-2.427.046-1.067.057-1.408.052-4.123s-.018-3.056-.068-4.122c-.051-1.067-.22-1.79-.47-2.427a4.9 4.9 0 0 0-1.156-1.769 4.9 4.9 0 0 0-1.774-1.15c-.636-.245-1.363-.413-2.427-.46-1.065-.045-1.407-.057-4.123-.052s-3.056.017-4.123.069m.117 18.078c-.975-.043-1.504-.205-1.857-.34-.467-.18-.8-.398-1.152-.746a3.1 3.1 0 0 1-.75-1.149c-.137-.352-.302-.881-.347-1.856-.05-1.054-.06-1.37-.066-4.04s.004-2.986.05-4.04c.042-.974.205-1.504.34-1.857.18-.468.397-.8.746-1.151a3.1 3.1 0 0 1 1.149-.75c.352-.138.881-.302 1.856-.348 1.054-.05 1.37-.06 4.04-.066s2.986.004 4.041.05c.974.043 1.504.204 1.857.34.467.18.8.397 1.151.746.351.35.568.682.75 1.15.138.35.302.88.348 1.855.05 1.054.061 1.37.066 4.04s-.004 2.986-.05 4.04c-.043.975-.205 1.504-.34 1.857a3.1 3.1 0 0 1-.747 1.152c-.349.35-.682.567-1.148.75-.352.137-.882.301-1.856.347-1.054.05-1.37.06-4.04.066s-2.986-.005-4.04-.05m8.151-13.493a1.2 1.2 0 1 0 2.4-.004 1.2 1.2 0 0 0-2.4.004M6.865 12.01a5.134 5.134 0 1 0 10.269-.02 5.134 5.134 0 0 0-10.269.02m1.802-.004a3.333 3.333 0 1 1 6.666-.013 3.333 3.333 0 0 1-6.666.013"
      />
    </Svg>
  );
};
Icon.displayName = 'Instagram';
export const Instagram = memo<IconProps>(themed(Icon));
