import {
  Stack,
  setupReactNative,
  styled,
  withStaticProperties,
} from '@tamagui/core';
import { useContext } from 'react';

import { Platform, TextInput, TextInputProps } from 'react-native';
import { Box } from '../box';
import { Typography } from '../typography';
import { InputContext } from './base-input.context';
import { InputState } from './base-input.types';

setupReactNative({
  TextInput,
});

const isWeb = Platform.OS === 'web';
const webStyles = {
  fontFeatureSettings: "'ss03'",
  outlineStyle: 'none',
};

const InputContainer = styled(Stack, {
  name: 'BaseInputContainer',
  context: InputContext,
  gap: '$xTiny',
  variants: {
    state: {
      default: {},
      error: {},
      success: {},
    },
  } as const,
});

const InputFrame = styled(Stack, {
  name: 'BaseInput',
  context: InputContext,
  height: 56,
  alignItems: 'flex-start',
  backgroundColor: '$backgroundPrimary',
  borderRadius: '$small',
  flexDirection: 'column',
  focusable: false,
  justifyContent: 'flex-end',
  variants: {
    disabled: {
      true: {
        pointerEvents: 'none',
      },
    },
  } as const,
});

const InputFrameBorder = styled(Stack, {
  name: 'BaseInputFrameBorder',
  context: InputContext,
  height: '100%',
  width: '100%',
  position: 'absolute',
  borderColor: '$borderDefault',
  borderWidth: 1,
  backgroundColor: '$backgroundPrimary',
  borderRadius: '$small',
  variants: {
    focus: (value, { props }) => {
      if (!value) {
        // @ts-expect-error
        if (props.state !== 'default' || props.disabled) return {};

        return {
          hoverStyle: {
            borderColor: '$contentSecondary',
          },
        };
      }

      let borderColor = '$borderActive';

      // @ts-expect-error
      if (props.state === 'error') borderColor = '$borderNegative';
      // @ts-expect-error
      if (props.state === 'success') borderColor = '$borderPositive';

      return {
        borderColor,
        borderWidth: 2,
        hoverStyle: {
          borderColor,
        },
      };
    },
    state: {
      default: {},
      error: {
        borderColor: '$borderNegative',
        hoverStyle: {
          borderColor: '$borderNegative',
        },
      },
      success: {
        borderColor: '$borderPositive',
        hoverStyle: {
          borderColor: '$borderPositive',
        },
      },
    },
    disabled: {
      true: {
        borderColor: '$borderDisabled',
        pointerEvents: 'none',
      },
    },
  } as const,
});

const InputField = styled(TextInput, {
  name: 'Input',
  context: InputContext,
  width: '100%',
  fontSize: 16,
  lineHeight: 24,
  fontFamily: '$hankenGrotesk',
  fontWeight: '400',
  fontStyle: 'normal',
  placeholderTextColor: '$contentSecondary',
  color: '$borderActive',
  flex: 1,
  paddingTop: 20,
  // @ts-expect-error
  style: isWeb ? webStyles : {},
  variants: {
    disabled: {
      true: {
        // @ts-ignore
        color: '$contentDisabled',
      },
    },
  } as const,
  tabIndex: 0,
});

const AccessibleInputField = InputField.styleable((props, ref) => {
  const { disabled } = useContext(InputContext);
  return (
    <InputField
      {...props}
      accessibilityLabel="input"
      ref={ref}
      accessibilityState={{
        disabled,
      }}
    />
  );
});

const HintText = styled(Typography, {
  variant: 'paragraphXSmall',
  color: '$contentSecondary',
  variants: {
    state: {
      default: {},
      error: {
        color: '$contentNegative',
      },
      success: {
        color: '$contentPositive',
      },
    },
  } as const,
});

export type BaseInputProps = TextInputProps & {
  label: string;
  disabled?: boolean;
  hintText?: string;
  state?: InputState;
  name?: string;
  id?: string;
  testID?: string;
  mask?: string;
  keyboardType?: TextInputProps['keyboardType'];
};

type ComposedFrameProps = {
  withoutBorder?: boolean;
  children: React.ReactNode;
  focus?: boolean;
  disabled?: boolean;
};

const ComposedFrame = (props: ComposedFrameProps) => {
  const { children, focus, withoutBorder, ...restProps } = props;

  return (
    <InputFrame {...restProps}>
      <InputFrameBorder
        {...restProps}
        focus={focus}
        borderWidth={withoutBorder ? 0 : 1}
      />
      <Box paddingHorizontal="$small" flex={1} flexDirection="row" width="100%">
        {children}
      </Box>
    </InputFrame>
  );
};

export const BaseInput = withStaticProperties(InputContainer, {
  InputFrame: ComposedFrame,
  InputField: AccessibleInputField,
  HintText,
  Props: InputContext.Provider,
});

export * from './components/label';
