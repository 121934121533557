import dayjs from 'dayjs';

export const DEFAULT_START_ADDRESS = '';

export const DEFAULT_END_ADDRESS = '';

export const START_DISABLED_DATES_BEFORE = [
  {
    before: new Date(),
  },
] as [Date | { before: Date }];

const AVERAGE_TRIP_LENGTH = 4;

export const DEFAULT_START_DATE = dayjs()
  .hour(11)
  .minute(0)
  .second(0)
  .millisecond(0)
  .add(1, 'day')
  .toDate();

export const DEFAULT_END_DATE = dayjs()
  .hour(11)
  .minute(0)
  .second(0)
  .millisecond(0)
  .add(1 + AVERAGE_TRIP_LENGTH, 'day')
  .toDate();

export const LANDING_PAGE_END_DATE = dayjs()
  .hour(11)
  .minute(0)
  .second(0)
  .millisecond(0)
  .add(4, 'day')
  .toDate();

export const ONE_WEEK_START_DATE = dayjs()
  .hour(11)
  .minute(0)
  .second(0)
  .millisecond(0)
  .add(7, 'day')
  .toDate();

export const START_FROM_MONTH = dayjs().subtract(6, 'month').toDate();

export const START_TO_MONTH = dayjs().add(12, 'month').toDate();

export const END_FROM_MONTH = dayjs().subtract(6, 'month').toDate();

export const END_TO_MONTH = dayjs().add(12, 'month').toDate();

export const DEFAULT_TAB = 'rent';

export const POSSIBLE_SEARCH_TABS = ['rent', 'subscribe'];
