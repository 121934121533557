import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M0 256c0 39.89 9.127 77.648 25.402 111.304L256 378.435l230.598-11.13C502.873 333.648 512 295.89 512 256s-9.127-77.648-25.402-111.304L256 133.565l-230.598 11.13C9.127 178.352 0 216.11 0 256"
        fill="#f0f0f0"
      />
      <Path
        d="M256 512c101.493 0 189.19-59.065 230.598-144.696H25.402C66.81 452.935 154.506 512 256 512"
        fill="#6da544"
      />
      <Path
        d="M256 0C154.506 0 66.81 59.065 25.402 144.696h461.195C445.19 59.065 357.493 0 256 0"
        fill="#0052b4"
      />
      <Path
        d="M272.696 250.435v-61.217h-33.391v61.217l-40.33 40.331c11.729 19.2 32.88 32.017 57.026 32.017s45.296-12.817 57.026-32.017z"
        fill="#000"
      />
    </Svg>
  );
};
Icon.displayName = 'Lesotho';
export const Lesotho = memo<IconProps>(Icon);
