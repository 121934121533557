import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 512c64.917 0 124.179-24.176 169.306-64H86.694c45.127 39.824 104.389 64 169.306 64"
        fill="#d80027"
      />
      <Path
        d="M8.065 320a254.5 254.5 0 0 0 26.191 64h443.487a254.5 254.5 0 0 0 26.191-64L256 300.522zM503.935 192a254.5 254.5 0 0 0-26.191-64L256 122.435V0c-19.017 0-37.551 2.075-55.386 6.008-.342.076-167.223 131.71-167.223 249.992C37.658 256.742 256 278.261 256 278.261V192z"
        fill="#0052b4"
      />
      <Path
        d="M256 159.722v-128h-81.075l25.697-25.697C95.131 29.291 14.076 117.615 1.67 226.783A259 259 0 0 0 0 256h129.67v-51.023L181.198 256h91.585l-97.859-96.278z"
        fill="#f0f0f0"
      />
      <Path
        d="m256.117 256-96.278-96.278H129.67v.001L225.947 256H97.391V128h380.353c-13.938-24.093-31.696-45.697-52.437-64H97.391v-8.925a257.2 257.2 0 0 0-64 74.479V256H0c0 22.099 2.8 43.545 8.065 64h495.87C509.2 299.544 512 278.099 512 256z"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'Hawaii';
export const Hawaii = memo<IconProps>(Icon);
