import { phone as parsePhoneNumber } from 'phone';
import { Country } from './phone-input.constants';

export const CLEAN_PHONE_NUMBER_REGEX = /[^\d\+]/g;

export const getCountryCodeFromNumber = (phone: string) => {
  return parsePhoneNumber(phone).countryIso2;
};

export const getMask = (country: Country) =>
  `+${country.value} (999) 999-99999`;

export const getNormalizedString = (str: string) =>
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const sanitizePhoneNumber = (
  phoneNumber?: string,
  pattern: RegExp = CLEAN_PHONE_NUMBER_REGEX,
) => {
  if (!phoneNumber) return '';

  return phoneNumber.replace(pattern, '');
};

export const ensurePlusSign = (phoneNumber: string) => {
  if (!phoneNumber) return '';

  return phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`;
};
