import * as React from 'react';
import { Svg, Path, Circle } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M512 256c0 141.384-114.616 256-256 256S0 397.384 0 256C0 256.061 256 .028 256 0c141.384 0 256 114.616 256 256"
        fill="#0052b4"
      />
      <Path d="M256 0h-.043zM255.315 256H256v-.685z" fill="#f0f0f0" />
      <Path
        d="M256 133.565V0h-.043C114.591.024 0 114.629 0 256h133.565v-75.211L208.776 256h46.54l.685-.685v-46.537l-75.213-75.213z"
        fill="#f0f0f0"
      />
      <Path
        d="M129.515 33.391a257.2 257.2 0 0 0-96.124 96.124V256h66.783V100.174H256V33.391z"
        fill="#d80027"
      />
      <Path
        d="m367.304 256-69.193 69.193c12.968 25.011 39.076 42.111 69.193 42.111s56.225-17.1 69.193-42.111z"
        fill="#ffda44"
      />
      <Path
        d="m256 224.519-90.955-90.953h-31.48v.002L255.998 256H256z"
        fill="#d80027"
      />
      <Path
        d="M289.391 133.565V256c.001 59.639 77.913 77.913 77.913 77.913s77.912-18.274 77.913-77.913V133.565z"
        fill="#6da544"
      />
      <Path d="M445.217 256v-.001zM289.391 256v-.001z" fill="#496e2d" />
      <Path d="M350.609 189.217H384v89.043h-33.391z" fill="#f0f0f0" />
      <Circle cx={367.304} cy={189.217} r={16.696} fill="#a2001d" />
      <Path
        d="M311.652 155.826h22.261v22.261h-22.261zM311.652 205.913h22.261v22.261h-22.261zM311.652 256h22.261v22.261h-22.261zM400.696 155.826h22.261v22.261h-22.261zM400.696 205.913h22.261v22.261h-22.261zM400.696 256h22.261v22.261h-22.261z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'BritishVirginIslands';
export const BritishVirginIslands = memo<IconProps>(Icon);
