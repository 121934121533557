import { useQuery } from 'react-query';
import request, { RequestError } from '@/utils/request';

export type EnableAuth0Response = {
  enabled: boolean;
};

const requestAuth0Permission = async () => {
  const { data: response } = await request({
    url: 'v2/users/enable-auth0',
  });

  return response.data;
};

const useEnableAuth0 = () =>
  useQuery<EnableAuth0Response, RequestError>('users-enable-auth0', () =>
    requestAuth0Permission(),
  );

export default useEnableAuth0;
