import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#ffda44" />
      <Path
        d="M294.932 509.057c41.636-6.352 79.962-22.748 112.456-46.618L256 256zM217.067 2.943C175.432 9.295 137.106 25.691 104.61 49.56L256 256zM104.61 462.439c32.494 23.869 70.82 40.265 112.455 46.619L256 256zM49.56 104.61C25.69 137.106 9.296 175.431 2.943 217.067L256 256zM2.943 294.932c6.352 41.636 22.748 79.962 46.618 112.456L256 256zM509.057 217.067c-6.353-41.635-22.748-79.961-46.618-112.456L256 256zM407.388 49.559C374.893 25.69 336.568 9.295 294.932 2.943L256 256zM462.441 407.389c23.869-32.495 40.264-70.822 46.618-112.456L256 256z"
        fill="#d80027"
      />
      <Circle cx={256} cy={256} r={89.043} fill="#d80027" />
      <Circle cx={256} cy={256} r={66.783} fill="#ffda44" />
    </Svg>
  );
};
Icon.displayName = 'RepublicOfMacedonia';
export const RepublicOfMacedonia = memo<IconProps>(Icon);
