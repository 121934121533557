import * as React from 'react';
import { Svg, Path, Circle } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill="#EEE"
        d="M22.5 17.867c0 1.173-.9 2.133-2 2.133h-17c-1.1 0-2-.96-2-2.133V6.133C1.5 4.96 2.4 4 3.5 4h17c1.1 0 2 .96 2 2.133z"
      />
      <Path fill="#FF6D00" d="M22.5 18c0 1.1-.9 2-2 2H8s11.8-1.9 14.5-7.5z" />
      <Path
        fill={`${color}`}
        d="M5.6 10h.55v3H5.6zm3 1.5c0 .85.65 1.5 1.5 1.5.25 0 .45-.05.7-.15v-.65c-.2.2-.4.3-.7.3-.55 0-.95-.4-.95-1 0-.55.4-1 .95-1 .25 0 .45.1.7.3v-.65c-.25-.1-.45-.2-.7-.2-.85.05-1.5.75-1.5 1.55m6.7.45L14.5 10h-.6l1.25 3h.3l1.25-3h-.6zM16.95 13h1.6v-.5H17.5v-.8h1v-.5h-1v-.7h1.05V10h-1.6zm3.8-2.1c0-.55-.35-.9-1-.9h-.85v3h.55v-1.2h.05l.8 1.2h.7l-.9-1.25c.4-.1.65-.4.65-.85m-1.15.5h-.15v-.9h.15c.35 0 .55.15.55.45 0 .25-.15.45-.55.45M3.85 10H3v3h.8c1.25 0 1.55-1.05 1.55-1.5.05-.9-.6-1.5-1.5-1.5m-.15 2.5h-.15v-2h.2c.75 0 1.05.5 1.05 1 0 .2-.05 1-1.1 1m3.95-1.35c-.35-.15-.45-.2-.45-.35 0-.2.2-.3.4-.3.15 0 .3.05.45.25l.3-.4c-.25-.25-.5-.35-.85-.35-.5 0-.9.35-.9.85 0 .4.2.6.7.8.3.1.55.2.55.45s-.2.4-.45.4a.65.65 0 0 1-.6-.4l-.35.35c.25.4.55.55 1 .55.6 0 1-.4 1-.95 0-.45-.2-.65-.8-.9"
      />
      <Circle cx={12.5} cy={11.5} r={1.5} fill="#FF6D00" />
    </Svg>
  );
};
Icon.displayName = 'Discover';
export const Discover = memo<IconProps>(themed(Icon));
