import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        fillRule="evenodd"
        d="M4.222 22h15.556A2.22 2.22 0 0 0 22 19.778V4.222A2.22 2.22 0 0 0 19.778 2H4.222A2.22 2.22 0 0 0 2 4.222v15.556C2 21.005 2.995 22 4.222 22"
        clipRule="evenodd"
      />
      <Path
        fill="#fff"
        fillRule="evenodd"
        d="M19.222 19.222h-2.968v-5.055c0-1.386-.527-2.16-1.624-2.16-1.193 0-1.817.806-1.817 2.16v5.055h-2.86v-9.63h2.86v1.298s.86-1.591 2.904-1.591c2.042 0 3.505 1.247 3.505 3.827zM6.54 8.332a1.77 1.77 0 0 1-1.764-1.777c0-.981.79-1.777 1.764-1.777a1.77 1.77 0 0 1 1.763 1.777 1.77 1.77 0 0 1-1.763 1.777m-1.477 10.89h2.983v-9.63H5.064z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
Icon.displayName = 'Linkedin';
export const Linkedin = memo<IconProps>(themed(Icon));
