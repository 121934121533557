import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M512 256c0-31.314-5.632-61.311-15.923-89.043L256 155.826l-240.077 11.13C5.633 194.689 0 224.686 0 256s5.633 61.311 15.923 89.043L256 356.174l240.077-11.13C506.368 317.311 512 287.314 512 256"
        fill="#d80027"
      />
      <Path
        d="M256 512c110.071 0 203.906-69.472 240.077-166.957H15.923C52.094 442.528 145.929 512 256 512"
        fill="#6da544"
      />
      <Path
        d="M15.923 166.957h480.155C459.906 69.472 366.071 0 256 0S52.094 69.472 15.923 166.957"
        fill="#338af3"
      />
      <Path
        d="M261.565 328.348c-39.956 0-72.348-32.392-72.348-72.348s32.392-72.348 72.348-72.348c12.458 0 24.181 3.15 34.415 8.696-16.056-15.701-38.012-25.392-62.241-25.392-49.178 0-89.043 39.866-89.043 89.043s39.866 89.043 89.043 89.043c24.23 0 46.186-9.691 62.241-25.392-10.234 5.548-21.957 8.698-34.415 8.698"
        fill="#f0f0f0"
      />
      <Path
        d="m317.217 205.913 9.583 26.951 25.834-12.282-12.281 25.834L367.304 256l-26.951 9.584 12.281 25.834-25.834-12.282-9.583 26.951-9.582-26.951-25.835 12.282 12.282-25.834L267.13 256l26.952-9.584-12.282-25.834 25.835 12.282z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Azerbaijan';
export const Azerbaijan = memo<IconProps>(Icon);
