import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 22 22"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M7.15 20.75 5.7 18.3l-2.75-.6a.94.94 0 0 1-.6-.387.93.93 0 0 1-.175-.688L2.45 13.8.575 11.65a.93.93 0 0 1-.25-.65q0-.375.25-.65L2.45 8.2l-.275-2.825a.93.93 0 0 1 .175-.687.94.94 0 0 1 .6-.388l2.75-.6 1.45-2.45A.98.98 0 0 1 7.7.813a.97.97 0 0 1 .7.037l2.6 1.1 2.6-1.1a.97.97 0 0 1 .7-.037q.35.112.55.437L16.3 3.7l2.75.6q.375.075.6.388.225.312.175.687L19.55 8.2l1.875 2.15q.25.275.25.65t-.25.65L19.55 13.8l.275 2.825a.93.93 0 0 1-.175.688.94.94 0 0 1-.6.387l-2.75.6-1.45 2.45a.98.98 0 0 1-.55.438.97.97 0 0 1-.7-.038l-2.6-1.1-2.6 1.1a.97.97 0 0 1-.7.038.98.98 0 0 1-.55-.438m1.3-1.8 2.55-1.1 2.6 1.1 1.4-2.4 2.75-.65-.25-2.8 1.85-2.1-1.85-2.15.25-2.8-2.75-.6-1.45-2.4L11 4.15l-2.6-1.1L7 5.45l-2.75.6.25 2.8L2.65 11l1.85 2.1-.25 2.85 2.75.6zm1.5-7.25L8.5 10.275A.93.93 0 0 0 7.812 10q-.412 0-.712.3a.95.95 0 0 0-.275.7q0 .426.275.7l2.15 2.15q.3.3.7.3t.7-.3L14.9 9.6q.3-.3.287-.7a1.06 1.06 0 0 0-.287-.7 1.02 1.02 0 0 0-.713-.312.93.93 0 0 0-.712.287z"
      />
    </Svg>
  );
};
Icon.displayName = 'NewReleases';
export const NewReleases = memo<IconProps>(themed(Icon));
