import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M512 256c0 141.384-114.616 256-256 256S0 397.384 0 256C0 256.061 256 .028 256 0c141.384 0 256 114.616 256 256"
        fill="#0052b4"
      />
      <Path d="M256 0h-.043zM255.315 256H256v-.685z" fill="#f0f0f0" />
      <Path
        d="M256 133.565V0h-.043C114.591.024 0 114.629 0 256h133.565v-75.211L208.776 256h46.54l.685-.685v-46.537l-75.213-75.213z"
        fill="#f0f0f0"
      />
      <Path
        d="M129.515 33.391a257.2 257.2 0 0 0-96.124 96.124V256h66.783V100.174H256V33.391z"
        fill="#d80027"
      />
      <Path
        d="m256 224.519-90.955-90.953h-31.48v.002L255.998 256H256z"
        fill="#d80027"
      />
      <Path d="M289.391 256" fill="#496e2d" />
      <Path
        d="M432.939 293.474c7.361-10.128 12.278-22.496 12.278-37.474V144.696a55.4 55.4 0 0 1-33.391 11.13c-18.206 0-34.369-8.743-44.522-22.259-10.154 13.516-26.317 22.259-44.522 22.259a55.4 55.4 0 0 1-33.391-11.128V256c0 14.978 4.917 27.346 12.278 37.474z"
        fill="#f3f3f3"
      />
      <Path
        d="M409.785 235.351c3.544-8.646 6.3-21.283 6.3-27.572 0-10.105-13.174-18.296-13.174-18.296s-13.174 8.191-13.174 18.296c0 6.29 2.757 18.927 6.299 27.572l-7.605 17.171c4.47 1.818 9.356 2.828 14.481 2.828s10.01-1.011 14.481-2.828zM358.294 179.752c-9.256 1.254-21.579 5.189-27.025 8.333-8.751 5.053-9.259 20.556-9.259 20.556s13.682 7.313 22.432 2.261c5.448-3.145 15.012-11.849 20.725-19.241l18.676-2a38.4 38.4 0 0 0-4.792-13.952 38.4 38.4 0 0 0-9.689-11.128zM335.892 252.146c5.713 7.388 15.28 16.091 20.728 19.237 8.751 5.052 22.432-2.259 22.432-2.259s-.51-15.505-9.259-20.558c-5.449-3.145-17.77-7.076-27.028-8.328l-11.067-15.173a38.4 38.4 0 0 0-9.688 11.125 38.4 38.4 0 0 0-4.793 13.955z"
        fill="#ff9811"
      />
      <Path
        d="M298.916 289.394c20.788 33.355 68.389 44.52 68.389 44.52s47.6-11.165 68.389-44.52z"
        fill="#338af3"
      />
    </Svg>
  );
};
Icon.displayName = 'Anguilla';
export const Anguilla = memo<IconProps>(Icon);
