import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M155.826 166.957h340.25C459.906 69.472 366.07 0 256 0 185.306 0 121.313 28.659 74.989 74.989z"
        fill="#000"
      />
      <Path
        d="M155.826 345.043h340.25C459.906 442.528 366.07 512 256 512c-70.694 0-134.687-28.659-181.011-74.989z"
        fill="#6da544"
      />
      <Path
        d="M74.98 74.98c-99.974 99.974-99.974 262.065 0 362.04L256 256z"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'Palestine';
export const Palestine = memo<IconProps>(Icon);
