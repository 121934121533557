import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M138.616 28.426C115.698 40.255 94.185 55.76 74.973 74.972c-19.213 19.213-34.718 40.726-46.546 63.644l164.607 180.349 180.349 164.607c22.919-11.829 44.432-27.334 63.644-46.545 19.213-19.213 34.717-40.726 46.546-63.644l-164.607-180.35z"
        fill="#f0f0f0"
      />
      <Path
        d="M437.027 437.027a257.6 257.6 0 0 0 28.749-34.217L109.191 46.224c-12.019 8.431-23.478 18.009-34.217 28.748S54.657 97.17 46.225 109.19L402.81 465.774a257.5 257.5 0 0 0 34.217-28.747"
        fill="#000"
      />
      <Path
        d="M74.972 437.027c80.767 80.767 202.064 96.271 298.41 46.545L28.427 138.616C-21.298 234.962-5.791 356.26 74.972 437.027M437.027 74.973c-80.767-80.766-202.064-96.27-298.41-46.547l344.955 344.958c49.726-96.346 34.222-217.645-46.545-298.411"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'TrinidadAndTobago';
export const TrinidadAndTobago = memo<IconProps>(Icon);
