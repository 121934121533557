import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M492.029 156.753C453.26 64.662 362.188 0 256 0 185.306 0 121.313 28.659 74.989 74.989l64.11 81.764z"
        fill="#000"
      />
      <Path
        d="m139.826 354.32-64.837 82.691C121.313 483.341 185.306 512 256 512c106.546 0 197.878-65.094 236.424-157.68z"
        fill="#496e2d"
      />
      <Path
        d="M55.652 188.29v134.492h447.53C508.924 301.491 512 279.107 512 256c0-23.442-3.158-46.142-9.061-67.71z"
        fill="#a2001d"
      />
      <Path
        d="M74.98 74.98c-99.974 99.974-99.974 262.065 0 362.04L256 256z"
        fill="#0052b4"
      />
      <Path
        d="m83.386 192.352 31.245 43.601 51.128-16.245-31.811 43.193 31.245 43.603-50.908-16.907-31.812 43.192.35-53.643-50.91-16.909 51.124-16.244z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'SouthSudan';
export const SouthSudan = memo<IconProps>(Icon);
