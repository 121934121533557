import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

// @ts-ignore
const Icon = (props) => {
  const { color = '#101010', size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M6 16C5.71667 16 5.47917 15.9042 5.2875 15.7125C5.09583 15.5208 5 15.2833 5 15C5 14.7167 5.09583 14.4792 5.2875 14.2875C5.47917 14.0958 5.71667 14 6 14H18C18.2833 14 18.5208 14.0958 18.7125 14.2875C18.9042 14.4792 19 14.7167 19 15C19 15.2833 18.9042 15.5208 18.7125 15.7125C18.5208 15.9042 18.2833 16 18 16H6Z"
        fill={color}
        {...otherProps}
      />
      <Path
        d="M6 10C5.71667 10 5.47917 9.90417 5.2875 9.7125C5.09583 9.52083 5 9.28333 5 9C5 8.71667 5.09583 8.47917 5.2875 8.2875C5.47917 8.09583 5.71667 8 6 8H18C18.2833 8 18.5208 8.09583 18.7125 8.2875C18.9042 8.47917 19 8.71667 19 9C19 9.28333 18.9042 9.52083 18.7125 9.7125C18.5208 9.90417 18.2833 10 18 10H6Z"
        fill={color}
      />
    </Svg>
  );
};

Icon.displayName = 'Menu';
export const Menu = memo<IconProps>(themed(Icon));
