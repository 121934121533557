import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill="#5869CC"
        d="M22.5 17.867c0 1.178-.895 2.133-2 2.133h-17c-1.104 0-2-.955-2-2.133V6.133C1.5 4.955 2.396 4 3.5 4h17c1.105 0 2 .955 2 2.133z"
      />
      <Path fill="#FFC043" d="M15.25 7a5 5 0 1 0 0 10 5 5 0 0 0 0-10" />
      <Path
        fill="#FF3D00"
        d="M11.257 15a5 5 0 0 1-.588-1h2.662a5 5 0 0 0 .319-1h-3.299a5 5 0 0 1-.101-1h3.5q-.001-.515-.1-1h-3.3q.11-.523.319-1h2.662a5 5 0 0 0-.588-1h-1.486c.219-.29.465-.561.74-.797A4.98 4.98 0 0 0 8.75 7a5 5 0 1 0 0 10 4.99 4.99 0 0 0 3.993-2z"
      />
    </Svg>
  );
};
Icon.displayName = 'Mastercard';
export const Mastercard = memo<IconProps>(themed(Icon));
