import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M2.168 289.391c5.956 45.713 23.978 87.628 50.755 122.464l136.294-122.464zM222.609 509.833v-187.05L100.145 459.078c34.837 26.777 76.751 44.8 122.464 50.755M222.609 2.167c-45.713 5.956-87.627 23.978-122.464 50.755l122.464 136.296zM2.168 222.609h187.049L52.922 100.145C26.145 134.981 8.123 176.896 2.168 222.609M289.391 2.167v187.05L411.857 52.922c-34.837-26.775-76.752-44.8-122.466-50.755M509.833 222.609c-5.956-45.713-23.978-87.628-50.755-122.465L322.783 222.609zM322.783 289.391l136.296 122.465c26.776-34.837 44.799-76.752 50.755-122.465zM289.391 509.833c45.713-5.956 87.629-23.978 122.465-50.756L289.391 322.783z"
        fill="#d80027"
      />
      <Path
        d="M459.098 411.876 303.224 256.001l-.001-.001 155.875-155.876a258.09 258.09 0 0 0-47.222-47.222L256.001 208.777H256L100.125 52.902A258.5 258.5 0 0 0 74.98 74.98a258 258 0 0 0-22.078 25.145l155.875 155.874.001.001L52.903 411.876a258.217 258.217 0 0 0 47.223 47.222L256 303.223l155.876 155.875a259 259 0 0 0 25.144-22.078 259 259 0 0 0 22.078-25.144"
        fill="#496e2d"
      />
      <Path
        d="M289.391 509.833V289.392h220.442a258.2 258.2 0 0 0 0-66.782h-220.44l-.001-.001V2.168A258.5 258.5 0 0 0 256 0a259 259 0 0 0-33.391 2.168l.001 220.439v.002H2.168a258.4 258.4 0 0 0 0 66.782h220.44l.001 220.442A258.5 258.5 0 0 0 256 512c11.319 0 22.462-.744 33.391-2.167"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'BasqueCountry';
export const BasqueCountry = memo<IconProps>(Icon);
