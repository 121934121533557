import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#0052b4" />
      <Circle cx={256} cy={256} r={256} fill="#ffda44" />
      <Path
        d="M256 0C122.116 0 12.258 102.781.975 233.739h510.051C499.742 102.781 389.884 0 256 0M256 512c133.884 0 243.742-102.781 255.026-233.739H.975C12.258 409.219 122.116 512 256 512"
        fill="#0052b4"
      />
      <Path
        d="m211.479 345.043-28.686 13.494 15.275 27.781-31.149-5.959-3.947 31.467-21.697-23.143-21.696 23.143-3.947-31.467-31.15 5.958 15.276-27.781-28.686-13.493 28.687-13.493-15.277-27.78 31.149 5.958 3.949-31.467 21.695 23.143 21.698-23.143 3.946 31.467 31.15-5.958-15.275 27.781z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Nauru';
export const Nauru = memo<IconProps>(Icon);
