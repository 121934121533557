import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M483.574 138.616c-11.829-22.919-27.334-44.431-46.546-63.643-19.213-19.213-40.726-34.718-63.644-46.546L193.035 193.033 28.428 373.382c11.829 22.919 27.334 44.432 46.546 63.644s40.725 34.717 63.643 46.546l180.349-164.607z"
        fill="#ffda44"
      />
      <Path
        d="M74.974 437.027a257.6 257.6 0 0 0 34.217 28.75l356.584-356.586a257.5 257.5 0 0 0-28.748-34.217 257.6 257.6 0 0 0-34.217-28.748L46.226 402.81a257.5 257.5 0 0 0 28.748 34.217"
        fill="#d80027"
      />
      <Path
        d="M74.974 74.972c-80.767 80.767-96.272 202.065-46.546 298.41L373.384 28.427C277.038-21.298 155.74-5.791 74.974 74.972M437.027 437.027c80.766-80.766 96.27-202.064 46.547-298.409L138.618 483.573c96.345 49.725 217.644 34.221 298.409-46.546"
        fill="#338af3"
      />
      <Path
        d="m136.426 77.905 13.814 42.517h44.704l-36.166 26.276 13.814 42.516-36.166-26.277-36.168 26.277 13.816-42.516-36.168-26.276h44.704z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'DemocraticRepublicOfCongo';
export const DemocraticRepublicOfCongo = memo<IconProps>(Icon);
