import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M509.806 222.608c-7.062-53.986-31.312-106.152-72.787-147.627S343.377 9.255 289.391 2.193l.001 220.415zM222.608 2.193C168.622 9.255 116.455 33.505 74.98 74.98S9.255 168.623 2.193 222.61l220.416-.001zM2.193 289.392C9.255 343.378 33.506 395.546 74.98 437.02s93.641 65.724 147.629 72.789l-.001-220.415zM289.392 509.806c53.986-7.062 106.152-31.312 147.627-72.787s65.725-93.641 72.787-147.627H289.392z"
        fill="#0052b4"
      />
      <Path
        d="M343.389 162.438a6.177 6.177 0 0 1 6.172-6.171h13.224c20.904 0 37.911-17.006 37.911-37.909s-17.006-37.91-37.911-37.91h-12.558c-4.099-3.079-9.598-4.978-15.664-4.978-12.653 0-22.91 8.205-22.91 18.327 0 10.024 10.061 18.156 22.545 18.314l-.006.073h28.593c3.405 0 6.172 2.768 6.172 6.172s-2.767 6.171-6.172 6.171h-13.224c-20.904 0-37.909 17.006-37.909 37.909s17.004 37.91 37.909 37.91h51.134v-31.738h-51.134a6.175 6.175 0 0 1-6.172-6.17M143.042 162.438a6.177 6.177 0 0 1 6.172-6.171h13.224c20.904 0 37.911-17.006 37.911-37.909s-17.006-37.91-37.911-37.91H149.88c-4.099-3.079-9.598-4.978-15.664-4.978-12.653 0-22.91 8.205-22.91 18.327 0 10.024 10.061 18.156 22.545 18.314l-.006.073h28.594c3.405 0 6.172 2.768 6.172 6.172s-2.767 6.171-6.172 6.171h-13.224c-20.904 0-37.909 17.006-37.909 37.909s17.004 37.91 37.909 37.91h51.133v-31.738h-51.134a6.175 6.175 0 0 1-6.172-6.17M343.389 398.62a6.177 6.177 0 0 1 6.172-6.171h13.224c20.904 0 37.911-17.006 37.911-37.909s-17.006-37.91-37.911-37.91h-12.558c-4.099-3.079-9.598-4.978-15.664-4.978-12.654 0-22.911 8.206-22.911 18.327 0 10.024 10.061 18.156 22.545 18.314l-.006.073h28.593a6.177 6.177 0 0 1 6.172 6.172 6.177 6.177 0 0 1-6.172 6.171H349.56c-20.904 0-37.909 17.006-37.909 37.909s17.004 37.91 37.909 37.91h51.135v-31.737h-51.134a6.177 6.177 0 0 1-6.172-6.171M143.042 398.62a6.177 6.177 0 0 1 6.172-6.171h13.224c20.904 0 37.911-17.006 37.911-37.909s-17.006-37.91-37.911-37.91H149.88c-4.099-3.079-9.598-4.978-15.664-4.978-12.654 0-22.911 8.206-22.911 18.327 0 10.024 10.061 18.156 22.545 18.314l-.006.073h28.594a6.177 6.177 0 0 1 6.172 6.172 6.177 6.177 0 0 1-6.172 6.171h-13.224c-20.904 0-37.909 17.006-37.909 37.909s17.004 37.91 37.909 37.91h51.134v-31.737h-51.134a6.177 6.177 0 0 1-6.172-6.171"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Martinique';
export const Martinique = memo<IconProps>(Icon);
