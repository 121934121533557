import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M19.797 7.822c-.089.055-2.207 1.231-2.207 3.838.1 2.973 2.673 4.015 2.717 4.015-.044.056-.388 1.42-1.408 2.85C18.089 19.759 17.19 21 15.826 21c-1.298 0-1.764-.821-3.261-.821-1.609 0-2.064.821-3.295.821-1.365 0-2.33-1.309-3.184-2.53-1.109-1.597-2.051-4.104-2.085-6.51-.022-1.276.223-2.53.843-3.595.876-1.487 2.44-2.496 4.149-2.53 1.309-.043 2.473.9 3.272.9.765 0 2.196-.9 3.816-.9.699.001 2.562.212 3.716 1.987m-7.643-2.24c-.233-1.166.41-2.33 1.01-3.074C13.929 1.61 15.138 1 16.18 1a4.34 4.34 0 0 1-1.109 3.14c-.676.898-1.84 1.574-2.917 1.441"
      />
    </Svg>
  );
};
Icon.displayName = 'Apple';
export const Apple = memo<IconProps>(themed(Icon));
