import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#d80027" />
      <Path
        d="M407.288 210.09H291.714L256 100.174 220.286 210.09H104.712l93.501 67.932-35.715 109.919L256 320.008l93.502 67.934-35.715-109.919zm-182.906 59.429 12.077-37.169h39.081l12.078 37.169v.001L256 292.491l-31.617-22.971zm43.926-59.429h-24.615L256 172.21zm38.245 45.672-7.607-23.411h39.829zm-93.5-23.411-7.607 23.411-32.223-23.411zm-8.212 97.31 12.308-37.879 19.915 14.468zm70.095-23.41 19.915-14.468 12.308 37.879z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Morocco';
export const Morocco = memo<IconProps>(Icon);
