import create from 'zustand';
import { persist } from 'zustand/middleware';
import { Me } from '@/types/me';

type MeStore = {
  me: Me;
  set: (nextMe: Me) => void;
  reset: () => void;
};

const useMeStore = create<MeStore>(
  persist(
    (set) => ({
      me: {} as Me,
      set: (nextMe) => set({ me: nextMe }),
      reset: () => set({ me: {} as Me }),
    }),
    {
      name: 'me',
    },
  ),
);

export default useMeStore;
