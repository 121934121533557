import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg viewBox="0 0 511.58 511.58" width={size} height={size} {...otherProps}>
      <Circle cx={255.79} cy={255.79} r={255.79} fill="#f0f0f0" />
      <Path
        d="M456.714 205.744h-84.151c0-15.355-12.448-27.803-27.803-27.803l-22.243 44.485s23.726 66.728 22.984 66.728h27.803c15.356 0 27.803-12.447 27.803-27.803 15.356 0 27.803-12.447 27.803-27.803h-.926c15.355-.001 28.73-12.448 28.73-27.804M54.865 205.744h84.151c0-15.355 12.448-27.803 27.803-27.803l22.243 44.485s-23.726 66.728-22.984 66.728h-27.803c-15.356 0-27.803-12.447-27.803-27.803-15.356 0-27.803-12.447-27.803-27.803h.926c-15.356-.001-28.73-12.448-28.73-27.804"
        fill="#acabb1"
      />
      <Path
        d="M344.76 355.881v11.121H166.819v-11.121h-44.485v44.485h44.485v11.121H344.76v-11.121h44.485v-44.485z"
        fill="#ffda44"
      />
      <Path
        d="M166.819 177.94v111.212c0 68.103 88.97 88.971 88.97 88.971s88.97-20.868 88.97-88.971V177.94l-88.97-11.121z"
        fill="#0052b4"
      />
      <Path d="M166.82 222.43h177.94v66.728H166.82z" fill="#d80027" />
      <Path
        d="M300.274 122.334v16.682l-11.121 5.561-11.121-11.122v-33.364h-44.485v33.364l-11.121 11.122-11.122-5.561v-16.682h-44.485v55.606H344.76v-55.606z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'StBarts';
export const StBarts = memo<IconProps>(Icon);
