import { baseTrackEvent, baseTrackPage } from '@/utils/analytics';
import AnalyticsEvents, {
  AnalyticsEventsType,
} from '@/config/analytics-events';
import { useMediaQuery, useTheme } from '@drivekyte/web-components';
import { getFbcAndFbp } from '@/utils/get-fbc-fbp';
import { AnalyticsOptions } from '@/utils/analytics/types';

const useAnalyticsWithSource = () => {
  const [theme] = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme?.breakpoints?.sm})`);
  const { fbc, fbp } = getFbcAndFbp();
  const traits = {
    ...(fbc && { fbc }),
    ...(fbp && { fbp }),
  };
  const context = {
    ...(Object.keys(traits).length > 0 && { traits }),
  };
  const options: AnalyticsOptions | undefined =
    Object.keys(context).length > 0 ? { context } : undefined;

  const trackEvent = (
    eventName: AnalyticsEventsType,
    eventPayload?: { [property: string]: unknown },
  ) => {
    baseTrackEvent(
      eventName,
      {
        source: isMobile ? 'web_mobile' : 'web_desktop',
        ...eventPayload,
      },
      options,
    );
  };

  const trackPageView = (
    pageName: string,
    eventPayload?: { [property: string]: unknown },
  ) => {
    baseTrackPage(
      pageName,
      {
        source: isMobile ? 'web_mobile' : 'web_desktop',
        ...eventPayload,
      },
      options,
    );
  };

  return {
    trackEvent,
    trackPageView,
    AnalyticsEvents,
  };
};

export default useAnalyticsWithSource;
