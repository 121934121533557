/* eslint-disable react-hooks/rules-of-hooks */
import { getTokenValue, getVariable, useTheme } from '@tamagui/core';
import React from 'react';
import { IconProps } from './icon-props';

export function themed(Component: React.FC<IconProps>) {
  const wrapped = (props: IconProps) => {
    const theme = useTheme();

    const defaultColor = props.color ?? 'primaryA';
    const color = getVariable(
      (defaultColor ? theme[defaultColor] : undefined) ||
        props.color ||
        '#101010',
    );

    const size =
      typeof props.size === 'string'
        ? getTokenValue(props.size as any, 'size')
        : props.size;

    return <Component {...props} size={size} color={color} />;
  };

  return wrapped;
}
