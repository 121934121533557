import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M256 0c141.384 0 256 114.616 256 256S397.384 512 256 512c0-11.13-33.391-256-33.391-256z"
        fill="#f0f0f0"
      />
      <Path
        d="M256 512C114.616 512 0 397.384 0 256S114.616 0 256 0"
        fill="#496e2d"
      />
      <Path
        d="m311.003 206.896-21.004 28.945-34.017-11.032 21.038 28.92-21.002 28.945 34.004-11.071 21.038 28.919-.022-35.761 34.005-11.072-34.016-11.03z"
        fill="#d80027"
      />
      <Path
        d="M277.237 328.348c-39.956 0-72.348-32.392-72.348-72.348s32.392-72.348 72.348-72.348c12.458 0 24.181 3.15 34.415 8.696-16.056-15.701-38.012-25.392-62.241-25.392-49.178 0-89.043 39.866-89.043 89.043s39.866 89.043 89.043 89.043c24.23 0 46.186-9.691 62.241-25.392-10.234 5.548-21.957 8.698-34.415 8.698"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'Algeria';
export const Algeria = memo<IconProps>(Icon);
