import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#338af3" />
      <Path
        d="m256 133.565 27.628 85.029h89.404l-72.33 52.55 27.628 85.03L256 303.623l-72.33 52.551 27.628-85.03-72.331-52.55h89.405z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Somalia';
export const Somalia = memo<IconProps>(Icon);
