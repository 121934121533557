import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="m11 17.85-2.575 2.525a.93.93 0 0 1-.688.275.9.9 0 0 1-.687-.3.9.9 0 0 1-.3-.687q0-.413.3-.713L11 15v-2H9l-3.975 3.975a.93.93 0 0 1-.687.275.97.97 0 0 1-.713-.3.93.93 0 0 1-.275-.687q0-.414.275-.688L6.15 13H2.975a.93.93 0 0 1-.7-.287A1 1 0 0 1 2 12q0-.424.288-.713A.97.97 0 0 1 3 11h3.15L3.625 8.45a.93.93 0 0 1-.275-.687q0-.413.3-.713a.93.93 0 0 1 .688-.275q.412 0 .712.275L9 11h2V9L7.025 5.05a.93.93 0 0 1-.275-.687q0-.413.3-.713a.93.93 0 0 1 .688-.275q.412 0 .687.275L11 6.15V3q0-.424.287-.712A.97.97 0 0 1 12 2q.424 0 .713.288Q13 2.575 13 3v3.15l2.55-2.5a.93.93 0 0 1 .688-.275q.412 0 .712.275.275.3.275.712a.93.93 0 0 1-.275.688L13 9v2h2l3.95-3.95a.93.93 0 0 1 .688-.275q.412 0 .712.3a.93.93 0 0 1 .275.688.93.93 0 0 1-.275.687L17.85 11H21q.424 0 .712.287.288.288.288.713 0 .424-.288.713A.97.97 0 0 1 21 13h-3.15l2.5 2.575a.93.93 0 0 1 .275.688.93.93 0 0 1-.275.687q-.3.3-.713.3a.9.9 0 0 1-.687-.3L15 13h-2v2l3.95 3.975a.93.93 0 0 1 .275.688q0 .412-.3.712a.93.93 0 0 1-.687.275.93.93 0 0 1-.688-.275L13 17.85v3.175a.93.93 0 0 1-.287.7A1 1 0 0 1 12 22a.97.97 0 0 1-.713-.288A.97.97 0 0 1 11 21z"
      />
    </Svg>
  );
};
Icon.displayName = 'AcUnit';
export const AcUnit = memo<IconProps>(themed(Icon));
