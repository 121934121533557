import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M25.402 144.696C9.128 178.352 0 216.11 0 256s9.128 77.648 25.402 111.304L256 389.565l230.598-22.261C502.872 333.648 512 295.89 512 256s-9.128-77.648-25.402-111.304L256 122.435z"
        fill="#f0f0f0"
      />
      <Path
        d="M25.411 367.304C66.818 452.934 154.507 512 256 512s189.183-59.066 230.589-144.696z"
        fill="#6da544"
      />
      <Path
        d="M25.411 144.696h461.178C445.183 59.066 357.493 0 256 0S66.818 59.066 25.411 144.696"
        fill="#d80027"
      />
      <Path
        d="M211.478 322.783h89.044v-37.844l-17.809 8.904L256 267.13l-26.713 26.713-17.809-8.904zM160.532 278.261l4.145 12.754h13.41l-10.85 7.883 4.145 12.754-10.85-7.882-10.85 7.882 4.145-12.754-10.85-7.883h13.41zM173.381 233.739l4.145 12.754h13.41l-10.85 7.883 4.144 12.754-10.849-7.882-10.85 7.882 4.145-12.754-10.85-7.883h13.41zM210.113 200.348l4.145 12.754h13.409l-10.848 7.883 4.143 12.754-10.849-7.882-10.849 7.882 4.144-12.754-10.85-7.883h13.411zM351.468 278.261l-4.145 12.754h-13.41l10.85 7.883-4.145 12.754 10.85-7.882 10.849 7.882-4.144-12.754 10.85-7.883h-13.41zM338.619 233.739l-4.145 12.754h-13.41l10.85 7.883-4.145 12.754 10.85-7.882 10.85 7.882-4.145-12.754 10.85-7.883h-13.41zM301.887 200.348l-4.145 12.754h-13.409l10.848 7.883-4.144 12.754 10.85-7.882 10.849 7.882-4.144-12.754 10.849-7.883h-13.41zM256 183.651l4.145 12.754h13.41l-10.85 7.883 4.145 12.754L256 209.16l-10.85 7.882 4.145-12.754-10.85-7.883h13.41z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Tajikistan';
export const Tajikistan = memo<IconProps>(Icon);
