import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M58.516 93.092h394.968a257.4 257.4 0 0 0-50.277-46.545H108.793a257.4 257.4 0 0 0-50.277 46.545M0 256c0 7.847.373 15.605 1.064 23.273h509.872A259 259 0 0 0 512 256c0-7.847-.373-15.605-1.064-23.272H1.064A259 259 0 0 0 0 256M27.916 372.363h456.168a254.3 254.3 0 0 0 18.276-46.544H9.64a254.3 254.3 0 0 0 18.276 46.544M108.793 465.455h294.413a257.4 257.4 0 0 0 50.277-46.545H58.516a257.5 257.5 0 0 0 50.277 46.545M9.639 186.182h492.722a254.3 254.3 0 0 0-18.277-46.545H27.916a254.3 254.3 0 0 0-18.277 46.545"
        fill="#f0f0f0"
      />
      <Path
        d="M122.435 46.545h280.772C361.567 17.227 310.797 0 256 0c-33.391 0-91.925 17.227-133.565 46.545M244.87 139.637h239.214a256.3 256.3 0 0 0-30.6-46.545H244.87zM244.87 232.728h266.066a255 255 0 0 0-8.575-46.545H244.87zM9.64 325.819h492.72a255 255 0 0 0 8.576-46.545H1.064a255 255 0 0 0 8.576 46.545M58.516 418.908h394.968a256.4 256.4 0 0 0 30.6-46.545H27.916a256.3 256.3 0 0 0 30.6 46.545M256 512c54.797 0 105.567-17.227 147.207-46.545H108.793C150.433 494.773 201.203 512 256 512"
        fill="#d80027"
      />
      <Path
        d="M9.639 186.182c-4.242 15-7.135 30.563-8.575 46.545H256V0C138.82 0 40.031 78.732 9.639 186.182"
        fill="#0052b4"
      />
      <Path
        d="m152.388 66.783 16.575 51.015h53.646l-43.398 31.532 16.574 51.018-43.397-31.53-43.399 31.53 16.578-51.018-43.398-31.532h53.643z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Liberia';
export const Liberia = memo<IconProps>(Icon);
