import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="m500.87 256 8.959-33.391a256 256 0 0 0-6.647-33.391H233.739V.974a255 255 0 0 0-33.391 5.121l-44.522 27.296-22.261-2.247a256 256 0 0 0-33.391 21.751v136.322H8.819a256 256 0 0 0-6.647 33.391L11.13 256l-8.959 33.391a256 256 0 0 0 6.647 33.391h91.355v136.322a256 256 0 0 0 33.391 21.751l33.391-2.247 33.391 27.296a256 256 0 0 0 33.391 5.121V322.783h269.442a256 256 0 0 0 6.647-33.391z"
        fill="#0052b4"
      />
      <Path
        d="M509.833 222.609H200.348V6.085a254.3 254.3 0 0 0-66.783 25.042v191.481H2.167C.744 233.539 0 244.683 0 256s.744 22.461 2.167 33.391h131.398v191.481a254.3 254.3 0 0 0 66.783 25.042V289.392h309.485C511.256 278.461 512 267.317 512 256s-.744-22.461-2.167-33.391"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'FaroeIslands';
export const FaroeIslands = memo<IconProps>(Icon);
