import type { CountryFlags } from '@drivekyte/flags';

export type Country = {
  text: string;
  value: string;
  code: string;
  startIconName: CountryFlags;
};

export type Countries = Country[];

export const unitedStatesCountry = {
  text: 'United States',
  value: '1',
  code: 'US',
  startIconName: 'UnitedStatesOfAmerica',
} as Country;

export const countries = [
  {
    text: 'United States',
    value: '1',
    code: 'US',
    startIconName: 'UnitedStatesOfAmerica',
  },
  {
    text: 'Afghanistan',
    value: '93',
    code: 'AF',
    startIconName: 'Afghanistan',
  },
  { text: 'Albania', value: '355', code: 'AL', startIconName: 'Albania' },
  { text: 'Algeria', value: '213', code: 'DZ', startIconName: 'Algeria' },
  {
    text: 'American Samoa',
    value: '1684',
    code: 'AS',
    startIconName: 'AmericanSamoa',
  },
  { text: 'Andorra', value: '376', code: 'AD', startIconName: 'Andorra' },
  { text: 'Angola', value: '244', code: 'AO', startIconName: 'Angola' },
  {
    text: 'Anguilla',
    value: '1264',
    code: 'AI',
    startIconName: 'Anguilla',
  },
  {
    text: 'Antigua and Barbuda',
    value: '1268',
    code: 'AG',
    startIconName: 'AntiguaAndBarbuda',
  },
  {
    text: 'Argentina',
    value: '54',
    code: 'AR',
    startIconName: 'Argentina',
  },
  { text: 'Armenia', value: '374', code: 'AM', startIconName: 'Armenia' },
  { text: 'Aruba', value: '297', code: 'AW', startIconName: 'Aruba' },
  {
    text: 'Australia',
    value: '61',
    code: 'AU',
    startIconName: 'Australia',
  },
  { text: 'Austria', value: '43', code: 'AT', startIconName: 'Austria' },
  {
    text: 'Azerbaijan',
    value: '994',
    code: 'AZ',
    startIconName: 'Azerbaijan',
  },
  { text: 'Bahamas', value: '1242', code: 'BS', startIconName: 'Bahamas' },
  { text: 'Bahrain', value: '973', code: 'BH', startIconName: 'Bahrain' },
  {
    text: 'Bangladesh',
    value: '880',
    code: 'BD',
    startIconName: 'Bangladesh',
  },
  {
    text: 'Barbados',
    value: '1246',
    code: 'BB',
    startIconName: 'Barbados',
  },
  { text: 'Belarus', value: '375', code: 'BY', startIconName: 'Belarus' },
  { text: 'Belgium', value: '32', code: 'BE', startIconName: 'Belgium' },
  { text: 'Belize', value: '501', code: 'BZ', startIconName: 'Belize' },
  { text: 'Benin', value: '229', code: 'BJ', startIconName: 'Benin' },
  { text: 'Bermuda', value: '1441', code: 'BM', startIconName: 'Bermuda' },
  { text: 'Bhutan', value: '975', code: 'BT', startIconName: 'Bhutan' },
  { text: 'Bolivia', value: '591', code: 'BO', startIconName: 'Bolivia' },
  {
    text: 'Bosnia and Herzegovina',
    value: '387',
    code: 'BA',
    startIconName: 'BosniaAndHerzegovina',
  },
  {
    text: 'Botswana',
    value: '267',
    code: 'BW',
    startIconName: 'Botswana',
  },
  { text: 'Brazil', value: '55', code: 'BR', startIconName: 'Brazil' },
  {
    text: 'British Indian Ocean Territory',
    value: '246',
    code: 'IO',
    startIconName: 'BritishIndianOceanTerritory',
  },
  {
    text: 'British Virgin Islands',
    value: '1284',
    code: 'VG',
    startIconName: 'BritishVirginIslands',
  },
  { text: 'Brunei', value: '673', code: 'BN', startIconName: 'Brunei' },
  {
    text: 'Bulgaria',
    value: '359',
    code: 'BG',
    startIconName: 'Bulgaria',
  },
  {
    text: 'Burkina Faso',
    value: '226',
    code: 'BF',
    startIconName: 'BurkinaFaso',
  },
  { text: 'Burundi', value: '257', code: 'BI', startIconName: 'Burundi' },
  {
    text: 'Cambodia',
    value: '855',
    code: 'KH',
    startIconName: 'Cambodia',
  },
  {
    text: 'Cameroon',
    value: '237',
    code: 'CM',
    startIconName: 'Cameroon',
  },
  { text: 'Canada', value: '1', code: 'CA', startIconName: 'Canada' },
  {
    text: 'Cape Verde',
    value: '238',
    code: 'CV',
    startIconName: 'CapeVerde',
  },
  {
    text: 'Cayman Islands',
    value: '1345',
    code: 'KY',
    startIconName: 'CaymanIslands',
  },
  {
    text: 'Central African Republic',
    value: '236',
    code: 'CF',
    startIconName: 'CentralAfricanRepublic',
  },
  { text: 'Chad', value: '235', code: 'TD', startIconName: 'Chad' },
  { text: 'Chile', value: '56', code: 'CL', startIconName: 'Chile' },
  { text: 'China', value: '86', code: 'CN', startIconName: 'China' },
  {
    text: 'Christmas Island',
    value: '61',
    code: 'CX',
    startIconName: 'ChristmasIsland',
  },
  {
    text: 'Cocos Islands',
    value: '61',
    code: 'CC',
    startIconName: 'CocosIslands',
  },
  { text: 'Colombia', value: '57', code: 'CO', startIconName: 'Colombia' },
  { text: 'Comoros', value: '269', code: 'KM', startIconName: 'Comoros' },
  {
    text: 'Democratic Republic of Congo ',
    value: '243',
    code: 'CD',
    startIconName: 'DemocraticRepublicOfCongo',
  },
  {
    text: 'Congo Republic',
    value: '242',
    code: 'CG',
    startIconName: 'RepublicOfTheCongo',
  },
  {
    text: 'Cook Islands',
    value: '682',
    code: 'CK',
    startIconName: 'CookIslands',
  },
  {
    text: 'Costa Rica',
    value: '506',
    code: 'CR',
    startIconName: 'CostaRica',
  },
  {
    text: 'Ivory Coast',
    value: '225',
    code: 'CI',
    startIconName: 'IvoryCoast',
  },
  { text: 'Croatia', value: '385', code: 'HR', startIconName: 'Croatia' },
  { text: 'Cuba', value: '53', code: 'CU', startIconName: 'Cuba' },
  { text: 'Curaçao', value: '599', code: 'BQ', startIconName: 'Curacao' },
  { text: 'Cyprus', value: '357', code: 'CY', startIconName: 'Cyprus' },
  {
    text: 'Czech Republic',
    value: '420',
    code: 'CZ',
    startIconName: 'CzechRepublic',
  },
  { text: 'Denmark', value: '45', code: 'DK', startIconName: 'Denmark' },
  {
    text: 'Djibouti',
    value: '253',
    code: 'DJ',
    startIconName: 'Djibouti',
  },
  {
    text: 'Dominica',
    value: '1767',
    code: 'DM',
    startIconName: 'Dominica',
  },
  {
    text: 'Dominican Republic',
    value: '1',
    code: 'DO',
    startIconName: 'DominicanRepublic',
  },
  {
    text: 'East Timor',
    value: '670',
    code: 'TL',
    startIconName: 'EastTimor',
  },
  { text: 'Ecuador', value: '593', code: 'EC', startIconName: 'Ecuador' },
  { text: 'Egypt', value: '20', code: 'EG', startIconName: 'Egypt' },
  {
    text: 'El Salvador',
    value: '503',
    code: 'SV',
    startIconName: 'ElSalvador',
  },
  {
    text: 'Equatorial Guinea',
    value: '240',
    code: 'GQ',
    startIconName: 'EquatorialGuinea',
  },
  { text: 'Eritrea', value: '291', code: 'ER', startIconName: 'Eritrea' },
  { text: 'Estonia', value: '372', code: 'EE', startIconName: 'Estonia' },
  {
    text: 'Ethiopia',
    value: '251',
    code: 'ET',
    startIconName: 'Ethiopia',
  },
  {
    text: 'Falkland Islands',
    value: '500',
    code: 'FK',
    startIconName: 'FalklandIslands',
  },
  {
    text: 'Faroe Islands',
    value: '298',
    code: 'FO',
    startIconName: 'FaroeIslands',
  },
  { text: 'Fiji', value: '679', code: 'FJ', startIconName: 'Fiji' },
  { text: 'Finland', value: '358', code: 'FI', startIconName: 'Finland' },
  { text: 'France', value: '33', code: 'FR', startIconName: 'France' },
  {
    text: 'French Polynesia',
    value: '689',
    code: 'PF',
    startIconName: 'FrenchPolynesia',
  },
  { text: 'Gabon', value: '241', code: 'GA', startIconName: 'Gabon' },
  { text: 'Gambia', value: '220', code: 'GM', startIconName: 'Gambia' },
  { text: 'Georgia', value: '995', code: 'GE', startIconName: 'Georgia' },
  { text: 'Germany', value: '49', code: 'DE', startIconName: 'Germany' },
  { text: 'Ghana', value: '233', code: 'GH', startIconName: 'Ghana' },
  {
    text: 'Gibraltar',
    value: '350',
    code: 'GI',
    startIconName: 'Gibraltar',
  },
  { text: 'Greece', value: '30', code: 'GR', startIconName: 'Greece' },
  {
    text: 'Greenland',
    value: '299',
    code: 'GL',
    startIconName: 'Greenland',
  },
  { text: 'Grenada', value: '1473', code: 'GD', startIconName: 'Grenada' },
  {
    text: 'Guadeloupe',
    value: '590',
    code: 'GP',
    startIconName: 'France',
  },
  { text: 'Guam', value: '1671', code: 'GU', startIconName: 'Guam' },
  {
    text: 'Guatemala',
    value: '502',
    code: 'GT',
    startIconName: 'Guatemala',
  },
  {
    text: 'Guernsey',
    value: '44',
    code: 'GG',
    startIconName: 'Guernsey',
  },
  { text: 'Guinea', value: '224', code: 'GN', startIconName: 'Guinea' },
  {
    text: 'GuineaBissau',
    value: '245',
    code: 'GW',
    startIconName: 'GuineaBissau',
  },
  { text: 'Guyana', value: '592', code: 'GY', startIconName: 'Guyana' },
  { text: 'Haiti', value: '509', code: 'HT', startIconName: 'Haiti' },
  {
    text: 'Honduras',
    value: '504',
    code: 'HN',
    startIconName: 'Honduras',
  },
  {
    text: 'Hong Kong',
    value: '852',
    code: 'HK',
    startIconName: 'HongKong',
  },
  { text: 'Hungary', value: '36', code: 'HU', startIconName: 'Hungary' },
  { text: 'Iceland', value: '354', code: 'IS', startIconName: 'Iceland' },
  { text: 'India', value: '91', code: 'IN', startIconName: 'India' },
  {
    text: 'Indonesia',
    value: '62',
    code: 'ID',
    startIconName: 'Indonesia',
  },
  { text: 'Iran', value: '98', code: 'IR', startIconName: 'Iran' },
  { text: 'Iraq', value: '964', code: 'IQ', startIconName: 'Iraq' },
  { text: 'Ireland', value: '353', code: 'IE', startIconName: 'Ireland' },
  {
    text: 'Isle of Man',
    value: '44',
    code: 'IM',
    startIconName: 'IsleOfMan',
  },
  { text: 'Israel', value: '972', code: 'IL', startIconName: 'Israel' },
  { text: 'Italy', value: '39', code: 'IT', startIconName: 'Italy' },
  { text: 'Jamaica', value: '1876', code: 'JM', startIconName: 'Jamaica' },
  { text: 'Japan', value: '81', code: 'JP', startIconName: 'Japan' },
  { text: 'Jersey', value: '44', code: 'JE', startIconName: 'Jersey' },
  { text: 'Jordan', value: '962', code: 'JO', startIconName: 'Jordan' },
  {
    text: 'Kazakhstan',
    value: '77',
    code: 'KZ',
    startIconName: 'Kazakhstan',
  },
  { text: 'Kenya', value: '254', code: 'KE', startIconName: 'Kenya' },
  {
    text: 'Kiribati',
    value: '686',
    code: 'KI',
    startIconName: 'Kiribati',
  },
  { text: 'Kuwait', value: '965', code: 'KW', startIconName: 'Kuwait' },
  {
    text: 'Kyrgyzstan',
    value: '996',
    code: 'KG',
    startIconName: 'Kyrgyzstan',
  },
  { text: 'Laos', value: '856', code: 'LA', startIconName: 'Laos' },
  { text: 'Latvia', value: '371', code: 'LV', startIconName: 'Latvia' },
  { text: 'Lebanon', value: '961', code: 'LB', startIconName: 'Lebanon' },
  { text: 'Lesotho', value: '266', code: 'LS', startIconName: 'Lesotho' },
  { text: 'Liberia', value: '231', code: 'LR', startIconName: 'Liberia' },
  { text: 'Libya', value: '218', code: 'LY', startIconName: 'Libya' },
  {
    text: 'Liechtenstein',
    value: '423',
    code: 'LI',
    startIconName: 'Liechtenstein',
  },
  {
    text: 'Lithuania',
    value: '370',
    code: 'LT',
    startIconName: 'Lithuania',
  },
  {
    text: 'Luxembourg',
    value: '352',
    code: 'LU',
    startIconName: 'Luxembourg',
  },
  { text: 'Macau', value: '853', code: 'MO', startIconName: 'Macau' },
  {
    text: 'Macedonia',
    value: '389',
    code: 'MK',
    startIconName: 'RepublicOfMacedonia',
  },
  {
    text: 'Madagascar',
    value: '261',
    code: 'MG',
    startIconName: 'Madagascar',
  },
  { text: 'Malawi', value: '265', code: 'MW', startIconName: 'Malawi' },
  { text: 'Malaysia', value: '60', code: 'MY', startIconName: 'Malaysia' },
  {
    text: 'Maldives',
    value: '960',
    code: 'MV',
    startIconName: 'Maldives',
  },
  { text: 'Mali', value: '223', code: 'ML', startIconName: 'Mali' },
  { text: 'Malta', value: '356', code: 'MT', startIconName: 'Malta' },
  {
    text: 'Marshall Islands',
    value: '692',
    code: 'MH',
    startIconName: 'MarshallIslands',
  },
  {
    text: 'Martinique',
    value: '596',
    code: 'MQ',
    startIconName: 'Martinique',
  },
  {
    text: 'Mauritania',
    value: '222',
    code: 'MR',
    startIconName: 'Mauritania',
  },
  {
    text: 'Mauritius',
    value: '230',
    code: 'MU',
    startIconName: 'Mauritius',
  },
  { text: 'Mexico', value: '52', code: 'MX', startIconName: 'Mexico' },
  {
    text: 'Micronesia',
    value: '691',
    code: 'FM',
    startIconName: 'Micronesia',
  },
  { text: 'Moldova', value: '373', code: 'MD', startIconName: 'Moldova' },
  { text: 'Monaco', value: '377', code: 'MC', startIconName: 'Monaco' },
  {
    text: 'Mongolia',
    value: '976',
    code: 'MN',
    startIconName: 'Mongolia',
  },
  {
    text: 'Montenegro',
    value: '382',
    code: 'ME',
    startIconName: 'Montenegro',
  },
  {
    text: 'Montserrat',
    value: '1664',
    code: 'MS',
    startIconName: 'Montserrat',
  },
  {
    text: 'Morocco',
    value: '212',
    code: 'MA',
    startIconName: 'Morocco',
  },
  {
    text: 'Mozambique',
    value: '258',
    code: 'MZ',
    startIconName: 'Mozambique',
  },
  { text: 'Myanmar', value: '95', code: 'MM', startIconName: 'Myanmar' },
  { text: 'Namibia', value: '264', code: 'NA', startIconName: 'Namibia' },
  { text: 'Nauru', value: '674', code: 'NR', startIconName: 'Nauru' },
  { text: 'Nepal', value: '977', code: 'NP', startIconName: 'Nepal' },
  {
    text: 'Netherlands',
    value: '31',
    code: 'NL',
    startIconName: 'Netherlands',
  },
  {
    text: 'New Zealand',
    value: '64',
    code: 'NZ',
    startIconName: 'NewZealand',
  },
  {
    text: 'Nicaragua',
    value: '505',
    code: 'NI',
    startIconName: 'Nicaragua',
  },
  { text: 'Niger', value: '227', code: 'NE', startIconName: 'Niger' },
  { text: 'Nigeria', value: '234', code: 'NG', startIconName: 'Nigeria' },
  { text: 'Niue', value: '683', code: 'NU', startIconName: 'Niue' },
  {
    text: 'Norfolk Island',
    value: '672',
    code: 'NF',
    startIconName: 'NorfolkIsland',
  },
  {
    text: 'North Korea',
    value: '850',
    code: 'KP',
    startIconName: 'NorthKorea',
  },
  {
    text: 'Northern Mariana Islands',
    value: '1670',
    code: 'MP',
    startIconName: 'NorthernMarianaIslands',
  },
  { text: 'Norway', value: '47', code: 'NO', startIconName: 'Norway' },
  { text: 'Oman', value: '968', code: 'OM', startIconName: 'Oman' },
  { text: 'Pakistan', value: '92', code: 'PK', startIconName: 'Pakistan' },
  { text: 'Palau', value: '680', code: 'PW', startIconName: 'Palau' },
  {
    text: 'Palestine',
    value: '970',
    code: 'PS',
    startIconName: 'Palestine',
  },
  { text: 'Panama', value: '507', code: 'PA', startIconName: 'Panama' },
  {
    text: 'Papua New Guinea',
    value: '675',
    code: 'PG',
    startIconName: 'PapuaNewGuinea',
  },
  {
    text: 'Paraguay',
    value: '595',
    code: 'PY',
    startIconName: 'Paraguay',
  },
  { text: 'Peru', value: '51', code: 'PE', startIconName: 'Peru' },
  {
    text: 'Philippines',
    value: '63',
    code: 'PH',
    startIconName: 'Philippines',
  },
  { text: 'Poland', value: '48', code: 'PL', startIconName: 'Poland' },
  {
    text: 'Portugal',
    value: '351',
    code: 'PT',
    startIconName: 'Portugal',
  },
  {
    text: 'Puerto Rico',
    value: '1',
    code: 'PR',
    startIconName: 'PuertoRico',
  },
  { text: 'Qatar', value: '974', code: 'QA', startIconName: 'Qatar' },
  { text: 'Romania', value: '40', code: 'RO', startIconName: 'Romania' },
  { text: 'Russia', value: '7', code: 'RU', startIconName: 'Russia' },
  { text: 'Rwanda', value: '250', code: 'RW', startIconName: 'Rwanda' },
  {
    text: 'Saint Kitts and Nevis',
    value: '1869',
    code: 'KN',
    startIconName: 'SaintKittsAndNevis',
  },
  {
    text: 'Saint Lucia',
    value: '1758',
    code: 'LC',
    startIconName: 'StLucia',
  },
  {
    text: 'Saint Martin',
    value: '590',
    code: 'MF',
    startIconName: 'SaintMartin',
  },
  {
    text: 'Saint Vincent and the Grenadines',
    value: '1784',
    code: 'VC',
    startIconName: 'StVincentAndTheGrenadines',
  },
  { text: 'Samoa', value: '685', code: 'WS', startIconName: 'Samoa' },
  {
    text: 'San Marino',
    value: '378',
    code: 'SM',
    startIconName: 'SanMarino',
  },
  {
    text: 'São Tomé and Príncipe',
    value: '239',
    code: 'ST',
    startIconName: 'SaoTomeAndPrincipe',
  },
  {
    text: 'Saudi Arabia',
    value: '966',
    code: 'SA',
    startIconName: 'SaudiArabia',
  },
  { text: 'Senegal', value: '221', code: 'SN', startIconName: 'Senegal' },
  { text: 'Serbia', value: '381', code: 'RS', startIconName: 'Serbia' },
  {
    text: 'Seychelles',
    value: '248',
    code: 'SC',
    startIconName: 'Seychelles',
  },
  {
    text: 'Sierra Leone',
    value: '232',
    code: 'SL',
    startIconName: 'SierraLeone',
  },
  {
    text: 'Singapore',
    value: '65',
    code: 'SG',
    startIconName: 'Singapore',
  },
  {
    text: 'Slovakia',
    value: '421',
    code: 'SK',
    startIconName: 'Slovakia',
  },
  {
    text: 'Slovenia',
    value: '386',
    code: 'SI',
    startIconName: 'Slovenia',
  },
  { text: 'Somalia', value: '252', code: 'SO', startIconName: 'Somalia' },
  {
    text: 'South Africa',
    value: '27',
    code: 'ZA',
    startIconName: 'SouthAfrica',
  },
  {
    text: 'South Korea',
    value: '82',
    code: 'KR',
    startIconName: 'SouthKorea',
  },
  {
    text: 'South Sudan',
    value: '211',
    code: 'SS',
    startIconName: 'SouthSudan',
  },
  {
    text: 'Spain',
    value: '34',
    code: 'ES',
    startIconName: 'Spain',
  },
  {
    text: 'Sri Lanka',
    value: '94',
    code: 'LK',
    startIconName: 'SriLanka',
  },
  {
    text: 'Sudan',
    value: '249',
    code: 'SD',
    startIconName: 'Sudan',
  },
  {
    text: 'Suriname',
    value: '597',
    code: 'SR',
    startIconName: 'Suriname',
  },
  {
    text: 'Swaziland',
    value: '268',
    code: 'SZ',
    startIconName: 'Swaziland',
  },
  { text: 'Sweden', value: '46', code: 'SE', startIconName: 'Sweden' },
  {
    text: 'Switzerland',
    value: '41',
    code: 'CH',
    startIconName: 'Switzerland',
  },
  { text: 'Syria', value: '963', code: 'SY', startIconName: 'Syria' },
  { text: 'Taiwan', value: '886', code: 'TW', startIconName: 'Taiwan' },
  { text: 'Tajikistan', value: '992', code: 'TJ', startIconName: 'Tajikistan' },
  { text: 'Tanzania', value: '255', code: 'TZ', startIconName: 'Tanzania' },
  { text: 'Thailand', value: '66', code: 'TH', startIconName: 'Thailand' },
  { text: 'Togo', value: '228', code: 'TG', startIconName: 'Togo' },
  { text: 'Tokelau', value: '690', code: 'TK', startIconName: 'Tokelau' },
  { text: 'Tonga', value: '676', code: 'TO', startIconName: 'Tonga' },
  {
    text: 'Trinidad and Tobago',
    value: '1868',
    code: 'TT',
    startIconName: 'TrinidadAndTobago',
  },
  { text: 'Tunisia', value: '216', code: 'TN', startIconName: 'Tunisia' },
  { text: 'Turkey', value: '90', code: 'TR', startIconName: 'Turkey' },
  {
    text: 'Turkmenistan',
    value: '993',
    code: 'TM',
    startIconName: 'Turkmenistan',
  },
  {
    text: 'Turks and Caicos Islands',
    value: '1649',
    code: 'TC',
    startIconName: 'TurksAndCaicos',
  },
  { text: 'Tuvalu', value: '688', code: 'TV', startIconName: 'Tuvalu' },
  {
    text: 'U.S. Virgin Islands',
    value: '1340',
    code: 'VI',
    startIconName: 'VirginIslands',
  },
  { text: 'Uganda', value: '256', code: 'UG', startIconName: 'Uganda' },
  { text: 'Ukraine', value: '380', code: 'UA', startIconName: 'Ukraine' },
  {
    text: 'United Arab Emirates',
    value: '971',
    code: 'AE',
    startIconName: 'UnitedArabEmirates',
  },
  {
    text: 'United Kingdom',
    value: '44',
    code: 'GB',
    startIconName: 'UnitedKingdom',
  },
  { text: 'Uruguay', value: '598', code: 'UY', startIconName: 'Uruguay' },
  {
    text: 'Uzbekistan',
    value: '998',
    code: 'UZ',
    startIconName: 'Uzbekistn',
  },
  { text: 'Vanuatu', value: '678', code: 'VU', startIconName: 'Vanuatu' },
  {
    text: 'Vatican City',
    value: '39',
    code: 'IT',
    startIconName: 'VaticanCity',
  },
  {
    text: 'Venezuela',
    value: '58',
    code: 'VE',
    startIconName: 'Venezuela',
  },
  { text: 'Vietnam', value: '84', code: 'VN', startIconName: 'Vietnam' },
  {
    text: 'Western Sahara',
    value: '212',
    code: 'EH',
    startIconName: 'WesternSahara',
  },
  { text: 'Yemen', value: '967', code: 'YE', startIconName: 'Yemen' },
  { text: 'Zambia', value: '260', code: 'ZM', startIconName: 'Zambia' },
  {
    text: 'Zimbabwe',
    value: '263',
    code: 'ZW',
    startIconName: 'Zimbabwe',
  },
] as Countries;
