import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M13.803 10.469 20.856 2h-1.671L13.06 9.353 8.168 2H2.526l7.398 11.12L2.526 22h1.672l6.468-7.765L15.832 22h5.642zm-2.29 2.748-.75-1.107L4.8 3.3h2.568l4.812 7.11.75 1.107 6.256 9.242h-2.568z"
      />
    </Svg>
  );
};
Icon.displayName = 'TwitterX';
export const TwitterX = memo<IconProps>(themed(Icon));
