import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M512 256C512 114.616 397.384 0 256 0c-50.08 0-96.794 14.395-136.252 39.251l56.113 23.841-75.687 32.158 75.687 32.157-75.687 32.156 75.687 32.155-75.687 32.151 75.687 32.149-75.687 32.156 75.687 32.154-75.687 32.151 75.687 32.154-75.687 32.156 75.687 32.151-56.088 23.828C159.226 497.612 205.93 512 256 512c141.384 0 256-114.616 256-256"
        fill="#751a46"
      />
    </Svg>
  );
};
Icon.displayName = 'Qatar';
export const Qatar = memo<IconProps>(Icon);
