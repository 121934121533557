import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="m12 18.275-4.15 2.5a.9.9 0 0 1-.575.15.97.97 0 0 1-.525-.2 1.2 1.2 0 0 1-.35-.437.87.87 0 0 1-.05-.588l1.1-4.725L3.775 11.8a.96.96 0 0 1-.275-1.075q.1-.275.3-.45.2-.176.55-.225l4.85-.425 1.875-4.45q.125-.3.388-.45t.537-.15.537.15.388.45l1.875 4.45 4.85.425q.35.05.55.225t.3.45q.1.274.038.563a.96.96 0 0 1-.313.512l-3.675 3.175 1.1 4.725a.87.87 0 0 1-.05.588q-.125.261-.35.437a.97.97 0 0 1-.525.2.9.9 0 0 1-.575-.15z"
      />
    </Svg>
  );
};
Icon.displayName = 'Star';
export const Star = memo<IconProps>(themed(Icon));
