import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M5 21q-.824 0-1.412-.587A1.93 1.93 0 0 1 3 19V5q0-.824.587-1.412A1.93 1.93 0 0 1 5 3h6q.424 0 .713.288Q12 3.575 12 4q0 .424-.287.713A.97.97 0 0 1 11 5H5v14h14v-6q0-.424.288-.713A.97.97 0 0 1 20 12q.424 0 .712.287.288.288.288.713v6q0 .824-.587 1.413A1.93 1.93 0 0 1 19 21zM19 6.4 10.4 15a.95.95 0 0 1-.7.275A.95.95 0 0 1 9 15a.95.95 0 0 1-.275-.7q0-.425.275-.7L17.6 5H15a.97.97 0 0 1-.713-.287A.97.97 0 0 1 14 4q0-.424.287-.712A.97.97 0 0 1 15 3h6v6q0 .424-.288.713A.97.97 0 0 1 20 10a.97.97 0 0 1-.712-.287A.97.97 0 0 1 19 9z"
      />
    </Svg>
  );
};
Icon.displayName = 'OpenLink';
export const OpenLink = memo<IconProps>(themed(Icon));
