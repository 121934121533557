import { Box, Button, Typography } from '@drivekyte/ui';
import { UniqueRentersType } from '@/types/unique-renters';

type OtpLoginProps = {
  data?: UniqueRentersType;
  onLogin: () => void;
};

const OtpLogin = ({ data, onLogin }: OtpLoginProps) => (
  <Box marginBottom="$medium">
    <Box marginBottom="$small">
      <Typography variant="paragraphMedium" flex={1}>
        {data?.confirmLogin.description}
      </Typography>
    </Box>
    <Box flexDirection="row" justifyContent="flex-end">
      <Button variant="primary" width={120} onPress={onLogin}>
        {data?.confirmLogin.cta}
      </Button>
    </Box>
  </Box>
);

export default OtpLogin;
