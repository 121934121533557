import React, { useEffect } from 'react';
import useGetMe from '@/hooks/use-get-me';
import useMeAuthentication from '@/hooks/use-me-authentication';
import { useRouter } from 'next/router';

type UserProviderProps = {
  children: React.ReactChild;
};

const UserProvider = ({ children }: UserProviderProps) => {
  const { mutate: fetchMe } = useGetMe();
  const { checkAuthenticationQuery } = useMeAuthentication();
  const router = useRouter();

  useEffect(() => {
    fetchMe(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!router.isReady) return;

    const authenticationEvent =
      router.query.auth_signin === 'true'
        ? 'signin'
        : router.query.auth_signout === 'true'
        ? 'signout'
        : undefined;

    checkAuthenticationQuery(authenticationEvent);
  }, [router.isReady, router.query.auth_signin, router.query.auth_signout]);

  return <>{children}</>;
};

export default UserProvider;
