import { useContext } from 'react';
import { Box } from '../box';
import { DialogContext } from './context/dialog-context';

type DialogContentProps = {
  testID?: string;
  children?: React.ReactNode;
};

const DialogContent = ({ testID, children }: DialogContentProps) => {
  const { variant, hasHeader, contentWidth } = useContext(DialogContext);

  const isLargeWithHeader = variant === 'large' && hasHeader;

  return (
    <Box
      testID={testID}
      backgroundColor="$backgroundPrimary"
      borderRadius="$small"
      width="100%"
      $gtSm={{
        width: isLargeWithHeader ? '400px' : contentWidth,
      }}
      justifyContent={!isLargeWithHeader ? 'flex-start' : 'center'}
    >
      {children}
    </Box>
  );
};

export { DialogContent };
