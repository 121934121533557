import * as React from 'react';
import { Svg, Path, Circle } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M0 256C0 114.616 114.616 0 256 0s256 114.616 256 256l-256 22.261z"
        fill="#ffda44"
      />
      <Path
        d="M34.256 384C78.521 460.516 161.245 512 256 512s177.479-51.484 221.744-128L256 367.304z"
        fill="#d80027"
      />
      <Path
        d="M477.744 384C499.526 346.346 512 302.631 512 256H0c0 46.631 12.474 90.346 34.256 128z"
        fill="#0052b4"
      />
      <Circle cx={256} cy={256} r={89.043} fill="#ffda44" />
      <Path
        d="M256 311.652c-30.687 0-55.652-24.966-55.652-55.652v-33.391c0-30.687 24.966-55.652 55.652-55.652s55.652 24.966 55.652 55.652V256c0 30.687-24.965 55.652-55.652 55.652"
        fill="#338af3"
      />
      <Path
        d="M345.043 122.435H278.26c0-12.294-9.967-22.261-22.261-22.261s-22.261 9.967-22.261 22.261h-66.783c0 12.295 10.709 22.261 23.002 22.261h-.741c0 12.295 9.966 22.261 22.261 22.261 0 12.295 9.966 22.261 22.261 22.261h44.522c12.295 0 22.261-9.966 22.261-22.261 12.295 0 22.261-9.966 22.261-22.261h-.741c12.294 0 23.002-9.967 23.002-22.261"
        fill="#000"
      />
    </Svg>
  );
};
Icon.displayName = 'Ecuador';
export const Ecuador = memo<IconProps>(Icon);
