import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#496e2d" />
      <Path
        d="M300.522 245.325c-6.284 0-12.25 1.253-17.79 3.247 3.035-6.764 5.07-14.29 5.07-22.327 0-31.801-31.801-55.652-31.801-55.652S224.2 194.444 224.2 226.245c0 8.036 2.035 15.563 5.07 22.327-5.541-1.992-11.507-3.247-17.79-3.247-31.801 0-55.652 31.801-55.652 31.801s23.851 31.802 55.652 31.802c18.645 0 34.547-10.928 44.522-19.974 9.975 9.045 25.877 19.974 44.522 19.974 31.801 0 55.652-31.802 55.652-31.802s-23.853-31.801-55.654-31.801"
        fill="#f0f0f0"
      />
      <Path
        d="m256 100.174 6.569 20.216h21.257l-17.195 12.496 6.564 20.216L256 140.606l-17.195 12.496 6.564-20.216-17.195-12.496h21.257zM175.167 135.533l12.638 11.378 14.727-8.502-6.916 15.535 12.637 11.38-16.912-1.778-6.916 15.535-3.538-16.633-16.911-1.778 14.726-8.504zM115.994 194.707l16.634 3.535 8.503-14.727 1.778 16.911 16.632 3.538-15.534 6.917 1.778 16.91-11.381-12.635-15.534 6.916 8.503-14.728zM336.833 135.533l-12.637 11.378-14.727-8.502 6.915 15.535-12.636 11.38 16.912-1.778 6.916 15.535 3.538-16.633 16.911-1.778-14.726-8.504zM396.007 194.707l-16.634 3.535-8.502-14.727-1.779 16.911-16.632 3.538 15.534 6.917-1.778 16.91 11.381-12.635 15.534 6.916-8.503-14.728z"
        fill="#ffda44"
      />
      <Path
        d="M256 397.972c25.261 0 48.545-8.428 67.223-22.609H188.778c18.678 14.18 41.961 22.609 67.222 22.609M153.967 331.189a111.3 111.3 0 0 0 13.275 22.609H344.76a111.3 111.3 0 0 0 13.275-22.609z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Macau';
export const Macau = memo<IconProps>(Icon);
