/* eslint-disable import/namespace */
import type { CountryFlags } from '@drivekyte/flags';
import * as Flags from '@drivekyte/flags';
import { Check } from '@drivekyte/icons';
import { getTokenValue, useTheme } from '@tamagui/core';
import {
  GetPropsCommonOptions,
  UseSelectGetItemPropsOptions,
  UseSelectGetMenuPropsOptions,
} from 'downshift';
import { useCallback, useRef } from 'react';
import { useVirtual } from 'react-virtual';
import { Box } from '../../../../box';
import { Divider } from '../../../../divider';
import { SearchInput } from '../../../../search-input';
import { Typography } from '../../../../typography';
import { Countries, Country } from '../../../phone-input.constants';

type CountryItemProps = {
  getItemProps: (options: UseSelectGetItemPropsOptions<Country>) => any;
  getMenuProps: (
    options?: UseSelectGetMenuPropsOptions,
    otherOptions?: GetPropsCommonOptions,
  ) => any;
  isOpen: boolean;
  selectedItem?: Country;
  countries: Countries;
  highlightedIndex: number;
  searchPlaceholder?: string;
  searchValue: string;
  onChangeSearch: (value: string) => void;
};

const CountryList = ({
  getItemProps,
  getMenuProps,
  highlightedIndex,
  isOpen,
  selectedItem,
  countries,
  searchPlaceholder,
  searchValue,
  onChangeSearch,
}: CountryItemProps) => {
  const theme = useTheme();
  const virtualParentRef = useRef<HTMLUListElement>();
  const virtualCountryList = useVirtual({
    size: countries.length,
    parentRef: virtualParentRef,
    estimateSize: useCallback(() => 51, []),
  });

  return (
    <Box
      marginTop="$tiny"
      borderWidth={isOpen ? 1 : 0}
      borderColor="$borderDefault"
      borderRadius="$small"
    >
      {isOpen ? (
        <>
          <SearchInput
            withoutBorder={true}
            placeholder={searchPlaceholder}
            value={searchValue}
            onChangeText={onChangeSearch}
            testID="input-search"
          />
          <Divider margin="$none" />
        </>
      ) : null}
      <ul
        {...getMenuProps(undefined, { suppressRefError: true })}
        ref={virtualParentRef}
        style={{
          padding: 0,
          maxHeight: '242px',
          overflowY: 'auto',
          borderBottomLeftRadius: getTokenValue('$small', 'radius'),
          borderBottomRightRadius: getTokenValue('$small', 'radius'),
        }}
      >
        {isOpen ? (
          <>
            <Box
              height={`${virtualCountryList.totalSize}px`}
              width="100%"
              position="relative"
              data-testid="countries-list"
            >
              {virtualCountryList.virtualItems.map((row) => {
                const option = countries[row.index];
                const isHighlighted = highlightedIndex === row.index;
                const isSelected = selectedItem?.value === option?.value;
                const Icon = Flags[option?.startIconName as CountryFlags];

                return option?.value ? (
                  <li
                    {...getItemProps({
                      key: `${option?.value}${row.index}`,
                      item: option,
                      index: row.index,
                    })}
                    style={{
                      boxSizing: 'border-box',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      transform: `translateY(${row.start}px)`,
                      backgroundColor: isHighlighted
                        ? theme.backgroundSecondary.val
                        : theme.backgroundPrimary.val,
                      padding: getTokenValue('$xSmall', 'space'),
                      flexDirection: 'row',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box flexDirection="row" display="flex">
                      <Box marginRight="$tiny">
                        <Icon />
                      </Box>
                      <Typography variant="labelMedium">
                        {option?.text}
                      </Typography>
                    </Box>
                    {isSelected && <Check />}
                  </li>
                ) : null;
              })}
            </Box>
          </>
        ) : null}
      </ul>
    </Box>
  );
};

export default CountryList;
