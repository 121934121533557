import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M437.02 437.019c-99.974 99.974-262.064 99.974-362.04 0-99.974-99.974-99.974-262.065.001-362.04 99.974-99.974 246.323-84.233 346.297 15.742 99.973 99.974 115.715 246.324 15.742 346.298"
        fill="#000"
      />
      <Path
        d="M74.981 74.98c99.974-99.974 262.063-99.972 362.039.001 99.974 99.974 99.974 262.064 0 362.039"
        fill="#a2001d"
      />
      <Path
        d="m195.642 345.044 4.144 12.755h13.411l-10.85 7.882 4.145 12.755-10.85-7.883-10.85 7.883 4.144-12.755-10.849-7.882h13.41zM161.391 222.609l6.909 21.258h22.35l-18.083 13.137 6.907 21.258-18.083-13.139-18.082 13.139 6.908-21.258-18.084-13.137h22.352zM161.391 378.436l6.907 21.258h22.352l-18.085 13.136 6.909 21.258-18.083-13.139-18.082 13.139 6.906-21.258-18.082-13.136h22.349zM226.741 278.262l6.909 21.258H256l-18.083 13.136 6.907 21.258-18.083-13.139-18.082 13.139 6.908-21.258-18.083-13.136h22.351zM96.04 278.262l6.909 21.258h22.349l-18.082 13.136 6.906 21.258-18.082-13.139-18.083 13.139 6.909-21.258-18.084-13.136h22.351z"
        fill="#f0f0f0"
      />
      <Path
        d="M428.549 189.561c-11.441-11.441-26.307-18.2-42.282-19.361l47.82-37.193c-19.045-19.045-44.571-27.646-69.479-25.827a66.46 66.46 0 0 0-17.094-29.265l-18.889 37.778a27.8 27.8 0 0 0-4.722-6.296c-10.868-10.867-28.486-10.867-39.352 0s-10.868 28.485 0 39.352a27.8 27.8 0 0 0 6.297 4.722L253.07 172.36a66.45 66.45 0 0 0 29.28 17.099c-1.825 24.908 6.767 50.43 25.813 69.476l41.78-53.718a16.58 16.58 0 0 1 11.71-4.804c4.46 0 8.652 1.736 11.805 4.891a16.58 16.58 0 0 1 4.891 11.805c0 4.46-1.737 8.652-4.891 11.806l15.742 15.742c7.357-7.358 11.41-17.141 11.41-27.547 0-8.514-2.716-16.608-7.73-23.3a44.23 44.23 0 0 1 19.931 11.494c17.36 17.359 17.36 45.605 0 62.963l15.742 15.742c12.613-12.614 19.56-29.384 19.56-47.223-.003-17.841-6.951-34.611-19.564-47.225"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'PapuaNewGuinea';
export const PapuaNewGuinea = memo<IconProps>(Icon);
