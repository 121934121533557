import { useEffect, useState } from 'react';

const HydrationZustandProvider = ({ children }) => {
  const [isHydrated, setIsHydrated] = useState(false);

  // Wait till Next.js rehydration completes
  useEffect(() => {
    setIsHydrated(true);
  }, []);

  return (
    <>
      {isHydrated ? (
        <div>{children}</div>
      ) : (
        <noscript>
          <div>{children}</div>
        </noscript>
      )}
    </>
  );
};

export default HydrationZustandProvider;
