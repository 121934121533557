import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M345.043 15.923C317.31 5.633 287.314 0 256 0s-61.31 5.633-89.043 15.923L144.696 256l22.261 240.077C194.69 506.368 224.686 512 256 512s61.31-5.632 89.043-15.923L367.304 256z"
        fill="#ffda44"
      />
      <Path
        d="M512 256c0-110.07-69.472-203.906-166.957-240.076v480.155C442.528 459.906 512 366.072 512 256"
        fill="#d80027"
      />
      <Path
        d="M0 256c0 110.072 69.472 203.906 166.957 240.078V15.924C69.472 52.094 0 145.93 0 256"
        fill="#000"
      />
    </Svg>
  );
};
Icon.displayName = 'Belgium';
export const Belgium = memo<IconProps>(Icon);
