import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#496e2d" />
      <Path
        d="M256 295.751c-42.626 0-78.236-29.958-86.973-69.963a89.4 89.4 0 0 0-2.07 19.081c0 49.179 39.865 89.043 89.043 89.043s89.043-39.865 89.043-89.043a89.4 89.4 0 0 0-2.07-19.082c-8.737 40.007-44.347 69.964-86.973 69.964"
        fill="#ffda44"
      />
      <Path
        d="m255.999 178.087 8.288 25.509h26.823l-21.699 15.765 8.288 25.509-21.7-15.766L234.3 244.87l8.289-25.509-21.699-15.765h26.821z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Mauritania';
export const Mauritania = memo<IconProps>(Icon);
