import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M0 256a257 257 0 0 0 5.127 51.2L256 322.783 506.874 307.2c3.36-16.543 5.126-33.665 5.126-51.2s-1.768-34.657-5.126-51.2L256 189.217 5.127 204.8A257 257 0 0 0 0 256"
        fill="#0052b4"
      />
      <Path
        d="M506.874 307.2H5.127C28.846 424.05 132.151 512 256 512s227.154-87.95 250.874-204.8"
        fill="#f0f0f0"
      />
      <Path
        d="M5.127 204.8h501.748C483.154 87.95 379.851 0 256 0S28.846 87.95 5.127 204.8"
        fill="#000"
      />
      <Path
        d="M367.304 204.8H144.696l45.48-21.394-24.219-44.045 49.385 9.445 6.26-49.889L256 135.611l34.4-36.694 6.256 49.889 49.387-9.445-24.217 44.047z"
        fill="#ffda44"
      />
      <Path
        d="M0 256c0 141.384 114.616 256 256 256L51.196 102.391C19.052 145.18 0 198.363 0 256M256 512c141.384 0 256-114.616 256-256 0-57.637-19.052-110.82-51.196-153.609z"
        fill="#a2001d"
      />
    </Svg>
  );
};
Icon.displayName = 'AntiguaAndBarbuda';
export const AntiguaAndBarbuda = memo<IconProps>(Icon);
