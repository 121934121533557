import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M5 21a.97.97 0 0 1-.713-.288A.97.97 0 0 1 4 20V5q0-.824.588-1.412A1.93 1.93 0 0 1 6 3h6q.825 0 1.412.587Q14 4.176 14 5v7h1q.824 0 1.413.588Q17 13.175 17 14v4.5q0 .424.288.712.287.288.712.288.424 0 .712-.288A.97.97 0 0 0 19 18.5v-7.2q-.225.125-.475.162A3.5 3.5 0 0 1 18 11.5q-1.05 0-1.775-.725T15.5 9q0-.8.438-1.437.437-.638 1.162-.913l-1.575-1.575a.72.72 0 0 1-.225-.525q0-.3.225-.525a.74.74 0 0 1 .513-.213.74.74 0 0 1 .537.188l3.175 3.1q.375.375.563.875T20.5 9v9.5q0 1.05-.725 1.775T18 21t-1.775-.725T15.5 18.5v-5H14V20q0 .424-.287.712A.97.97 0 0 1 13 21zm1-11h6V5H6zm12 0q.424 0 .712-.287A.97.97 0 0 0 19 9a.97.97 0 0 0-.288-.713A.97.97 0 0 0 18 8a.97.97 0 0 0-.712.287A.97.97 0 0 0 17 9q0 .424.288.713.287.287.712.287M6 19h6v-7H6z"
      />
    </Svg>
  );
};
Icon.displayName = 'GasStation';
export const GasStation = memo<IconProps>(themed(Icon));
