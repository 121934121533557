import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#ffda44" />
      <Path
        d="M411.856 52.92C368.689 19.739 314.654 0 256 0S143.31 19.74 100.143 52.92L256 208.776z"
        fill="#6da544"
      />
      <Path
        d="M52.919 100.144C19.74 143.311 0 197.346 0 256s19.74 112.69 52.92 155.858L208.777 256z"
        fill="#000"
      />
      <Path
        d="M100.143 459.08C143.311 492.261 197.346 512 256 512s112.689-19.74 155.857-52.92L256 303.224z"
        fill="#6da544"
      />
      <Path
        d="M459.08 411.856C492.261 368.689 512 314.654 512 256s-19.739-112.689-52.92-155.856L303.224 256z"
        fill="#000"
      />
    </Svg>
  );
};
Icon.displayName = 'Jamaica';
export const Jamaica = memo<IconProps>(Icon);
