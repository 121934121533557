import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M7 21q-.824 0-1.412-.587A1.93 1.93 0 0 1 5 19V8q0-.824.588-1.412A1.93 1.93 0 0 1 7 6h2V3.5q0-.625.438-1.062A1.45 1.45 0 0 1 10.5 2h3q.624 0 1.063.438Q15 2.874 15 3.5V6h2q.824 0 1.413.588Q19 7.175 19 8v11q0 .824-.587 1.413A1.93 1.93 0 0 1 17 21q0 .424-.288.712A.97.97 0 0 1 16 22a.97.97 0 0 1-.713-.288A.97.97 0 0 1 15 21H9q0 .424-.287.712A.97.97 0 0 1 8 22a.97.97 0 0 1-.713-.288A.97.97 0 0 1 7 21m0-2h10V8H7zm1.75-1a.73.73 0 0 0 .75-.75v-7.5A.73.73 0 0 0 8.75 9a.73.73 0 0 0-.75.75v7.5a.73.73 0 0 0 .75.75M12 18a.73.73 0 0 0 .75-.75v-7.5A.73.73 0 0 0 12 9a.73.73 0 0 0-.75.75v7.5A.73.73 0 0 0 12 18m3.25 0a.73.73 0 0 0 .75-.75v-7.5a.73.73 0 0 0-.75-.75.73.73 0 0 0-.75.75v7.5a.73.73 0 0 0 .75.75M10.5 6h3V3.5h-3z"
      />
    </Svg>
  );
};
Icon.displayName = 'Luggage';
export const Luggage = memo<IconProps>(themed(Icon));
