import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M31.127 133.565 256 155.826l224.873-22.261C437.454 53.989 353.035 0 256 0S74.546 53.989 31.127 133.565"
        fill="#d80027"
      />
      <Path
        d="M31.127 378.435 256 400.696l224.873-22.261C500.717 342.064 512 300.35 512 256l-256-22.261L0 256c0 44.35 11.283 86.064 31.127 122.435"
        fill="#ffda44"
      />
      <Path
        d="M31.127 133.565C11.283 169.936 0 211.65 0 256h512c0-44.35-11.283-86.064-31.127-122.435H31.127"
        fill="#0052b4"
      />
      <Path
        d="M256 512c97.035 0 181.454-53.989 224.873-133.565H31.127C74.546 458.011 158.965 512 256 512"
        fill="#6da544"
      />
    </Svg>
  );
};
Icon.displayName = 'Mauritius';
export const Mauritius = memo<IconProps>(Icon);
