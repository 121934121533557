import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 0c-45.261 0-87.769 11.762-124.661 32.372v447.256C168.232 500.238 210.739 512 256 512c141.384 0 256-114.616 256-256S397.384 0 256 0"
        fill="#6da544"
      />
      <Path
        d="M132.092 31.951a256.6 256.6 0 0 0-40.676 27.983L287.481 256 91.415 452.066a256.636 256.636 0 0 0 40.676 27.983L512 256z"
        fill="#f0f0f0"
      />
      <Path
        d="M110.47 45.382a258 258 0 0 0-22.45 17.44L256 256 88.019 449.178a257 257 0 0 0 22.45 17.44L468.178 256z"
        fill="#ffda44"
      />
      <Path
        d="M91.415 59.934A258 258 0 0 0 74.98 74.98L233.739 256 74.98 437.02a257 257 0 0 0 16.435 15.046L287.481 256z"
        fill="#000"
      />
      <Path
        d="M74.98 74.98c-99.974 99.974-99.974 262.065 0 362.04L256 256z"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'Guyana';
export const Guyana = memo<IconProps>(Icon);
