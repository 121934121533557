import { createTokens } from '@tamagui/core';
import {
  black,
  blue,
  gray,
  green,
  primary,
  red,
  white,
  yellow,
} from './colors';

export const tokens = createTokens({
  size: {
    borderSmall: 1,
    borderMedium: 2,
  },
  space: {
    none: 0,
    xTiny: 4,
    tiny: 8,
    xSmall: 12,
    small: 16,
    medium: 24,
    large: 32,
    xLarge: 40,
    xxLarge: 56,
    huge: 80,
    xHuge: 120,
  },
  color: {
    ...primary,
    ...black,
    ...white,
    ...gray,
    ...blue,
    ...yellow,
    ...green,
    ...red,
  },
  radius: {
    xTiny: 2,
    tiny: 4,
    small: 8,
    medium: 12,
    large: 16,
    xLarge: 24,
    xxLarge: 36,
  },
  zIndex: {},
});
