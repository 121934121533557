import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M512 256c0 141.384-114.616 256-256 256S0 397.384 0 256"
        fill="#496e2d"
      />
      <Path
        d="M443.014 178.143c-9.155-25.963-33.892-44.577-62.987-44.577h-57.244c-36.883 0-66.783 29.9-66.783 66.783 0 12.05 3.208 23.631 8.791 33.391-21.257-.174-40.93.147-53.313 0v-65.431l2.63-2.63c11.806-11.806 7.869-31.482 7.869-31.482s-19.674-3.935-31.48 7.871l-13.759 13.759h-15.347c-33.81 0-61.217 27.408-61.217 61.217h44.522v50.087a53.2 53.2 0 0 0 4.146 20.661l-15.277 12.73h-22.261v-22.261H89.043v44.522h100.174v44.522h-22.261v22.261h44.522v-44.522l20.405-24.487h48.233l20.405 24.487v22.261H278.26v22.261h89.043V267.13c0-18.441 14.95-33.391 33.391-33.391.474 0 .944.017 1.414.036a44.6 44.6 0 0 1-1.414-11.166c.002-23.849 18.755-43.317 42.32-44.466M322.783 367.304v-46.748h1.855l20.405 24.487v22.261z"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'Wales';
export const Wales = memo<IconProps>(Icon);
