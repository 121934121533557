export type DurationType = {
  xSmall: number;
  small: number;
  medium: number;
  large: number;
};

const MINUTE = 60 * 1000;

/* Careful setting stale and cache times*/
/*
If we set staleTime : 15 mins and cacheTime : 5 mins.
The client sends a request for the first time, the backend interface sends the data requested
and the staleTime set to 15 mins make data to be considered as fresh for that amount of time.
After the User jumps on different application or tab, and return after 10 minutes so he should
have the data to be rendered on client side should be fresh, but cacheTime as set to be
5 minutes, lower than staleTime hence the data will not be fetched from cached data we need
to make a re fetch for the data from the backend interface even though the data is not stale.

*/

export const STALE_DURATION_MS: DurationType = {
  xSmall: 1 * MINUTE,
  small: 5 * MINUTE,
  medium: 10 * MINUTE,
  large: 15 * MINUTE,
};

export const CACHE_DURATION_MS: DurationType = {
  xSmall: 1 * MINUTE,
  small: 10 * MINUTE,
  medium: 15 * MINUTE,
  large: 20 * MINUTE,
};

export const REFETCH_INTERVAL_MS: DurationType = {
  xSmall: 1 * MINUTE,
  small: 5 * MINUTE,
  medium: 10 * MINUTE,
  large: 15 * MINUTE,
};
