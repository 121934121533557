import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M7 18a.97.97 0 0 1-.713-.288A.97.97 0 0 1 6 17v-2h13V6h2q.424 0 .712.287Q22 6.576 22 7v12.575q0 .674-.613.938-.612.262-1.087-.213L18 18zm-1-5-2.3 2.3q-.476.475-1.088.212Q2 15.25 2 14.576V3q0-.424.288-.712A.97.97 0 0 1 3 2h13q.424 0 .712.288Q17 2.575 17 3v9q0 .424-.288.713A.97.97 0 0 1 16 13zm9-2V4H4v7z"
      />
    </Svg>
  );
};
Icon.displayName = 'MessageThread';
export const MessageThread = memo<IconProps>(themed(Icon));
