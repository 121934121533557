import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#ffda44" />
      <Path
        d="M256 0C193.323 0 135.917 22.537 91.415 59.934L367.304 256 91.415 452.066C135.917 489.463 193.323 512 256 512c141.384 0 256-114.616 256-256S397.384 0 256 0"
        fill="#d80027"
      />
      <Path
        d="M74.98 74.98c-99.974 99.974-99.974 262.065 0 362.04L256 256z"
        fill="#000"
      />
      <Path
        d="m70.979 197.355 38.96 36.872 47.108-25.664-23.025 48.45 38.961 36.873-53.194-6.928-23.028 48.449-9.847-52.732-53.194-6.93 47.106-25.661z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'EastTimor';
export const EastTimor = memo<IconProps>(Icon);
