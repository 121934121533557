import { Box } from '../box';
import { Dot } from './components/dot';

type LoadingIndicatorProps = {
  testID?: string;
};

const LoadingIndicator = ({ testID }: LoadingIndicatorProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
      testID={testID}
    >
      <Dot />
      <Dot animationDelay={250} />
      <Dot animationDelay={500} />
    </Box>
  );
};

export { LoadingIndicator };
