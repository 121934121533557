import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M512 256c0 141.384-114.616 256-256 256S0 397.384 0 256s256 0 256 0z"
        fill="#d80027"
      />
      <Path d="M0 256C0 114.616 114.616 0 256 0v256z" fill="#0052b4" />
      <Path
        d="m152.389 89.043 16.577 51.018h53.643l-43.398 31.53 16.576 51.018-43.398-31.531-43.398 31.531 16.576-51.018-43.398-31.53h53.643z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Chile';
export const Chile = memo<IconProps>(Icon);
