import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M12 22q-2.65 0-4.325-.837Q6 20.324 6 19q0-.875.725-1.525t2-1.025l.575 1.9a4 4 0 0 0-.775.313q-.35.187-.475.337.325.4 1.5.7T12 20t2.463-.3 1.512-.7q-.125-.15-.475-.337a4 4 0 0 0-.775-.313l.575-1.9q1.275.375 1.988 1.025Q18 18.125 18 19q0 1.325-1.675 2.163T12 22m0-6.675q.45-.825.95-1.512t.975-1.313q.925-1.2 1.475-2.162.55-.963.55-2.388 0-1.65-1.15-2.8T12 4 9.2 5.15t-1.15 2.8q0 1.425.55 2.388t1.475 2.162q.476.625.975 1.313.5.687.95 1.512M12 19a.84.84 0 0 1-.5-.163.9.9 0 0 1-.325-.437q-.575-1.775-1.45-2.975t-1.7-2.3a16 16 0 0 1-1.388-2.275q-.587-1.175-.587-2.9 0-2.5 1.725-4.225T12 2t4.225 1.725T17.95 7.95q0 1.725-.575 2.9t-1.4 2.275q-.8 1.1-1.687 2.3-.888 1.2-1.463 2.975a.9.9 0 0 1-.325.438.84.84 0 0 1-.5.162m0-8.925q.875 0 1.5-.625.625-.626.625-1.5 0-.875-.625-1.5a2.05 2.05 0 0 0-1.5-.625q-.875 0-1.5.625a2.05 2.05 0 0 0-.625 1.5q0 .874.625 1.5.625.625 1.5.625"
      />
    </Svg>
  );
};
Icon.displayName = 'Location2';
export const Location2 = memo<IconProps>(themed(Icon));
