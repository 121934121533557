import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#ffda44" />
      <Path
        d="M111.713 44.522C152.798 16.436 202.476 0 256 0s103.202 16.436 144.287 44.522L256 55.652z"
        fill="#a2001d"
      />
      <Path d="m256 256-16.338-16.338-4.338 7.771z" fill="#0052b4" />
      <Path
        d="M74.98 74.98c-99.974 99.974-99.974 262.065 0 362.04L256 256zM437.02 74.98c99.974 99.974 99.974 262.065 0 362.04L256 256z"
        fill="#496e2d"
      />
      <Circle cx={256} cy={256} r={89.043} fill="#a2001d" />
      <Path
        d="m255.999 166.957 19.959 61.429h64.598l-52.258 37.973 19.959 61.432-52.258-37.966-52.26 37.966 19.963-61.432-52.258-37.973h64.594zM256 0l-.086.002h.171z"
        fill="#ffda44"
      />
      <Path
        d="M256.086 0h-.171C185.262.023 121.301 28.666 74.994 74.968h362.013C390.699 28.666 326.738.023 256.086 0M74.995 437.033C121.321 483.352 185.315 512 256 512s134.679-28.648 181.005-74.967z"
        fill="#a2001d"
      />
      <Path
        d="m256 27.826 4.145 12.754h13.41l-10.849 7.883 4.145 12.754L256 53.335l-10.849 7.882 4.145-12.754-10.851-7.883h13.41zM193.923 27.826l4.145 12.754h13.41l-10.849 7.883 4.145 12.754-10.851-7.882-10.848 7.882 4.144-12.754-10.851-7.883h13.41zM318.077 27.826l4.145 12.754h13.41l-10.849 7.883 4.145 12.754-10.851-7.882-10.849 7.882 4.145-12.754-10.851-7.883h13.41zM256 450.783l4.145 12.754h13.41l-10.849 7.883 4.145 12.754L256 476.291l-10.849 7.883 4.145-12.754-10.851-7.883h13.41zM193.923 450.783l4.145 12.754h13.41l-10.849 7.883 4.145 12.754-10.851-7.883-10.848 7.883 4.144-12.754-10.851-7.883h13.41zM318.077 450.783l4.145 12.754h13.41l-10.849 7.883 4.145 12.754-10.851-7.883-10.849 7.883 4.145-12.754-10.851-7.883h13.41zM78.003 251.772c6.575 10.389 3.485 24.14-6.904 30.716s-24.14 3.486-30.716-6.903c-11.905-18.808-5.002-49.525-5.002-49.525s30.716 6.903 42.622 25.712"
        fill="#ffda44"
      />
      <Circle cx={65.146} cy={273.085} r={11.13} fill="#a2001d" />
    </Svg>
  );
};
Icon.displayName = 'Grenada';
export const Grenada = memo<IconProps>(Icon);
