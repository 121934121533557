import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M512 256c0 141.384-114.616 256-256 256S0 397.384 0 256C0 256.061 256 .028 256 0c141.384 0 256 114.616 256 256"
        fill="#0052b4"
      />
      <Path d="M256 0h-.043zM255.315 256H256v-.685z" fill="#f0f0f0" />
      <Path
        d="M256 133.565V0h-.043C114.592.024 0 114.629 0 256h133.565v-75.211L208.777 256h46.539l.685-.685v-46.537l-75.213-75.213z"
        fill="#f0f0f0"
      />
      <Path
        d="M129.515 33.391a257.2 257.2 0 0 0-96.124 96.124V256h66.783V100.174H256V33.391z"
        fill="#d80027"
      />
      <Path
        d="m256 224.519-90.953-90.953h-31.481v.002L255.998 256H256z"
        fill="#d80027"
      />
      <Path
        d="M411.826 300.522v11.13h-89.043v-11.13h-33.392v44.521h22.261v11.131h111.305v-11.131h22.26v-44.521z"
        fill="#ffda44"
      />
      <Path
        d="M289.391 133.565V256c0 59.639 77.913 77.913 77.913 77.913s77.913-18.274 77.913-77.913V133.565z"
        fill="#338af3"
      />
      <Path
        d="M367.3 224.889c-19.477 0-19.477 17.809-38.954 17.809s-19.477-17.809-38.954-17.809v31.165c19.477 0 19.477 17.809 38.954 17.809s19.477-17.809 38.954-17.809c19.479 0 19.479 17.809 38.959 17.809s19.479-17.809 38.959-17.809v-31.165c-19.479 0-19.479 17.809-38.959 17.809s-19.48-17.809-38.959-17.809M367.3 162.504c-19.477 0-19.477 17.809-38.954 17.809s-19.477-17.809-38.954-17.809v31.165c19.477 0 19.477 17.809 38.954 17.809s19.477-17.809 38.954-17.809c19.479 0 19.479 17.809 38.959 17.809s19.479-17.809 38.959-17.809v-31.165c-19.479 0-19.479 17.809-38.959 17.809s-19.48-17.809-38.959-17.809"
        fill="#f3f3f3"
      />
    </Svg>
  );
};
Icon.displayName = 'FalklandIslands';
export const FalklandIslands = memo<IconProps>(Icon);
