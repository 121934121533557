import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 0C154.506 0 66.81 59.065 25.402 144.696h461.195C445.19 59.065 357.493 0 256 0M256 512c101.493 0 189.19-59.065 230.598-144.696H25.402C66.81 452.935 154.506 512 256 512"
        fill="#338af3"
      />
      <Path
        d="M256 178.087c-43.03 0-77.913 34.883-77.913 77.913S212.97 333.913 256 333.913 333.913 299.03 333.913 256 299.03 178.087 256 178.087m0 122.435c-24.588 0-44.522-19.932-44.522-44.522s19.933-44.522 44.522-44.522S300.522 231.41 300.522 256 280.588 300.522 256 300.522"
        fill="#ffda44"
      />
      <Path
        d="M294.558 267.13 256 256l-38.558 11.13-12.852 22.261h102.819z"
        fill="#0052b4"
      />
      <Path
        d="m256 200.348-25.705 44.522L256 256l25.705-11.13z"
        fill="#338af3"
      />
      <Path d="M217.442 267.13h77.116l-12.853-22.26h-51.41z" fill="#6da544" />
    </Svg>
  );
};
Icon.displayName = 'Nicaragua';
export const Nicaragua = memo<IconProps>(Icon);
