import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M21 21V5H3v8H1V5q0-.824.587-1.412A1.93 1.93 0 0 1 3 3h18q.824 0 1.413.587Q23 4.176 23 5v14q0 .824-.587 1.413A1.93 1.93 0 0 1 21 21M9 14q-1.65 0-2.825-1.175T5 10t1.175-2.825T9 6t2.825 1.175T13 10t-1.175 2.825T9 14m0-2q.825 0 1.412-.588Q11 10.826 11 10t-.588-1.412A1.93 1.93 0 0 0 9 8q-.825 0-1.412.588A1.93 1.93 0 0 0 7 10q0 .825.588 1.412Q8.175 12 9 12M1 22v-2.8q0-.85.438-1.562.437-.713 1.162-1.088a14.8 14.8 0 0 1 3.15-1.163A13.8 13.8 0 0 1 9 15q1.65 0 3.25.387 1.6.388 3.15 1.163.724.376 1.162 1.087Q17 18.35 17 19.2V22zm2-2h12v-.8a.973.973 0 0 0-.5-.85q-1.35-.675-2.725-1.012a11.6 11.6 0 0 0-5.55 0Q4.85 17.675 3.5 18.35a.97.97 0 0 0-.5.85z"
      />
    </Svg>
  );
};
Icon.displayName = 'CoPresent';
export const CoPresent = memo<IconProps>(themed(Icon));
