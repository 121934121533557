import * as React from 'react';
import { Svg, G, Path, Defs, ClipPath } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <G clipPath="url(#a)">
        <Path
          fill={`${color}`}
          d="M4.793 7.36c-.401.02-.895.275-1.179.634-.259.31-.48.811-.425 1.283.453.039.907-.236 1.19-.585.278-.359.46-.84.414-1.331m3.848.96v7.2H9.78v-2.4h1.56c1.433 0 2.46-.996 2.46-2.4s-1.009-2.4-2.422-2.4zm1.139.96h1.32c.983 0 1.56.53 1.56 1.44s-.572 1.44-1.56 1.44H9.78zm-5.04.24c-.66 0-1.132.36-1.44.36-.312 0-.78-.357-1.32-.357-.66 0-1.306.415-1.647 1.02-.7 1.206-.181 2.994.495 3.977.332.487.732 1 1.272 1 .496-.018.662-.3 1.26-.3.601 0 .78.3 1.32.3s.867-.495 1.198-.98c.379-.553.533-1.092.543-1.12-.01-.008-1.047-.407-1.057-1.604-.008-1.002.817-1.48.855-1.507C5.753 9.617 4.98 9.52 4.8 9.52zm11.676.72c-1.194 0-2.077.67-2.11 1.591h1.013c.083-.437.498-.725 1.066-.725.688 0 1.076.315 1.076.894v.4l-1.406.075c-1.306.078-2.012.6-2.012 1.513 0 .92.729 1.532 1.775 1.532.706 0 1.362-.351 1.659-.907h.023v.853h1.04v-3.538c0-1.027-.837-1.687-2.124-1.687m2.544 0 1.925 5.255-.103.32c-.174.542-.457.75-.958.75-.086 0-.248-.008-.322-.017v.866c.075.017.339.027.42.027 1.107 0 1.629-.418 2.085-1.682L24 10.241h-1.154l-1.338 4.264h-.023l-1.338-4.264zm-1.499 2.88-.004.418c0 .665-.576 1.139-1.338 1.139-.6 0-.981-.282-.981-.716 0-.447.368-.707 1.07-.748z"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h24v24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
Icon.displayName = 'Applepay';
export const Applepay = memo<IconProps>(themed(Icon));
