import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M367.304 25.402C333.648 9.128 295.891 0 256 0s-77.648 9.128-111.304 25.402L122.435 256l22.261 230.598C178.352 502.872 216.109 512 256 512s77.648-9.128 111.304-25.402L389.565 256z"
        fill="#f0f0f0"
      />
      <Path
        d="M144.696 25.411C59.065 66.817 0 154.506 0 256s59.065 189.183 144.696 230.589zM367.304 25.411v461.178C452.935 445.183 512 357.493 512 256S452.935 66.817 367.304 25.411M322.783 333.913 256 122.435l-66.783 211.478h50.087v55.652h33.392v-55.652z"
        fill="#6da544"
      />
    </Svg>
  );
};
Icon.displayName = 'NorfolkIsland';
export const NorfolkIsland = memo<IconProps>(Icon);
