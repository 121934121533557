import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M380.196 32.112C343.412 11.664 301.07 0 256 0 114.626 0 .018 114.598 0 255.97l178.087-89.013zM0 256.03C.018 397.402 114.626 512 256 512c45.07 0 87.412-11.662 124.196-32.112L178.087 345.043z"
        fill="#0052b4"
      />
      <Path
        d="M401.53 45.382a256 256 0 0 0-21.343-13.266L0 255.97v.06l380.188 223.853a257 257 0 0 0 21.343-13.266L43.822 256z"
        fill="#d80027"
      />
      <Path
        d="M445.217 246.453h-30.494c7.929-9.52 7.44-23.683-1.493-32.614 9.462-9.461 9.462-24.802 0-34.265l-.57.572c9.461-9.461 10.032-25.374.57-34.835L276.174 282.367c9.462 9.463 24.534 9.35 33.995-.112l2.638-2.411 65.628-5.966v28.226h22.261v-30.25l33.391-3.035z"
        fill="#a2001d"
      />
      <Path
        d="M278.269 311.652 256 300.522l22.269-11.131h144.695v22.261z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'AmericanSamoa';
export const AmericanSamoa = memo<IconProps>(Icon);
