import Countdown, { CountdownRenderProps } from 'react-countdown';
import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Typography } from '@drivekyte/ui';
import { InputText, LinkButton } from '@drivekyte/web-components';
import { UniqueRentersState } from '@/types/unique-renters';
import { makeArray } from '@/utils/arrays-format/arrays-format';
import {
  UniqueRentersStatus,
  UniqueRentersStatusVariantEnum,
} from '@/hooks/use-unique-renters/use-unique-renters';

type OtpConfirmationFormProps = {
  data: UniqueRentersState;
  phone: string;
  verificationStatus?: UniqueRentersStatus;
  isLoading: boolean;
  countdownActive?: number | undefined;
  isCancelEnabled?: boolean;
  fixedSize?: string;
  onCancel: () => void;
  onHelp: () => void;
  onResendOtp: () => void;
  onValidateOtp: (otp: string) => void;
  stopCountdown: () => void;
};

const OTP_LENGTH = 4;

const OtpConfirmationForm = ({
  data,
  phone,
  verificationStatus,
  isLoading,
  isCancelEnabled,
  countdownActive,
  stopCountdown,
  onResendOtp,
  onCancel,
  onHelp,
  onValidateOtp,
}: OtpConfirmationFormProps) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const placeholder = makeArray('', OTP_LENGTH);
  const [otpCode, setOtpCode] = useState(placeholder);
  const [resendCounter, setResendCounter] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const isFormDisabled = isLoading || otpCode.includes('');
  const showError =
    verificationStatus?.status === UniqueRentersStatusVariantEnum.Error &&
    isSubmitted &&
    !isLoading;

  const shouldShowTroubleshoot =
    resendCounter >= data?.troubleshoot?.visibleAfterResending!!;

  const handleOtpInputChange = (index: number, value: string) => {
    const newOtpCode = [...otpCode];
    newOtpCode[index] = value;
    setOtpCode(newOtpCode);
    setIsSubmitted(false);

    // Move focus to the next input field on typing
    if (value !== '' && index < otpCode.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (event.key === 'Backspace' && index > 0 && otpCode[index] === '') {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const renderer = ({ minutes, seconds, completed }: CountdownRenderProps) => {
    if (completed) {
      stopCountdown();
    }

    return (
      <Typography variant="paragraphMedium" color="quaternary">
        {data?.notification?.description} {minutes}:
        {String(seconds).padStart(2, '0')}
      </Typography>
    );
  };

  const handleSubmit = () => {
    setIsSubmitted(true);
    onValidateOtp(otpCode.join(''));
  };

  const handleResend = () => {
    onResendOtp();
    setResendCounter(resendCounter + 1);
  };

  useEffect(() => {
    // Initialize the array of refs to match the number of inputs
    inputRefs.current = inputRefs.current.slice(0, otpCode.length);
  }, [otpCode.length]);

  return (
    <Box marginBottom="$medium">
      <Box marginBottom="$small">
        <Typography variant="paragraphMedium" flex={1}>
          {data.description} <span style={{ fontWeight: 700 }}>{phone}</span>
        </Typography>
      </Box>
      <form>
        <Box>
          <Box flexDirection="row" justifyContent="center" marginBottom="$tiny">
            {placeholder.map((element, index) => (
              <Box key={index} width={50} marginRight="$small">
                <InputText
                  mask="9"
                  centerInputText
                  ref={(el: HTMLInputElement) =>
                    (inputRefs.current[index] = el)
                  }
                  error={showError}
                  autoComplete="off"
                  autoFocus={index === 0}
                  onChange={(event) =>
                    handleOtpInputChange(index, event.target.value)
                  }
                  onKeyDown={(event) => handleKeyDown(event, index)}
                  value={otpCode[index]}
                  placeholder=""
                  maxLength={1}
                />
              </Box>
            ))}
          </Box>
          <Box justifyContent="center">
            {verificationStatus && (
              <Typography
                textAlign="center"
                color="$contentNegative"
                variant="labelSmall"
              >
                {verificationStatus.message}
              </Typography>
            )}
          </Box>
        </Box>
        <Box marginVertical="$medium">
          {countdownActive ? (
            <Countdown date={countdownActive} renderer={renderer} />
          ) : (
            <LinkButton
              color="tertiary"
              disabled={isLoading}
              onPress={handleResend}
            >
              {data?.ctas.resend || ''}
            </LinkButton>
          )}
        </Box>
        {shouldShowTroubleshoot && (
          <Box
            justifyContent="center"
            alignItems="center"
            marginVertical="$medium"
          >
            <Typography
              variant="paragraphSmall"
              color="secondary"
              textAlign="center"
            >
              {data.troubleshoot?.title}
            </Typography>
            <LinkButton
              color="tertiary"
              variant="medium"
              disabled={isLoading}
              onPress={onHelp}
            >
              {data.troubleshoot?.cta!!}
            </LinkButton>
          </Box>
        )}
        <Box>
          <Box
            flexDirection="row"
            justifyContent={isCancelEnabled ? 'space-between' : 'flex-end'}
          >
            {isCancelEnabled && (
              <Button
                size="small"
                disabled={isFormDisabled}
                variant={'secondary'}
                onPress={onCancel}
              >
                {data.ctas?.cancel}
              </Button>
            )}
            <Button
              size="small"
              loading={isLoading}
              disabled={isFormDisabled}
              variant={isFormDisabled ? 'secondary' : 'primary'}
              onPress={handleSubmit}
            >
              {data.ctas?.confirm}
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default OtpConfirmationForm;
