import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M496.077 166.957H15.923C5.632 194.69 0 224.686 0 256s5.632 61.31 15.923 89.043h480.155C506.368 317.31 512 287.314 512 256s-5.632-61.31-15.923-89.043"
        fill="#0052b4"
      />
      <Path
        d="M256 0C178.409 0 108.886 34.524 61.939 89.043H450.06C403.114 34.524 333.591 0 256 0M450.061 422.957H61.939C108.886 477.476 178.409 512 256 512s147.114-34.524 194.061-89.043"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'Thailand';
export const Thailand = memo<IconProps>(Icon);
