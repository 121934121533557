import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M0 256c0 31.314 5.633 61.31 15.923 89.043L256 356.174l240.077-11.13C506.368 317.31 512 287.314 512 256s-5.632-61.31-15.923-89.043L256 155.826l-240.077 11.13C5.633 194.69 0 224.686 0 256"
        fill="#ffda44"
      />
      <Path
        d="M256 0C145.929 0 52.094 69.472 15.923 166.957h480.155C459.906 69.472 366.071 0 256 0"
        fill="#d80027"
      />
      <Path
        d="M496.077 345.043H15.923C52.094 442.527 145.929 512 256 512s203.906-69.473 240.077-166.957"
        fill="#496e2d"
      />
      <Path d="m255.998 166.957 22.1 68.02h71.528l-57.863 42.043 22.1 68.023-57.865-42.04-57.865 42.04 22.104-68.023-57.863-42.043h71.524z" />
    </Svg>
  );
};
Icon.displayName = 'Ghana';
export const Ghana = memo<IconProps>(Icon);
