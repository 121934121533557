import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M12 21.9a2 2 0 0 1-.625-.1Q8 20.676 6 17.637 4 14.6 4 11.1V6.375q0-.625.362-1.125.363-.5.938-.725l6-2.25a2.1 2.1 0 0 1 .7-.125q.35 0 .7.125l6 2.25q.575.225.938.725.362.5.362 1.125V11.1q0 3.5-2 6.538-2 3.037-5.375 4.162-.15.05-.3.075T12 21.9m0-2q2.6-.825 4.3-3.3t1.7-5.5V6.375l-6-2.25-6 2.25V11.1q0 3.025 1.7 5.5t4.3 3.3"
      />
      <Path
        fill={`${color}`}
        d="M12 17q.426 0 .738-.312.312-.313.312-.738t-.312-.737A1 1 0 0 0 12 14.9q-.425 0-.737.313a1 1 0 0 0-.313.737q0 .425.313.738.312.312.737.312m2.85-7.45q0-1.174-.775-1.863Q13.301 7 12.125 7q-1.15 0-1.937.525t-.788 1.2q0 .25.1.412a.58.58 0 0 0 .3.238.7.7 0 0 0 .538-.012q.263-.113.512-.388.225-.275.525-.4t.65-.125q.575 0 .95.313.375.312.375.787 0 .45-.212.725-.213.275-.713.7-.624.525-.875 1.075-.25.55-.25 1 0 .4.188.625t.537.225a.7.7 0 0 0 .525-.2q.2-.2.25-.575a1.5 1.5 0 0 1 .338-.738q.288-.361.612-.687.576-.575.838-1.112a2.36 2.36 0 0 0 .262-1.038"
      />
    </Svg>
  );
};
Icon.displayName = 'ShieldQuestion';
export const ShieldQuestion = memo<IconProps>(themed(Icon));
