import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M0 256C0 114.616 114.616 0 256 0s256 114.616 256 256c-11.13 0-256 33.391-256 33.391z"
        fill="#d80027"
      />
      <Path
        d="M512 256c0 141.384-114.616 256-256 256S0 397.384 0 256"
        fill="#6da544"
      />
      <Path
        d="m255.999 166.957 19.337 59.518h62.587l-50.632 36.786 19.338 59.522-50.63-36.785-50.631 36.785 19.341-59.522-50.633-36.786h62.584z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'BurkinaFaso';
export const BurkinaFaso = memo<IconProps>(Icon);
