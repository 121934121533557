import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M512 256c0 141.384-114.616 256-256 256S0 397.384 0 256C0 256.06 256 .029 256 0c141.384 0 256 114.616 256 256"
        fill="#0052b4"
      />
      <Path d="m256 0-.043.001zM255.315 256H256v-.685z" fill="#f0f0f0" />
      <Path
        d="M256 133.566V.001h-.043C114.592.024 0 114.629 0 256h133.565v-75.212L208.777 256h46.539l.685-.685v-46.536l-75.213-75.213z"
        fill="#f0f0f0"
      />
      <Path
        d="M129.515 33.391a257.2 257.2 0 0 0-96.124 96.124V256h66.783V100.174H256V33.391z"
        fill="#d80027"
      />
      <Path
        d="m256 224.519-90.953-90.952h-31.481L255.999 256H256z"
        fill="#d80027"
      />
      <Path
        d="m154.395 300.522 14.05 29.378 31.727-7.333-14.208 29.302 25.514 20.233-31.767 7.16.089 32.564-25.405-20.373-25.404 20.373.089-32.564-31.768-7.16 25.515-20.233-14.21-29.302 31.729 7.333zM383.284 356.174l7.025 14.689 15.864-3.667-7.105 14.651 12.758 10.117-15.884 3.58.044 16.282-12.702-10.187-12.702 10.187.044-16.282-15.883-3.58 12.757-10.117-7.104-14.651 15.863 3.667zM317.933 200.348l7.024 14.69 15.864-3.668-7.104 14.651 12.757 10.117-15.883 3.58.043 16.282-12.701-10.187L305.231 256l.043-16.282-15.883-3.58 12.757-10.117-7.104-14.651 15.864 3.668zM383.284 111.304l7.025 14.69 15.864-3.667-7.104 14.651 12.756 10.116-15.883 3.581.044 16.282-12.702-10.187-12.702 10.187.044-16.282-15.883-3.581 12.756-10.116-7.103-14.651 15.863 3.667zM440.368 178.087l7.024 14.69 15.864-3.668-7.104 14.651 12.757 10.117-15.884 3.581.044 16.281-12.701-10.186-12.702 10.186.043-16.281-15.883-3.581 12.757-10.117-7.104-14.651 15.863 3.668zM399.55 256l5.525 17.006h17.882l-14.467 10.511 5.527 17.005-14.467-10.51-14.466 10.51 5.525-17.005-14.466-10.511h17.881z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Australia';
export const Australia = memo<IconProps>(Icon);
