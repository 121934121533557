import * as React from 'react';
import { Svg, Path, Circle } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M233.739 278.261S75.13 74.945 74.98 74.981C121.306 28.654 185.307 0 256 0c141.384 0 256 114.616 256 256z"
        fill="#d80027"
      />
      <Path
        d="M233.739 256S75.13 437.055 74.98 437.019C121.306 483.346 185.307 512 256 512c141.384 0 256-114.616 256-256z"
        fill="#0052b4"
      />
      <Path
        d="M74.98 74.98c-99.974 99.974-99.974 262.065 0 362.04L256 256z"
        fill="#f0f0f0"
      />
      <Path
        d="M28.061 256.001a73 73 0 0 0-.235 5.564c0 39.956 32.392 72.348 72.348 72.348s72.348-32.392 72.348-72.348c0-1.874-.095-3.727-.235-5.564z"
        fill="#ffda44"
      />
      <Circle cx={100.174} cy={200.348} r={22.261} fill="#ffda44" />
      <Path
        d="M50.087 194.783v72.348c0 38.34 50.087 50.087 50.087 50.087s50.087-11.747 50.087-50.087v-72.348z"
        fill="#d80027"
      />
      <Path
        d="M100.198 293.92c-9.292-3.344-27.851-11.89-27.851-26.79v-50.087H128v50.087c0 14.949-18.557 23.474-27.802 26.79"
        fill="#338af3"
      />
      <Path
        d="M111.304 244.87v-11.131l-11.13-5.565-11.131 5.565v11.131l-5.565 5.565v22.261h33.392v-22.261z"
        fill="#f3f3f3"
      />
    </Svg>
  );
};
Icon.displayName = 'SaintMartin';
export const SaintMartin = memo<IconProps>(Icon);
