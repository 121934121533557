import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M111.713 467.478C152.797 495.563 202.477 512 256 512s103.202-16.437 144.287-44.522L256 445.217zM400.287 44.522C359.202 16.437 309.523 0 256 0S152.797 16.437 111.713 44.522L256 66.783z"
        fill="#d80027"
      />
      <Path
        d="M512 256c0-84.71-50.716-169.78-111.713-211.478H111.713C44.27 90.626 0 168.14 0 256c0 87.863 44.27 165.374 111.713 211.478h288.574C467.731 421.374 512 343.863 512 256"
        fill="#0052b4"
      />
      <Path
        d="M241.513 417.597c-3.498-3-85.687-74.448-85.687-161.597s82.188-158.598 85.687-161.597L256 81.985l14.487 12.417c3.498 3 85.687 74.448 85.687 161.598s-82.188 158.599-85.687 161.597L256 430.015z"
        fill="#d80027"
      />
      <Path
        d="M333.913 256c0-77.913-77.913-144.696-77.913-144.696S178.087 178.089 178.087 256c0 23.835 7.297 46.624 17.419 66.783h120.988c10.122-20.159 17.419-42.948 17.419-66.783"
        fill="#338af3"
      />
      <Path
        d="M333.913 256c0 23.835-7.297 46.624-17.419 66.783L256 333.913l-60.494-11.13c-10.122-20.158-17.419-42.948-17.419-66.783z"
        fill="#0052b4"
      />
      <Path
        d="M256 400.696s37.528-32.177 60.494-77.913H195.506C218.472 368.519 256 400.696 256 400.696"
        fill="#ffda44"
      />
      <Path d="M205.913 211.478h100.174L256 261.565z" fill="#6da544" />
      <Path d="M239.304 244.87h33.391v100.174h-33.391z" fill="#a2001d" />
    </Svg>
  );
};
Icon.displayName = 'Guam';
export const Guam = memo<IconProps>(Icon);
