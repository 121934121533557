import { Close } from '@drivekyte/icons';
import { Dialog as DialogTM } from '@tamagui/dialog';
import React, { useContext } from 'react';
import { Box } from '../box';
import { IconButton } from '../icon-button';
import { Typography } from '../typography';
import { DialogContext } from './context/dialog-context';

type DialogTitleProps = {
  children: React.ReactNode;
};

const DialogTitle = ({ children }: DialogTitleProps) => {
  const { hasHeader } = useContext(DialogContext);

  return (
    <Box
      paddingLeft="$medium"
      paddingTop="$small"
      paddingBottom="$tiny"
      paddingRight={hasHeader ? '$medium' : '$tiny'}
      gap="$tiny"
      flexDirection="row"
      alignItems="center"
    >
      <DialogTM.Title flex={1}>
        <Typography variant="headingMedium" flex={1}>
          {children}
        </Typography>
      </DialogTM.Title>
      {!hasHeader && (
        <DialogTM.Close asChild tabIndex="-1">
          <IconButton size="medium" variant="tertiary" icon={<Close />} />
        </DialogTM.Close>
      )}
    </Box>
  );
};

export { DialogTitle };
