import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M345.046 15.924C317.312 5.633 287.315 0 256 0c-31.313 0-61.309 5.633-89.041 15.922L155.828 256l11.13 240.078C194.691 506.368 224.687 512 256 512c31.316 0 61.312-5.632 89.046-15.924L356.176 256z"
        fill="#ffda44"
      />
      <Path
        d="M0 256c0 110.07 69.472 203.906 166.957 240.076V15.922C69.472 52.094 0 145.928 0 256"
        fill="#d80027"
      />
      <Path
        d="M512 256c0-110.072-69.472-203.906-166.957-240.078v480.155C442.528 459.906 512 366.07 512 256"
        fill="#6da544"
      />
    </Svg>
  );
};
Icon.displayName = 'Guinea';
export const Guinea = memo<IconProps>(Icon);
