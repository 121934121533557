import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M306.087 507.087c101.203-20.076 180.924-99.798 201-201h-201zM4.913 306.087c20.075 101.202 99.797 180.924 201 201v-201zM507.087 205.913c-20.076-101.202-99.797-180.924-201-201v201zM205.913 4.913c-101.203 20.076-180.925 99.798-201 201h201z"
        fill="#496e2d"
      />
      <Path
        d="M239.304 306.087h-33.391v201a256 256 0 0 0 33.391 4.356zM239.304 205.913V.557a256 256 0 0 0-33.391 4.356v201z"
        fill="#ffda44"
      />
      <Path
        d="M272.696.557C267.174.2 261.611 0 256 0s-11.174.2-16.696.557v205.357h33.391V.557zM239.304 511.443c5.522.357 11.085.557 16.696.557s11.174-.2 16.696-.557V306.087h-33.391v205.356z"
        fill="#000"
      />
      <Path
        d="M205.913 239.304v-33.391h-201a256 256 0 0 0-4.356 33.391zM306.087 239.304h205.357a256 256 0 0 0-4.356-33.391h-201z"
        fill="#ffda44"
      />
      <Path
        d="M306.087 272.696h205.357c.356-5.521.556-11.085.556-16.696s-.2-11.175-.557-16.696H306.087zM.557 239.304C.201 244.825 0 250.389 0 256s.201 11.175.557 16.696h205.357v-33.391H.557z"
        fill="#000"
      />
      <Circle cx={256} cy={256} r={122.435} fill="#d80027" />
      <Path
        d="M283.894 269.66c-9.107-18.213-20.944-36.673-20.944-36.673s.294-8.405.294-13.413c0-7.544-6.116-13.66-13.66-13.66-7.147 0-13.003 5.491-13.6 12.482-5.62.367-10.067 5.029-10.067 10.741 0 4.153 2.353 7.752 5.797 9.551a15.52 15.52 0 0 1 9.21-8.555 14 14 0 0 0 2.177 1.466l1.75.838s-6.489 18.291-6.489 28.117c0 27.624 18.214 36.143 18.214 36.143v.285l-9.107 9.106h18.213v-18.214l9.107 9.107c.08-.171 17.25-11.03 9.105-27.321M256 155.826l4.144 12.754h13.41l-10.849 7.883 4.145 12.754-10.85-7.882-10.85 7.882 4.145-12.754-10.849-7.883h13.41zM197.12 174.958l10.848 7.883 10.85-7.884-4.144 12.756 10.85 7.881-13.411.001-4.144 12.754-4.145-12.754-13.41-.001 10.849-7.883zM160.729 225.045h13.409l4.145-12.754 4.144 12.754 13.411-.001-10.849 7.884 4.145 12.754-10.851-7.883-10.849 7.882 4.143-12.754zM160.729 286.955l10.848-7.881-4.144-12.755 10.85 7.883 10.85-7.883-4.144 12.754 10.85 7.883-13.412.001-4.144 12.753-4.145-12.755zM197.12 337.042l4.143-12.752-10.85-7.883h13.411l4.145-12.755 4.144 12.754h13.411l-10.85 7.884 4.144 12.753-10.85-7.883zM256 356.174l-4.144-12.752h-13.41l10.849-7.884-4.145-12.754 10.85 7.881 10.849-7.882-4.144 12.755 10.849 7.883-13.41.001zM314.88 337.042l-10.848-7.882-10.849 7.884 4.143-12.754-10.85-7.883 13.411-.001 4.143-12.754 4.146 12.755h13.41l-10.849 7.883zM351.271 286.955h-13.409l-4.145 12.755-4.144-12.753h-13.412l10.85-7.884-4.145-12.753 10.851 7.882 10.848-7.882-4.142 12.754zM351.271 225.045l-10.848 7.882 4.143 12.754-10.849-7.882-10.85 7.884 4.144-12.755-10.85-7.882 13.412-.002 4.143-12.752 4.146 12.753zM314.88 174.958l-4.143 12.753 10.849 7.883h-13.41l-4.144 12.755-4.145-12.754-13.411.001 10.85-7.885-4.144-12.752 10.85 7.881z"
        fill="#496e2d"
      />
    </Svg>
  );
};
Icon.displayName = 'Dominica';
export const Dominica = memo<IconProps>(Icon);
