import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M511.991 255.259h-277.51c-55.097 55.098-88.415 97.635-122.435 131.655l144.696 13.04 224.534-22.261C500.871 341.498 512 300.049 512 256c0-.248-.009-.493-.009-.741"
        fill="#d80027"
      />
      <Path
        d="M112.047 132.824h368.412C436.921 53.656 352.731 0 256 0 185.495 0 121.659 28.511 75.364 74.624z"
        fill="#ffda44"
      />
      <Path
        d="M123.177 377.693c-23.014 23.014-31.908 42.296-48.559 58.946C120.973 483.186 185.118 512 256 512c97.336 0 181.98-54.325 225.277-134.307z"
        fill="#0052b4"
      />
      <Path
        d="M74.98 74.239c-99.974 99.974-99.974 262.065 0 362.04L256 255.259z"
        fill="#6da544"
      />
      <Path
        d="M67.525 255.259c0-37.298 26.213-68.457 61.217-76.101a78 78 0 0 0-16.696-1.812c-43.031 0-77.913 34.882-77.913 77.913s34.882 77.913 77.913 77.913c5.733 0 11.315-.637 16.696-1.812-35.004-7.645-61.217-38.803-61.217-76.101"
        fill="#f0f0f0"
      />
      <Path
        d="m127.883 188.476 4.145 12.754h13.41l-10.85 7.883 4.145 12.754-10.85-7.882-10.85 7.882 4.145-12.754-10.85-7.883h13.41zM127.883 221.867l4.145 12.755h13.41l-10.85 7.882 4.145 12.755-10.85-7.883-10.85 7.883 4.145-12.755-10.85-7.882h13.41zM127.883 255.259l4.145 12.754h13.41l-10.85 7.883 4.145 12.754-10.85-7.883-10.85 7.883 4.145-12.754-10.85-7.883h13.41zM127.883 288.65l4.145 12.754h13.41l-10.85 7.883 4.145 12.754-10.85-7.882-10.85 7.882 4.145-12.754-10.85-7.883h13.41z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Comoros';
export const Comoros = memo<IconProps>(Icon);
