import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 0C255.761-.18-.068 255.933 0 256 0 114.616 114.616 0 256 0M256 0c.239-.18 256.068 255.932 256 256C512 114.616 397.384 0 256 0"
        fill="#d80027"
      />
      <Path
        d="M256 512c-.239.18-256.068-255.932-256-256 0 141.384 114.616 256 256 256M256 512c.239.18 256.068-255.932 256-256 0 141.384-114.616 256-256 256"
        fill="#0052b4"
      />
      <Path
        d="m256 133.565 27.628 85.029h89.405l-72.331 52.55 27.628 85.03L256 303.623l-72.33 52.551 27.628-85.03-72.33-52.55h89.404z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'SabaIsland';
export const SabaIsland = memo<IconProps>(Icon);
