import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M487.497 146.603c-13.006-27.474-30.757-52.257-52.197-73.302L256 55.652 76.7 73.302c-.572.561-1.13 1.135-1.696 1.702l71.6 71.6L256 149.945zM75.001 436.999c.942.942 1.882 1.885 2.838 2.813L256 456.348l178.159-16.536c21.661-21 39.629-45.785 52.818-73.302l-331.152-10.336c-28.493 28.494-61.357 61.356-80.824 80.825"
        fill="#ffda44"
      />
      <Path
        d="m509.454 219.905-297.976-8.427L256 256l-44.522 44.522 297.82-7.314A258 258 0 0 0 512 256c0-12.254-.88-24.3-2.546-36.095"
        fill="#000"
      />
      <Path
        d="M256 0C186.172 0 122.886 27.97 76.7 73.302h358.6C389.114 27.97 325.828 0 256 0"
        fill="#6da544"
      />
      <Path
        d="M219.905 219.905h289.549a254.2 254.2 0 0 0-21.957-73.302H146.603zM145.49 366.51h341.488a254.2 254.2 0 0 0 22.321-73.302H218.792z"
        fill="#d80027"
      />
      <Path
        d="M256 512c69.255 0 132.075-27.512 178.159-72.189H77.841C123.925 484.488 186.745 512 256 512"
        fill="#6da544"
      />
      <Path
        d="M91.415 59.934A258 258 0 0 0 74.98 74.98L256 256 74.98 437.02a258 258 0 0 0 16.435 15.046L287.481 256z"
        fill="#000"
      />
      <Path
        d="m102.925 189.217 16.575 51.016h53.645l-43.398 31.532 16.576 51.018-43.398-31.531-43.399 31.531 16.578-51.018-43.397-31.532h53.642z"
        fill="#d80027"
      />
      <Path
        d="m148.519 260.174-43.198-15.304s-3.192-29.943-3.385-30.958c-1.466-7.723-8.253-13.564-16.403-13.564-9.22 0-16.696 7.475-16.696 16.696 0 1.529.223 3.002.608 4.41l-12.112 12.193h21.536c0 22.353-16.68 22.353-16.68 44.614l9.253 22.261h55.652l9.276-22.261h-.004a22.2 22.2 0 0 0 1.726-6.54c7.996-3.234 10.427-11.547 10.427-11.547"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Zimbabwe';
export const Zimbabwe = memo<IconProps>(Icon);
