import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M367.304 25.402C333.648 9.128 295.89 0 256 0s-77.648 9.128-111.304 25.402L122.435 256l22.261 230.598C178.352 502.872 216.11 512 256 512s77.648-9.128 111.304-25.402L389.565 256z"
        fill="#d80027"
      />
      <Path
        d="m255.998 166.957 22.1 68.019h71.528l-57.864 42.044 22.101 68.023-57.865-42.041-57.865 42.041 22.105-68.023-57.864-42.044h71.523z"
        fill="#ffda44"
      />
      <Path
        d="M144.696 25.411C59.066 66.817 0 154.506 0 256s59.066 189.183 144.696 230.589z"
        fill="#496e2d"
      />
      <Path
        d="M367.304 25.411v461.178C452.934 445.183 512 357.493 512 256S452.934 66.817 367.304 25.411"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Cameroon';
export const Cameroon = memo<IconProps>(Icon);
