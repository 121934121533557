import useAnalyticsWithBookingCart from '../use-analytics-with-booking-cart-data';
import useCustomerSupportVisibility from '../use-customer-support-visibility/use-customer-support-visibility';
import { INTERCOM_APP_ID } from '@/config/intercom';
import { useDebounce } from 'react-use';

const useInitIntercomTracking = () => {
  const { trackEvent, AnalyticsEvents } = useAnalyticsWithBookingCart();

  const isIntercomDefined = typeof window !== 'undefined' && window?.Intercom;
  const { intercomShouldBeVisible, intercomLoaded, supportContextProps } =
    useCustomerSupportVisibility();

  // Intercom caps at 20 user data updates per 30m session; debounced to prevent spamming
  useDebounce(
    () => {
      if (isIntercomDefined && intercomLoaded) {
        window.intercomSettings = {
          app_id: INTERCOM_APP_ID,
          hide_default_launcher: !intercomShouldBeVisible,
          ...supportContextProps,
        };
        window.Intercom('update', supportContextProps);

        window.Intercom('onShow', () => {
          trackEvent(AnalyticsEvents.Support.IntercomLaunched, {
            platform_source: supportContextProps.platform_source,
            chat_source: supportContextProps.chat_source,
          });
        });
      }
    },
    1000,
    [
      isIntercomDefined,
      intercomLoaded,
      intercomShouldBeVisible,
      JSON.stringify(supportContextProps),
    ],
  );
};

export default useInitIntercomTracking;
