import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="m16.1 13.3-1.45-1.45q.225-1.175-.675-2.2t-2.325-.8L10.2 7.4q.424-.2.862-.3A4.2 4.2 0 0 1 12 7q1.875 0 3.187 1.313Q16.5 9.625 16.5 11.5q0 .5-.1.938t-.3.862m3.2 3.15-1.45-1.4a11 11 0 0 0 1.687-1.588A9 9 0 0 0 20.8 11.5q-1.251-2.524-3.588-4.012T12 6q-.725 0-1.425.1a10 10 0 0 0-1.375.3L7.65 4.85A11.1 11.1 0 0 1 12 4q3.575 0 6.425 1.888T22.7 10.8q.075.125.1.313t.025.387a2 2 0 0 1-.125.7 10.9 10.9 0 0 1-3.4 4.25m-.2 5.45-3.5-3.45q-.875.274-1.763.413Q12.95 19 12 19q-3.576 0-6.425-1.887Q2.725 15.225 1.3 12.2a.8.8 0 0 1-.1-.312 3 3 0 0 1 0-.763.8.8 0 0 1 .1-.3Q1.825 9.7 2.55 8.75A13.3 13.3 0 0 1 4.15 7L2.075 4.9a.93.93 0 0 1-.275-.687q0-.413.3-.713a.95.95 0 0 1 .7-.275q.424 0 .7.275l17 17q.275.275.287.688a.93.93 0 0 1-.287.712.95.95 0 0 1-.7.275.95.95 0 0 1-.7-.275M5.55 8.4q-.726.65-1.325 1.425A9 9 0 0 0 3.2 11.5q1.25 2.524 3.587 4.012T12 17q.5 0 .975-.062.475-.063.975-.138l-.9-.95q-.276.075-.525.113A3.5 3.5 0 0 1 12 16q-1.875 0-3.188-1.312Q7.5 13.375 7.5 11.5q0-.274.037-.525.038-.25.113-.525z"
      />
    </Svg>
  );
};
Icon.displayName = 'VisibilityOff';
export const VisibilityOff = memo<IconProps>(themed(Icon));
