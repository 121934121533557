import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M25.402 144.696C9.128 178.352 0 216.109 0 256s9.128 77.648 25.402 111.304L256 389.565l230.598-22.261C502.872 333.648 512 295.891 512 256s-9.128-77.648-25.402-111.304L256 122.435z"
        fill="#000"
      />
      <Path
        d="M25.411 367.304C66.817 452.935 154.506 512 256 512c101.493 0 189.183-59.065 230.589-144.696z"
        fill="#496e2d"
      />
      <Path
        d="M25.411 144.696h461.178C445.183 59.065 357.493 0 256 0 154.506 0 66.817 59.065 25.411 144.696"
        fill="#d80027"
      />
      <Path
        d="m315.586 209.186 21.004 28.945 34.017-11.03-21.038 28.92 21.002 28.944-34.004-11.072-21.038 28.92.022-35.761-34.006-11.072 34.018-11.03z"
        fill="#f0f0f0"
      />
      <Path
        d="M258.261 328.348c-39.956 0-72.348-32.392-72.348-72.348s32.392-72.348 72.348-72.348c12.458 0 24.181 3.15 34.415 8.696-16.056-15.701-38.012-25.392-62.241-25.392-49.178 0-89.043 39.866-89.043 89.043s39.866 89.043 89.043 89.043c24.23 0 46.186-9.691 62.241-25.392-10.235 5.548-21.956 8.698-34.415 8.698"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Libya';
export const Libya = memo<IconProps>(Icon);
