import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 739 350"
      {...otherProps}
    >
      <Circle cx={709.424} cy={232.233} r={28.631} fill="#20D14F" />
      <Path
        fill="#002809"
        fillRule="evenodd"
        d="M571.667 102.231c21.88 0 40.424 12.714 45.986 37.353h-94.578c5.568-23.1 22.995-37.353 48.592-37.353m39.14 98.566c-8.571 8.591-19.625 14.274-33.97 14.274-28.468 0-47.004-12.45-52.121-34.168h139.348c1.106-8.749 1.844-15.205 1.844-21.661 0-28.485-8.867-52.031-26.61-71.4-17.373-19.37-39.921-28.861-66.897-28.861-28.466 0-52.121 9.49-70.227 28.861-18.116 18.989-27.353 42.915-27.353 71.4q-.002 43.293 27.719 71.779c18.483 18.991 42.875 28.485 73.557 28.485 27.462 0 49.105-9.764 65.895-26.667zM47.973 139.007 49.113 0H0v259.503h49.114l.943-49.971 30.257-32.033 53.675 82.004h57.609L113.84 140.501l18.967-20.403c10.759-11.391 25.264-17.737 40.742-17.737h26.223l.308-43.34-40.942.002c-22.044.003-43.141 9.165-58.463 25.39z"
        clipRule="evenodd"
      />
      <Path
        fill="#002809"
        fillRule="evenodd"
        d="M395.69 58.98h-20.573c-29.598 0-55.892 19.445-65.339 48.319l-25.602 78.246-9.767 29.843c-12.807-1.502-25.121-8.796-28.861-20.232l-17.583-53.735-26.974-82.44h-26.686l-19.482 1.606q-2.877 2.478-2.209 4.52l48.813 149.19c7.384 22.564 22.903 45.477 57.214 49.277l-28.258 86.365h51.387l74.761-229.978c3.94-12.038 17.403-17.73 29.743-17.73h9.581z"
        clipRule="evenodd"
      />
      <Path
        fill="#002809"
        fillRule="evenodd"
        d="M479.168 169.227c3.471 22.923-7.537 44.04-25.558 44.04-16.722 0-30.146-13.879-30.146-37.524v-75.48h43.26V58.978h-43.26l.003-57.012-48.068-.042v165.013c0 43.438 15.062 73.135 41.682 86.197 28.174 13.827 61.198 3.63 78.906-16.082 15.678-17.462 26.379-38.162 22.381-67.377z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
Icon.displayName = 'KyteLogo';
export const KyteLogo = memo<IconProps>(themed(Icon));
