import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M31.127 133.565h449.745C437.454 53.989 353.035 0 256 0S74.546 53.989 31.127 133.565M256 512c97.035 0 181.454-53.989 224.873-133.565H31.127C74.546 458.011 158.965 512 256 512"
        fill="#d80027"
      />
      <Path
        d="M345.043 256c0 49.178-39.866 77.913-89.043 77.913S166.957 305.178 166.957 256s39.866-89.043 89.043-89.043 89.043 39.865 89.043 89.043"
        fill="#ffda44"
      />
      <Path
        d="M345.043 256c0 49.178-39.866 89.043-89.043 89.043S166.957 305.178 166.957 256"
        fill="#0052b4"
      />
      <Path
        d="M200.348 233.739h22.261v44.522h-22.261zM289.391 233.739h22.261v44.522h-22.261zM244.87 200.348h22.261v77.913H244.87z"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'FrenchPolynesia';
export const FrenchPolynesia = memo<IconProps>(Icon);
