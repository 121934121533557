import { useQuery } from 'react-query';
import request, { RequestError, ApiResponse } from '@/utils/request';
import { getAnonymousId } from '@/utils/analytics/analytics';

export enum UniqueRentersVariants {
  On = 'on',
  Control = 'control',
}

type UniqueRentersResponse = {
  enabled: boolean;
  variant_name: UniqueRentersVariants | null;
};

const requestExperimentDetails = async () => {
  const urlIncludesTestingParam = window.location.search.includes(
    'unique_renters=true',
  );

  const { data: response } = await request<ApiResponse<UniqueRentersResponse>>({
    url: 'v2/experiments/unique_renters/assign',
  });

  return (
    (!!response.data.enabled &&
      response.data.variant_name === UniqueRentersVariants.On) ||
    urlIncludesTestingParam
  );
};

const useUniqueRentersExperiment = () => {
  const anonymousId = getAnonymousId();
  return useQuery<boolean, RequestError>(
    ['unique-renters-experiment', anonymousId],
    () => requestExperimentDetails(),
    {
      enabled: !!anonymousId,
    },
  );
};

export default useUniqueRentersExperiment;
