import { createFont } from '@tamagui/core';

export const Gilroy = createFont({
  family: 'Gilroy',
  // keys used for the objects you pass to `size`, `lineHeight`, `weight`
  // and `letterSpacing` should be consistent. The `createFont` function
  // will fill-in any missing values if `lineHeight`, `weight` or `letterSpacing`
  // are subsets of `size`
  size: {
    true: 14,
    1: 14,
  },
  lineHeight: {
    true: 20,
  },
  weight: {
    true: '600',
  },
  // (native) swap out fonts by face/style
  face: {
    700: { normal: 'GilroyBold' },
  },
});

export const HankenGrotesk = createFont({
  family: 'Hanken Grotesk',
  // keys used for the objects you pass to `size`, `lineHeight`, `weight`
  // and `letterSpacing` should be consistent. The `createFont` function
  // will fill-in any missing values if `lineHeight`, `weight` or `letterSpacing`
  // are subsets of `size`
  size: {
    true: 14,
    1: 14,
  },
  lineHeight: {
    true: 20,
  },
  weight: {
    true: '600',
  },
  // (native) swap out fonts by face/style
  face: {
    400: { normal: 'HankenGroteskRegular' },
    600: { normal: 'HankenGroteskSemiBold' },
    700: { normal: 'HankenGroteskBold' },
  },
});
