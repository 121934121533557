import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M18.8 4a1.2 1.2 0 0 0-1.2 1.2c0 6.236-3.454 4.517-4 10.46 0 0-2.486-.46-4-.46-2.929 0-5.6.496-5.6 2.114 0 1.007.84 1.97.99 2.133v.001A1.2 1.2 0 0 0 6 20h8.927c1.101 0 2.061-.75 2.328-1.817l3.51-13.125-.006-.01A.8.8 0 0 0 20 4zM8 4.464a.49.49 0 0 0-.42.238L5.963 7.394A.4.4 0 0 0 6.306 8h.855c-.086 1.238-.354 1.858-.67 2.467C6.11 11.2 5.6 12.074 5.6 13.6a.8.8 0 1 0 1.6 0c0-1.215.29-1.588.71-2.394.36-.694.76-1.639.863-3.206h.92a.4.4 0 0 0 .345-.606L8.42 4.702A.49.49 0 0 0 8 4.464m4.8 0a.49.49 0 0 0-.42.238l-1.618 2.692a.4.4 0 0 0 .344.606h.855c-.086 1.238-.354 1.858-.67 2.467-.381.732-.891 1.607-.891 3.133a.8.8 0 1 0 1.6 0c0-1.215.29-1.588.71-2.394.36-.694.76-1.639.863-3.206h.92a.4.4 0 0 0 .345-.606L13.22 4.702a.49.49 0 0 0-.42-.238"
      />
    </Svg>
  );
};
Icon.displayName = 'SeatHeater';
export const SeatHeater = memo<IconProps>(themed(Icon));
