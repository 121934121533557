import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M4.167 18.333q-.688 0-1.177-.49a1.6 1.6 0 0 1-.49-1.177v-2.75q0-1.541.438-2.969.437-1.427 1.374-2.844A2.25 2.25 0 0 1 3.01 7.25a2.4 2.4 0 0 1-.51-1.5v-.875q0-.999.865-1.677.864-.676 1.802-.573l6.583.73q.354.041.552.28t.198.553v2.479a.8.8 0 0 1-.219.552.85.85 0 0 1-.531.281l-.75.084q.229.771.542 1.416.312.646.75 1.104.228.25.302.584a.85.85 0 0 1-.094.625.84.84 0 0 1-.49.396.55.55 0 0 1-.572-.125 5.1 5.1 0 0 1-1.26-1.677 11.5 11.5 0 0 1-.761-2.178l-.917.084q.167 1.042.635 2.104.47 1.062.948 1.917.375.667.563 1.396t.187 1.479v1.958q0 .687-.49 1.177-.489.49-1.176.49zm0-13.25v.666q0 .375.27.604.271.23.646.188l5.75-.625v-1l-5.75-.625a.82.82 0 0 0-.646.187.75.75 0 0 0-.27.605m0 11.583h5v-1.958q0-.542-.136-1.063a4.1 4.1 0 0 0-.406-1Q7.979 11.52 7.5 10.322q-.48-1.197-.667-2.344l-.416.105-.542.708a8.4 8.4 0 0 0-1.27 2.427 8.5 8.5 0 0 0-.438 2.698zM15 5a.833.833 0 1 1-1.667 0A.833.833 0 0 1 15 5M17.5 5.833a.833.833 0 1 1-1.667 0 .833.833 0 0 1 1.667 0M17.5 2.916a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0"
      />
    </Svg>
  );
};
Icon.displayName = 'Clean';
export const Clean = memo<IconProps>(themed(Icon));
