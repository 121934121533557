import { useMutation } from 'react-query';
import useMe from '@/hooks/use-me';
import { Me, MeApiResponse } from '@/types/me';
import transformMe from '@/utils/data-transform/me';
import request, { ApiResponse, RequestError } from '@/utils/request';
import useMeAuthentication from '../use-me-authentication';

const requestMe = async () => {
  const { data: response } = await request<ApiResponse<MeApiResponse>>({
    url: 'v1/users/me',
  });

  return response.data;
};

const useGetMe = () => {
  const { identify } = useMe();
  const { clearUserData } = useMeAuthentication();

  return useMutation<MeApiResponse, RequestError, null>(requestMe, {
    onSuccess: (data) => {
      const me = transformMe(data) as Me;

      identify(me);
    },
    onError: (error) => {
      const status = error.response?.status;
      if (status === 401 || status === 403) {
        clearUserData();
      }
    },
  });
};

export default useGetMe;
