import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 0c141.384 0 256 114.616 256 256S397.384 512 256 512"
        fill="#d80027"
      />
      <Path
        d="M178.087 100.174V66.783h-33.391v33.391h-33.392v33.391h33.392v33.392h33.391v-33.392h33.391v-33.391z"
        fill="#acabb1"
      />
    </Svg>
  );
};
Icon.displayName = 'Malta';
export const Malta = memo<IconProps>(Icon);
