import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#ffda44" />
      <Path
        d="M256 512c84 0 158.549-40.458 205.229-102.949H50.771C97.451 471.542 172 512 256 512M512 256C512 114.616 397.384 0 256 0S0 114.616 0 256c0 30.273 5.269 59.312 14.916 86.269h482.167C506.731 315.312 512 286.273 512 256"
        fill="#0052b4"
      />
      <Path
        d="m175.222 164.182 13.813 42.512h44.704l-36.165 26.278 13.813 42.514-36.165-26.276-36.165 26.276 13.815-42.514-36.165-26.278h44.702zM98.454 119.66l8.288 25.508h26.823l-21.699 15.766 8.288 25.509-21.7-15.766-21.699 15.766 8.289-25.509-21.698-15.766h26.822z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Curacao';
export const Curacao = memo<IconProps>(Icon);
