import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M11.95 18q.525 0 .888-.363.362-.362.362-.887t-.362-.887a1.2 1.2 0 0 0-.888-.363q-.525 0-.887.363a1.2 1.2 0 0 0-.363.887q0 .525.363.887.362.363.887.363m.05 4a9.7 9.7 0 0 1-3.9-.788 10.1 10.1 0 0 1-3.175-2.137q-1.35-1.35-2.137-3.175A9.7 9.7 0 0 1 2 12q0-2.075.788-3.9a10.1 10.1 0 0 1 2.137-3.175q1.35-1.35 3.175-2.137A9.7 9.7 0 0 1 12 2q2.075 0 3.9.788a10.1 10.1 0 0 1 3.175 2.137q1.35 1.35 2.137 3.175A9.7 9.7 0 0 1 22 12a9.7 9.7 0 0 1-.788 3.9 10.1 10.1 0 0 1-2.137 3.175q-1.35 1.35-3.175 2.137A9.7 9.7 0 0 1 12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4 6.325 6.325 4 12t2.325 5.675T12 20m.1-12.3q.626 0 1.088.4t.462 1q0 .55-.338.975-.337.425-.762.8-.574.5-1.013 1.1-.437.6-.437 1.35 0 .35.263.588.262.237.612.237a.9.9 0 0 0 .638-.25q.262-.25.337-.625.1-.525.45-.937t.75-.788q.575-.55.987-1.2a2.65 2.65 0 0 0 .413-1.45q0-1.275-1.038-2.088Q13.476 6 12.1 6q-.95 0-1.812.4a2.8 2.8 0 0 0-1.313 1.225.9.9 0 0 0-.112.637.74.74 0 0 0 .337.513q.35.2.725.125a1 1 0 0 0 .625-.425q.274-.375.688-.575.412-.2.862-.2"
      />
    </Svg>
  );
};
Icon.displayName = 'Help';
export const Help = memo<IconProps>(themed(Icon));
