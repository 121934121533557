import * as React from 'react';
import { Svg, Path, Circle } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M486.598 367.304C502.872 333.649 512 295.89 512 256s-9.128-77.649-25.402-111.304L256 133.565l-230.598 11.13C9.128 178.351 0 216.11 0 256s9.128 77.649 25.402 111.304L256 378.435z"
        fill="#ffda44"
      />
      <Path
        d="M256 0C154.506 0 66.81 59.065 25.402 144.696h461.195C445.19 59.065 357.493 0 256 0"
        fill="#000"
      />
      <Path
        d="M256 512c-101.494 0-189.19-59.065-230.598-144.696h461.195C445.19 452.935 357.493 512 256 512"
        fill="#0052b4"
      />
      <Path
        d="M499.911 178.087H12.089C4.247 202.656 0 228.831 0 256s4.247 53.344 12.089 77.913H499.91C507.753 309.344 512 283.169 512 256s-4.247-53.344-12.089-77.913"
        fill="#a2001d"
      />
      <Path
        d="M89.043 244.87h333.913v22.261H89.043zM133.565 200.348h244.87v22.261h-244.87z"
        fill="#ffda44"
      />
      <Path
        d="M380.025 256S322.782 322.783 256 322.783 189.217 256 189.217 256s0-66.783 66.783-66.783S380.025 256 380.025 256"
        fill="#f0f0f0"
      />
      <Path
        d="M256 322.783c-66.783 0-124.025-66.783-124.025-66.783S189.217 189.217 256 189.217"
        fill="#000"
      />
      <Path d="M211.478 233.739h22.261v44.522h-22.261z" fill="#f0f0f0" />
      <Path d="M278.261 233.739h22.261v44.522h-22.261z" />
      <Circle cx={89.043} cy={256} r={22.261} fill="#0052b4" />
      <Circle cx={422.957} cy={256} r={22.261} fill="#0052b4" />
    </Svg>
  );
};
Icon.displayName = 'Swaziland';
export const Swaziland = memo<IconProps>(Icon);
