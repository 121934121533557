import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M367.304 25.402C333.648 9.128 295.89 0 256 0s-77.648 9.128-111.304 25.402L122.435 256l22.261 230.598C178.352 502.872 216.11 512 256 512s77.648-9.128 111.304-25.402L389.565 256z"
        fill="#d80027"
      />
      <Path
        d="M144.696 25.411C59.066 66.818 0 154.507 0 256s59.066 189.182 144.696 230.589z"
        fill="#000"
      />
      <Path
        d="M367.304 25.411v461.178C452.934 445.182 512 357.493 512 256S452.934 66.818 367.304 25.411"
        fill="#496e2d"
      />
      <Path
        d="M256 166.957c-49.178 0-89.043 39.866-89.043 89.043s39.866 89.043 89.043 89.043 89.043-39.866 89.043-89.043-39.865-89.043-89.043-89.043m0 144.695c-30.736 0-55.652-24.917-55.652-55.652s24.917-55.652 55.652-55.652 55.652 24.917 55.652 55.652-24.916 55.652-55.652 55.652"
        fill="#ffda44"
      />
      <Path
        d="M256 222.609c-12.295 0-22.261 9.966-22.261 22.261v33.391h44.522V244.87c0-12.295-9.966-22.261-22.261-22.261"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Afghanistan';
export const Afghanistan = memo<IconProps>(Icon);
