import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill="#1976D2"
        d="M22.5 17.867c0 1.178-.895 2.133-2 2.133h-17c-1.104 0-2-.955-2-2.133V6.133C1.5 4.955 2.396 4 3.5 4h17c1.105 0 2 .955 2 2.133z"
      />
      <Path
        fill="#fff"
        d="m11.128 10.25-1.057 2.342L9.02 10.25H7.672v3.363L6.171 10.25H5.033L3.5 13.74h.907l.336-.779h1.716l.341.78h1.733v-2.593l1.149 2.593h.781l1.176-2.548v2.548h.861V10.25zm-6.06 1.957.513-1.22.532 1.22zm13.874-.25 1.558-1.698h-1.108l-.997 1.082-.965-1.091H14v3.491h3.317l1.046-1.155 1.02 1.164h1.105zm-2.078 1.051h-2.006v-.69h1.92v-.662h-1.92v-.654l2.117.006.847.949z"
      />
    </Svg>
  );
};
Icon.displayName = 'AmericanExpress';
export const AmericanExpress = memo<IconProps>(themed(Icon));
