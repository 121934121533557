import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M12 20q-.825 0-1.412-.587A1.93 1.93 0 0 1 10 18q0-.824.588-1.413A1.93 1.93 0 0 1 12 16q.825 0 1.412.587Q14 17.176 14 18q0 .824-.588 1.413A1.93 1.93 0 0 1 12 20m0-6q-.825 0-1.412-.588A1.93 1.93 0 0 1 10 12q0-.825.588-1.412A1.93 1.93 0 0 1 12 10q.825 0 1.412.588Q14 11.175 14 12t-.588 1.412A1.93 1.93 0 0 1 12 14m0-6q-.825 0-1.412-.588A1.93 1.93 0 0 1 10 6q0-.824.588-1.412A1.93 1.93 0 0 1 12 4q.825 0 1.412.588Q14 5.175 14 6q0 .824-.588 1.412A1.93 1.93 0 0 1 12 8"
      />
    </Svg>
  );
};
Icon.displayName = 'MoreVert';
export const MoreVert = memo<IconProps>(themed(Icon));
