import React, { useEffect } from 'react';
import { Dialog } from '@drivekyte/ui';
import useUniqueRenters, {
  UniqueRentersStatusVariantEnum,
} from '@/hooks/use-unique-renters/use-unique-renters';
import useCustomerSupport from '@/hooks/use-customer-support';
import { FormProvider, useForm } from 'react-hook-form';
import useCustomerSupportVisibility from '@/hooks/use-customer-support-visibility/use-customer-support-visibility';
import useAuthentication from '@/hooks/use-authentication';
import OtpLogin from './components/otp-login';
import PhoneConfirmationForm from './components/phone-confirmation-form';
import OtpConfirmationForm from './components/otp-confirmation-form';
import useAnalytics from '@/hooks/use-analytics-with-source';
import useMeStore from '@/hooks/use-me-store';

export enum OtpContentEnum {
  Phone = 'phone',
  Otp = 'otp',
  Login = 'login',
}

export type ModalContentVariant =
  | OtpContentEnum.Phone
  | OtpContentEnum.Otp
  | OtpContentEnum.Login;

const OtpModal = () => {
  const formMethods = useForm();
  const { trackEvent, AnalyticsEvents } = useAnalytics();
  const { showSupport } = useCustomerSupport();
  const handleAuth = useAuthentication();

  const { me } = useMeStore();
  const isInternalTest = me?.email?.includes('kyte.com');

  const {
    data,
    phone,
    status,
    isVerifyingPhone,
    variant,
    requestOtp,
    countdownActive,
    requestOtpValidation,
    requestRenterVerification,
    reset,
    validateOtpCallback,
    setVariant,
    setStatus,
    setPhone,
    finishVerification,
    restartVerification,
    stopCountdown,
    startCountdown,
    togglePhoneVerification,
  } = useUniqueRenters();

  const { addIntercomSupportContextProps } = useCustomerSupportVisibility();

  const isLoading =
    requestRenterVerification.isLoading ||
    requestOtp.isLoading ||
    requestOtpValidation.isLoading;

  const handleSendOtp = (newPhone: string) => {
    trackEvent(AnalyticsEvents.UniqueRenters.PhoneVerificationOtpRequested, {
      phone_number: newPhone,
    });
    requestOtp.mutate(
      { phone_number: newPhone },
      {
        onSuccess: () => {
          startCountdown();
          setVariant(OtpContentEnum.Otp);
          togglePhoneVerification(false);
        },
        onError: () => {
          restartVerification(variant);
        },
      },
    );
  };

  const handleVerify = (value?: string) => {
    const newPhone = value || phone;

    if (!newPhone) return;

    if (isInternalTest) {
      handleSendOtp(newPhone);
      return;
    }

    requestRenterVerification.mutate(
      { phone: newPhone },
      {
        onSuccess: (response) => {
          if (response?.verified) {
            restartVerification(OtpContentEnum.Login);
            return;
          }

          handleSendOtp(newPhone);
        },
        onError: () => {
          restartVerification(variant);
        },
      },
    );
  };

  const handleResendOtp = () => {
    setStatus(undefined);
    trackEvent(AnalyticsEvents.UniqueRenters.PhoneVerificationOtpResent, {
      phone_number: phone,
    });

    requestOtp.mutate(
      {
        phone_number: phone!!,
      },
      {
        onSuccess: () => {
          setVariant(OtpContentEnum.Otp);
          startCountdown();
        },
      },
    );
  };

  const handleRequestOtpValidation = (otp: string) => {
    trackEvent(AnalyticsEvents.UniqueRenters.PhoneVerificationOtpSubmitted, {
      phone_number: phone,
    });
    requestOtpValidation.mutate(
      {
        phone_number: phone!!,
        code: otp,
      },
      {
        onSuccess: (response) => {
          validateOtpCallback();
          finishVerification(response?.token, {
            status: UniqueRentersStatusVariantEnum.Success,
            message: data?.status.verified ?? '',
          });
          stopCountdown();
        },
      },
    );
  };

  const handlePhoneSubmit = () => {
    const newPhoneValue = formMethods.getValues().phone;
    setPhone(formMethods.getValues().phone);
    setStatus(undefined);
    handleVerify(newPhoneValue);
  };

  const renderTitle = () => {
    const variants = {
      otp: data?.confirmOtp.title,
      login: data?.confirmLogin.title,
      phone: data?.confirmPhone.title,
    };

    if (!variant) return '';

    return variants[variant as string];
  };

  const renderContent = () => {
    if (!data || !variant) return null;

    const Content: {
      [variant in ModalContentVariant]: JSX.Element;
    } = {
      phone: (
        <FormProvider {...formMethods}>
          <PhoneConfirmationForm
            content={data.confirmPhone}
            verificationStatus={status}
            onSubmit={formMethods.handleSubmit(handlePhoneSubmit)}
            isLoading={isLoading}
          />
        </FormProvider>
      ),
      login: <OtpLogin data={data} onLogin={handleAuth} />,
      otp: (
        <OtpConfirmationForm
          data={data.confirmOtp}
          phone={phone!!}
          verificationStatus={status}
          isLoading={isLoading}
          countdownActive={countdownActive}
          onHelp={showSupport}
          onCancel={() => setVariant(undefined)}
          onResendOtp={handleResendOtp}
          onValidateOtp={handleRequestOtpValidation}
          stopCountdown={stopCountdown}
        />
      ),
    };

    return Content[variant];
  };

  useEffect(() => {
    if (!!variant) {
      addIntercomSupportContextProps('otp-verification');
    }
  }, [variant]);

  useEffect(() => {
    if (isVerifyingPhone) {
      handleVerify();
    }
  }, [isVerifyingPhone]);

  return (
    <Dialog variant="small" visible={!!variant} onRequestToClose={reset}>
      <Dialog.Content>
        <Dialog.Title>{renderTitle()}</Dialog.Title>
        <Dialog.Body>{renderContent()}</Dialog.Body>
      </Dialog.Content>
    </Dialog>
  );
};

export default OtpModal;
