import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M4 20q-.824 0-1.412-.587A1.93 1.93 0 0 1 2 18V6q0-.824.587-1.412A1.93 1.93 0 0 1 4 4h16q.824 0 1.413.588Q22 5.175 22 6v12q0 .824-.587 1.413A1.93 1.93 0 0 1 20 20zm0-2h16V6H4zm7.25-3L9.4 12.525a.48.48 0 0 0-.4-.2q-.25 0-.4.2l-2 2.675a.44.44 0 0 0-.05.525Q6.7 16 7 16h10q.3 0 .45-.275a.44.44 0 0 0-.05-.525l-2.75-3.675a.48.48 0 0 0-.4-.2q-.25 0-.4.2z"
      />
    </Svg>
  );
};
Icon.displayName = 'Image';
export const Image = memo<IconProps>(themed(Icon));
