import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M8.3 19.3a.95.95 0 0 1-.275-.7q0-.425.275-.7l1.1-1.1q-3.2-.425-5.3-1.75T2 12q0-2.075 2.888-3.537Q7.775 7 12 7t7.113 1.463T22 12q0 1.35-1.3 2.475t-3.475 1.8q-.499.15-.863-.125-.362-.274-.362-.825 0-.3.212-.587.213-.288.513-.388 1.575-.5 2.425-1.175T20 12q0-.8-2.137-1.9T12 9q-3.724 0-5.862 1.1T4 12q0 .6 1.275 1.438Q6.55 14.275 8.9 14.7l-.6-.6a.95.95 0 0 1-.275-.7q0-.425.275-.7a.95.95 0 0 1 .7-.275q.425 0 .7.275l2.6 2.6q.15.15.212.325.063.175.063.375t-.062.375a.9.9 0 0 1-.213.325l-2.6 2.6a.95.95 0 0 1-.7.275.95.95 0 0 1-.7-.275"
      />
    </Svg>
  );
};
Icon.displayName = 'Return';
export const Return = memo<IconProps>(themed(Icon));
