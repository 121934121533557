import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M512 256c0-101.494-59.065-189.19-144.696-230.598v461.195C452.935 445.19 512 357.494 512 256M0 256c0 101.494 59.065 189.19 144.696 230.598V25.402C59.065 66.81 0 154.506 0 256M300.522 289.391l44.521-22.261-22.26-11.13v-22.261L278.261 256l22.261-44.522h-22.261L256 178.087l-22.261 33.391h-22.261L233.739 256l-44.522-22.261V256l-22.26 11.13 44.521 22.261-11.13 22.261h44.522v33.391h22.26v-33.391h44.522z"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'Canada';
export const Canada = memo<IconProps>(Icon);
