import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#d80027" />
      <Path
        d="m381.197 256-51.155 24.062 27.241 49.547-55.553-10.629-7.036 56.116-38.695-41.27-38.692 41.27-7.039-56.116-55.548 10.627 27.24-49.547L130.803 256l51.157-24.061-27.24-49.548 55.546 10.63 7.043-56.117 38.69 41.271 38.697-41.271 7.034 56.117 55.555-10.63-27.242 49.551z"
        fill="#ffda44"
      />
      <Circle cx={256} cy={256} r={77.913} fill="#d80027" />
      <Path
        d="M217.043 256c-1.877 0-3.73.096-5.559.278a44.33 44.33 0 0 0 10.419 28.341 78.1 78.1 0 0 1 15.053-24.922A55.4 55.4 0 0 0 217.043 256M241.025 297.933c4.68 1.672 9.719 2.589 14.975 2.589s10.295-.917 14.975-2.589c-2.551-10.012-7.818-18.95-14.975-25.974-7.157 7.023-12.424 15.962-14.975 25.974M294.555 233.746c-7.698-13.306-22.075-22.268-38.555-22.268s-30.857 8.961-38.555 22.268A77.4 77.4 0 0 1 256 244.222a77.4 77.4 0 0 1 38.555-10.476M275.042 259.698a78.1 78.1 0 0 1 15.053 24.922 44.33 44.33 0 0 0 10.419-28.341 56 56 0 0 0-5.559-.278 55.3 55.3 0 0 0-19.913 3.697"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Kyrgyzstan';
export const Kyrgyzstan = memo<IconProps>(Icon);
