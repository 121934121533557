import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M256 0c-23.106 0-45.49 3.08-66.783 8.819L166.957 256l22.261 247.181C210.51 508.92 232.894 512 256 512c141.384 0 256-114.616 256-256S397.384 0 256 0"
        fill="#ffda44"
      />
      <Path
        d="m166.957 256 22.261 247.181C210.51 508.92 232.894 512 256 512c141.384 0 256-114.616 256-256z"
        fill="#6da544"
      />
      <Path
        d="M0 256c0 97.035 53.99 181.455 133.565 224.873V31.127C53.99 74.545 0 158.965 0 256"
        fill="#d80027"
      />
      <Path
        d="M0 256c0 118.279 80.221 217.805 189.217 247.181V8.819C80.221 38.195 0 137.721 0 256"
        fill="#d80027"
      />
      <Path d="m96.736 189.217 16.575 51.016h53.646l-43.398 31.532 16.574 51.018-43.397-31.531-43.399 31.531 16.578-51.018-43.398-31.532H80.16z" />
    </Svg>
  );
};
Icon.displayName = 'GuineaBissau';
export const GuineaBissau = memo<IconProps>(Icon);
