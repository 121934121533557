import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M5 22q-.824 0-1.412-.587A1.93 1.93 0 0 1 3 20V6q0-.824.587-1.412A1.93 1.93 0 0 1 5 4h1V3q0-.424.287-.712A.97.97 0 0 1 7 2q.424 0 .713.288Q8 2.575 8 3v1h8V3q0-.424.288-.712A.97.97 0 0 1 17 2q.424 0 .712.288Q18 2.575 18 3v1h1q.824 0 1.413.588Q21 5.175 21 6v14q0 .824-.587 1.413A1.93 1.93 0 0 1 19 22zm0-2h14V10H5zM5 8h14V6H5z"
      />
    </Svg>
  );
};
Icon.displayName = 'Calendar';
export const Calendar = memo<IconProps>(themed(Icon));
