import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color = '#FFFFFF', size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill={color}
      viewBox="0 0 11 10"
      {...otherProps}
    >
      <Path
        fill={color}
        d="M5.087 10a1 1 0 0 1-.712-.3L.8 6.125a.9.9 0 0 1-.219-.331 1.03 1.03 0 0 1 0-.744.9.9 0 0 1 .219-.337L5.2.3a1.04 1.04 0 0 1 .712-.3H9.5q.412 0 .706.294A.96.96 0 0 1 10.5 1v3.588a.99.99 0 0 1-.287.7L5.8 9.7a1 1 0 0 1-.713.3M8.25 3a.72.72 0 0 0 .531-.219A.72.72 0 0 0 9 2.25a.72.72 0 0 0-.219-.531.72.72 0 0 0-.531-.219.72.72 0 0 0-.531.219.72.72 0 0 0-.22.531q.001.312.22.531a.72.72 0 0 0 .53.219"
      />
    </Svg>
  );
};
Icon.displayName = 'ShoppingTag';
export const ShoppingTag = memo<IconProps>(themed(Icon));
