import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M509.833 222.609H289.392V2.167C278.461.744 267.317 0 256 0s-22.461.744-33.391 2.167v220.441H2.167A259 259 0 0 0 0 256c0 11.319.744 22.461 2.167 33.391h220.441v220.442a258.35 258.35 0 0 0 66.783 0V289.392h220.442A259 259 0 0 0 512 256c0-11.319-.744-22.461-2.167-33.391"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'England';
export const England = memo<IconProps>(Icon);
