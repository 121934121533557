import * as React from 'react';
import { Svg, Path, Circle } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M486.598 144.696C445.19 59.065 357.494 0 256 0S66.81 59.065 25.402 144.696L256 166.957zM25.402 367.304C66.81 452.935 154.506 512 256 512s189.19-59.065 230.598-144.696L256 345.043z"
        fill="#d80027"
      />
      <Path
        d="M486.598 144.696H25.402C9.128 178.351 0 216.109 0 256s9.128 77.649 25.402 111.304h461.195C502.873 333.649 512 295.891 512 256s-9.127-77.649-25.402-111.304"
        fill="#0052b4"
      />
      <Circle cx={256} cy={256} r={89.043} fill="#f0f0f0" />
    </Svg>
  );
};
Icon.displayName = 'Laos';
export const Laos = memo<IconProps>(Icon);
