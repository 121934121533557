import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M12.089 178.086C4.247 202.655 0 228.828.001 255.999 0 283.168 4.247 309.342 12.09 333.912l243.911 11.132 243.91-11.132C507.752 309.342 512 283.167 512 256c0-27.17-4.247-53.345-12.089-77.914L256 166.956z"
        fill="#f0f0f0"
      />
      <Path
        d="M512 256c0-15.187-1.33-30.063-3.867-44.523H3.868C1.33 225.937 0 240.811 0 255.999s1.331 30.062 3.868 44.522h504.265A257.6 257.6 0 0 0 512 256"
        fill="#000"
      />
      <Path
        d="M256.001 511.999c114.216 0 210.946-74.803 243.91-178.088H12.09c32.965 103.284 129.696 178.086 243.911 178.088M256.001.001C141.786.001 45.056 74.804 12.089 178.086l487.82.001C466.946 74.804 370.215-.001 256.001.001"
        fill="#338af3"
      />
    </Svg>
  );
};
Icon.displayName = 'Botswana';
export const Botswana = memo<IconProps>(Icon);
