import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M480.873 133.565c-33.08-60.629-89.963-106.398-158.09-124.76l-11.13 124.76zM31.127 133.565h169.22l-11.13-124.76C121.09 27.167 64.208 72.937 31.127 133.565"
        fill="#0052b4"
      />
      <Path
        d="M311.652 256v122.435h169.22C500.717 342.064 512 300.35 512 256zM200.348 256H0c0 44.35 11.283 86.064 31.127 122.435h169.22V256z"
        fill="#6da544"
      />
      <Path
        d="M31.127 378.435c33.081 60.629 89.963 106.398 158.09 124.76l11.13-109.109zM322.783 503.195c68.127-18.362 125.01-64.131 158.09-124.76l-169.22 15.649zM311.652 394.084l169.221-15.649H311.652zM200.348 378.435H31.127l169.221 15.649z"
        fill="#ffda44"
      />
      <Path
        d="M256 0c-23.107 0-45.489 3.08-66.783 8.819v494.363C210.511 508.92 232.893 512 256 512s45.489-3.08 66.783-8.819V8.819C301.489 3.08 279.107 0 256 0"
        fill="#d80027"
      />
      <Path
        d="m137.698 55.652 6.906 21.257h22.353l-18.082 13.138 6.907 21.257-18.084-13.137-18.083 13.137 6.909-21.257-18.082-13.138h22.351z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'CentralAfricanRepublic';
export const CentralAfricanRepublic = memo<IconProps>(Icon);
