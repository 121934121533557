import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#ffda44" />
      <Path
        d="M256 512c77.591 0 147.114-34.524 194.061-89.043H61.939C108.886 477.476 178.409 512 256 512M512 256C512 114.616 397.384 0 256 0S0 114.616 0 256c0 23.107 3.08 45.489 8.819 66.783h494.363C508.92 301.489 512 279.107 512 256M20.349 356.174a255 255 0 0 0 17.225 33.391h436.852a255.6 255.6 0 0 0 17.227-33.391z"
        fill="#338af3"
      />
      <Path
        d="m117.317 161.463-50.016-22.074 50.016-22.074L139.39 67.3l22.073 50.015 50.015 22.074-50.015 22.074-22.073 50.015z"
        fill="#f0f0f0"
      />
      <Path
        d="m139.39 94.867 13.631 30.889 30.89 13.633-30.89 13.632-13.631 30.889-13.633-30.889-30.889-13.632 30.889-13.633z"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'Aruba';
export const Aruba = memo<IconProps>(Icon);
