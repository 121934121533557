import { useDateFormatter, useDateTransformer } from '@drivekyte/date-utils';
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from '@/constants/booking-flow';
import { BookingCart, QueryBookingCart } from '@/types/booking-cart';
import useMe from '../use-me';
import usePush from '../use-push';
import useQuery from '../use-query';
import { PaymentMethodEnum } from '@/types/card';

type UseBookingCartType = BookingCart & {
  toQuery: () => QueryBookingCart;
  updateBookingCart: (nextValues: Partial<QueryBookingCart>) => void;
};

const useBookingCart = (): UseBookingCartType => {
  const { me } = useMe();
  const { sanitizeDate } = useDateTransformer();
  const { formatISOWithoutTimezone } = useDateFormatter();
  const query = useQuery();
  const push = usePush();

  const startDateQuery = query('start_date', 'string') as string;
  const endDateQuery = query('end_date', 'string') as string;

  const startDate = startDateQuery
    ? sanitizeDate(startDateQuery)
    : DEFAULT_START_DATE;
  const endDate = endDateQuery ? sanitizeDate(endDateQuery) : DEFAULT_END_DATE;

  const bookingCart = {
    endDate,
    startDate,
    applyCredits: true,
    code: query('code') as string,
    endAddress: query('end_address') as string,
    endAddressExclusionZoneId: query('end_address_exclusion_zone_id') as string,
    endTz: query('end_timezone') as string,
    endPlace: query('end_place') as string,
    hasDamageCoverage: query('has_damage_coverage', 'boolean') as boolean,
    hasLiabilityCoverage: query('has_liability_coverage', 'boolean') as boolean,
    hasUnlimitedMileage: query('has_unlimited_mileage', 'boolean') as boolean,
    isSameDeliveryReturnLocations: query(
      'same_delivery_and_return',
      'boolean',
      'true',
    ) as boolean,
    startAddress: query('start_address') as string,
    startAddressExclusionZoneId: query(
      'start_address_exclusion_zone_id',
    ) as string,
    startPlace: query('start_place') as string,
    startTz: query('start_timezone') as string,
    vehicleClass: query('vehicle_class') as string,
    paymentCard: query('selected_card') as string,
    paymentType: query('selected_payment_type') as PaymentMethodEnum,
    quotesToken: query('quotes_token') as string,
    coverageBundle: query('coverage_bundle') as string,
    coverageProducts: query('coverage_products') as string,
    youngRenter: query('young_renter', 'string') as string,
    serviceAreaUUID: query('service_area_uuid') as string,
    serviceAreaCode: query('service_area_code') as string,
    endServiceAreaUUID: query('end_service_area_uuid') as string,
    endServiceAreaCode: query('end_service_area_code') as string,
    enableAuth0: query('enable_auth0', 'boolean') as boolean,
    isStartAddressPickupAtLot: query(
      'is_start_address_pickup_at_lot',
      'boolean',
    ) as boolean,
    isEndAddressPickupAtLot: query(
      'is_end_address_pickup_at_lot',
      'boolean',
    ) as boolean,
    isMappingStartToPickupAtLot: query(
      'is_mapping_start_to_pickup_at_lot',
      'boolean',
    ) as boolean,
    isMappingEndToPickupAtLot: query(
      'is_mapping_end_to_pickup_at_lot',
      'boolean',
    ) as boolean,
  };

  const toQuery = () => {
    const commonParams: QueryBookingCart = {
      code: bookingCart.code,
      end_address: bookingCart.endAddress,
      end_address_exclusion_zone_id: bookingCart.endAddressExclusionZoneId,
      end_date: formatISOWithoutTimezone(bookingCart.endDate),
      end_place: bookingCart.endPlace,
      end_timezone: bookingCart.endTz,
      has_damage_coverage: bookingCart.hasDamageCoverage,
      has_liability_coverage: bookingCart.hasLiabilityCoverage,
      has_unlimited_mileage: bookingCart.hasUnlimitedMileage,
      quotes_token: bookingCart.quotesToken,
      same_delivery_and_return: bookingCart.isSameDeliveryReturnLocations,
      selected_card: bookingCart.paymentCard,
      selected_payment_type: bookingCart.paymentType,
      start_address: bookingCart.startAddress,
      start_address_exclusion_zone_id: bookingCart.startAddressExclusionZoneId,
      start_date: formatISOWithoutTimezone(bookingCart.startDate),
      start_place: bookingCart.startPlace,
      start_timezone: bookingCart.startTz,
      vehicle_class: bookingCart.vehicleClass,
      coverage_bundle: bookingCart.coverageBundle,
      coverage_products: bookingCart.coverageProducts,
      service_area_uuid: bookingCart.serviceAreaUUID,
      service_area_code: bookingCart.serviceAreaCode,
      end_service_area_uuid: bookingCart.endServiceAreaUUID,
      end_service_area_code: bookingCart.endServiceAreaCode,
      ...(!!bookingCart.youngRenter && {
        young_renter: bookingCart.youngRenter === 'true',
      }),
      ...(bookingCart.isStartAddressPickupAtLot && {
        is_start_address_pickup_at_lot:
          bookingCart.isStartAddressPickupAtLot === true,
      }),
      ...(bookingCart.isEndAddressPickupAtLot && {
        is_end_address_pickup_at_lot:
          bookingCart.isEndAddressPickupAtLot === true,
      }),
      ...(bookingCart.isMappingStartToPickupAtLot && {
        is_mapping_start_to_pickup_at_lot:
          bookingCart.isMappingStartToPickupAtLot === true,
      }),
      ...(bookingCart.isMappingEndToPickupAtLot && {
        is_mapping_end_to_pickup_at_lot:
          bookingCart.isMappingEndToPickupAtLot === true,
      }),
      enable_auth0: bookingCart.enableAuth0,
    };

    if (me && me.youngRenter) {
      commonParams.young_renter = me.youngRenter;
    }

    return commonParams;
  };

  const updateBookingCart = (nextValues: Partial<QueryBookingCart>) =>
    push(nextValues);

  return {
    ...bookingCart,
    toQuery,
    updateBookingCart,
  };
};

export default useBookingCart;
