import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 0C165.23 0 85.5 47.243 40.044 118.479l347.159 357.37C461.946 431.147 512 349.423 512 256 512 114.616 397.384 0 256 0"
        fill="#ffda44"
      />
      <Path
        d="M512 256c0-66.422-25.298-126.93-66.783-172.428v344.857C486.702 382.93 512 322.422 512 256M319.789 481.873l26.815-.017 8.272-25.509 8.302 25.499 13.45-.008a256 256 0 0 0 23.9-14.529L44.689 111.471a256 256 0 0 0-16.021 26.75l3.162 9.712 26.815-.017-21.684 15.775 8.302 25.499-21.704-15.748-12.945 9.416C3.713 206.034 0 230.583 0 256c0 141.384 114.616 256 256 256 29.892 0 58.583-5.132 85.25-14.552zM57.099 244.87l8.27-25.508-21.702-15.75 26.815-.017 8.271-25.509 8.302 25.499 26.815-.017-21.685 15.775 8.302 25.499-21.704-15.748zm55.225 55.652 8.27-25.508-21.702-15.75 26.815-.017 8.271-25.509 8.302 25.499 26.815-.017-21.684 15.775 8.302 25.499-21.704-15.748zm55.224 55.652 8.27-25.508-21.702-15.75 26.815-.017 8.271-25.509 8.302 25.499 26.815-.017-21.684 15.775 8.302 25.499-21.704-15.75zm76.909 39.877-21.685 15.775 8.27-25.508-21.702-15.75 26.815-.017 8.272-25.509 8.302 25.499 26.815-.017-21.684 15.775 8.302 25.499zm33.54 71.427 8.27-25.508-21.702-15.75 26.815-.017 8.271-25.509 8.302 25.499 26.815-.017-21.684 15.775 8.302 25.499-21.704-15.75z"
        fill="#0052b4"
      />
    </Svg>
  );
};
Icon.displayName = 'BosniaAndHerzegovina';
export const BosniaAndHerzegovina = memo<IconProps>(Icon);
