export const getFbcAndFbp = () => {
  if (typeof window === 'undefined') {
    return { fbc: '', fbp: '' };
  }

  const getFbc = /_fbc=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie);
  const getFbp = /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie);

  const urlParams = new URLSearchParams(window.location.search);
  const fbclid = urlParams.get('fbclid') || '';

  const fbc = getFbc && getFbc[1] ? getFbc[1] : fbclid;
  const fbp = getFbp && getFbp[1] ? getFbp[1] : '';

  return { fbc, fbp };
};
