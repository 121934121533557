import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="m138.371 356.174-63.382 80.837C121.313 483.341 185.306 512 256 512c105.83 0 196.644-64.229 235.631-155.826z"
        fill="#ffda44"
      />
      <Path
        d="M492.029 156.753C453.26 64.662 362.188 0 256 0 185.306 0 121.313 28.659 74.989 74.989l64.11 81.764z"
        fill="#496e2d"
      />
      <Path
        d="M55.652 188.29v134.492h447.53C508.924 301.491 512 279.107 512 256c0-23.442-3.158-46.142-9.061-67.71z"
        fill="#000"
      />
      <Path
        d="M74.98 74.98c-99.974 99.974-99.974 262.065 0 362.04L256 256z"
        fill="#a2001d"
      />
      <Path
        d="m103.61 189.217 16.575 51.016h53.646l-43.398 31.532 16.574 51.018-43.397-31.531-43.399 31.531 16.578-51.018-43.398-31.532h53.642z"
        fill="#ffda44"
      />
      <Path d="M55.107 256h97.024v44.522H55.107z" fill="#f0f0f0" />
      <Path d="m170.5 204.959-15.741-15.742-51.148 51.148-51.148-51.148-15.741 15.742 51.193 51.102-51.193 51.194 15.741 15.528 51.148-51.056 51.148 51.056 15.741-15.528-51.193-51.194z" />
    </Svg>
  );
};
Icon.displayName = 'Mozambique';
export const Mozambique = memo<IconProps>(Icon);
