import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 512c141.384 0 256-114.616 256-256 0-31.314-5.632-61.311-15.923-89.043H15.923C5.633 194.689 0 224.686 0 256c0 141.384 114.616 256 256 256"
        fill="#0052b4"
      />
      <Path
        d="M256 512c110.071 0 203.906-69.472 240.077-166.957H15.923C52.094 442.528 145.929 512 256 512"
        fill="#d80027"
      />
      <Path
        d="M66.198 144.696v127.706c0 72.644 94.901 94.903 94.901 94.903s94.9-22.259 94.9-94.903V144.696z"
        fill="#f0f0f0"
      />
      <Path
        d="M88.459 144.696v127.706c0 8.528 1.894 16.582 5.646 24.133h133.987c3.752-7.55 5.646-15.605 5.646-24.133V144.696z"
        fill="#d80027"
      />
      <Path
        d="M205.62 233.739h-33.39v-22.261h22.261v-22.261H172.23v-22.26h-22.261v22.26h-22.26v22.261h22.26v22.261h-33.392V256h33.392v22.261h22.261V256h33.39z"
        fill="#f0f0f0"
      />
      <Path
        d="M124.471 327.61c14.345 9.015 29.083 14.249 36.628 16.577 7.545-2.327 22.283-7.562 36.628-16.577 14.466-9.092 24.607-19.49 30.365-31.075a38.78 38.78 0 0 0-22.471-7.143c-3.048 0-6.009.361-8.856 1.024-6.033-13.708-19.728-23.285-35.665-23.285s-29.633 9.577-35.665 23.285a39 39 0 0 0-8.856-1.024c-8.371 0-16.12 2.65-22.471 7.143 5.755 11.584 15.896 21.982 30.363 31.075"
        fill="#0052b4"
      />
    </Svg>
  );
};
Icon.displayName = 'Slovakia';
export const Slovakia = memo<IconProps>(Icon);
