import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="m8.068 19.78-1.035-3.864-.966.259a1.93 1.93 0 0 1-1.516-.202 1.93 1.93 0 0 1-.933-1.213L3.1 12.83a1.93 1.93 0 0 1 .202-1.517q.415-.72 1.212-.933l3.864-1.035 2.813-2.928q.405-.42.978-.262t.721.713l2.188 8.162q.15.555-.27.977-.416.424-.977.262l-3.9-1.129-.966.26L10 19.261zm4.618-5.948-1.268-4.733-1.991 2.034-4.395 1.178.517 1.932 4.395-1.178z"
      />
      <Path
        fill={`${color}`}
        fillRule="evenodd"
        d="M18.273 4.751a.75.75 0 0 1 0 1.061l-2.121 2.121a.75.75 0 1 1-1.06-1.06l2.12-2.122a.75.75 0 0 1 1.061 0m-1.633 5.485a.75.75 0 0 1 .53-.919l2.898-.776a.75.75 0 1 1 .388 1.449l-2.898.776a.75.75 0 0 1-.918-.53m-.19 2.768a.75.75 0 0 1 .919-.53l2.897.777a.75.75 0 1 1-.388 1.448l-2.898-.776a.75.75 0 0 1-.53-.919"
        clipRule="evenodd"
      />
    </Svg>
  );
};
Icon.displayName = 'Announcement';
export const Announcement = memo<IconProps>(themed(Icon));
