import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M0 256c0 97.035 53.99 181.455 133.565 224.873V31.127C53.99 74.545 0 158.965 0 256"
        fill="#d80027"
      />
      <Path
        d="M0 256c0 118.279 80.221 217.805 189.217 247.181V8.819C80.221 38.195 0 137.721 0 256"
        fill="#0052b4"
      />
      <Path
        d="M91.826 89.043v41.739c0 20.747 16.818 37.565 37.565 37.565s37.565-16.818 37.565-37.565V89.043z"
        fill="#d80027"
      />
      <Path
        d="M129.391 146.087c-8.439 0-15.304-6.865-15.304-15.304v-19.478h30.609v19.478c0 8.439-6.866 15.304-15.305 15.304"
        fill="#f0f0f0"
      />
      <Path
        d="M278.261 267.13h-66.783c0-12.294-9.967-22.261-22.261-22.261s-22.261 9.967-22.261 22.261h-66.783c0 12.295 10.709 22.261 23.002 22.261h-.741c0 12.295 9.965 22.261 22.261 22.261 0 12.295 9.965 22.261 22.261 22.261h44.522c12.294 0 22.261-9.966 22.261-22.261 12.294 0 22.261-9.966 22.261-22.261h-.741c12.293 0 23.002-9.966 23.002-22.261M287.409 231.507l-5.844 9.357 7.089 8.448-10.704-2.665-5.845 9.353-.771-11.005-10.704-2.669 10.231-4.132-.77-11.005 7.091 8.452zM253.306 202.892l-2.291 10.791 9.553 5.515-10.972 1.158-2.293 10.788-4.488-10.078-10.971 1.152 8.199-7.382-4.486-10.076 9.552 5.515zM211.476 187.668l1.535 10.925 10.863 1.915-9.914 4.836 1.535 10.925-7.662-7.936-9.916 4.836 5.179-9.741-7.663-7.934 10.865 1.914zM166.959 187.668l5.18 9.74 10.863-1.915-7.663 7.937 5.179 9.74-9.914-4.837-7.664 7.934 1.536-10.923-9.915-4.835 10.863-1.916zM125.128 202.892l8.198 7.383 9.552-5.515-4.484 10.08 8.195 7.379-10.969-1.153-4.489 10.078-2.29-10.79-10.972-1.152 9.552-5.518zM91.026 231.507l10.231 4.134 7.087-8.451-.766 11.007 10.227 4.133-10.705 2.666-.772 11.004-5.841-9.353-10.704 2.667 7.091-8.45z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'AzoresIslands';
export const AzoresIslands = memo<IconProps>(Icon);
