import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M428.809 67.137C383.269 25.445 322.608 0 256 0c-10.904 0-21.649.687-32.195 2.01L100.174 144.696 8.101 320.131c5.948 23.061 15.042 44.855 26.775 64.917L256 256z"
        fill="#ffda44"
      />
      <Path
        d="m469.131 397.838-358.168 69.12C152.192 495.359 202.15 512 256 512c88.921 0 167.24-45.343 213.131-114.162"
        fill="#6da544"
      />
      <Path
        d="M428.845 67.171 35.022 385.296a257 257 0 0 0 36.681 48.368L512 256c0-74.759-32.051-142.027-83.155-188.829"
        fill="#d80027"
      />
      <Path
        d="M0 256c0 22.147 2.814 43.636 8.101 64.131L223.805 2.01C97.618 17.844 0 125.52 0 256"
        fill="#0052b4"
      />
    </Svg>
  );
};
Icon.displayName = 'Seychelles';
export const Seychelles = memo<IconProps>(Icon);
