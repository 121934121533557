import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M512 256c0 141.384-114.616 256-256 256S0 397.384 0 256C0 256.061 256 .028 256 0c141.384 0 256 114.616 256 256"
        fill="#0052b4"
      />
      <Path d="M256 0h-.043zM255.315 256H256v-.685z" fill="#f0f0f0" />
      <Path
        d="M256 133.565V0h-.043C114.591.024 0 114.629 0 256h133.565v-75.211L208.776 256h46.54l.685-.685v-46.537l-75.213-75.213z"
        fill="#f0f0f0"
      />
      <Path
        d="M129.515 33.391a257.2 257.2 0 0 0-96.124 96.124V256h66.783V100.174H256V33.391z"
        fill="#d80027"
      />
      <Path
        d="m256 224.519-90.955-90.953h-31.48v.002L255.998 256H256z"
        fill="#d80027"
      />
      <Path
        d="M289.391 133.565v122.434c0 59.64 77.913 77.914 77.913 77.914s77.913-18.274 77.913-77.914V133.565z"
        fill="#ffda44"
      />
      <Path
        d="M356.174 178.087c0 12.295-9.966 44.522-22.261 44.522s-22.261-32.227-22.261-44.522 22.261-22.261 22.261-22.261 22.261 9.966 22.261 22.261"
        fill="#ff9811"
      />
      <Path
        d="M415.245 202.332c3.592-8.765 6.386-21.577 6.386-27.955 0-10.246-13.357-18.551-13.357-18.551s-13.357 8.306-13.357 18.551c0 6.378 2.794 19.19 6.386 27.955l-7.711 17.41a38.9 38.9 0 0 0 14.681 2.866 38.9 38.9 0 0 0 14.681-2.866z"
        fill="#a2001d"
      />
      <Path
        d="M350.609 256s-11.13 22.261-11.13 44.522h55.652C395.13 278.261 384 256 384 256l-16.696-11.13z"
        fill="#6da544"
      />
      <Path
        d="M384 256v-5.565c0-9.22-7.475-16.696-16.696-16.696-9.22 0-16.696 7.475-16.696 16.696V256z"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'TurksAndCaicos';
export const TurksAndCaicos = memo<IconProps>(Icon);
