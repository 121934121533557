import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#496e2d" />
      <Path
        d="M356.174 256H256v256c29.924 0 58.641-5.151 85.333-14.586z"
        fill="#d80027"
      />
      <Path
        d="M426.666 446.793C479.034 399.919 512 331.813 512 256H411.826z"
        fill="#ff9811"
      />
      <Path
        d="M341.333 256v241.414c31.797-11.241 60.711-28.58 85.334-50.62V256z"
        fill="#000"
      />
      <Path
        d="M473.043 166.957H406.26c0-12.294-9.967-22.261-22.261-22.261s-22.261 9.967-22.261 22.261h-66.783c0 12.295 10.709 22.261 23.002 22.261h-.741c0 12.295 9.965 22.261 22.261 22.261 0 12.295 9.965 22.261 22.261 22.261h44.522c12.294 0 22.261-9.966 22.261-22.261 12.294 0 22.261-9.966 22.261-22.261h-.741c12.294-.001 23.002-9.967 23.002-22.261"
        fill="#ff9811"
      />
    </Svg>
  );
};
Icon.displayName = 'Zambia';
export const Zambia = memo<IconProps>(Icon);
