import * as React from 'react';
import { Svg, Path, Circle } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M0 256c0 31.316 5.633 61.312 15.924 89.046L256 367.304l240.076-22.259C506.368 317.312 512 287.315 512 256c0-30.885-5.471-60.492-15.494-87.905L256 144.696l-240.506 23.4C5.471 195.508 0 225.115 0 256"
        fill="#ffda44"
      />
      <Path
        d="M256 512c110.071 0 203.906-69.472 240.077-166.957H15.923C52.094 442.528 145.929 512 256 512"
        fill="#d80027"
      />
      <Path
        d="M256 0C145.929 0 52.094 69.472 15.923 166.957h480.155C459.906 69.472 366.071 0 256 0"
        fill="#6da544"
      />
      <Circle cx={256} cy={256} r={122.435} fill="#0052b4" />
      <Path
        d="m255.974 161.191 22.102 68.022h71.637l-57.978 42.041 22.276 68.224-58.037-42.241-57.948 42.163 22.186-68.146-57.925-42.041h71.585z"
        fill="#ffda44"
      />
      <Path
        d="m344.124 272.93-70.116-22.784 43.333-59.64-18.008-13.086-43.335 59.644-43.331-59.643-18.01 13.084 43.332 59.645-70.113 22.779 6.878 21.173 70.116-22.781v73.722h22.26V271.32l70.113 22.782z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Ethiopia';
export const Ethiopia = memo<IconProps>(Icon);
