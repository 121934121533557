import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#338af3" />
      <Path d="M161.391 345.043h189.218L256 122.435z" fill="#f3f3f3" />
      <Path d="M194.271 322.783 256 182.374l61.729 140.409z" fill="#333" />
      <Path d="M161.391 345.043h189.218L256 256z" fill="#ffda44" />
    </Svg>
  );
};
Icon.displayName = 'StLucia';
export const StLucia = memo<IconProps>(Icon);
