import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M0 256c0 44.35 11.283 86.064 31.127 122.435L256 400.696l224.873-22.261C500.717 342.064 512 300.35 512 256s-11.283-86.064-31.127-122.435L256 111.304 31.127 133.565C11.283 169.936 0 211.65 0 256"
        fill="#d80027"
      />
      <Path
        d="M31.127 133.565h449.745C437.454 53.989 353.035 0 256 0S74.546 53.989 31.127 133.565M256 512c97.035 0 181.454-53.989 224.873-133.565H31.127C74.546 458.011 158.965 512 256 512"
        fill="#0052b4"
      />
      <Path
        d="M345.043 306.087v-33.391h-22.26v-44.522l-22.261-22.261-22.261 22.261v-44.522L256 161.391l-22.261 22.261v44.522l-22.261-22.261-22.261 22.261v44.522h-22.26v33.391h-22.261v33.391h222.608v-33.391z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Cambodia';
export const Cambodia = memo<IconProps>(Icon);
