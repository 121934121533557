export const EMAIL_REGEX =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const CREDIT_CARD_NUMBER_REGEX = /^(?=.*\d)[\d ]+$/;

export const CREDIT_CARD_EXPIRY_REGEX =
  /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/;

export const CREDIT_CARD_CVC_REGEX = /^[0-9]{3,4}$/;

export const CREDIT_CARD_ZIP_REGEX = /^[0-9]{5}$/;

export const CLEAN_PHONE_NUMBER_REGEX = /[^\d\+]/g;

export const CLEAN_PHONE_NUMBER_FORMAT_REGEX = /^[\d\+]+$/;

export const CLEAN_CURRENCY_REGEX = /[^0-9.-]+/g;
