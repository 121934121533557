import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 189.217h247.181a254.6 254.6 0 0 0-28.755-66.783H256zM96.643 456.348h318.713a257.3 257.3 0 0 0 59.069-66.783H37.574a257.4 257.4 0 0 0 59.069 66.783M89.043 61.939a257.3 257.3 0 0 0-51.469 60.496h51.469z"
        fill="#338af3"
      />
      <Path
        d="M256 256v-66.783H155.826V256H89.043v-66.783H8.819C3.08 210.511 0 232.893 0 256s3.08 45.489 8.819 66.783h494.363C508.92 301.489 512 279.107 512 256zM256 0c-35.549 0-69.404 7.253-100.174 20.348v102.087H256V55.652h159.357C371.626 20.824 316.249 0 256 0"
        fill="#338af3"
      />
    </Svg>
  );
};
Icon.displayName = 'Greece';
export const Greece = memo<IconProps>(Icon);
