import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#338af3" />
      <Path
        d="m255.999 111.304 11.05 34.012h35.764l-28.932 21.021 11.05 34.011-28.932-21.02-28.933 21.02 11.053-34.011-28.933-21.021h35.763zM111.304 256.002l34.012-11.052v-35.764l21.021 28.934 34.011-11.051-21.02 28.933 21.02 28.93-34.011-11.052-21.021 28.933v-35.762zM256.001 400.696l-11.051-34.012h-35.764l28.934-21.021-11.051-34.011 28.932 21.02 28.931-21.02-11.052 34.011 28.933 21.021h-35.762zM400.696 255.999l-34.012 11.051v35.763l-21.021-28.933-34.011 11.051 21.02-28.932-21.02-28.931 34.011 11.052 21.021-28.934v35.763z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Micronesia';
export const Micronesia = memo<IconProps>(Icon);
