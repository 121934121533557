import * as React from 'react';
import { Svg, Path, Circle } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M512 256c0-87.861-44.269-165.374-111.713-211.478L256 33.391l-144.287 11.13C44.27 90.626 0 168.14 0 256c0 87.863 44.27 165.374 111.713 211.478L256 478.609l144.287-11.13C467.731 421.374 512 343.863 512 256"
        fill="#0052b4"
      />
      <Path
        d="M400.287 44.522C359.202 16.436 309.524 0 256 0S152.798 16.436 111.713 44.522zM111.713 467.478C152.797 495.564 202.477 512 256 512s103.202-16.436 144.287-44.522z"
        fill="#a2001d"
      />
      <Circle cx={256} cy={256} r={122.435} fill="#f0f0f0" />
      <Circle cx={256} cy={256} r={100.174} fill="#6da544" />
      <Circle cx={256} cy={256} r={66.783} fill="#f0f0f0" />
      <Path
        d="M256 239.304 219.826 256v27.826L256 306.087l36.174-22.261V256z"
        fill="#0052b4"
      />
      <Path d="M219.826 222.609h72.348V256h-72.348z" fill="#ffda44" />
    </Svg>
  );
};
Icon.displayName = 'Belize';
export const Belize = memo<IconProps>(Icon);
