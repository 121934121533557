import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#6da544" />
      <Path
        d="M144.696 306.087c0 18.441 14.95 33.391 33.391 33.391h100.174c0 15.368 12.458 27.826 27.826 27.826h33.391c15.368 0 27.826-12.458 27.826-27.826v-33.391zM370.087 144.696v77.913c0 12.275-9.986 22.261-22.261 22.261v33.391c30.687 0 55.652-24.966 55.652-55.652v-77.913zM130.783 222.609c0 12.275-9.986 22.261-22.261 22.261v33.391c30.687 0 55.652-24.966 55.652-55.652v-77.913h-33.391z"
        fill="#f0f0f0"
      />
      <Path
        d="M320 144.696h33.391v77.913H320zM269.913 189.217c0 3.069-2.497 5.565-5.565 5.565s-5.565-2.497-5.565-5.565v-44.522h-33.391v44.522c0 3.069-2.497 5.565-5.565 5.565s-5.565-2.497-5.565-5.565v-44.522H180.87v44.522c0 21.481 17.476 38.957 38.957 38.957a38.7 38.7 0 0 0 22.261-7.016 38.73 38.73 0 0 0 22.261 7.016c1.666 0 3.304-.117 4.915-.322-2.366 9.749-11.146 17.017-21.611 17.017v33.391c30.687 0 55.652-24.966 55.652-55.652v-77.913h-33.391z"
        fill="#f0f0f0"
      />
      <Path d="M180.87 244.87h50.087v33.391H180.87z" fill="#f0f0f0" />
    </Svg>
  );
};
Icon.displayName = 'SaudiArabia';
export const SaudiArabia = memo<IconProps>(Icon);
