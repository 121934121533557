import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M61.939 89.043a257 257 0 0 0-24.366 33.391h436.852a257 257 0 0 0-24.366-33.391zM61.939 422.957H450.06a257 257 0 0 0 24.366-33.391H37.574a257 257 0 0 0 24.365 33.391M223.24 209.186l21.004 28.945 34.017-11.03-21.037 28.92 21.002 28.944-34.005-11.072-21.037 28.92.021-35.761L189.2 255.98l34.018-11.03z"
        fill="#d80027"
      />
      <Path
        d="M165.915 328.348c-39.956 0-72.348-32.392-72.348-72.348s32.392-72.348 72.348-72.348c12.458 0 24.181 3.15 34.415 8.696-16.055-15.701-38.012-25.392-62.241-25.392-49.178 0-89.043 39.866-89.043 89.043s39.866 89.043 89.043 89.043c24.229 0 46.186-9.691 62.241-25.392-10.234 5.548-21.956 8.698-34.415 8.698"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'NorthenCyprus';
export const NorthenCyprus = memo<IconProps>(Icon);
