import { useQueryClient } from 'react-query';
import {
  IS_LOGGED_IN_COOKIE,
  IS_LOGGED_IN_COOKIE_MAX_AGE,
} from '@/config/global-constants';
import useAnalyticsWithBookingCart from '@/hooks/use-analytics-with-booking-cart-data';
import useCustomerSupport from '@/hooks/use-customer-support';
import useMeStore from '@/hooks/use-me-store';
import usePush from '@/hooks/use-push';

type AuthenticationEvent = 'signin' | 'signout';

const useMeAuthentication = () => {
  const { me, reset } = useMeStore();
  const { trackEvent, AnalyticsEvents } = useAnalyticsWithBookingCart();
  const { shutdownSupport } = useCustomerSupport();
  const push = usePush();
  const queryClient = useQueryClient();

  const clearUserData = () => {
    if (!me.uuid) {
      return;
    }

    reset();
    shutdownSupport();
    queryClient.clear();
    document.cookie = `${IS_LOGGED_IN_COOKIE}=false; max-age=${IS_LOGGED_IN_COOKIE_MAX_AGE}`;
  };

  const checkAuthenticationQuery = (event?: AuthenticationEvent) => {
    if (event === 'signin') {
      trackEvent(AnalyticsEvents.Auth0.SigninRedirectSuccess);
      push({ auth_signin: undefined });
    } else if (event === 'signout') {
      trackEvent(AnalyticsEvents.Auth0.SignoutRedirectSuccess);
      push({ auth_signout: undefined });
      clearUserData();
    }
  };

  return { clearUserData, checkAuthenticationQuery };
};

export default useMeAuthentication;
