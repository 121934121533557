import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#fcfcfc" />
      <Path
        d="M345.136 216.833c0-54.144-43.892-98.035-98.035-98.035-39.954 0-74.301 23.91-89.573 58.194l-12.091 43.835 11.13 11.13-22.261 44.522 22.261 11.13-11.13 11.13v11.13l11.13 11.13v22.261l11.13 11.13 33.391-11.13s0 11.13 11.13 22.261 22.262 33.392 22.262 33.392 33.391 11.13 55.652-22.261 44.522-44.522 44.522-44.522l-21.456-42.914c19.622-17.923 31.938-43.71 31.938-72.383"
        fill="#000"
      />
      <Path
        d="M353.126 254.322c45.229-7.629 52.763-96.195 52.763-96.195h-62.33c32.278 77.913-1.507 83.154-1.507 83.154-4.577-3.405-168.375-81.001-173.567-82.974-1.251 1.674-5.856 6.071-11.454 19.761s-6.973 26.006-6.973 26.006c2.622 1.709 176.575 62.45 181.173 62.956 9.125 5.966 20.353 23.807 14.078 76.705l59.994-16.908c.002 0-20.359-54.163-52.177-72.505"
        fill="#acabb1"
      />
    </Svg>
  );
};
Icon.displayName = 'Corsica';
export const Corsica = memo<IconProps>(Icon);
