import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M111.713 467.478C152.799 495.563 202.476 512 256 512s103.202-16.437 144.287-44.522L256 445.217zM400.287 44.522C359.202 16.437 309.524 0 256 0S152.797 16.437 111.713 44.522L256 66.783z"
        fill="#d80027"
      />
      <Path
        d="M111.713 467.478C152.799 495.563 202.476 512 256 512s103.202-16.437 144.287-44.522L256 445.217zM400.287 44.522C359.202 16.437 309.524 0 256 0S152.797 16.437 111.713 44.522L256 66.783z"
        fill="#d80027"
      />
      <Path
        d="M511.348 239.304c-6.065-79.01-54.072-155.826-111.061-194.783H111.713C48.577 87.682 5.75 158.37.538 239.304L256 256zM.538 272.696c5.212 80.935 48.038 151.622 111.174 194.783h288.574c63.139-43.16 105.963-113.848 111.175-194.783L256 256z"
        fill="#0052b4"
      />
      <Path
        d="M511.348 239.303H272.696V33.391h-33.391v205.912H.539A260 260 0 0 0 0 256c0 5.609.183 11.175.538 16.695h238.767v205.914h33.391V272.695h238.767c.354-5.52.537-11.086.537-16.695 0-5.569-.225-11.138-.652-16.697"
        fill="#d80027"
      />
      <Path
        d="m100.174 256 155.825-97.942L411.826 256l-155.827 97.943z"
        fill="#f0f0f0"
      />
      <Path
        d="M166.957 300.522h200.347l-44.521-66.783L300.522 256l-22.261-11.13L256 278.261h-44.522L189.217 256z"
        fill="#6da544"
      />
      <Path
        d="m256 189.217 5.526 17.005h17.879l-14.465 10.511 5.526 17.006L256 223.229l-14.466 10.51 5.526-17.006-14.465-10.511h17.879z"
        fill="#ffda44"
      />
      <Path
        d="M255.999 130.074 55.652 256l200.347 125.926L456.348 256zM118.4 256l137.599-86.487L393.6 256l-137.601 86.487z"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'SintEustatius';
export const SintEustatius = memo<IconProps>(Icon);
