import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M25.402 144.696C9.128 178.352 0 216.109 0 256s9.128 77.648 25.402 111.304L256 389.565l230.598-22.261C502.872 333.648 512 295.891 512 256s-9.128-77.648-25.402-111.304L256 122.435z"
        fill="#f0f0f0"
      />
      <Path
        d="M25.411 367.304C66.817 452.935 154.507 512 256 512s189.183-59.065 230.589-144.696z"
        fill="#496e2d"
      />
      <Path
        d="M25.411 144.696h461.178C445.183 59.065 357.494 0 256 0S66.817 59.065 25.411 144.696"
        fill="#000"
      />
      <Path
        d="M74.98 74.98c-99.974 99.974-99.974 262.065 0 362.04L256 256zM322.783 256c0-37.298 26.213-68.457 61.217-76.101a78 78 0 0 0-16.696-1.812c-43.031 0-77.913 34.882-77.913 77.913s34.882 77.913 77.913 77.913c5.733 0 11.315-.637 16.696-1.812-35.004-7.644-61.217-38.803-61.217-76.101"
        fill="#d80027"
      />
      <Path
        d="m387.273 205.913 11.053 34.014h35.761l-28.932 21.018 11.052 34.012-28.934-21.02-28.931 21.02 11.053-34.012-28.934-21.018h35.761z"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'WesternSahara';
export const WesternSahara = memo<IconProps>(Icon);
