import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg
      viewBox="0 0 512.001 512.001"
      width={size}
      height={size}
      {...otherProps}
    >
      <Path
        d="M256.001.001c141.384 0 256 114.616 256 256s-114.616 256-256 256-256-114.616-256-256"
        fill="#ffda44"
      />
      <Path
        d="M250.436 113.779H468.88a257.4 257.4 0 0 0-51.987-56.889H250.436zM250.436 227.557h259.985a254.6 254.6 0 0 0-13.006-56.888H250.436zM14.588 341.335h482.825a254.6 254.6 0 0 0 13.007-56.89H1.582a254.4 254.4 0 0 0 13.006 56.89M95.108 455.112h321.784a257.4 257.4 0 0 0 51.987-56.889H43.123a257.5 257.5 0 0 0 51.985 56.889"
        fill="#d80027"
      />
      <Path
        d="M256.001.001C114.616.001-.001 114.617 0 256.003c58.426-.002 114.616 0 256 0z"
        fill="#4a1f63"
      />
      <Path
        d="M211.479 133.566v22.261h-11.13v-22.261h-22.261v22.261h-11.13v-44.522h-44.522v44.522h-11.131v-22.261h-22.26v22.261H77.914v-22.261H55.652v66.782H233.74v-66.782z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'BalearicIslands';
export const BalearicIslands = memo<IconProps>(Icon);
