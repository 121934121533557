import { INTERCOM_APP_ID } from '@/config/intercom';
import SupportArticles from '@/config/support-articles';
import useDcsFlag from '@/hooks/use-dcs-flag';
import DcsFlags from '@/types/dcs-flags';
import { IntercomArticleFlag } from '@/types/flags';
import { useCallback } from 'react';
import { useChat } from 'react-live-chat-loader';
import useCustomerSupportVisibility from '../use-customer-support-visibility/use-customer-support-visibility';

const useCustomerSupport = () => {
  const [state, loadChat] = useChat();
  const { supportContextProps, intercomShouldBeVisible } =
    useCustomerSupportVisibility();

  const ensureIntercom = useCallback(
    (callback: (() => void) | (() => string)) => {
      if (!window.Intercom || state !== 'complete') {
        // Load Intercom script
        loadChat({ open: false });
      }
      callback();
    },
    [state],
  );

  const { data: intercomArticles } = useDcsFlag<IntercomArticleFlag>({
    flag: DcsFlags.EnableIntercom,
    options: {
      staleTime: 1000 * 60 * 60, // 1 hour
    },
  });

  const shutdownSupport = useCallback(() => {
    ensureIntercom(() => {
      window.intercomSettings = {
        app_id: INTERCOM_APP_ID,
        hide_default_launcher: !intercomShouldBeVisible,
      };
      window.Intercom('shutdown');
    });
  }, [intercomShouldBeVisible, ensureIntercom]);

  const showSupportArticle = useCallback(
    (article: SupportArticles | string) => {
      ensureIntercom(() => {
        window.Intercom(
          'showArticle',
          Number(intercomArticles?.[article] || article),
        );
      });
    },
    [ensureIntercom, intercomArticles],
  );

  const showNewMessage = useCallback(
    (message?: string) => {
      ensureIntercom(() => window.Intercom('showNewMessage', message));
    },
    [ensureIntercom],
  );

  const showSupportMessages = useCallback(() => {
    ensureIntercom(() => window.Intercom('showMessages'));
  }, [ensureIntercom]);

  const showSupport = useCallback(() => {
    loadChat({ open: true });
  }, [ensureIntercom]);

  return {
    showSupport,
    showSupportMessages,
    showNewMessage,
    showSupportArticle,
    shutdownSupport,
    SupportArticles,
    userProps: supportContextProps,
  };
};

export default useCustomerSupport;
