import { PortalProvider } from '@gorhom/portal';
import { TamaguiProvider, TamaguiProviderProps } from '@tamagui/core';
import { PortalProvider as PortalTMProvider } from '@tamagui/portal';
import { themeConfig } from '../config';

const KyteThemeProvider = ({
  children,
  ...rest
}: Omit<TamaguiProviderProps, 'config'>) => {
  return (
    <TamaguiProvider config={themeConfig} {...rest}>
      <PortalTMProvider>
        <PortalProvider>{children}</PortalProvider>
      </PortalTMProvider>
    </TamaguiProvider>
  );
};

export { KyteThemeProvider };

export default KyteThemeProvider;
