import i18next, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import common from './locales/common.json';
import enCAN from './locales/english-ca.json';
import enUS from './locales/english.json';
import sfPage from './locales/pages/seo-page-sf.json';

export type Language = 'enUS' | 'enCAN';

const resources = {
  enUS: { main: enUS, common, sfPage },
  enCAN: { main: enCAN, common },
};

const I18NOptions = {
  defaultNS: 'main',
  resources,
  lng: 'enUS',
  fallbackLng: 'enUS',
  interpolation: {
    escapeValue: false,
  },
};

use(initReactI18next).init(I18NOptions);

export default i18next;
