import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M8.819 322.783c23.638 87.703 92.695 156.761 180.399 180.399V322.783z"
        fill="#d80027"
      />
      <Path
        d="M189.217 8.819C101.514 32.456 32.456 101.514 8.819 189.217h180.399z"
        fill="#0052b4"
      />
      <Path
        d="M503.181 189.217C479.544 101.514 410.486 32.456 322.783 8.819v180.399z"
        fill="#d80027"
      />
      <Path
        d="M322.783 503.181c87.703-23.638 156.761-92.695 180.399-180.399H322.783z"
        fill="#0052b4"
      />
      <Path
        d="M322.783 256c0 36.884-29.899 66.783-66.783 66.783S189.217 292.884 189.217 256 256 189.217 256 189.217s66.783 29.899 66.783 66.783"
        fill="#496e2d"
      />
      <Path
        d="M189.217 256c0-36.884 29.9-66.783 66.783-66.783s66.783 29.899 66.783 66.783"
        fill="#0052b4"
      />
      <Path
        d="M218.435 222.609v41.739c0 20.747 16.818 37.565 37.565 37.565s37.565-16.818 37.565-37.565v-41.739z"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'DominicanRepublic';
export const DominicanRepublic = memo<IconProps>(Icon);
