import { useMutation } from 'react-query';
import request, { RequestError } from '@/utils/request';
import {
  UniqueRentersStatusVariantEnum,
  useUniqueRentersStore,
} from '../use-unique-renters/use-unique-renters';

type ValidateOtpPayload = {
  code: string;
  phone_number: string;
  email?: string;
  uuid?: string;
};

export type ValidateOtpResponse = {
  token: string;
  status: string;
};

const requestValidateOtp = async (payload: ValidateOtpPayload) => {
  const { data: response } = await request({
    method: 'POST',
    url: 'rest/user/phone-verification/verify-otp',
    data: payload,
  });

  return response;
};

const useRequestValidateOtp = () => {
  const { setStatus: setError } = useUniqueRentersStore();

  return useMutation<ValidateOtpResponse, RequestError, ValidateOtpPayload>(
    requestValidateOtp,
    {
      onError: (error) => {
        setError({
          status: UniqueRentersStatusVariantEnum.Error,
          message: error.response?.data.status ?? error.message,
        });
      },
    },
  );
};

export default useRequestValidateOtp;
