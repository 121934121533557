import { lightTheme } from './light';

type BaseTheme = typeof lightTheme;

const allThemes = {
  light: lightTheme,
};

type ThemeName = keyof typeof allThemes;
type Themes = {
  [key in ThemeName]: BaseTheme;
};

export const themes: Themes = allThemes;
