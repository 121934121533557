import { useMutation } from 'react-query';
import request, { RequestError } from '@/utils/request';
import {
  UniqueRentersStatusVariantEnum,
  useUniqueRentersStore,
} from '../use-unique-renters/use-unique-renters';

type StatusPayload = {
  phone: string;
  email?: string;
};

type RenterVerificationResponse = {
  verified: boolean;
};

const requestRenterVerificationStatus = async (payload: StatusPayload) => {
  const { data: response } = await request({
    url: 'rest/user/phone-verification/status',
    params: payload,
  });

  return response;
};

const useRenterVerificationStatus = () => {
  const { setStatus: setError } = useUniqueRentersStore();

  return useMutation<RenterVerificationResponse, RequestError, StatusPayload>(
    requestRenterVerificationStatus,
    {
      onError: (error) => {
        setError({
          status: UniqueRentersStatusVariantEnum.Error,
          message: error.response?.data.status ?? error.message,
        });
      },
    },
  );
};

export default useRenterVerificationStatus;
