import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 0C154.506 0 66.81 59.065 25.402 144.696h461.195C445.19 59.065 357.493 0 256 0M256 512c101.493 0 189.19-59.065 230.598-144.696H25.402C66.81 452.935 154.506 512 256 512"
        fill="#338af3"
      />
      <Path
        d="m332.515 256-31.265 14.707 16.649 30.279-33.95-6.495-4.302 34.296L256 303.563l-23.648 25.224-4.301-34.296-33.95 6.494 16.648-30.279L179.485 256l31.265-14.707-16.649-30.278 33.949 6.494 4.303-34.296L256 208.437l23.648-25.224 4.301 34.296 33.951-6.494-16.649 30.279z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Argentina';
export const Argentina = memo<IconProps>(Icon);
