import * as React from 'react';
import { Svg, Path, Circle } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M256 512c31.314 0 61.311-5.632 89.043-15.923L356.174 256l-11.13-240.077C317.311 5.633 287.314 0 256 0s-61.311 5.633-89.043 15.923L155.826 256l11.13 240.077C194.689 506.368 224.686 512 256 512"
        fill="#0052b4"
      />
      <Path
        d="M0 256c0 110.071 69.472 203.906 166.957 240.077V15.923C69.472 52.094 0 145.929 0 256M345.043 15.923v480.155C442.528 459.906 512 366.071 512 256S442.528 52.094 345.043 15.923"
        fill="#a2001d"
      />
      <Path
        d="M122.435 256h22.261v89.043h-22.261zM33.391 256h22.261v89.043H33.391z"
        fill="#ffda44"
      />
      <Circle cx={89.043} cy={289.391} r={22.261} fill="#ffda44" />
      <Circle cx={89.043} cy={211.478} r={11.13} fill="#ffda44" />
      <Path
        d="M66.783 322.783h44.522v22.261H66.783zM66.783 233.739h44.522V256H66.783zM89.043 133.565l7.884 24.26h25.508L101.8 172.819l7.878 24.26-20.635-14.994-20.634 14.994 7.878-24.26-20.635-14.994H81.16z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Mongolia';
export const Mongolia = memo<IconProps>(Icon);
