import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M0 256c0 44.35 11.283 86.064 31.127 122.435L256 400.696l224.873-22.261C500.717 342.064 512 300.35 512 256l-256-22.261z"
        fill="#ffda44"
      />
      <Path
        d="M480.873 133.565C437.454 53.989 353.035 0 256 0S74.546 53.989 31.127 133.565C11.283 169.936 0 211.65 0 256h512c0-44.35-11.283-86.064-31.127-122.435"
        fill="#338af3"
      />
      <Path
        d="M256 512c97.035 0 181.454-53.989 224.873-133.565H31.127C74.546 458.011 158.965 512 256 512"
        fill="#496e2d"
      />
      <Path
        d="m289.391 149.821 31.266 14.707-16.649 30.28 33.95-6.494 4.302 34.295 23.646-25.224 23.648 25.224 4.301-34.295 33.95 6.492-16.648-30.279 31.264-14.706-31.265-14.705 16.649-30.28-33.949 6.494-4.303-34.295-23.647 25.224-23.647-25.224-4.301 34.295-33.951-6.494 16.649 30.281z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Rwanda';
export const Rwanda = memo<IconProps>(Icon);
