import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M233.739 278.261S75.13 74.945 74.98 74.981C121.306 28.654 185.307 0 256 0c141.384 0 256 114.616 256 256z"
        fill="#338af3"
      />
      <Path
        d="M233.739 256S75.13 437.055 74.98 437.019C121.306 483.346 185.307 512 256 512c141.384 0 256-114.616 256-256z"
        fill="#6da544"
      />
      <Path
        d="M74.98 74.98c-99.974 99.974-99.974 262.065 0 362.04L256 256z"
        fill="#f0f0f0"
      />
      <Path
        d="m103.61 189.217 16.575 51.016h53.646l-43.398 31.532 16.574 51.018-43.397-31.531-43.399 31.531 16.578-51.018-43.398-31.532h53.642z"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'Djibouti';
export const Djibouti = memo<IconProps>(Icon);
