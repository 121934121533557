import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M8 22q0-.424.287-.712A.97.97 0 0 1 9 21h6q.424 0 .713.288.287.287.287.712 0 .424-.287.712A.97.97 0 0 1 15 23H9a.97.97 0 0 1-.713-.288A.97.97 0 0 1 8 22m0-3q-.824 0-1.412-.587A1.93 1.93 0 0 1 6 17V3q0-.824.588-1.412A1.93 1.93 0 0 1 8 1h8q.824 0 1.413.587Q18 2.176 18 3v14q0 .824-.587 1.413A1.93 1.93 0 0 1 16 19zm0-3v1h8v-1zm0-2h8V6H8zM8 4h8V3H8z"
      />
    </Svg>
  );
};
Icon.displayName = 'Dock';
export const Dock = memo<IconProps>(themed(Icon));
