import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        fillRule="evenodd"
        d="m2.146 17.565 9.122-15.083a1 1 0 0 1 1.706-.007l9.312 15.082a1 1 0 0 1-.85 1.525h-4.251 1.043l-6-12-6 12h1.043-4.27a1 1 0 0 1-.855-1.517m5.126 1.517 4.956-9.5 4.957 9.5 1.043 2-6-2-6 2z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
Icon.displayName = 'AndroidAuto';
export const AndroidAuto = memo<IconProps>(themed(Icon));
