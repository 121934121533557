import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#338af3" />
      <Path d="M122.435 278.261h267.13v33.391h-267.13z" fill="#818085" />
      <Path
        d="M166.957 178.087 256 189.219l89.043-11.132v-53.426l-35.617 17.809L256 89.043l-53.426 53.427-35.617-17.809z"
        fill="#ffda44"
      />
      <Path
        d="M144.696 345.045h-33.392v-61.218l11.131-16.697-11.131-16.695v-38.956h33.392zM400.696 345.045h-33.392V211.479h33.392v38.955l-11.131 16.976 11.131 16.419z"
        fill="#f0f0f0"
      />
      <Path d="M111.304 250.435h289.391v33.391H111.304z" fill="#acabb1" />
      <Path
        d="M256 389.565c-49.099 0-89.043-39.945-89.043-89.043V178.088h178.087v122.434c-.001 49.098-39.945 89.043-89.044 89.043"
        fill="#f0f0f0"
      />
      <Path
        d="M256 356.174c-30.687 0-55.652-24.966-55.652-55.652V211.48h111.304v89.042c0 30.686-24.965 55.652-55.652 55.652"
        fill="#0052b4"
      />
      <Path
        d="M166.957 178.087h33.391v33.391h-33.391zM311.652 178.087h33.391v33.391h-33.391zM311.652 250.435h33.391v33.391h-33.391zM166.957 250.435h33.391v33.391h-33.391zM239.304 178.087h33.391v33.391h-33.391zM256 356.174c-5.816 0-11.423-.9-16.696-2.562v34.361c5.412 1.032 10.988 1.593 16.696 1.593s11.284-.561 16.696-1.593v-34.361A55.5 55.5 0 0 1 256 356.174M201.092 309.556l-26.648 26.648a89.4 89.4 0 0 0 19.314 27.908l23.6-23.6c-8.422-8.137-14.289-18.897-16.266-30.956M337.556 336.206l-26.648-26.648c-1.978 12.058-7.844 22.817-16.265 30.957l23.6 23.6a89.4 89.4 0 0 0 19.313-27.909"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'Melilla';
export const Melilla = memo<IconProps>(Icon);
