import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M8 5q-.824 0-1.412-.588A1.93 1.93 0 0 1 6 3q0-.824.588-1.412A1.93 1.93 0 0 1 8 1q.825 0 1.412.587Q10 2.176 10 3q0 .824-.588 1.412A1.93 1.93 0 0 1 8 5m8.025-1.025a.72.72 0 0 1-.225-.525q0-.3.225-.525L17.95 1h2.1l-2.975 2.975a.72.72 0 0 1-.525.225.72.72 0 0 1-.525-.225M9 19q-.724 0-1.35-.275A4.6 4.6 0 0 1 6.5 18q.35-.275.725-.575T8 16.85l-1.55-2.975a3.8 3.8 0 0 1-.338-.9A4.2 4.2 0 0 1 6 12V8q0-.824.588-1.412A1.93 1.93 0 0 1 8 6h3q1.05 0 1.95-.388a5.3 5.3 0 0 0 1.6-1.062l1.4 1.4a7.1 7.1 0 0 1-2.225 1.5Q12.45 8 11 8H9.6v3.5h1.925q.425 0 .813.162.387.163.662.488l1.1 1.225q1.475-.625 2.775-1T19.15 12q.65 0 1.25.25t.6 1.025q0 1.1-1.45 2.6t-3.6 2.975q-.225.075-.462.112a3.429 3.429 0 0 1-2.163-.387A4.6 4.6 0 0 1 12 17.5a4.6 4.6 0 0 1-1.325 1.075A3.4 3.4 0 0 1 9 19m.825-3.4q.45-.275 1.2-.7t1.175-.625l-.7-.775-2.5.1zM3 23a.97.97 0 0 1-.712-.288A.97.97 0 0 1 2 22q0-.424.288-.712A.97.97 0 0 1 3 21q.65 0 1.287-.163.639-.162 1.213-.512.225-.125.5-.125t.5.125q.574.35 1.213.512a5.2 5.2 0 0 0 2.574 0q.638-.162 1.213-.512.225-.125.512-.125t.488.125q.574.35 1.213.512a5.2 5.2 0 0 0 2.575 0q.637-.162 1.212-.512a.93.93 0 0 1 .5-.125q.3 0 .5.125.575.35 1.212.512Q20.35 21 21 21q.424 0 .712.288.288.287.288.712 0 .424-.288.712A.97.97 0 0 1 21 23a6.3 6.3 0 0 1-1.525-.187A6.8 6.8 0 0 1 18 22.25a6.8 6.8 0 0 1-1.475.563 6.26 6.26 0 0 1-3.05 0A6.8 6.8 0 0 1 12 22.25a6.8 6.8 0 0 1-1.475.563 6.26 6.26 0 0 1-3.05 0A6.8 6.8 0 0 1 6 22.25a6.8 6.8 0 0 1-1.475.563Q3.775 23 3 23"
      />
    </Svg>
  );
};
Icon.displayName = 'Surfer';
export const Surfer = memo<IconProps>(themed(Icon));
