import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M2 20a.97.97 0 0 1-.712-.288A.97.97 0 0 1 1 19v-1.8q0-.85.438-1.562.437-.713 1.162-1.088a14.8 14.8 0 0 1 3.15-1.163A13.8 13.8 0 0 1 9 13q1.65 0 3.25.387 1.6.388 3.15 1.163.724.375 1.162 1.087Q17 16.35 17 17.2V19q0 .424-.288.712A.97.97 0 0 1 16 20zm16.525 0q.225-.175.35-.437a1.35 1.35 0 0 0 .125-.588V17q0-1.1-.613-2.113-.612-1.012-1.737-1.737 1.275.15 2.4.512 1.125.363 2.1.888.9.5 1.375 1.112T23 17v2q0 .424-.288.712A.97.97 0 0 1 22 20zM9 12q-1.65 0-2.825-1.175T5 8t1.175-2.825T9 4t2.825 1.175T13 8t-1.175 2.825T9 12m10-4q0 1.65-1.175 2.825T15 12q-.275 0-.7-.062a6 6 0 0 1-.7-.138 6 6 0 0 0 1.037-1.775Q15 9.05 15 8t-.363-2.025A6 6 0 0 0 13.6 4.2a3 3 0 0 1 .7-.163Q14.65 4 15 4q1.65 0 2.825 1.175T19 8M3 18h12v-.8a.973.973 0 0 0-.5-.85q-1.35-.675-2.725-1.012a11.6 11.6 0 0 0-5.55 0Q4.85 15.675 3.5 16.35a.97.97 0 0 0-.5.85zm6-8q.825 0 1.412-.588Q11 8.826 11 8q0-.824-.588-1.412A1.93 1.93 0 0 0 9 6q-.825 0-1.412.588A1.93 1.93 0 0 0 7 8q0 .825.588 1.412Q8.175 10 9 10"
      />
    </Svg>
  );
};
Icon.displayName = 'Group';
export const Group = memo<IconProps>(themed(Icon));
