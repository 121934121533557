import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M9 12q-1.65 0-2.825-1.175T5 8t1.175-2.825T9 4t2.825 1.175T13 8t-1.175 2.825T9 12m6 8c-5-.587-7-.587-12 0q-.824 0-1.412-.587A1.93 1.93 0 0 1 1 18v-.8q0-.85.438-1.562.437-.713 1.162-1.088a14.8 14.8 0 0 1 3.15-1.163A13.8 13.8 0 0 1 9 13q1.65 0 3.25.387 1.6.388 3.15 1.163.724.375 1.162 1.087Q17 16.35 17 17.2v.8q0 .824-.587 1.413A1.93 1.93 0 0 1 15 20M3 18c4.706-.532 7.304-.555 12 0v-.8a.973.973 0 0 0-.5-.85q-1.35-.675-2.725-1.012a11.6 11.6 0 0 0-5.55 0Q4.85 15.675 3.5 16.35a.97.97 0 0 0-.5.85zm6-8q.825 0 1.412-.588Q11 8.826 11 8q0-.824-.588-1.412A1.93 1.93 0 0 0 9 6q-.825 0-1.412.588A1.93 1.93 0 0 0 7 8q0 .825.588 1.412Q8.175 10 9 10M19 14a.97.97 0 0 1-.712-.287A.97.97 0 0 1 18 13v-2h-2a.97.97 0 0 1-.713-.287A.97.97 0 0 1 15 10q0-.424.287-.713A.97.97 0 0 1 16 9h2V7q0-.424.288-.713A.97.97 0 0 1 19 6q.424 0 .712.287Q20 6.576 20 7v2h2q.424 0 .712.287Q23 9.576 23 10q0 .424-.288.713A.97.97 0 0 1 22 11h-2v2q0 .424-.288.713A.97.97 0 0 1 19 14"
      />
    </Svg>
  );
};
Icon.displayName = 'Invite';
export const Invite = memo<IconProps>(themed(Icon));
