export type CustomSupportContextProps = {
  trip_uuid: string | null;
  current_leg: string | null;
  current_leg_state: string | null;
  trip_status: string | null;
  trip_list_status: string | null;
  first_leg_type: string | null;
  second_leg_type: string | null;
  first_leg_market: string | null;
  second_leg_market: string | null;
  chat_source: string;
};

export type SupportMeContextProps = {
  platform_source: string | null;
  email?: string | null;
  user_id?: string | null;
  created_at?: string | null;
  name?: string | null;
  phone?: string | null;
  user_hash?: string | null;
  credits?: number | null;
  active_trip_uuid?: string | null;
  is_active_trip_subscription?: boolean | null;
  accepted_invitations?: number | null;
  invite_code?: string | null;
  verification_status?: string | null;
  verification_error?: string | null;
  is_passport_required?: boolean | null;
  is_secondary_driver?: boolean | null;
  has_app_installed?: boolean | null;
  age?: number | null;
  is_young_renter?: boolean | null;
};

export const initialCustomSupport: CustomSupportContextProps = {
  chat_source: '',
  trip_uuid: null,
  current_leg: null,
  current_leg_state: null,
  trip_status: null,
  trip_list_status: null,
  first_leg_type: null,
  second_leg_type: null,
  first_leg_market: null,
  second_leg_market: null,
};

export const initialMeSupport: SupportMeContextProps = {
  platform_source: null,
  email: null,
  user_id: null,
  created_at: null,
  name: null,
  phone: null,
  user_hash: null, // TODO get hash from BE
  credits: null,
  active_trip_uuid: null,
  is_active_trip_subscription: null,
  accepted_invitations: null,
  invite_code: null,
  verification_status: null,
  verification_error: null,
  is_passport_required: null,
  is_secondary_driver: null,
  has_app_installed: null,
  age: null,
  is_young_renter: null,
};
