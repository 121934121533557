import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 0C154.506 0 66.81 59.065 25.402 144.696h461.195C445.19 59.065 357.493 0 256 0"
        fill="#000"
      />
      <Path
        d="M256 512c-101.494 0-189.19-59.065-230.598-144.696h461.195C445.19 452.935 357.493 512 256 512"
        fill="#496e2d"
      />
      <Path
        d="M499.911 178.087H12.089C4.247 202.656 0 228.831 0 256s4.247 53.344 12.089 77.913H499.91C507.753 309.344 512 283.169 512 256s-4.247-53.344-12.089-77.913"
        fill="#a2001d"
      />
      <Path
        d="m335.715 118.301-30.317-13.993L256 216.159l-49.398-111.851-30.317 13.993L237.613 256l-61.328 137.699 30.317 13.993L256 295.841l49.398 111.851 30.317-13.993L274.387 256z"
        fill="#f0f0f0"
      />
      <Path
        d="M276.591 131.489C264.855 118.672 256 111.304 256 111.304s-8.855 7.367-20.591 20.185v249.02C247.145 393.328 256 400.696 256 400.696s8.855-7.367 20.591-20.186z"
        fill="#f0f0f0"
      />
      <Path
        d="M200.348 179.243v153.512c12.402 21.975 27.581 39.987 38.957 51.929V127.315c-11.376 11.941-26.556 29.954-38.957 51.928M311.652 179.243c-12.403-21.975-27.581-39.987-38.957-51.928v257.369c11.375-11.942 26.554-29.954 38.957-51.929z"
        fill="#a2001d"
      />
      <Path
        d="M311.652 179.243v153.512c12.555-22.249 22.261-48.558 22.261-76.755s-9.706-54.507-22.261-76.757M200.348 179.243v153.512c-12.555-22.249-22.261-48.558-22.261-76.755s9.706-54.507 22.261-76.757"
        fill="#000"
      />
    </Svg>
  );
};
Icon.displayName = 'Kenya';
export const Kenya = memo<IconProps>(Icon);
