import { useEffect } from 'react';

export const useInitBranch = () => {
  const initBranch = async () => {
    const branchSDK = (await import('branch-sdk')).default;
    branchSDK.init(process.env.NEXT_PUBLIC_BRANCH_KEY);
  };

  useEffect(() => {
    void initBranch();
  }, []);
};
