import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 511.989 511.989"
      {...otherProps}
    >
      <Circle cx={255.997} cy={255.996} r={255.957} fill="#f0f0f0" />
      <Path
        d="M34.33 128.016c-11.553 20.008-20.075 40.926-25.75 62.233L436.638 437.39c15.615-15.569 29.471-33.41 41.021-53.417z"
        fill="#000"
      />
      <Path
        d="M383.974 34.329C281.558-24.8 155.278-5.093 75.351 74.599l428.058 247.139c29.053-109.062-17.021-228.279-119.435-287.409M128.017 477.66c102.414 59.129 228.697 39.422 308.622-40.269L8.58 190.249C-20.471 299.314 25.601 418.531 128.017 477.66"
        fill="#ffda44"
      />
      <Path
        d="M328.331 228.173c0 39.949-32.386 72.336-72.336 72.336s-72.336-32.386-72.336-72.336c0-12.456 3.149-24.177 8.695-34.41-15.697 16.053-25.388 38.005-25.388 62.231 0 49.169 39.859 89.029 89.029 89.029s89.029-39.859 89.029-89.029c0-24.226-9.69-46.178-25.388-62.231 5.546 10.235 8.695 21.954 8.695 34.41"
        fill="#d80027"
      />
      <Path d="M239.304 144.71h33.386v166.929h-33.386z" fill="#d80027" />
      <Path
        d="M311.638 178.095H200.353c0 7.683 6.693 13.911 14.375 13.911h-.464c0 7.683 6.229 13.911 13.911 13.911 0 7.683 6.229 13.911 13.911 13.911h27.821c7.683 0 13.911-6.228 13.911-13.911 7.683 0 13.911-6.228 13.911-13.911h-.463c7.68-.001 14.372-6.227 14.372-13.911M178.097 322.767h155.8v33.386h-155.8z"
        fill="#d80027"
      />
      <Path
        d="M289.382 333.896h66.771v33.386h-66.771zM155.84 333.896h66.771v33.386H155.84z"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'Brunei';
export const Brunei = memo<IconProps>(Icon);
