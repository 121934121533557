import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#d80027" />
      <Path
        d="m350.787 171.612-18.094 64.571-54.29-10.025-34.946-72.035-94.407 33.501-7.445-20.98-24.7-3.043 18.611 52.449 64.969-16.615 18.463 52.029-44.912 66.282 76.217 65.006-14.446 16.937 9.714 22.914 36.118-42.344-46.874-47.957 35.827-42.003 79.858 5.754 18.189-98.51 21.891 4.043 14.989-19.87zm-94.807 84.362"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'IsleOfMan';
export const IsleOfMan = memo<IconProps>(Icon);
