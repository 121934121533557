import * as React from 'react';
import { Svg, Circle } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#338af3" />
      <Circle cx={200.348} cy={256} r={111.304} fill="#ffda44" />
    </Svg>
  );
};
Icon.displayName = 'Palau';
export const Palau = memo<IconProps>(Icon);
