import * as React from 'react';
import { Svg, Path, Circle } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 511.999 511.999"
      {...otherProps}
    >
      <Path d="M.001 44.522H0v422.955h.001V256.001z" fill="#ff9811" />
      <Circle cx={255.999} cy={255.999} r={255.999} fill="#ffda44" />
      <Path
        d="M200.349 44.522h-88.635a258 258 0 0 0-11.539 8.373L77.914 255.999l22.261 203.104a256 256 0 0 0 11.539 8.373h88.635z"
        fill="#ff9811"
      />
      <Path
        d="M.001 256.001c0 82.745 39.268 156.307 100.174 203.104V52.896C39.269 99.694.001 173.256.001 256.001"
        fill="#6da544"
      />
      <Path d="M411.826 156.064v6.405l.238.036z" fill="#ffda44" />
      <Path
        d="M491.595 322.782h-48.233l-20.405 22.261v44.522h-44.522v-22.261h22.261v-44.522H300.522v27.826h-22.261v-53.031c-6.83-6.114-11.13-14.995-11.13-24.882V128c0-18.441 14.95-33.391 33.391-33.391v205.913h44.522l15.276-12.731a53.25 53.25 0 0 1-4.146-20.66V233.74h-33.391v-66.782h66.782c0-11.13 16.696-22.261 16.696-22.261s16.696 11.13 16.696 22.261v66.783c12.383.147 32.057-.174 53.312 0-5.584-9.76-8.791-21.341-8.791-33.391 0-19.641 8.48-37.299 21.978-49.519C470 107.716 438.954 70.954 400.287 44.522H233.74v422.956h166.548c47.019-32.141 82.768-79.552 100.112-135.09z"
        fill="#a2001d"
      />
    </Svg>
  );
};
Icon.displayName = 'SriLanka';
export const SriLanka = memo<IconProps>(Icon);
