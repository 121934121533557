import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#d80027" />
      <Path d="M89.043 133.565h333.913v244.87H89.043z" fill="#6da544" />
      <Path
        d="M297.227 328.348c-39.956 0-72.348-32.392-72.348-72.348s32.392-72.348 72.348-72.348c12.458 0 24.181 3.15 34.415 8.696-16.055-15.701-38.01-25.392-62.241-25.392-49.176 0-89.043 39.866-89.043 89.043s39.867 89.043 89.043 89.043c24.231 0 46.187-9.691 62.241-25.392-10.234 5.548-21.957 8.698-34.415 8.698"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Maldives';
export const Maldives = memo<IconProps>(Icon);
