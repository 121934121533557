import omitBy from 'lodash/omitBy';
import { useRouter } from 'next/router';

type Query = {
  [key: string]: string | number | boolean | null | undefined;
};

type ReplaceOptions = {
  scroll?: boolean;
  shallow?: boolean;
};

const usePush = () => {
  const router = useRouter();

  return (nextQuery: Query, options: ReplaceOptions = {}) => {
    const queryWithoutUndefinedValues = omitBy(
      { ...router.query, ...nextQuery },
      (queryValue) => queryValue === undefined,
    );

    void router.replace(
      {
        pathname: router.pathname,
        query: queryWithoutUndefinedValues,
      },
      undefined,
      { shallow: true, scroll: false, ...options },
    );
  };
};

export default usePush;
