import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M496.077 345.043C506.368 317.311 512 287.314 512 256s-5.632-61.311-15.923-89.043L256 144.696 15.923 166.957C5.632 194.689 0 224.686 0 256s5.632 61.311 15.923 89.043L256 367.304z"
        fill="#6da544"
      />
      <Path
        d="M496.077 166.957C459.906 69.472 366.071 0 256 0S52.094 69.472 15.923 166.957z"
        fill="#ffda44"
      />
      <Path
        d="M256 512c110.071 0 203.906-69.472 240.077-166.957H15.923C52.094 442.528 145.929 512 256 512"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'Lithuania';
export const Lithuania = memo<IconProps>(Icon);
