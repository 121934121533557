export const primary = {
  primaryA: '#101010',
  primaryB: '#FFFFFF',
};

export const gray = {
  gray900: '#141414',
  gray800: '#2B2B2B',
  gray700: '#424242',
  gray600: '#595959',
  gray500: '#7E7E7E',
  gray400: '#A6A6A6',
  gray300: '#CBCBCB',
  gray200: '#E0E0E0',
  gray100: '#EEEEEE',
  gray50: '#F5F5F5',
};

export const green = {
  green700: '#002809',
  green600: '#024213',
  green500: '#046720',
  green400: '#098A35',
  green300: '#20D14F',
  green200: '#6FDB87',
  green100: '#B8EECB',
  green50: '#DFFCE5',
};

export const blue = {
  blue700: '#102C60',
  blue600: '#174291',
  blue500: '#1D52B4',
  blue400: '#276EF1',
  blue300: '#5B91F5',
  blue200: '#A0BFF8',
  blue100: '#D4E2FC',
  blue50: '#EFF3FE',
};

export const red = {
  red700: '#5A0A00',
  red600: '#870F00',
  red500: '#AB1300',
  red400: '#E11900',
  red300: '#E85C4A',
  red200: '#F1998E',
  red100: '#FED7D2',
  red50: '#FFEFED',
};

export const yellow = {
  yellow700: '#674D1B',
  yellow600: '#996F00',
  yellow500: '#BC8B2C',
  yellow400: '#FFC043',
  yellow300: '#FFCF70',
  yellow200: '#FFE3AC',
  yellow100: '#FFF2D9',
  yellow50: '#FFFAF0',
};

export const black = {
  black100: '#000000',
  black90: '#000000E6',
  black80: '#000000CC',
  black70: '#000000B3',
  black60: '#00000099',
  black50: '#00000080',
  black40: '#00000066',
  black30: '#0000004D',
  black20: '#00000033',
  black10: '#0000001A',
};

export const white = {
  white100: '#FFFFFF',
  white90: '#FFFFFFE6',
  white80: '#FFFFFFCC',
  white70: '#FFFFFFB3',
  white60: '#FFFFFF99',
  white50: '#FFFFFF80',
  white40: '#FFFFFF66',
  white30: '#FFFFFF4D',
  white20: '#FFFFFF33',
  white10: '#FFFFFF1A',
};
