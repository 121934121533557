import { Text, styled, useTheme } from '@tamagui/core';
import { useContext, useEffect, useRef } from 'react';
import { Animated } from 'react-native';
import { parseAnimationProps } from '../../../../utils/animation-parser';
import { Box } from '../../../box';
import { InputContext } from '../../base-input.context';
import { InputState } from '../../base-input.types';

const Label = styled(Text, {
  color: '$contentDisabled',
  fontFamily: '$hankenGrotesk',
  fontWeight: '400',
  tag: 'label',
  variants: {
    focused: {
      true: {
        color: '$contentSecondary',
      },
    },
    disabled: {
      true: {
        color: '$contentDisabled',
      },
    },
  },
});

type InputLabelProps = Readonly<{
  children: string;
  size: 'base' | 'small';
  onPress: (e?: any) => void;
  inputId?: string;
}>;

const AnimatedLabel = Animated.createAnimatedComponent(Label);
const AnimatedBox = Animated.createAnimatedComponent(Box);

export const InputLabel = ({
  size,
  children,
  onPress,
  inputId,
}: InputLabelProps) => {
  const animation = useRef(new Animated.Value(0)).current;
  const { labelId, state, disabled } = useContext(InputContext);

  const theme = useTheme();

  const colorInterpolation: Record<InputState, string[]> = {
    default: [theme.contentSecondary.val, theme.contentSecondary.val],
    error: [theme.contentNegative.val, theme.contentNegative.val],
    success: [theme.contentPositive.val, theme.contentPositive.val],
  };

  useEffect(() => {
    Animated.timing(animation, {
      duration: 200,
      toValue: size === 'base' ? 0 : 1,
      useNativeDriver: false,
    }).start();
  }, [animation, size]);

  return (
    <AnimatedBox
      position="absolute"
      height="100%"
      {...parseAnimationProps({
        paddingTop: animation.interpolate({
          inputRange: [0, 1],
          outputRange: [16, 6],
        }),
      })}
      onPress={onPress}
    >
      <AnimatedLabel
        {...parseAnimationProps({
          fontSize: animation.interpolate({
            inputRange: [0, 1],
            outputRange: [16, 12],
          }),
          lineHeight: animation.interpolate({
            inputRange: [0, 1],
            outputRange: [24, 20],
          }),
          color: animation.interpolate({
            inputRange: [0, 1],
            outputRange: !disabled
              ? colorInterpolation[state]
              : [theme.contentDisabled.val, theme.contentDisabled.val],
          }),
        })}
        id={labelId + ' ' + inputId}
        nativeID={labelId + ' ' + inputId}
        whiteSpace="nowrap"
        for={inputId}
      >
        {children}
      </AnimatedLabel>
    </AnimatedBox>
  );
};
