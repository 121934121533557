import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M256 512c141.384 0 256-114.616 256-256 0-42.963-10.6-83.446-29.302-119L255.699 256.3 375 29.302C339.446 10.6 298.963 0 256 0 114.616 0 0 114.616 0 256c0 70.693 28.654 134.693 74.98 181.02S185.307 512 256 512"
        fill="#0052b4"
      />
      <Path
        d="M482.428 137.279c-11.718-22.304-26.953-43.245-45.707-61.999s-39.693-33.989-61.999-45.706L74.681 437.319z"
        fill="#f0f0f0"
      />
      <Path
        d="m74.681 437.319 407.747-300.04c-11.718-22.304-26.953-43.245-45.707-61.999z"
        fill="#ff9811"
      />
      <Path
        d="m222.308 150.122-31.264 14.706 16.649 30.28-33.95-6.495-4.302 34.296-23.648-25.225-23.648 25.225-4.3-34.296-33.95 6.493 16.647-30.279-31.264-14.705 31.265-14.707-16.648-30.278 33.948 6.493 4.303-34.296 23.647 25.225 23.648-25.225 4.302 34.296 33.951-6.493-16.649 30.279z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'MarshallIslands';
export const MarshallIslands = memo<IconProps>(Icon);
