export enum SupportArticles {
  AdditionalDrivers = 'additionalDrivers',
  HandoffInstructions = 'handoffInstructions',
  MinimumAge = 'minimumAge',
  AirportInstructions = 'airportInstructions',
  IsDepositRequired = 'isDepositRequired',
  SelfPickup = 'selfPickup',
  EditTripPolicies = 'editTripPolicies',
}

export default SupportArticles;
