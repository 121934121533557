import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M12 21q-3.775 0-6.387-2.613Q3 15.776 3 12q0-3.45 2.25-5.987T11 3.05q.625-.075.975.45t-.025 1.1a5.2 5.2 0 0 0-.637 1.375A5.4 5.4 0 0 0 11.1 7.5q0 2.25 1.575 3.825T16.5 12.9q.776 0 1.538-.225t1.362-.625q.525-.35 1.075-.038.55.313.475.988-.35 3.45-2.937 5.725Q15.423 21 12 21m0-2q2.2 0 3.95-1.212a7 7 0 0 0 2.55-3.163q-.5.125-1 .2t-1 .075q-3.075 0-5.238-2.162Q9.1 10.575 9.1 7.5q0-.5.075-1t.2-1a7 7 0 0 0-3.162 2.55Q5 9.8 5 12q0 2.9 2.05 4.95T12 19"
      />
    </Svg>
  );
};
Icon.displayName = 'DarkMode';
export const DarkMode = memo<IconProps>(themed(Icon));
