import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#fcfcfc" />
      <Path
        d="M333.913 211.478v22.261h30.552c-12.75 17.72-29.733 32.186-49.465 41.927-5.062-5.259-12.169-8.536-20.044-8.536-13.275 0-24.371 9.299-27.15 21.738-3.89.342-7.827.523-11.806.523s-7.916-.183-11.806-.523c-2.779-12.438-13.875-21.738-27.15-21.738-7.875 0-14.98 3.277-20.044 8.536-19.732-9.74-36.714-24.208-49.464-41.927h30.551v-22.261h-77.913c0 86.059 69.767 155.826 155.826 155.826 86.061 0 155.826-69.767 155.826-155.826z"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'RapaNui';
export const RapaNui = memo<IconProps>(Icon);
