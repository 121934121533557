import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M244.87 256H512c0-23.107-3.08-45.489-8.819-66.783H244.87zM244.87 122.435h229.556a257.3 257.3 0 0 0-59.069-66.783H244.87zM256 512c60.249 0 115.626-20.824 159.357-55.652H96.643C140.374 491.176 195.751 512 256 512M37.574 389.565h436.852a254.5 254.5 0 0 0 28.755-66.783H8.819a254.5 254.5 0 0 0 28.755 66.783"
        fill="#d80027"
      />
      <Path d="M256 256V0C114.616 0 0 114.616 0 256z" fill="#0052b4" />
      <Path
        d="M170.234 219.13c-34.962 0-63.304-28.343-63.304-63.304s28.343-63.304 63.304-63.304c10.901 0 21.158 2.757 30.113 7.609-14.048-13.737-33.26-22.217-54.461-22.217-43.029 0-77.913 34.883-77.913 77.913s34.884 77.913 77.913 77.913c21.201 0 40.413-8.48 54.461-22.217-8.955 4.85-19.211 7.607-30.113 7.607"
        fill="#ffda44"
      />
      <Path
        d="m188.073 111.304 11.239 23.502 25.381-5.864-11.366 23.439 20.412 16.187-25.414 5.729.071 26.051-20.323-16.298-20.324 16.298.07-26.051-25.414-5.729 20.412-16.187-11.367-23.439 25.383 5.864z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Malaysia';
export const Malaysia = memo<IconProps>(Icon);
