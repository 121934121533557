import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M4.8 4A1.6 1.6 0 0 0 4 6.984v4.882a.8.8 0 0 0 0 .26v4.891a1.6 1.6 0 0 0-.331 2.514A1.6 1.6 0 1 0 5.6 17.016V12.8h5.6v4.216a1.6 1.6 0 1 0 1.6.001V12.8h5.6v6.617a1.6 1.6 0 0 0-.331 2.514A1.6 1.6 0 1 0 20 19.416v-7.282a.8.8 0 0 0 0-.26V6.982a1.6 1.6 0 0 0 .332-2.514A1.6 1.6 0 1 0 18.4 6.984V11.2h-5.6V6.983a1.6 1.6 0 0 0 .332-2.514A1.6 1.6 0 1 0 11.2 6.984V11.2H5.6V6.983a1.6 1.6 0 0 0 .332-2.514A1.6 1.6 0 0 0 4.8 4"
      />
    </Svg>
  );
};
Icon.displayName = 'Gearbox';
export const Gearbox = memo<IconProps>(themed(Icon));
