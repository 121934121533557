import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 0C178.409 0 108.886 34.524 61.939 89.043H450.06C403.114 34.524 333.591 0 256 0M450.061 422.957H61.939C108.886 477.476 178.409 512 256 512s147.114-34.524 194.061-89.043"
        fill="#0052b4"
      />
      <Path
        d="M474.426 122.435H37.574C13.745 161.32 0 207.053 0 256s13.745 94.681 37.574 133.565h436.852C498.255 350.681 512 304.947 512 256s-13.745-94.68-37.574-133.565"
        fill="#d80027"
      />
      <Circle cx={157.496} cy={256} r={98.504} fill="#f0f0f0" />
      <Path
        d="m157.496 157.496 22.101 68.021h71.638l-57.977 42.042 22.274 68.224-58.036-42.242-57.948 42.163 22.186-68.145-57.925-42.042h71.585z"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'NorthKorea';
export const NorthKorea = memo<IconProps>(Icon);
