import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M503.181 189.217C473.805 80.221 374.279 0 256 0S38.195 80.221 8.819 189.217L256 200.348zM8.819 322.783C38.195 431.778 137.721 512 256 512s217.805-80.221 247.181-189.217L256 311.652z"
        fill="#a2001d"
      />
      <Path
        d="M503.181 189.217H8.819C3.08 210.51 0 232.893 0 256s3.08 45.49 8.819 66.783h494.363C508.92 301.49 512 279.107 512 256s-3.08-45.49-8.819-66.783"
        fill="#6da544"
      />
    </Svg>
  );
};
Icon.displayName = 'Transnistria';
export const Transnistria = memo<IconProps>(Icon);
