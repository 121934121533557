import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M74.98 437.02c-99.974-99.974-99.974-262.064 0-362.039s262.065-99.974 362.04 0c0 .035 67.968 230.057-32.007 330.033C305.04 504.989 75.05 437.09 74.98 437.02"
        fill="#ffda44"
      />
      <Path
        d="M74.98 437.02c99.974 99.974 262.065 99.974 362.04 0s99.974-262.064 0-362.039z"
        fill="#ff9811"
      />
      <Path
        d="M400.816 144.695v-44.522H315.48l-6.52 6.519c-23.189 23.189-19.288 50.241-16.44 69.993 2.641 18.323 2.958 25.342-3.856 32.157-6.814 6.813-13.835 6.497-32.156 3.854-19.752-2.847-46.803-6.748-69.992 16.44-23.188 23.188-19.285 50.238-16.435 69.989 2.643 18.321 2.961 25.34-3.851 32.151-6.813 6.811-13.834 6.494-32.151 3.851-7.142-1.031-14.527-2.095-22.411-2.183l-.489 44.52c4.934.055 10.573.867 16.543 1.729 7.068 1.021 15.068 2.175 23.494 2.175 8.732 0 17.918-1.245 26.99-5.162v35.621h66.783v-33.391h-33.391v-22.261h22.261v-33.391h-17.792c.844-10.802-.64-21.146-1.92-30.014-2.643-18.321-2.961-25.34 3.851-32.15 6.814-6.814 13.832-6.498 32.156-3.856 14.393 2.075 32.661 4.697 50.488-2.999v35.627h66.783v-33.391h-33.391V233.74h22.261v-33.391h-17.791c.845-10.803-.638-21.146-1.918-30.017-1.845-12.801-2.557-20.085-.493-25.636h64.723z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Bhutan1';
export const Bhutan1 = memo<IconProps>(Icon);
