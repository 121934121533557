import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M74.981 437.02a257.5 257.5 0 0 0 34.216 28.748l356.569-356.57a257.4 257.4 0 0 0-28.748-34.216 257.5 257.5 0 0 0-34.216-28.747L46.234 402.805a257.6 257.6 0 0 0 28.747 34.215"
        fill="#a2001d"
      />
      <Path
        d="M74.981 74.98c-80.764 80.764-96.268 202.056-46.545 298.398L373.378 28.437C277.036-21.286 155.743-5.78 74.981 74.98"
        fill="#0052b4"
      />
      <Path
        d="M437.019 437.02c80.762-80.762 96.266-202.055 46.545-298.398L138.622 483.564c96.341 49.723 217.634 34.219 298.397-46.544"
        fill="#496e2d"
      />
      <Path
        d="m211.478 144.696-28.686 13.493 15.276 27.782-31.149-5.958-3.948 31.465-21.696-23.143-21.697 23.143-3.946-31.465-31.15 5.957 15.275-27.782-28.685-13.492 28.686-13.494-15.276-27.78 31.149 5.958 3.948-31.467 21.696 23.144 21.697-23.144 3.947 31.467 31.149-5.958-15.274 27.781z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Namibia';
export const Namibia = memo<IconProps>(Icon);
