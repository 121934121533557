import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 511.999 511.999"
      {...otherProps}
    >
      <Circle cx={255.999} cy={255.999} r={255.999} fill="#ffda44" />
      <Path
        d="M74.98 437.019a257.5 257.5 0 0 0 34.217 28.748l356.568-356.568a257.3 257.3 0 0 0-28.748-34.217 257.6 257.6 0 0 0-34.216-28.747L46.235 402.804a257.4 257.4 0 0 0 28.745 34.215"
        fill="#000"
      />
      <Path
        d="M74.981 74.98c-80.763 80.763-96.268 202.056-46.544 298.397l344.94-344.939C277.036-21.285 155.741-5.78 74.981 74.98"
        fill="#6da544"
      />
      <Path
        d="M437.018 437.019c80.762-80.761 96.266-202.054 46.545-298.397L138.62 483.564c96.342 49.722 217.635 34.218 298.398-46.545"
        fill="#d80027"
      />
      <Path
        d="m162.77 302.008 23.898 12.177 18.966-18.965-4.196 26.489 23.897 12.178-26.491 4.195-4.196 26.491-12.176-23.898-26.49 4.196 18.965-18.965zM302.007 162.771l23.897 12.177 18.966-18.965-4.195 26.489 23.897 12.178-26.491 4.196-4.195 26.49-12.177-23.897-26.49 4.195 18.965-18.965z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'SaintKittsAndNevis';
export const SaintKittsAndNevis = memo<IconProps>(Icon);
