import { Me, MeApiResponse } from '@/types/me';

const transformMe = (me?: MeApiResponse): Me | undefined => {
  if (!me) return undefined;

  return {
    credits: me.credits,
    email: me.email,
    firstName: me.first_name,
    lastName: me.last_name,
    name: `${me.first_name ? me.first_name : ''} ${
      me.last_name ? me.last_name : ''
    }`.trim(),
    phone: me.phone,
    uuid: me.uuid,
    activeTrip: {
      isSubscription: me.active_trip_uuid?.is_subscription,
      tripUUID: me.active_trip_uuid?.trip_uuid,
    },
    invite: {
      acceptedInvitations: me.invite?.accepted_invitations,
      code: me.invite?.code,
      bonus: me.invite.bonus,
      discount: me.invite.discount,
    },
    primaryDriver: {
      firstName: me.primary_driver.first_name,
      lastName: me.primary_driver.last_name,
      phone: me.primary_driver.phone,
      status: me.primary_driver.verification_status,
      verification_status: me.primary_driver.verification_status,
      passport_required: me.primary_driver.passport_required,
      verificationError: me.primary_driver.verification_error,
      secondary_drivers_toggle: me.primary_driver.secondary_drivers_toggle,
    },
    featureFlags: {
      ...(me.feature_flags || {}),
      secondary_drivers_toggle: me.primary_driver.secondary_drivers_toggle,
    },
    hasAppInstalled: me.has_app_installed || false,
    age: me.age,
    youngRenter: me.young_renter ?? false,
  };
};

export default transformMe;
