import { useRef, useState } from 'react';
import { NativeSyntheticEvent, TextInputFocusEventData } from 'react-native';
import { BaseInput, InputLabel } from '../base-input';
import { Box } from '../box';
import { InputMask } from '../masked-input';
import { PhoneInputProps } from './phone-input.types';
import { getMask } from './phone-input.utils';

export const PhoneInputBase = ({
  country,
  value,
  placeholder,
  hintText,
  state = 'default',
  label,
  disabled,
  onFocus,
  onBlur,
  onPressFlag,
  FlagIcon,
  ...props
}: PhoneInputProps) => {
  const [focus, setFocus] = useState(false);
  const maskInputRef = useRef<any>(null);

  const handleFocus = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    setFocus(true);
    onFocus?.(e);
    maskInputRef.current?.focus();
  };

  const handleBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    setFocus(false);
    onBlur?.(e);
  };

  const isActive = !!focus || !!value || !!placeholder;

  return (
    <BaseInput state={state}>
      <BaseInput.InputFrame focus={focus} disabled={disabled}>
        <Box marginRight="$tiny" alignSelf="center" onPress={onPressFlag}>
          {FlagIcon}
        </Box>
        <Box width="100%" flex={1}>
          <InputLabel size={isActive ? 'small' : 'base'} onPress={handleFocus}>
            {label}
          </InputLabel>
          <InputMask
            {...props}
            keyboardType="phone-pad"
            disabled={disabled}
            onBlur={handleBlur}
            onFocus={handleFocus}
            options={{ mask: getMask(country) }}
            placeholder={placeholder}
            type="custom"
            value={value}
            refInput={(ref) => (maskInputRef.current = ref)}
          />
        </Box>
      </BaseInput.InputFrame>
      {!!hintText && (
        <BaseInput.HintText state={state}>{hintText}</BaseInput.HintText>
      )}
    </BaseInput>
  );
};
