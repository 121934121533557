import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#ffda44" />
      <Path
        d="M411.826 122.435c-2.296 11.035-3.339 22.788-3.339 34.504 0 67.534 40.259 125.985 97.954 152.298A257 257 0 0 0 512 256C512 114.616 397.384 0 256 0S0 114.616 0 256s114.616 256 256 256c87.682 0 165.058-44.092 211.196-111.304h-322.5v-33.391h341.902a255 255 0 0 0 9.48-22.261H144.696z"
        fill="#0052b4"
      />
      <Path
        d="m188.072 133.565 5.526 17.006h17.88l-14.465 10.511 5.526 17.005-14.467-10.509-14.465 10.509 5.525-17.005-14.467-10.511h17.881zM115.438 256l6.908 21.259h22.35l-18.083 13.135 6.908 21.258-18.083-13.138-18.082 13.138 6.907-21.258-18.082-13.135h22.35zM115.438 77.913l6.908 21.259h22.35l-18.083 13.135 6.908 21.258-18.083-13.138-18.082 13.138 6.907-21.258-18.082-13.135h22.35zM59.786 178.087l6.908 21.259h22.349l-18.082 13.135 6.908 21.258-18.083-13.138-18.082 13.138 6.907-21.258-18.082-13.135h22.349z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Tokelau';
export const Tokelau = memo<IconProps>(Icon);
