import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="m510.497 283.826-.006-.008-.001.008zM510.49 283.826l.001-.008L229.746 1.331a255 255 0 0 0-37.109 6.574C81.898 36.1 0 256 0 256s358.398 239.835 399.285 212.164a257 257 0 0 0 23.493-17.953L256.395 283.826z"
        fill="#0052b4"
      />
      <Path
        d="M445.217 256 196.245 7.029C83.688 33.946 0 135.192 0 256c0 141.384 114.616 256 256 256 53.629 0 103.397-16.502 144.529-44.689L189.217 256z"
        fill="#d80027"
      />
      <Path
        d="m243.472 377.993-31.265-14.706 16.649-30.279-33.95 6.495-4.302-34.298-23.647 25.225-23.647-25.225-4.303 34.298-33.949-6.496 16.649 30.28-31.266 14.706 31.266 14.705-16.649 30.28 33.951-6.494 4.3 34.296 23.648-25.225 23.647 25.225 4.302-34.296 33.949 6.495-16.649-30.279zM222.609 141.983l-22.74-10.695 12.109-22.023-24.693 4.724-3.129-24.946-17.199 18.347-17.199-18.347-3.13 24.946-24.693-4.724 12.11 22.023-22.741 10.695 55.653 11.132z"
        fill="#f0f0f0"
      />
      <Path
        d="M233.739 141.983c0 36.883-29.9 66.783-66.783 66.783s-66.783-29.9-66.783-66.783"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Nepal';
export const Nepal = memo<IconProps>(Icon);
