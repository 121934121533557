import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M166.957 256v240.077C194.689 506.368 224.686 512 256 512c141.384 0 256-114.616 256-256s-345.043 0-345.043 0"
        fill="#6da544"
      />
      <Path
        d="M256 0c-31.314 0-61.311 5.633-89.043 15.923V256H512C512 114.616 397.384 0 256 0"
        fill="#d80027"
      />
    </Svg>
  );
};
Icon.displayName = 'Madagascar';
export const Madagascar = memo<IconProps>(Icon);
