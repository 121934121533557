import { Close, Search } from '@drivekyte/icons';
import { useRef, useState } from 'react';
import { TextInput } from 'react-native';
import { BaseInput, BaseInputProps } from '../base-input';
import { Box } from '../box';

export type SearchInputProps = Omit<BaseInputProps, 'label'> & {
  withoutBorder?: boolean;
};

export const SearchInput = ({
  value,
  placeholder,
  hintText,
  state = 'default',
  disabled,
  onChangeText,
  withoutBorder = false,
  ...props
}: SearchInputProps) => {
  const [focus, setFocus] = useState(false);
  const inputRef = useRef<TextInput>(null);

  const handleFocus = () => {
    setFocus(true);
    inputRef.current?.focus();
  };

  const handleBlur = () => {
    setFocus(false);
  };

  const resetText = () => onChangeText?.('');

  return (
    <BaseInput state={state}>
      <BaseInput.InputFrame
        focus={focus}
        disabled={disabled}
        withoutBorder={withoutBorder}
      >
        <Box marginRight="$tiny" alignSelf="center" onPress={handleFocus}>
          <Search size={20} />
        </Box>
        <Box width="100%" flex={1} paddingBottom="$xTiny">
          <BaseInput.InputField
            {...props}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            onChangeText={onChangeText}
            padding="$none"
            ref={inputRef}
          />
        </Box>
        {!!value && (
          <Box marginLeft="$tiny" alignSelf="center" onPress={resetText}>
            <Close size={20} />
          </Box>
        )}
      </BaseInput.InputFrame>
      {!!hintText && (
        <BaseInput.HintText state={state}>{hintText}</BaseInput.HintText>
      )}
    </BaseInput>
  );
};
