import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M12 12q.825 0 1.412-.588Q14 10.826 14 10t-.588-1.412A1.93 1.93 0 0 0 12 8q-.825 0-1.412.588A1.93 1.93 0 0 0 10 10q0 .825.588 1.412Q11.175 12 12 12m0 7.35q3.05-2.8 4.525-5.088Q18 11.976 18 10.2q0-2.725-1.738-4.462Q14.526 4 12 4T7.738 5.737Q6 7.476 6 10.2q0 1.775 1.475 4.063Q8.95 16.549 12 19.35m0 2.275q-.2 0-.4-.075a1.2 1.2 0 0 1-.35-.2Q7.6 18.125 5.8 15.362 4 12.6 4 10.2q0-3.75 2.412-5.975T12 2t5.587 2.225T20 10.2q0 2.4-1.8 5.163-1.8 2.762-5.45 5.987a1.2 1.2 0 0 1-.35.2q-.2.075-.4.075"
      />
    </Svg>
  );
};
Icon.displayName = 'Location';
export const Location = memo<IconProps>(themed(Icon));
