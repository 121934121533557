import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M7 23q-.824 0-1.412-.587A1.93 1.93 0 0 1 5 21V3q0-.824.588-1.412A1.93 1.93 0 0 1 7 1h10q.824 0 1.413.587Q19 2.176 19 3v18q0 .824-.587 1.413A1.93 1.93 0 0 1 17 23zm0-3v1h10v-1zm0-2h10V6H7zM7 4h10V3H7z"
      />
    </Svg>
  );
};
Icon.displayName = 'Phone';
export const Phone = memo<IconProps>(themed(Icon));
