import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M352.393 200.348H288.13L256 144.696l-32.129 55.652h-64.264L191.741 256l-32.134 55.652h64.264L256 367.304l32.13-55.652h64.263L320.259 256zM295.475 256l-19.736 34.188h-39.475L216.525 256l19.738-34.188h39.475zM256 187.623l7.346 12.724h-14.69zm-59.214 34.189h14.692l-7.346 12.724zm0 68.376 7.347-12.724 7.346 12.724zM256 324.376l-7.345-12.724h14.691zm59.214-34.188h-14.692l7.347-12.724zm-14.692-68.376h14.692l-7.346 12.724zM415.357 55.652H96.643a257.3 257.3 0 0 0-59.069 66.783h436.852a257.4 257.4 0 0 0-59.069-66.783M96.643 456.348h318.713a257.3 257.3 0 0 0 59.069-66.783H37.574a257.4 257.4 0 0 0 59.069 66.783"
        fill="#0052b4"
      />
    </Svg>
  );
};
Icon.displayName = 'Israel';
export const Israel = memo<IconProps>(Icon);
