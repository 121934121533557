import * as React from 'react';
import { Svg, Path, Circle } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M255.999 0a257 257 0 0 0-55.651 6.085l-11.13 249.914 11.13 249.915A257 257 0 0 0 255.999 512c141.385 0 256-114.616 256-256.001C511.998 114.615 397.384 0 255.999 0M66.783 83.571C25.299 129.067.001 189.577.001 255.999S25.3 382.931 66.784 428.429l11.13-172.43z"
        fill="#496e2d"
      />
      <Path
        d="M374.421 193.099c12.595-35.106-1.555-73.287-31.922-92.303a78.2 78.2 0 0 1 16.326 3.932c40.503 14.532 61.557 59.144 47.025 99.646-14.532 40.505-59.144 61.557-99.647 47.025a78 78 0 0 1-15.103-7.344c35.53 4.627 70.726-15.85 83.321-50.956M304.564 91.173l-10.945 14.142-16.829-6.039 10.066 14.778-10.943 14.14 17.165-5.006 10.068 14.776.54-17.872 17.166-5.007-16.829-6.037z"
        fill="#f0f0f0"
      />
      <Path
        d="m334.116 137.252-10.945 14.141-16.83-6.039 10.067 14.778-10.944 14.14 17.165-5.008 10.068 14.779.542-17.873 17.165-5.008-16.83-6.038zM252.46 107.955l-10.945 14.14-16.829-6.037 10.067 14.777-10.944 14.141 17.164-5.007 10.068 14.777.542-17.872 17.165-5.007-16.829-6.039zM251.26 160.737l-10.944 14.141-16.83-6.038 10.068 14.778-10.946 14.14 17.167-5.006 10.067 14.776.541-17.872 17.166-5.007-16.831-6.039zM301.486 178.758l-10.945 14.14-16.829-6.038 10.068 14.778-10.946 14.139 17.167-5.006 10.067 14.778.541-17.874 17.166-5.006-16.831-6.038z"
        fill="#f0f0f0"
      />
      <Path
        d="M66.783 83.571v344.857c34.88 38.254 81.21 65.879 133.565 77.485V6.085c-52.356 11.606-98.684 39.231-133.565 77.486"
        fill="#d80027"
      />
      <Path
        d="m117.753 134.785-22.361-16.658V94.57l22.361-16.657h31.625l22.361 16.657v23.557l-22.361 16.658z"
        fill="#f0f0f0"
      />
      <Path
        d="M133.566 77.913h-15.813L95.392 94.57v11.517h38.174zM133.566 134.785h15.812l22.361-16.658V106.11h-38.173z"
        fill="#ff9811"
      />
      <Path
        d="m117.753 434.085-22.361-16.658v-23.556l22.361-16.658h31.625l22.361 16.658v23.556l-22.361 16.658z"
        fill="#f0f0f0"
      />
      <Path
        d="M133.566 377.213h-15.813l-22.361 16.658v11.517h38.174zM133.566 434.085h15.812l22.361-16.658v-12.016h-38.173z"
        fill="#ff9811"
      />
      <Path
        d="m117.753 284.435-22.361-16.658v-23.556l22.361-16.658h31.625l22.361 16.658v23.556l-22.361 16.658zM172.522 321.085h-9.74v-9.739h-17.115l-12.102-12.102-12.101 12.102h-17.115v9.739H94.61v19.478h9.739v9.739h17.114l12.103 12.103 12.101-12.103h17.115v-9.739h9.74zM172.522 171.434h-9.74v-9.74h-17.115l-12.102-12.102-12.101 12.102h-17.115v9.74H94.61v19.478h9.739v9.739h17.114l12.103 12.104 12.101-12.104h17.115v-9.739h9.74z"
        fill="#496e2d"
      />
      <Path d="M122.434 244.868h22.261v22.261h-22.261z" fill="#d80027" />
      <Circle cx={133.564} cy={181.169} r={11.13} fill="#d80027" />
      <Circle cx={133.564} cy={330.828} r={11.13} fill="#d80027" />
    </Svg>
  );
};
Icon.displayName = 'Turkmenistan';
export const Turkmenistan = memo<IconProps>(Icon);
