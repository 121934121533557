import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M22 6v12q0 .824-.587 1.413A1.93 1.93 0 0 1 20 20H4q-.824 0-1.412-.587A1.93 1.93 0 0 1 2 18V6q0-.824.587-1.412A1.93 1.93 0 0 1 4 4h16q.824 0 1.413.588Q22 5.175 22 6M4 8h16V6H4zm0 4v6h16v-6z"
      />
    </Svg>
  );
};
Icon.displayName = 'CreditCard';
export const CreditCard = memo<IconProps>(themed(Icon));
