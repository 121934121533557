import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M8 19v.5q0 .625-.437 1.063A1.45 1.45 0 0 1 6.5 21q-.625 0-1.062-.437A1.45 1.45 0 0 1 5 19.5v-1.55a4.3 4.3 0 0 1-.725-1.113A3.2 3.2 0 0 1 4 15.5V6q0-2.075 1.925-3.037Q7.85 2 12 2q4.3 0 6.15.925Q20 3.851 20 6v9.5q0 .726-.275 1.337-.275.614-.725 1.113v1.55q0 .625-.437 1.063A1.45 1.45 0 0 1 17.5 21q-.625 0-1.062-.437A1.45 1.45 0 0 1 16 19.5V19zm-2-9h12V7H6zm2.5 6q.624 0 1.063-.437Q10 15.125 10 14.5t-.437-1.062A1.45 1.45 0 0 0 8.5 13q-.625 0-1.062.438A1.45 1.45 0 0 0 7 14.5q0 .624.438 1.063Q7.875 16 8.5 16m7 0q.624 0 1.063-.437Q17 15.125 17 14.5t-.437-1.062A1.45 1.45 0 0 0 15.5 13q-.625 0-1.062.438A1.45 1.45 0 0 0 14 14.5q0 .624.438 1.063.437.437 1.062.437M6.45 5h11.2q-.375-.424-1.612-.713Q14.8 4 12.05 4q-2.675 0-3.913.313Q6.9 4.625 6.45 5M8 17h8q.824 0 1.413-.587Q18 15.825 18 15v-3H6v3q0 .824.588 1.413Q7.175 17 8 17"
      />
    </Svg>
  );
};
Icon.displayName = 'Bus';
export const Bus = memo<IconProps>(themed(Icon));
