import * as React from 'react';
import { Svg, Path, Rect } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M6 19v.525q0 .625-.425 1.05T4.5 21q-.625 0-1.062-.437A1.45 1.45 0 0 1 3 19.5V12l2.1-6q.15-.45.538-.725Q6.025 5 6.5 5h11q.475 0 .863.275.387.275.537.725l2.1 6v7.525q0 .625-.425 1.05T19.5 21q-.625 0-1.062-.437A1.45 1.45 0 0 1 18 19.5V19zm-.2-9h12.4l-1.05-3H6.85zM5 17h14v-5H5z"
      />
      <Rect width={4} height={2} x={4} y={13} fill={`${color}`} rx={1} />
      <Rect width={3} height={2} x={2} y={10} fill={`${color}`} rx={1} />
      <Path fill={`${color}`} d="M19 10h2a1 1 0 1 1 0 2h-2z" />
      <Rect width={4} height={2} x={16} y={13} fill={`${color}`} rx={1} />
    </Svg>
  );
};
Icon.displayName = 'Car';
export const Car = memo<IconProps>(themed(Icon));
