import { styled } from '@tamagui/core';
import { BaseButton, BaseButtonProps, ButtonContext } from '../base-button';
import { Box } from '../box';
import { LoadingIndicator } from '../loading-indicator';

const StyledFrame = styled(BaseButton, {
  context: ButtonContext,
  borderRadius: '$xLarge',
  width: 48,
  height: 48,
  focusStyle: {},
  variants: {
    size: {
      large: {},
      medium: {},
      small: {
        width: 36,
        height: 36,
      },
    },
  },
});

type IconButtonProps = Omit<BaseButtonProps, 'size'> & {
  size: 'small' | 'medium';
};

export const IconButton = ({
  loading,
  children,
  icon,
  size,
  variant,
  state = 'default',
  ...props
}: IconButtonProps) => {
  return (
    <StyledFrame
      size={size}
      variant={variant}
      state={state}
      {...props}
      role="button"
    >
      {loading && (
        <Box position="absolute">
          <LoadingIndicator />
        </Box>
      )}
      <Box opacity={loading ? 0 : 1}>
        {icon && (
          <BaseButton.Icon size={size === 'small' ? 20 : 24}>
            {icon}
          </BaseButton.Icon>
        )}
      </Box>
    </StyledFrame>
  );
};
