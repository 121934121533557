import { Trip } from '@/types/trip';

export const getCurrentLegType = (trip: Trip) => {
  const currentLeg =
    !trip.status || trip.status.includes('delivery') ? 'delivery' : 'return';

  if (trip.status === 'cancelled') return undefined;

  return currentLeg;
};
