import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M509.833 222.609H289.392V2.167C278.461.744 267.317 0 256 0s-22.461.744-33.391 2.167v220.441H2.167C.744 233.539 0 244.683 0 256s.744 22.461 2.167 33.391h220.441v220.442a258.35 258.35 0 0 0 66.783 0V289.392h220.442C511.256 278.461 512 267.317 512 256s-.744-22.461-2.167-33.391"
        fill="#d80027"
      />
      <Path
        d="M378.435 89.043c-20.746 0-38.177 14.189-43.119 33.391l43.119 11.13 43.119-11.13c-4.942-19.201-22.373-33.391-43.119-33.391M333.913 189.217l55.652 11.13v-23.663c15.645-4.028 27.959-16.345 31.986-31.989l-43.116-11.13h-55.652l11.13 33.391z"
        fill="#000"
      />
      <Path
        d="M421.554 122.435h-98.771v44.522h11.13v-22.261h87.638a44.6 44.6 0 0 0 1.406-11.13c0-3.844-.487-7.574-1.403-11.131"
        fill="#acabb1"
      />
      <Path
        d="M144.696 89.043c-20.746 0-38.177 14.189-43.119 33.391l43.119 11.13 43.119-11.13c-4.942-19.201-22.373-33.391-43.119-33.391M100.174 189.217l55.652 11.13v-23.663c15.645-4.028 27.959-16.345 31.986-31.989l-43.116-11.13H89.043l11.13 33.391v22.261z"
        fill="#000"
      />
      <Path
        d="M187.815 122.435H89.043v44.522h11.13v-22.261h87.638a44.6 44.6 0 0 0 1.406-11.13c0-3.844-.486-7.574-1.402-11.131"
        fill="#acabb1"
      />
      <Path
        d="M378.435 311.652c-20.746 0-38.177 14.189-43.119 33.391l43.119 11.13 43.119-11.13c-4.942-19.202-22.373-33.391-43.119-33.391M333.913 411.826l55.652 11.13v-23.663c15.645-4.028 27.959-16.345 31.986-31.989l-43.116-11.13h-55.652l11.13 33.391z"
        fill="#000"
      />
      <Path
        d="M421.554 345.043h-98.771v44.522h11.13v-22.261h87.638a44.6 44.6 0 0 0 1.406-11.13c0-3.843-.487-7.573-1.403-11.131"
        fill="#acabb1"
      />
      <Path
        d="M144.696 311.652c-20.746 0-38.177 14.189-43.119 33.391l43.119 11.13 43.119-11.13c-4.942-19.202-22.373-33.391-43.119-33.391M100.174 411.826l55.652 11.13v-23.663c15.645-4.028 27.959-16.345 31.986-31.989l-43.116-11.13H89.043l11.13 33.391v22.261z"
        fill="#000"
      />
      <Path
        d="M187.815 345.043H89.043v44.522h11.13v-22.261h87.638a44.6 44.6 0 0 0 1.406-11.13c0-3.843-.486-7.573-1.402-11.131"
        fill="#acabb1"
      />
    </Svg>
  );
};
Icon.displayName = 'Sardinia';
export const Sardinia = memo<IconProps>(Icon);
