import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M12 22q-.825 0-1.412-.587A1.93 1.93 0 0 1 10 20h4q0 .824-.588 1.413A1.93 1.93 0 0 1 12 22m-3-3a.97.97 0 0 1-.713-.288A.97.97 0 0 1 8 18q0-.424.287-.712A.97.97 0 0 1 9 17h6q.424 0 .713.288.287.287.287.712 0 .424-.287.712A.97.97 0 0 1 15 19zm-.75-3a7.66 7.66 0 0 1-2.737-2.75A7.3 7.3 0 0 1 4.5 9.5q0-3.125 2.188-5.312Q8.875 2 12 2t5.313 2.188T19.5 9.5q0 2.025-1.012 3.75A7.66 7.66 0 0 1 15.75 16zm.6-2h6.3a5.55 5.55 0 0 0 1.737-1.975A5.4 5.4 0 0 0 17.5 9.5q0-2.3-1.6-3.9T12 4 8.1 5.6 6.5 9.5q0 1.35.612 2.525A5.55 5.55 0 0 0 8.85 14"
      />
    </Svg>
  );
};
Icon.displayName = 'Lightbulb';
export const Lightbulb = memo<IconProps>(themed(Icon));
