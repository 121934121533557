import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="M5.025 21q-.825 0-1.412-.587A1.93 1.93 0 0 1 3.025 19v-8.95q-.575-.525-.887-1.35t-.013-1.8l1.05-2.4a2.2 2.2 0 0 1 .713-1.075A1.8 1.8 0 0 1 5.075 3h13.9q.675 0 1.175.413.501.412.726 1.087l1.05 2.4q.3.975-.013 1.775a3.95 3.95 0 0 1-.887 1.375V19q0 .824-.588 1.413a1.93 1.93 0 0 1-1.412.587zm9.2-12q.675 0 1.026-.463.35-.462.274-1.037l-.55-2.5h-1.95v2.7q0 .525.35.913.35.387.85.387m-4.5 0q.575 0 .938-.387.363-.388.362-.913V5h-1.95l-.55 2.5q-.1.6.263 1.05t.937.45m-4.45 0q.45 0 .788-.325.337-.326.412-.825L7.025 5h-1.95l-1 2.35q-.15.5.163 1.075Q4.55 9 5.275 9m13.5 0q.726 0 1.05-.575t.15-1.075L18.926 5h-1.9l.55 2.85q.075.5.413.825a1.1 1.1 0 0 0 .788.325M5.026 19h14v-8.05a.6.6 0 0 1-.162.05h-.087a2.9 2.9 0 0 1-1.188-.225q-.514-.225-1.012-.725a3.3 3.3 0 0 1-1.026.7q-.574.25-1.225.25-.674 0-1.262-.25a3.3 3.3 0 0 1-1.038-.7 3.1 3.1 0 0 1-.987.7q-.563.25-1.213.25a3.3 3.3 0 0 1-1.312-.25 3.3 3.3 0 0 1-1.038-.7q-.524.525-1.037.737A3 3 0 0 1 5.275 11h-.112a.25.25 0 0 1-.138-.05z"
      />
      <Path
        fill={`${color}`}
        fillRule="evenodd"
        d="M8 14.5A1.5 1.5 0 0 1 9.5 13h5a1.5 1.5 0 0 1 1.5 1.5V19h-2v-4h-4v4H8z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
Icon.displayName = 'Lot';
export const Lot = memo<IconProps>(themed(Icon));
