import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="m144.696 345.043 22.261 151.036C194.689 506.37 224.686 512 256 512c110.07 0 203.906-69.472 240.076-166.957z"
        fill="#000"
      />
      <Path
        d="m144.696 166.957 22.261-151.036C194.689 5.63 224.686 0 256 0c110.07 0 203.906 69.472 240.076 166.957z"
        fill="#6da544"
      />
      <Path
        d="M0 256c0 110.071 69.473 203.906 166.957 240.077V15.923C69.473 52.094 0 145.929 0 256"
        fill="#a2001d"
      />
    </Svg>
  );
};
Icon.displayName = 'UnitedArabEmirates';
export const UnitedArabEmirates = memo<IconProps>(Icon);
