import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M512 256c0 141.384-114.616 256-255.999 256C114.616 512 0 397.384 0 256 0 256.061 256.001.028 256.001 0 397.384 0 512 114.616 512 256"
        fill="#338af3"
      />
      <Path
        d="M122.435 256h86.342l-75.212-89.043-11.13 33.391zM256.001 208.778l-89.044-86.343H256z"
        fill="#0052b4"
      />
      <Path d="M256.001 0h-.043zM255.315 256H256v-.685z" fill="#f0f0f0" />
      <Path
        d="M256.001 133.565V0h-.043C114.592.024 0 114.629 0 256h133.566v-75.211L208.777 256h46.54l.685-.685v-46.537l-75.214-75.213z"
        fill="#f0f0f0"
      />
      <Path
        d="M129.515 33.391a257.2 257.2 0 0 0-96.124 96.124V256h66.783V100.174H256V33.391z"
        fill="#d80027"
      />
      <Path
        d="m256.001 224.519-90.955-90.953h-31.48v.002L255.999 256h.002z"
        fill="#d80027"
      />
      <Path
        d="m279.406 422.957 5.526 17.006h17.881l-14.465 10.51 5.526 17.005-14.468-10.509-14.465 10.509 5.526-17.005L256 439.963h17.88zM421.811 311.652l5.525 17.006h17.881l-14.465 10.511 5.527 17.005-14.468-10.509-14.465 10.509 5.526-17.005-14.468-10.511h17.881zM457.493 144.696l5.526 17.006H480.9l-14.465 10.51 5.525 17.005-14.467-10.509-14.465 10.509 5.526-17.005-14.467-10.51h17.881zM466.333 267.13l5.525 17.007h17.881l-14.465 10.51 5.526 17.005-14.467-10.509-14.465 10.509 5.526-17.005-14.468-10.51h17.881zM305.046 382.959l15.935 8.119 12.643-12.643-2.796 17.659 15.931 8.118-17.662 2.798-2.797 17.661-8.116-15.933-17.662 2.799 12.643-12.643zM305.046 316.177l15.935 8.118 12.643-12.643-2.796 17.66 15.931 8.117-17.662 2.798-2.797 17.661-8.116-15.932-17.662 2.798 12.643-12.643zM360.698 358.984l15.935 8.119 12.643-12.643-2.796 17.659 15.931 8.118-17.662 2.798-2.797 17.661-8.116-15.934-17.662 2.8 12.643-12.643zM360.698 216.003l15.935 8.118 12.643-12.643-2.796 17.66 15.931 8.117-17.662 2.798-2.797 17.661-8.116-15.932-17.662 2.798 12.643-12.643zM414.635 214.289l15.935 8.118 12.643-12.643-2.797 17.66 15.932 8.117-17.662 2.798L435.889 256l-8.116-15.932-17.662 2.798 12.643-12.643z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Tuvalu';
export const Tuvalu = memo<IconProps>(Icon);
