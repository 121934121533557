import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 0C137.721 0 38.195 80.221 8.819 189.217h494.363C473.805 80.221 374.279 0 256 0M256 512c118.279 0 217.805-80.221 247.181-189.217H8.819C38.195 431.778 137.721 512 256 512"
        fill="#a2001d"
      />
    </Svg>
  );
};
Icon.displayName = 'Latvia';
export const Latvia = memo<IconProps>(Icon);
