import React, { useContext } from 'react';
import { Box } from '../box';
import { DialogContext } from './context/dialog-context';

type DialogFooterProps = {
  children: React.ReactNode;
};

const DialogFooter = ({ children }: DialogFooterProps) => {
  const { variant } = useContext(DialogContext);

  const isXSmall = variant === 'xSmall';

  return (
    <Box
      padding="$medium"
      flexDirection={isXSmall ? 'column' : 'row'}
      justifyContent={isXSmall ? 'flex-start' : 'flex-end'}
      gap="$tiny"
    >
      {children}
    </Box>
  );
};

export { DialogFooter };
