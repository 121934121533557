import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M0 256c0 97.035 53.99 181.455 133.565 224.873V31.127C53.99 74.545 0 158.965 0 256"
        fill="#d80027"
      />
      <Path
        d="M496.078 345.043C506.368 317.31 512 287.314 512 256s-5.632-61.311-15.922-89.043H15.924C5.633 194.689 0 224.686 0 256s5.633 61.31 15.924 89.043L256 367.304z"
        fill="#d80027"
      />
      <Path
        d="M256 512c110.072 0 203.906-69.472 240.078-166.957H15.924C52.094 442.528 145.93 512 256 512"
        fill="#6da544"
      />
      <Path
        d="M0 256c0 118.279 80.221 217.805 189.217 247.181V8.819C80.221 38.195 0 137.721 0 256"
        fill="#d80027"
      />
      <Path
        d="m156.617 112.654-23.611-23.611-15.74 15.742-15.74-15.742-23.611 23.611 15.741 15.741-15.743 15.742 23.611 23.611 15.741-15.742 15.741 15.742 23.611-23.611-15.741-15.742z"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Oman';
export const Oman = memo<IconProps>(Icon);
