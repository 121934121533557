import * as React from 'react';
import { Svg, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Circle cx={256} cy={256} r={256} fill="#f0f0f0" />
      <Path
        d="M256 0C114.616 0 0 114.616 0 256c0 31.314 5.633 61.311 15.923 89.043h480.155C506.368 317.311 512 287.314 512 256 512 114.616 397.384 0 256 0"
        fill="#0052b4"
      />
      <Path
        d="M256 0C145.929 0 52.094 69.472 15.923 166.957h480.155C459.906 69.472 366.071 0 256 0"
        fill="#d80027"
      />
      <Path
        d="M66.198 144.696v127.706c0 72.644 94.901 94.903 94.901 94.903s94.9-22.259 94.9-94.903V144.696z"
        fill="#d80027"
      />
      <Path
        d="M105.446 166.957h111.305v-44.522l-22.261 11.13-33.391-33.391-33.392 33.391-22.261-11.13zM233.739 290.183l-72.348-72.348-72.348 72.348 23.611 23.611 48.737-48.737 48.737 48.737z"
        fill="#ffda44"
      />
      <Path
        d="M233.739 222.609h-33.756a22.1 22.1 0 0 0 3.002-11.128c0-12.296-9.967-22.261-22.261-22.261-8.495 0-15.872 4.758-19.625 11.753-3.753-6.994-11.13-11.753-19.625-11.753-12.294 0-22.261 9.965-22.261 22.261a22.1 22.1 0 0 0 3.002 11.128H89.043c0 12.295 10.709 22.261 23.002 22.261h-.741c0 12.295 9.966 22.261 22.261 22.261 0 10.886 7.818 19.93 18.143 21.866l-17.54 39.602a72.1 72.1 0 0 0 27.224 5.315 72.1 72.1 0 0 0 27.224-5.315l-17.54-39.602c10.325-1.934 18.143-10.979 18.143-21.866 12.295 0 22.261-9.966 22.261-22.261h-.741c12.292 0 23-9.967 23-22.261"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Serbia';
export const Serbia = memo<IconProps>(Icon);
