import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path d="M256 0h-.043z" fill="#f0f0f0" />
      <Path
        d="M512 256c0 141.384-114.616 256-256 256S0 397.384 0 256C0 256.061 256 .028 256 0c141.384 0 256 114.616 256 256"
        fill="#ffda44"
      />
      <Path
        d="M256 122.435v86.343l-89.043-86.343zM133.565 256h75.211l-75.211-100.174-11.13 77.913z"
        fill="#0052b4"
      />
      <Path d="M255.315 256H256v-.685z" fill="#f0f0f0" />
      <Path
        d="M256 133.565V0h-.043C114.591.024 0 114.629 0 256h133.565v-75.211L208.776 256h46.54l.685-.685v-46.537l-75.213-75.213z"
        fill="#f0f0f0"
      />
      <Path
        d="m256 224.519-90.955-90.953h-31.48v.002L255.998 256H256z"
        fill="#d80027"
      />
      <Path
        d="M129.515 33.391a256.5 256.5 0 0 0-28.316 18.72h10.504L83.941 72.285l10.602 32.637-27.762-20.17-2.954 2.147a256.8 256.8 0 0 0-30.436 42.616V256h66.783V100.174H256V33.391z"
        fill="#d80027"
      />
      <Path
        d="M109.36 46.152a257 257 0 0 0-8.164 5.959L83.941 72.285l-17.16 12.466-2.979 2.164a257 257 0 0 0-17.651 22.445 47.1 47.1 0 0 0 20.63 4.727c26.126 0 47.304-21.178 47.304-47.304a47.1 47.1 0 0 0-4.725-20.631"
        fill="#0052b4"
      />
      <Path
        d="m63.803 86.915 2.979-2.164 27.762 20.171-10.603-32.637 27.76-20.174h-10.506a257.6 257.6 0 0 0-37.392 34.804M176.942 44.522l5.526 17.005h17.88l-14.465 10.511 5.523 17.005-14.464-10.51-14.465 10.51 5.522-17.005-14.465-10.511h17.881zM66.783 155.826l5.526 17.005h17.88l-14.464 10.512 5.523 17.005-14.465-10.511-14.466 10.511 5.523-17.005-14.464-10.512h17.88z"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Niue';
export const Niue = memo<IconProps>(Icon);
