import { getTokens } from '@tamagui/core';
import { useEffect, useRef } from 'react';
import { Animated } from 'react-native';
import { parseAnimationProps } from '../../../../utils/animation-parser';
import { Box } from '../../../box';

const AnimatedBox = Animated.createAnimatedComponent(Box);

type DotProps = {
  animationDelay?: number;
};

export const Dot = ({ animationDelay = 0 }: DotProps) => {
  const animation = useRef(new Animated.Value(0)).current;
  const tokens = getTokens();

  useEffect(() => {
    Animated.sequence([
      Animated.delay(animationDelay),
      Animated.loop(
        Animated.sequence([
          Animated.timing(animation, {
            toValue: 1,
            duration: 625,
            useNativeDriver: false,
          }),
          Animated.timing(animation, {
            toValue: 0,
            duration: 625,
            useNativeDriver: false,
          }),
        ]),
      ),
    ]).start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnimatedBox
      width={6}
      height={6}
      borderRadius={3}
      margin={2}
      {...parseAnimationProps({
        backgroundColor: animation.interpolate({
          inputRange: [0, 1],
          outputRange: [tokens.color.gray200.val, tokens.color.gray500.val],
        }),
        transform: [
          {
            scale: animation.interpolate({
              inputRange: [0, 1],
              outputRange: [1, 1.1],
            }),
          },
        ],
      })}
    />
  );
};
