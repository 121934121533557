import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import { themed } from '../helpers/themed';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { color, size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        d="m7.4 12.575 4.9 4.9q.3.3.287.7-.012.4-.312.7-.3.275-.7.288a.92.92 0 0 1-.7-.288l-6.6-6.6a.9.9 0 0 1-.213-.325A1.1 1.1 0 0 1 4 11.575q0-.2.063-.375a.9.9 0 0 1 .212-.325l6.6-6.6A.93.93 0 0 1 11.563 4q.412 0 .712.275.3.3.3.712 0 .414-.3.713L7.4 10.575h11.175q.424 0 .713.288.287.287.287.712 0 .424-.287.713a.97.97 0 0 1-.713.287z"
      />
    </Svg>
  );
};
Icon.displayName = 'ArrowLeftAlt';
export const ArrowLeftAlt = memo<IconProps>(themed(Icon));
