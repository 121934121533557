import create from 'zustand';

type EnableAuth0Store = {
  enableAuth0QueryParam: boolean;
  setEnableAuth0QueryParam: (enableAuth0QueryParam: boolean) => void;
};

const useEnableAuth0Store = create<EnableAuth0Store>((set) => ({
  enableAuth0QueryParam: false,
  setEnableAuth0QueryParam: (enableAuth0QueryParam: boolean) =>
    set(() => ({ enableAuth0QueryParam })),
}));

export default useEnableAuth0Store;
