import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="m166.957 233.739 22.261 269.442C210.511 508.92 232.893 512 256 512c141.384 0 256-114.616 256-256z"
        fill="#d80027"
      />
      <Path
        d="m166.957 256 22.26-247.181C210.511 3.08 232.893 0 256 0c141.384 0 256 114.616 256 256z"
        fill="#ffda44"
      />
      <Path
        d="M0 256c0 118.279 80.221 217.805 189.217 247.181V8.819C80.221 38.195 0 137.721 0 256"
        fill="#6da544"
      />
    </Svg>
  );
};
Icon.displayName = 'Benin';
export const Benin = memo<IconProps>(Icon);
