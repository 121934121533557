import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M512 256c0 141.384-114.616 256-256 256S0 397.384 0 256c11.13 0 256-33.391 256-33.391z"
        fill="#d80027"
      />
      <Path
        d="M0 256C0 114.616 114.616 0 256 0s256 114.616 256 256"
        fill="#0052b4"
      />
      <Path
        d="M189.217 178.087c0-18.441-14.95-33.391-33.391-33.391-8.555 0-16.352 3.22-22.261 8.509v-19.64h11.13v-22.261h-11.13v-11.13h-22.261v11.13h-11.13v22.261h11.13v19.64c-5.909-5.289-13.706-8.509-22.261-8.509-18.441 0-33.391 14.95-33.391 33.391 0 9.887 4.301 18.767 11.13 24.882v19.64h111.304v-19.64c6.831-6.115 11.131-14.995 11.131-24.882"
        fill="#ffda44"
      />
    </Svg>
  );
};
Icon.displayName = 'Liechtenstein';
export const Liechtenstein = memo<IconProps>(Icon);
