import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M144.696 486.598C178.352 502.872 216.109 512 256 512s77.648-9.128 111.304-25.402L389.565 256 367.304 25.402C333.648 9.128 295.891 0 256 0s-77.648 9.128-111.304 25.402L122.435 256z"
        fill="#ffda44"
      />
      <Path
        d="M367.304 486.589C452.935 445.183 512 357.493 512 256S452.935 66.817 367.304 25.411z"
        fill="#d80027"
      />
      <Path
        d="M144.696 486.589V25.411C59.065 66.817 0 154.506 0 256s59.065 189.183 144.696 230.589"
        fill="#0052b4"
      />
      <Path
        d="M256 345.043V256h66.783v33.391c0 5.801-11.13 27.057-38.603 44.522-10.405 6.616-21.176 8.744-28.18 11.13M189.217 189.217H256V256h-66.783z"
        fill="#d80027"
      />
      <Path
        d="M289.391 166.957c0-12.295-9.967-22.261-22.261-22.261-4.06 0-7.854 1.104-11.13 3.002a22.13 22.13 0 0 0-11.13-3.002c-12.294 0-22.261 9.966-22.261 22.261h-55.652V278.26c0 41.436 32.906 65.399 58.711 77.821a22.14 22.14 0 0 0-3.059 11.224c0 12.295 9.967 22.261 22.261 22.261 4.06 0 7.854-1.104 11.13-3.002a22.13 22.13 0 0 0 11.13 3.002c12.294 0 22.261-9.966 22.261-22.261 0-4.099-1.128-7.926-3.06-11.224 25.805-12.422 58.712-36.384 58.712-77.821V166.957zm22.261 111.303c0 5.801 0 23.452-27.472 40.918-10.406 6.616-21.176 10.906-28.18 13.291-7.004-2.385-17.774-6.675-28.18-13.291-27.472-17.466-27.472-35.117-27.472-40.918v-77.912h111.304z"
        fill="#ff9811"
      />
    </Svg>
  );
};
Icon.displayName = 'Andorra';
export const Andorra = memo<IconProps>(Icon);
