import * as React from 'react';
import { Svg, Path, Circle } from 'react-native-svg';
import { memo } from 'react';
import type { IconProps } from '../helpers/icon-props';

const Icon = (props: IconProps) => {
  const { size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512" {...otherProps}>
      <Path
        d="M512 256c0 141.384-114.616 256-256 256S0 397.384 0 256L256 0c141.384 0 256 114.616 256 256"
        fill="#d80027"
      />
      <Path d="M256 256V0C114.616 0 0 114.616 0 256z" fill="#0052b4" />
      <Path
        d="m222.609 149.821-31.265 14.708 16.648 30.279-33.949-6.496-4.303 34.297-23.646-25.224-23.648 25.224-4.301-34.297-33.95 6.494 16.648-30.279-31.264-14.706 31.265-14.705-16.649-30.28 33.949 6.495 4.303-34.296 23.647 25.224 23.647-25.224 4.302 34.296 33.95-6.495-16.649 30.281z"
        fill="#f0f0f0"
      />
      <Circle cx={146.098} cy={149.816} r={47.653} fill="#0052b4" />
      <Path
        d="M146.094 175.213c-14.001 0-25.391-11.391-25.391-25.392s11.391-25.392 25.391-25.392c14.001 0 25.392 11.391 25.392 25.392-.001 14.001-11.393 25.392-25.392 25.392"
        fill="#f0f0f0"
      />
    </Svg>
  );
};
Icon.displayName = 'Taiwan';
export const Taiwan = memo<IconProps>(Icon);
